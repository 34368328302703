import React, { useEffect, useState } from "react";
import Select from "react-select";
import { EntityType, ItemType } from "../../../models/Ndtrc";
import {
  SelectOptions,
  SingleSelectOption,
} from "../../../models/SelectOption";
import {
  CategorizationSourceDictionary,
  GroupedOptions,
  SelectGroupedOptions,
  getCategorizationSourceDictionary,
  getCategoryById,
  getItemById,
  useCategorizationOntologyWrapper,
} from "helpers";
import { useTranslation } from "react-i18next";
import { CategorizationOntologyWrapper } from "models/CategorizationOntology/CategorizationOntology";
import { ca } from "date-fns/locale";

/* Select grouping styles/markup */
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles: any = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const multiOptionColors = {
  deprecatedBase: "red",
  deprecatedContrast: "white",
  regularBase: "lightgrey",
  regularContrast: "black",
};

// Shows deprecated options if true
const debugDeprecatedOptions = false;

// Adapted from https://stackoverflow.com/a/58330359

const CategorySelectWidget = (props: {
  value: ItemType[];
  onChange: (categories: ItemType[]) => any;
  entityType: EntityType;
  disabled?: boolean;
}) => {
  console.log("CategorySelectWidget", props);
  const value = props.value;
  const onChange = props.onChange;
  const { t } = useTranslation();
  const categoryOntologyWrapper: CategorizationOntologyWrapper =
    useCategorizationOntologyWrapper();

  const [categoryOntology, setCategoryOntology] = useState(
    undefined as CategorizationSourceDictionary | undefined
  );

  const [categoryOptions, setCategoryOptions] = useState(
    undefined as SelectGroupedOptions[] | undefined
  );

  useEffect(() => {
    setCategoryOntology(
      getCategorizationSourceDictionary(categoryOntologyWrapper)
    );
    setCategoryOptions(
      GroupedOptions(
        props.entityType,
        debugDeprecatedOptions,
        categoryOntologyWrapper
      )
    );
  }, [categoryOntologyWrapper]);

  const filterOptionSearchLogic = (
    { label, value }: { label: string; value: string },
    string: string
  ) => {
    label = label.toLocaleLowerCase();
    string = string.toLocaleLowerCase();

    // default search
    if (label.includes(string) || value.includes(string)) return true;

    // check if a group as the filter string as label
    const groupOptions = categoryOptions?.filter((group) =>
      group.label.toLocaleLowerCase().includes(string)
    );

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        // Check if current option is in group
        const option = groupOption.options.find((opt) => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  return categoryOntology !== undefined ? (
    <Select
      isDisabled={props?.disabled}
      value={value?.map(
        (item: ItemType): SingleSelectOption => ({
          value: item.catid,
          label: getCategoryById(item.catid, categoryOntology) || item.catid,
        })
      )}
      isClearable={!props?.disabled}
      options={categoryOptions}
      formatGroupLabel={formatGroupLabel}
      filterOption={filterOptionSearchLogic}
      isMulti
      menuPlacement={"auto"}
      placeholder={`${t("form.select")}...`}
      styles={{
        menu: (provided, state) => {
          return { ...provided, zIndex: 2 };
        },
        multiValue: (styles, { data }) => {
          const optionValue = data.value;
          const optionData = getItemById(optionValue, categoryOntology);
          const color = optionData?.deprecated
            ? multiOptionColors.deprecatedBase
            : multiOptionColors.regularBase;
          return {
            ...styles,
            backgroundColor: color,
          };
        },
        multiValueLabel: (styles, { data }) => {
          const optionValue = data.value;
          const optionData = getItemById(optionValue, categoryOntology);
          const color = optionData?.deprecated
            ? multiOptionColors.deprecatedContrast
            : multiOptionColors.regularContrast;
          return {
            ...styles,
            color: color,
            paddingRight: props?.disabled ? "6px" : "initial",
          };
        },
        option: debugDeprecatedOptions
          ? (styles, { data }) => {
              const optionValue = data.value;
              const optionData = getItemById(optionValue, categoryOntology);
              const color = optionData?.deprecated
                ? multiOptionColors.deprecatedBase
                : undefined;
              return {
                ...styles,
                color: color,
              };
            }
          : undefined,
        multiValueRemove: (styles, { data }) => {
          if (props?.disabled) {
            return { ...styles, display: "none" };
          }

          const optionValue = data.value;
          const optionData = getItemById(optionValue, categoryOntology);
          const color = optionData?.deprecated
            ? multiOptionColors.deprecatedContrast
            : multiOptionColors.regularContrast;
          return {
            ...styles,
            color: color,
            ":hover": {
              backgroundColor: color,
              color: optionData?.deprecated
                ? multiOptionColors.deprecatedBase
                : multiOptionColors.regularBase,
            },
          };
        },
      }}
      // @ts-ignore // Is correct type
      onChange={(items: SelectOptions) => {
        if (!items) {
          onChange([]);
          return;
        }
        onChange(
          items.map((item): ItemType => {
            return {
              catid: item.value,
            };
          })
        );
      }}
    />
  ) : null;
};

export default CategorySelectWidget;
