import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import PopupButton from "../../buttons/PopupButton/PopupButton";
import { AiFillDelete } from "react-icons/ai";
import classes from "./CoordinatesWidget.module.scss";
import { ButtonType } from "../../buttons";

const containerStyle = {
  width: "400px",
  height: "400px",
};

// Center of NL
const center = {
  lat: 52.1326,
  lng: 5.2913,
};

type LatLng = {
  lat: number;
  lng: number;
};

const CoordinatesWidget = (props: {
  onChange: (position: LatLng | null) => any;
  value: LatLng | null;
}) => {
  const [position, setPosition] = useState<LatLng | null>(null);

  const updatePosition = (newPosition: LatLng | null) => {
    props.onChange(newPosition);
  };

  useEffect(() => {
    setPosition(props.value);
  }, [props.value]);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={7}
      onClick={(e) => {
        if (!e.latLng) return;
        updatePosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      }}
    >
      {position && <Marker position={position} />}
      {position && (
        <PopupButton
          type={ButtonType.Failure}
          className={classes.deleteButton}
          action={() => {
            updatePosition(null);
          }}
        >
          <AiFillDelete />
        </PopupButton>
      )}
    </GoogleMap>
  );
};

export default CoordinatesWidget;
