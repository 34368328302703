import clsx from "clsx";
import { useEffect, useState } from "react";
import { NodeApi, NodeRendererProps, Tree, TreeApi } from "react-arborist";
import styles from "./CategorizationTree.module.scss";
import { MdArrowDropDown, MdArrowRight } from "react-icons/md";
import {
  CategorizationOntology,
  CategorizationOntologyTreeItem,
  treeifyCategorizationOntology,
} from "../../models/CategorizationOntology/CategorizationOntology";
import { useTranslation } from "react-i18next";
import { TbCategoryFilled } from "react-icons/tb";
import { GoDotFill, GoCheck } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { useCategorizationTree } from "./useCategorizationTree";
import { IoMdAddCircle } from "react-icons/io";
import { ca, tr } from "date-fns/locale";

const INDENT_STEP = 15;

export const CategorizationTree = (props: {
  initialValue: CategorizationOntology;
  treeRef: (tree: TreeApi<CategorizationOntologyTreeItem> | null | undefined) => void;
}) => {
  const { t } = useTranslation();

  const [tree, setTree] = useState<TreeApi<CategorizationOntologyTreeItem> | null | undefined>(null);
  const [active ] = useState<CategorizationOntologyTreeItem | null>(null);
  const [followsFocus ] = useState(false);
  const [disableMulti ] = useState(false);
  const [data, controller, addCategory] = useCategorizationTree<CategorizationOntologyTreeItem>(treeifyCategorizationOntology(props.initialValue));

  useEffect(() => {
    props.treeRef(tree);
  }, [tree, props]);

  return (
    <div className={styles.container}>
      <div className={styles.buttonRow}>
      <div
          onClick={() => {
            addCategory("EVENEMENT");
          }}
          className={styles.add}
        >
          <IoMdAddCircle /> 
        </div>
        <div>
        EVENEMENT
        </div>
        <div
          onClick={() => {
            addCategory("LOCATIE");
          }}
          className={styles.add}
        >
          <IoMdAddCircle />
        </div>
        <div>
        LOCATIE
        </div>
        <div
          onClick={() => {
            addCategory("ROUTE");
          }}
          className={styles.add}
        >
          <IoMdAddCircle /> 
        </div>
        <div>
        ROUTE
        </div>
      </div>
        <div className={styles.treeContainer}>
          <Tree
            data={data}
            selectionFollowsFocus={followsFocus}
            disableMultiSelection={disableMulti}
            ref={(t) => setTree(t)}
            openByDefault={true}
            selection={active?.id}
            className={styles.tree}
            rowClassName={styles.row}
            width={500}
            padding={15}
            rowHeight={30}
            indent={INDENT_STEP}
            overscanCount={8}
            {...controller}
          >
            {Node}
          </Tree>
        </div>
      </div>
  );
};

function Node({ node, style, dragHandle }: NodeRendererProps<CategorizationOntologyTreeItem>) {
  const Icon = node.isInternal
    ? TbCategoryFilled
    : node.data.deprecated
    ? RxCross2
    : GoDotFill;
  const indentSize = Number.parseFloat(`${style.paddingLeft || 0}`);

  return (
    <div
      ref={dragHandle}
      style={style}
      className={clsx(
        styles.node,
        node.state,
        node.data.deprecated && styles.isDeprecated,
      )}
    >
      <div className={styles.indentLines}>
        {new Array(indentSize / INDENT_STEP).fill(0).map((_, index) => {
          return <div key={index}></div>;
        })}
      </div>
      <div onClick={() => node.isInternal && node.toggle()}>
        <FolderArrow node={node} />
      </div>
      <Icon className={styles.icon} />{" "}
      <span className={styles.text}>
        {node.isEditing ? <Input node={node} /> : (<span>{node.data.name}{node.data.entityType ? (" (" + node.data.entityType + ")") :  ""}</span>)}
      </span>
    </div>
  );
}

function Input({ node }: { node: NodeApi<CategorizationOntologyTreeItem> }) {
  const [id, setId] = useState(node.data.id);
  const [name, setName] = useState(node.data.name);

  const save = () => {
    node.data.deprecated = false;
    node.id = id;
    node.data.id = id;
    node.submit(name);
  }
  const cancel = () => {
    node.reset();
  }

  return (
    <div>
    <input
      autoFocus
      name="name"
      type="text"
      defaultValue={node.data.name}
      onChange={(e) => setName(e.currentTarget.value)}
      onKeyDown={(e) => {
        if (e.key === "Escape") cancel();
        if (e.key === "Enter") save();
      }}
    />
    <input
      name="id"
      type="text"
      defaultValue={node.data.id}
      onChange={(e) => setId(e.currentTarget.value)}  
      onKeyDown={(e) => {
        if (e.key === "Escape") cancel();
        if (e.key === "Enter") save();
      }}
    />
    </div>
  );
}

function FolderArrow({ node }: { node: NodeApi<CategorizationOntologyTreeItem> }) {
  return (
    <span className={styles.arrow}>
      {node.isInternal ? (
        node.isOpen ? (
          <MdArrowDropDown />
        ) : (
          <MdArrowRight />
        )
      ) : null}
    </span>
  );
}

export default CategorizationTree;
