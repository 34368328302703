import React, { useCallback, useEffect, useState } from "react";
import {
  EntityType,
  InternalEventGroupItemModel,
  InternalEventItemModel,
  InternalItemModel,
  InternalLocationItemModel,
  InternalRouteItemModel,
} from "../../models/Ndtrc/Ndtrc";
import classes from "./ListWidget.module.scss";
import { Lang } from "../../models/Lang/Lang";
import { HiBadgeCheck } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GiCardPickup } from "react-icons/gi";
import assertValidResponse from "../../containers/Settings/components/helpers/assertValidResponse";
import { feedFactoryAxios } from "helpers";
import { AxiosResponse } from "axios";
import { AccountModel } from "../../models/AccountModel/AccountModel";
import CardWidgetHeader from "../CardWidget/CardWidgetHeader/CardWidgetHeader";
import EventListItem from "./ListItem/EventListItem";
import Checkbox from "react-three-state-checkbox";
import LocationListItem from "./ListItem/LocationListItem";
import EventGroupListItem from "./ListItem/EventGroupListItem";
import RouteListItem from "./ListItem/RouteListItem";

type PageSelectionStatus = "none" | "all" | "some";

const ListWidget = <T extends InternalItemModel>(props: {
  items: T[];
  hits: number;
  header?: React.ReactNode;
  lang: Lang;
  activeItemId?: string | null;
  isCheckWidget?: boolean;
  entityType?: EntityType;
  selectedItems: string[];
  onSelectionChange: (
    id: string[],
    isSelected: boolean,
    removeAll?: boolean
  ) => any;
}) => {
  const { t } = useTranslation();
  const [userOrganisations, setUserOrganisations] = useState<string[]>([]);
  const [items, setItems] = useState<any>(<></>);
  const [pageSelectionStatus, setPageSelectionStatus] =
    useState<PageSelectionStatus>("none");

  useEffect(() => {
    const pageCount = props.items.length;
    const selectedCount = props.items.filter((item) => {
      return props.selectedItems.some((i) => {
        if (i === item.id) {
          return true;
        }
      });
    }).length;

    setPageSelectionStatus(
      pageCount === selectedCount
        ? "all"
        : selectedCount === 0
        ? "none"
        : "some"
    );
  }, [props.selectedItems, props.items]);

  useEffect(() => {
    const accountUrl = "/accounts/me";

    feedFactoryAxios
      .get(accountUrl)
      .then((response) =>
        assertValidResponse(response, "No account found for this account id")
      )
      .then((response: AxiosResponse<AccountModel>) => {
        const account = response.data;
        setUserOrganisations(account?.userOrganisation?.split(";") || []);
        //   }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, []);

  useEffect(() => {
    const newItems = props.items.map((item: T, i: number) => {
      const isActiveItem = props.activeItemId === item.id;
      const isSelectedItem = !!props.selectedItems.find(
        (sel) => sel === item.id
      );

      if (item.entitytype === "EVENEMENT") {
        return (
          <EventListItem
            key={i}
            entry={item as InternalEventItemModel}
            lang={props.lang}
            active={isActiveItem}
            userorganisations={userOrganisations}
            eventUrl={`/${t("root.events.slug")}/${item.id}`}
            locationUrl={
              item.location?.locationItem?.trcid
                ? `/${t("root.locations.slug")}/${
                    item.location.locationItem.trcid
                  }`
                : null
            }
            isSelected={isSelectedItem}
            onSelection={(isSelected) => {
              if (!item.id) {
                console.error("Item without id found", item);
                return;
              }
              props.onSelectionChange([item.id], isSelected);
            }}
          />
        );
      } else if (item.entitytype === "LOCATIE") {
        return (
          <LocationListItem
            key={i}
            entry={item as InternalLocationItemModel}
            lang={props.lang}
            active={isActiveItem}
            userorganisations={userOrganisations}
            eventUrl={`/${t("root.locations.slug")}/${item.id}`}
            isSelected={isSelectedItem}
            onSelection={(isSelected: any) => {
              if (!item.id) {
                console.error("Item without id found", item);
                return;
              }
              props.onSelectionChange([item.id], isSelected);
            }}
          />
        );
      } else if (item.entitytype === "EVENEMENTGROEP") {
        return (
          <EventGroupListItem
            key={i}
            entry={item as InternalEventGroupItemModel}
            lang={props.lang}
            active={isActiveItem}
            userorganisations={userOrganisations}
            eventUrl={`/${t("root.eventGroups.slug")}/${item.id}`}
            isSelected={isSelectedItem}
            onSelection={(isSelected: any) => {
              if (!item.id) {
                console.error("Item without id found", item);
                return;
              }
              props.onSelectionChange([item.id], isSelected);
            }}
          />
        );
      } else if (item.entitytype === "ROUTE") {
        return (
          <RouteListItem
            key={i}
            entry={item as InternalRouteItemModel}
            lang={props.lang}
            active={isActiveItem}
            userorganisations={userOrganisations}
            eventUrl={`/${t("root.routes.slug")}/${item.id}`}
            isSelected={isSelectedItem}
            onSelection={(isSelected: any) => {
              if (!item.id) {
                console.error("Item without id found", item);
                return;
              }
              props.onSelectionChange([item.id], isSelected);
            }}
          />
        );
      } else {
        return <div>Unknown entity type</div>;
      }
    });
    setItems(newItems);
  }, [
    props.items,
    props.activeItemId,
    props.selectedItems,
    props.lang,
    props.onSelectionChange,
  ]);

  const checkTextsEvents = useCallback(
    () => (
      <>
        <HiBadgeCheck className={classes.success} />
        <div>
          <p>{t("root.allEventsChecked")}</p>
          <Link to={`/${t("root.events.slug")}`}>
            <p>{t("root.visitEventsMainPage")}</p>
          </Link>
        </div>
      </>
    ),
    [t]
  );
  const checkTextsLocations = useCallback(
    () => (
      <>
        <HiBadgeCheck className={classes.success} />
        <div>
          <p>{t("root.allLocationsChecked")}</p>
          <Link to={`/${t("root.locations.slug")}`}>
            <p>{t("root.visitLocationsMainPage")}</p>
          </Link>
        </div>
      </>
    ),
    [t]
  );
  const noCheckTexts = useCallback(
    () => (
      <>
        <GiCardPickup />
        <div>
          <p>{t("root.noResults")}</p>
        </div>
      </>
    ),
    [t]
  );

  const emptyDisplay =
    !props.entityType || !props.isCheckWidget
      ? noCheckTexts()
      : props.entityType === "EVENEMENT"
      ? checkTextsEvents()
      : checkTextsLocations();

  return (
    <section className={classes.ListWidget}>
      {props.header && (
        <CardWidgetHeader
          header={props.header}
          badge={
            props.selectedItems.length
              ? `${props.selectedItems.length} geselecteerd van ${props.hits}`
              : props.hits
              ? props.hits
              : null
          }
        />
      )}
      {items.length > 0 && (
        <table>
          <thead>
            <tr>
              <th className={classes.checkboxColumn}>
                <div>
                  <Checkbox
                    checked={pageSelectionStatus !== "none"}
                    indeterminate={
                      pageSelectionStatus === "some" ||
                      (pageSelectionStatus === "none" &&
                        props.selectedItems.length > 0)
                    }
                    onChange={() => {
                      if (pageSelectionStatus === "all") {
                        // console.log("SELECT NONE");
                        const ids = props.items.map(
                          (item) => item.id as string
                        );
                        props.onSelectionChange(ids, false, true);
                      } else {
                        // console.log("SELECT ALL");
                        const ids = props.items.map(
                          (item) => item.id as string
                        );
                        props.onSelectionChange(ids, true);
                      }
                    }}
                  />
                </div>
              </th>
              <th className={classes.eventTitleColumn}>{t("event.title")}</th>
              {(props.entityType === "EVENEMENT" ||
                props.entityType === "ROUTE") && <th>{t("event.location")}</th>}
              {props.entityType === "ROUTE" && (
                <th>{t("routeInfo.distanceInKilometers")}</th>
              )}
              {props.entityType === "EVENEMENT" && (
                <th>{t("calendarWidget.date")}</th>
              )}
              {props.entityType === "LOCATIE" && (
                <th>{t("form.location.address")}</th>
              )}
              <th>{t("event.categories")}</th>
              <th>{t("event.keywords")}</th>
              <th>{t("event.markers")}</th>
              <th>{t("root.lastupdated")}</th>
              <th>{t("tuning.validator")}</th>
              <th className={classes.commentColumn}>{t("tuning.comment")}</th>
              <th className={classes.wfStatusColumn}>{t("tuning.wfstatus")}</th>
            </tr>
          </thead>
          <tbody>{items}</tbody>
        </table>
      )}
      {items.length === 0 && (
        <div className={classes.noItems}>{emptyDisplay}</div>
      )}
    </section>
  );
};

export default ListWidget;
