import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

// Source: https://github.com/atlassian/react-beautiful-dnd/issues/128#issuecomment-669083882
export const useDraggableInPortal = () => {
  const self = useRef({}).current;

  useEffect(() => {
    const div = document.createElement("div");
    div.style.position = "absolute";
    div.style.pointerEvents = "none";
    div.style.top = "0";
    div.style.width = "100%";
    div.style.height = "100%";
    // @ts-ignore
    self.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return (render: any) =>
    (provided: any, ...args: any) => {
      const element = render(provided, ...args);
      if (provided.draggableProps.style.position === "fixed") {
        // @ts-ignore
        return createPortal(element, self.elt);
      }
      return element;
    };
};
