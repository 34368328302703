import React, { useEffect, useState } from "react";
import classes from "./UrlsWidget.module.scss";
import MediaPreview from "components/MediaPreview";
import Select from "react-select";
import {
  IntUrlDetails,
  UrlServiceType,
  UrlServiceTypes,
} from "models/Ndtrc";
import { AiFillDelete } from "react-icons/ai";
import { MicrolinkItem } from "models/MicrolinkItem";
import axios from "axios";
import { MdError } from "react-icons/md";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Twemoji } from "helpers";
import { Lang, langMetadata } from "models/Lang";

const microlinkApiKey = "v3HDz9zOik7WHS2Fnh8IWiT3gC9NwjE9yRHhhKdj";

const UrlWidget = (props: {
  urlDetails: IntUrlDetails;
  onUrlDeletion: (url: string) => any;
  onUrlTypeChange: (
    id: string,
    newType: { value: UrlServiceType | null; label: string } | null
  ) => any;
  onUrlLangChange: (
    id: string,
    newLang: { value: Lang | null; label: string } | null
  ) => any;
  changeableLang: boolean;
}) => {
  const [urlPreview, setUrlPreview] = useState<MicrolinkItem | undefined>();
  const urlDetails = props.urlDetails;
  const [queryState, setQueryState] = useState<"loading" | "success" | "fail">(
    "loading"
  );
  const [queryError, setQueryError] = useState<string | null>(null);
  const { t } = useTranslation();

  const langOptions = Object.values(Lang).map((lang: Lang) => {
    return {
      value: lang,
      label: (
        <>
          <Twemoji>{langMetadata[lang]?.flag}</Twemoji> {lang}
        </>
      ),
    };
  });

  useEffect(() => {
    axios
      .get(`https://pro.microlink.io/?url=${urlDetails.url}`, {
        headers: { "x-api-key": microlinkApiKey },
      })
      .then((res) => {
        if (!("data" in res.data)) {
          res.data.data = {};
        }
        setUrlPreview(res.data);
        setQueryState("success");
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status === "fail") {
          // client side issue
          setQueryError(err.response.data.data.url);
        } else if (err.response.data.status === "error") {
          // API side issue
          setQueryError(err.response.data.message);
        }
        console.error(err);
        setQueryState("fail");
      });
  }, [urlDetails]);

  const onUrlChangeWrapper = (
    newType: { value: UrlServiceType | null; label: string } | null
  ) => {
    props.onUrlTypeChange(urlDetails.id, newType);
  };

  const onLangChangeWrapper = (
    newLang: { value: Lang | null; label: string } | null
  ) => {
    props.onUrlLangChange(urlDetails.id, newLang);
  };

  const urlServiceTypesTranslations = UrlServiceTypes.map((type) => {
    const newType = _.cloneDeep(type);
    newType.label = t(type.label);
    return newType;
  });

  if (!urlDetails.id) {
    console.error("UrlsWidget: id is undefined", urlDetails.id);
  }

  return (
    <div className={classes.urlItem} key={urlDetails.id || urlDetails.url}>
      <a href={urlDetails.url} target="_blank" rel="noopener noreferrer">
        <div
          className={`${classes.image} ${
            queryState === "fail" ? classes.error : ""
          } ${queryState === "loading" ? classes.loading : ""}`}
        >
          {(urlPreview?.data.image?.url || urlPreview?.data.logo?.url) && (
            <MediaPreview
              mediaItem={{
                hlink:
                  urlPreview?.data.image?.url || urlPreview?.data.logo?.url,
                mediatype: "photo",
              }}
              coverView
            />
          )}
        </div>

        <div className={classes.content}>
          <div>
            {queryState === "success" && <h4>{urlPreview?.data.title}</h4>}
            {queryState === "fail" && (
              <p className={`form-error-message ${classes.errorBox}`}>
                <MdError /> {queryError}
              </p>
            )}
            {queryState === "loading" && <Loader />}
            <p>{urlPreview?.data.description}</p>
          </div>
          <p className={classes.url}>{urlDetails.url}</p>
          <div className={classes.urlMenu}>
            {!props.changeableLang ? (
              <div className={classes.urlLangPreview}>
                {props.urlDetails.targetLanguage ? (
                  <Twemoji>
                    {langMetadata[props.urlDetails.targetLanguage]?.flag}
                  </Twemoji>
                ) : (
                  "???"
                )}
              </div>
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                className={classes.urlLangSelectWrapper}
              >
                <Select
                  className={classes.urlLangSelect}
                  value={
                    urlDetails.targetLanguage && {
                      value: urlDetails.targetLanguage,
                      label:
                        langOptions.find(
                          (t) => t.value === urlDetails.targetLanguage
                        )?.label || "[???]",
                    }
                  }
                  options={langOptions}
                  menuPlacement={"auto"}
                  styles={{
                    menu: (provided: any, state) => {
                      return { ...provided, zIndex: 2 };
                    },
                  }}
                  onChange={(val) => {
                    onLangChangeWrapper(val as { value: Lang; label: string });
                  }}
                />
              </div>
            )}
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Select
                className={classes.urlServiceTypeSelect}
                value={{
                  value: urlDetails.urlServiceType,
                  label:
                    urlServiceTypesTranslations.find(
                      (t) => t.value === urlDetails.urlServiceType
                    )?.label || "[???]",
                }}
                options={urlServiceTypesTranslations}
                menuPlacement={"auto"}
                styles={{
                  menu: (provided: any, state) => {
                    return { ...provided, zIndex: 2 };
                  },
                }}
                onChange={onUrlChangeWrapper}
              />
            </div>
            <AiFillDelete
              className={`${classes.icon} ${classes.danger}`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.onUrlDeletion(urlDetails.id);
              }}
            />
          </div>
        </div>
      </a>
    </div>
  );
};

export default UrlWidget;
