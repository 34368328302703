export const nl = {
  "language.nl": "Nederlands",
  "language.en": "Engels",
  "language.de": "Duits",
  "language.fr": "Frans",
  "language.es": "Spaans",
  "language.it": "Italiaans",
  "language.pt": "Portugees",
  "language.ja": "Japans",
  "language.zh": "Chinees",
  "language.primary": "primaire taal",
  "login.login": "Inloggen",
  "login.submit": "Inloggen",
  "login.usernameOrEmailLabel": "E-mailadres",
  "login.passwordLabel": "Wachtwoord",
  "login.usernameOrEmailRequired": "E-mailadres is vereist",
  "login.passwordRequired": "Wachtwoord is vereist",
  "login.usernameOrEmailOrPasswordInvalid":
    "De ingevoerde gegevens zijn onjuist.",
  "login.passwordInvalid": "De ingevoerde gegevens zijn onjuist.",
  "login.selectOrganisation": "Selecteer je organisatie",
  "login.switchOrganisation": "Inloggen voor andere organisatie",
  "login.whatIsTheFeedFactory": "Wat is The Feed Factory?",
  "login.eventConnectors": "Event Connectors",
  "login.passwordReset": "Wachtwoord vergeten",
  "login.passwordReset.slug": "wachtwoord-vergeten",
  "login.passwordResetButton": "Verstuur herstellink",
  "login.passwordResetSuccess":
    "Je ontvangt binnen enkele minuten een e-mail met herstellink.",
  "login.submitNewPassword": "Nieuw wachtwoord instellen",
  "login.passwordSetFail":
    "De gebruikte resetlink werkt (niet) meer. Vraag een nieuwe link aan.",
  "login.passwordResetNudge":
    "Je hebt succesvol een nieuw wachtwoord ingesteld. Log nu opnieuw in.",
  "login.emailUnknown": "Het opgegeven e-mailadres is niet bekend.",
  "root.dashboard": "Statistieken",
  "root.dashboard.slug": "dashboard",
  "root.inbox": "Inbox",
  "root.inbox.slug": "inbox",
  "root.events": "Evenementen",
  "root.events.slug": "evenementen",
  "root.eventGroups": "Evenementgroepen",
  "root.eventGroups.slug": "evenementgroepen",
  "root.routes": "Routes",
  "root.routes.slug": "routes",
  "root.feeds": "Bronnen",
  "root.locations": "Locaties",
  "root.locations.slug": "locaties",
  "root.users": "Gebruikers",
  "root.users.slug": "gebruikers",
  "root.new": "nieuw",
  "root.nextItem": "volgende item",
  "root.backToDashboard": "focusmodus afronden",
  "root.stopQueue": "Stop focusmodus",
  "root.settings": "Instellingen",
  "root.settings.slug": "instellingen",
  "root.account": "Organisatie",
  "root.account.slug": "organisatie",
  "root.accounts": "Organisaties",
  "root.accounts.slug": "organisaties",
  "root.myaccount": "Mijn Organisatie",
  "root.myaccount.slug": "mijn-organisatie",
  "root.myprofile": "Mijn Profiel",
  "root.myprofile.slug": "mijn-profiel",
  "root.backToMainUser": "Terug naar hoodfgebruiker",
  "root.logOut": "Uitloggen",
  "root.support": "Support",
  "root.help": "Helpcentrum",
  "root.save": "Opslaan",
  "root.yes": "Ja",
  "root.no": "Nee",
  "root.loggingIntoZendesk": "Aan het inloggen bij Zendesk...",
  "root.makeNewEvent": "Nieuw evenement aanmaken",
  "root.makeNewEventGroup": "Nieuwe evenementgroep aanmaken",
  "root.makeNewLocation": "Nieuwe locatie aanmaken",
  "root.makeNewRoute": "Nieuwe route aanmaken",
  "root.allEventsChecked": "Alle evenementen zijn gecontroleerd",
  "root.allLocationsChecked": "Alle locaties zijn gecontroleerd",
  "root.visitLocationsMainPage": "Klik hier om locaties bij te werken",
  "root.visitEventsMainPage": "Klik hier om evenementen bij te werken",
  "root.userOrganisation": "User organisation",
  "root.userorganisation": "Beheerdersorganisatie",
  "root.noResults": "Geen resultaten voor deze zoekopdracht",
  "root.copyLocationTemplate": "Kopieer gegevens van locatie",
  "root.locationItem": "Locatie item",
  "root.selectLocationItem": "Selecteer bestaande locatie",
  "root.unlinkLocationItem": "Verwijder koppeling met locatie",
  "root.noLocationItemSelected": "Nog geen locatie gekoppeld",
  "locationSelect.promptMinChar": "Type minimaal {{ charCount }} letters...",
  "locationSelect.noOptions": "Geen locaties gevonden voor '{{ inputString }}'",
  "locationSelect.loading": "Aan het laden...",
  "root.personal": "Persoonlijke",
  "root.itemDoesNotExist":
    "Het opgevraagde item bestaat niet of is niet toegankelijk voor je",
  "root.locationMapper": "Locatie-mapper",
  "root.locationmapper.slug": "locatie-mapper",
  "locationMapper.edit": "Bewerken",
  "locationMapper.block": "Blokkeren",
  "locationMapper.unblock": "Toelaten",
  "locationMapper.noLink": "Geen link",
  "locationMapper.onlyShowUnlinkedItems": "Alleen ongelinkte items",
  "locationMapper.noMappingsAvailable": "Geen mappings beschikbaar",
  "form.copyLocationTemplatePrompt":
    "Dit overschrijft de huidige adresgegevens - wil je doorgaan? (website links blijven onaangepast)",
  "form.unlinkLocationItemPrompt":
    "Zeker weten dat je de koppeling wil verwijderen? De ingevulde gegevens blijven verder onaangepast.",
  "event.noDescriptionAvailable": "Geen beschrijving beschikbaar",
  "event.noTitleAvailable": "Geen titel beschikbaar",
  "location.noNameAvailable": "Geen naam beschikbaar",
  "event.what": "Wat",
  "event.where": "Waar",
  "event.when": "Wanneer",
  "event.who": "Wie (contactinformatie)",
  "event.whoWhatWhereWhyHow": "Wie, wat, waar, waarom en hoe?",
  "form.routeInfo": "Route informatie",
  "routeInfo.import": "Importeer",
  "routeInfo.export": "Exporteer",
  "routeInfo.type.route_maker": "Routemaker.nl",
  "routeInfo.type.route_iq": "RouteIQ",
  "routeInfo.type.odp_routes": "ODP Routes",
  "routeInfo.url": "Link naar kaartservice",
  "routeInfo.type": "Kaartservice",
  "routeInfo.distanceInKilometers": "Afstand (km)",
  "routeInfo.durationInMinutes": "Tijd (min)",
  "routeInfo.error":
    "Afstand en/of tijd hebben niet het juiste formaat (bijv. 0.5 of 30)",
  "event.instructions": "Instructies",
  "event.title": "Titel",
  "event.shortdescription": "Korte beschrijving",
  "event.longdescription": "Lange beschrijving",
  "event.location": "Locatie",
  "event.locationName": "Locatie naam",
  "event.locationType": "Locatie type",
  "event.capacity": "Capaciteit",
  "event.online": "Online",
  "event.hybrid": "Hybride",
  "event.physical": "Fysiek",
  "event.location.label": "Naam locatie",
  info: "Contactinformatie",
  "event.eventContactInfo": "Contactinformatie evenement",
  "event.applicantContactInfo": "Contactinformatie aanmelder",
  "event.contactinfo.label": "Organisatie of persoon",
  "event.extra": "Extra's",
  "event.keywords": "Tags",
  "event.markers": "Markeringen",
  "event.routeMarkers": "Hier zichtbaar",
  "event.categories": "Categorieën",
  "event.categories.events": "Evenementen",
  "event.categories.music": "Muziek",
  "event.categories.theater": "Theater",
  "event.categories.exhibitions": "Tentoonstellingen",
  "event.categories.festivals": "Festivals",
  "event.categories.film": "Film",
  "event.categories.sport": "Sport",
  "event.categories.nightlife": "Nachtleven",
  "event.category": "Categorie",
  "event.type.fair": "Beurs",
  "event.type.braderie": "Braderie",
  "event.type.festival": "Festival",
  "event.type.circus": "Circus",
  "event.type.culinary": "Culinair",
  "event.type.culturalFestival": "Cultureel festival",
  "event.type.filmFestival": "Filmfestival",
  "event.type.youthFestival": "Jeugdfestival",
  "event.type.danceFestival": "Dancefestival",
  "event.type.funFair": "Kermis",
  "event.type.lectureDebate": "Lezing/Debat",
  "event.type.musicFestival": "Muziekfestival",
  "event.type.openDay": "Open Dag",
  "event.type.sportsEvent": "Sportevenement",
  "event.type.theatreFestival": "Theaterfestival",
  "event.type.workshop": "Workshop",
  "event.type.danceMusic": "Dance",
  "event.type.jazzWorldMusic": "Jazz of wereldmuziek",
  "event.type.jazz": "Jazz",
  "event.type.worldMusic": "Wereldmuziek",
  "event.type.classicalMusic": "Klassieke muziek",
  "event.type.musicOther": "Muziek overig",
  "event.type.popRock": "Pop en Rock",
  "event.type.RBSoul": "R&B en Soul",
  "event.type.comedy": "Cabaret",
  "event.type.dance": "Dans en Ballet",
  "event.type.youthTheatre": "Jeugdtheater",
  "event.type.musical": "Musical of Show",
  "event.type.excursion": "Excursie",
  "event.type.film": "Film",
  "event.type.market": "Markt",
  "event.type.guidedTour": "Rondleiding",
  "event.type.opera": "Opera",
  "event.type.theatre": "Theater en Toneel",
  "event.type.theatreOther": "Theater overig",
  "event.type.nightlife": "Nachtleven",
  "event.type.outdoor": "Outdooractiviteiten",
  "event.submitEventToRemote": "Opslaan",
  "event.submitEventToRemoteAndPublish": "Publiceren",
  "event.remoteSaveSuccess": "Opgeslagen",
  "event.availablefrom": "Embargodatum",
  "event.availableto": "Vervaldatum",
  "event.availabletoAutomatic": "Vervalt automatisch",
  "event.availableFromTo": "Publicatie",
  "event.calendar.type.singleDates": "Specifieke data",
  "event.calendar.type.patternDates": "Periode(s)",
  "event.calendar.type.none": "Geen informatie",
  "event.calendar.type.alwaysOpen": "Altijd open",
  "event.calendar.type.onRequest": "Op aanvraag",
  "event.calendar.type.openingTimes": "Openingstijden",
  "event.calendar.patternDates.startdate": "Startdatum",
  "event.calendar.patternDates.enddate": "Einddatum",
  "event.noExpiryDate": "Niet automatisch laten vervallen",
  "event.noExpiryDateTooltip":
    "Standaard vervallen evenementen de dag na de laatst opgegeven datum. Deze worden dan automatisch gearchiveerd. Als deze checkbox aanstaat blijft het evenement op actief staan.",
  "event.manualExpiryDate": "Of kies handmatig",
  "calendar.excludeholidays": "Gesloten op zon- en feestdagen",
  "calendar.cancelled": "Geheel geannuleerd",
  "calendar.soldout": "Geheel uitverkocht",
  "calendar.onrequest": "Op aanvraag",
  "calendar.alwaysopen": "Altijd open",
  "calendar.general": "Algemeen",
  "calendar.comment": "Meer uitleg (vrije tekst)",
  "priceWidget.deleteAll": "Verwijder alle tickets",
  "item.priceElements.free": "Gratis?",
  "item.priceElements.freeEvent": "Dit is een gratis evenement",
  "item.priceElements.priceValue.from": "Van",
  "item.priceElements.priceValue.until": "Tot",
  "item.priceElements.priceValue.description": "Omschrijving",
  "item.priceElements.priceValue.comments": "Toelichting",
  "item.priceElements": "Tickets",
  "item.priceElements.priceValue.fromError": "Foutief bedrag in 'van' veld",
  "item.priceElements.priceValue.untilError":
    "Foutief bedrag in 'tot' veld (of lager bedrag 'van' veld)",
  "item.prices": "Prijzen",
  "item.legalowner": "E-mailadres van partner met bewerkingsrechten",
  "item.properties": "Eigenschappen",
  "item.userorganisation": "Account",
  "item.createdby": "Aangemaakt door",
  "item.owner": "Eigenaar",
  "item.lastupdated": "Laatst aangepast",
  "item.externalid": "External ID",
  "item.trcid": "TRC ID",
  "item.lastupdatedby": "Laatst aangepast door",
  "item.trcitemRelations": "Kamers",
  "item.permissions": "Toegang",
  "permissions.none": "Geen toegang",
  "permissions.readers": "Lezers",
  "permissions.reader": "Lezer",
  "permissions.readers.EVENEMENT.desc":
    "Onderstaande accounts hebben leesrechten op dit evenement",
  "permissions.readers.LOCATIE.desc":
    "Onderstaande accounts hebben leesrechten op deze locatie én alle gekoppelde evenementen",
  "permissions.editors": "Bewerkingsrechten",
  "permissions.editor": "Bewerkingsrechten",
  "permissions.editors.EVENEMENT.desc":
    "Onderstaande accounts hebben lees- en schrijfrechten op dit evenement",
  "permissions.editors.LOCATIE.desc":
    "Onderstaande accounts hebben lees- en schrijfrechten op deze locatie én alle gekoppelde evenementen",
  "permissions.managers": "Managers",
  "permissions.manager": "Manager",
  "permissions.managers.EVENEMENT.desc":
    "Onderstaand accounts hebben volledige toegang tot dit evenement; zij mogen ook toegang toewijzen",
  "permissions.managers.LOCATIE.desc":
    "Onderstaand accounts hebben volledige toegang tot deze locatie én alle gekoppelde evenementen; zij mogen ook toegang toewijzen",
  "permissions.global-superadmin": "Superadmin",
  "permissions.global-admin": "Admin",
  "permissions.global-editor": "Redacteur",
  "permissions.local-manager": "Manager",
  "permissions.local-editor": "Bewerker",
  "permissions.local-reader": "Lezer",
  "callout.permit": "Vergunningplichtig",
  "callout.permit.desc":
    "Voor dit evenement is een vergunning verleend. Aanpassingen aan datum en locatie moeten binnen de kaders van de verstrekte vergunning vallen. Voor wijzigingen, neem contact op met de redactie.",
  "callout.permit.desc.override":
    "Voor dit evenement is een vergunning verleend. Aanpassingen aan datum en locatie moeten binnen de kaders van de verstrekte vergunning vallen.",
  "callout.copiedItemBlock": "Gelinkt met hoofditem",
  "callout.copiedItemBlock.desc":
    "Deze sectie kan alleen bewerkt worden in het [hoofditem]({{parentUrl}})",
  "callout.copiedItemWarning": "Dit item is een kopie",
  "callout.copiedItemWarning.desc":
    "Sommige velden kunnen alleen bewerkt worden in het [hoofditem]({{parentUrl}})",
  "callout.childItems": "Hoofditem",
  "callout.childItems.desc": "Dit item heeft kopieën in andere organisaties",
  "address.street": "Straatnaam",
  "address.housenr": "Huisnummer",
  "address.zipcode": "Postcode",
  "address.city": "Plaatsnaam",
  "address.country": "Land",
  "address.neighbourhood": "Buurt",
  "address.district": "Wijk",
  "alias.aliasOnNew": "Sla op om printversie toe te voegen",
  "form.characters": "Karakters",
  "form.phoneNumber": "Telefoonnummer",
  "form.translateAll": "Vertaal alles",
  "form.contactInfo.urls": "Websites",
  "form.promotions": "Promoties",
  "form.removeLanguage": "Deze taal verwijderen?",
  "form.removeAlias": "Deze alias verwijderen?",
  "form.cannotDeleteLastLanguage":
    "Je hebt minstens één taal nodig op dit item.",
  "form.isSaving": "Aan het opslaan...",
  "form.published": "Gepubliceerd",
  "form.restoreDeletedItem": "Herstel verwijderd / afgekeurd item",
  "form.deleteItem": "Verwijderen",
  "form.archiveItem": "Archiveren",
  "form.unarchiveItem": "Dearchiveren",
  "form.retractItem": "Zet item offline",
  "form.retractItemPrompt":
    "Weet je dit zeker? Het item wordt hierdoor gearchiveerd. Deze kan daarna opnieuw ingediend worden voor herpublicatie.",
  "form.assign": "Toewijzen",
  "form.assigned": "Toegewezen",
  "form.toAssignEmail": "E-mail adres van volgende bewerker",
  "form.removePropertyPrompt":
    "Weet je zeker dat je deze categorie wil verwijderen?",
  "form.addPropertyCategory": "Voeg eigenschappencategorie toe",
  "form.addPropertyCategoryPrompt": "Voeg categorie toe...",
  "form.coordinates": "Coördinaten",
  "form.noSupportMultipleCoords":
    "Dit item bevat meer dan één set coördinaten. Dit wordt nog niet ondersteund.",
  "form.revisions": "Vorige versies",
  "form.noRevisions": "Geen vorige versies beschikbaar",
  "form.restore": "Herstel deze versie",
  "form.lookingAtRev":
    "Je kijkt naar een eerdere versie. Herstel deze om verder te bewerken.",
  "form.seeEditAllProperties": "Zie en bewerk (alle) eigenschappen",
  "form.removeRoomPrompt": "Weet je zeker dat je deze kamer wil verwijderen?",
  "form.addRoom": "Voeg kamer toe",
  "form.addRoomPrompt": "Wat is de naam van de kamer?",
  "form.changeRoomNamePrompt": "Wat is de nieuwe naam van de kamer?",
  "form.previewNotAvailable": "Preview niet beschikbaar",
  "form.previewNotAvailableNoProps": "deze kamer heeft geen eigenschappen",
  "form.previewNotAvailableNoVFProps":
    "deze kamer heeft geen Venue Finder eigenschappen",
  "form.hideEditAllProperties": "Verberg eigenschappen",
  "form.noPropsToShow": "Dit item bevat geen eigenschappen",
  "form.suggestAddProps": "voeg toe met bovenstaande knop",
  "form.categoryRequired": "Categorie is vereist",
  "form.nameRequired": "Naam is vereist",
  "form.nameInvalid": "Naam is ongeldig",
  "form.phoneRequired": "Telefoonnummer is vereist",
  "form.phoneInvalid": "Telefoonnummer is ongeldig",
  "form.typeRequired": "Type is vereist",
  "form.eventTitleRequired": "Titel van evenement is vereist",
  "form.websiteRequired": "Website is vereist",
  "form.ticketLinkRequired": "Ticket link is vereist",
  "form.shortDescriptionRequired": "Korte beschrijving is vereist",
  "form.shortDescriptionMaxLength":
    "Korte beschrijving moet minder dan {{maxLength}} karakters zijn",
  "form.longDescriptionRequired": "Lange beschrijving is vereist",
  "form.locationTypeRequired": "Locatie type is vereist",
  "form.calendarRequired": "Minstens één datum en tijd is vereist",
  "form.emailRequired": "E-mailadres is vereist",
  "form.emailInvalid": "Ingevoerde e-mailadres is ongeldig",
  "form.onlyCopyrightFreeImages": "Alleen rechtenvrij beeld",
  "form.imageRequired": "Minstens één afbeelding is vereist",
  "form.copyrightInfoRequired":
    "Elke afbeelding moet voorzien zijn van copyright informatie",
  "form.languageNoProblem": "Geschikt voor niet-Nederlandstaligen",
  "form.languageNoProblem.label": "Geschikt voor niet-Nederlandstaligen",
  "form.addEnglishShortDescription":
    "Ik wil een Engelstalige korte omschrijving toevoegen",
  "form.addEnglishLongDescription":
    "Ik wil een Engelstalige lange omschrijving toevoegen",
  "form.eventDescriptionPlaceholder":
    "Uitleg van het event, achtergrond van de artiest, eventuele line-up (tussen de 75 en 125 woorden).",
  "form.hybridEventInfo":
    "Voeg de website van het evenement toe in het Website gedeelte voor online deelname",
  "form.onlineEventInfo":
    "Voeg de website van het evenement toe in het Website gedeelte",
  "form.eventTypeInfo": "Selecteer eerst het evenement type",
  "form.permissionRequired": "Toestemming is vereist",
  "form.manuallyEnterAddress": "Vul handmatig een adres in",
  "form.selectRegisteredLocation": "Bestaande locatie selecteren",
  "form.saveForNextEvent": "Ja, gegevens bewaren voor volgende evenement",
  "form.noTitleError":
    "Er is geen titel aangegeven in de hoofdtaal. Dit is een vereiste om op te kunnen slaan.",
  "calendarWidget.date": "Datum",
  "calendarWidget.timestart": "Starttijd",
  "calendarWidget.timeend": "Eindtijd",
  "calendarWidget.dateRequiredError": "Datum is vereist",
  "calendarWidget.timestartRequiredError": "Starttijd is vereist",
  "calendarWidget.timeendRequiredError": "Eindtijd is vereist",
  "calendarWidget.dateRangeError": "Fout met datum: {{value}}.",
  "calendarWidget.dateRangeErrorButton": "Reset datum",
  "calendarWidget.deleteAll": "Verwijder alle data",
  "calendarWidget.status": "Status",
  "calendarWidget.time": "Start/eindtijd",
  "calendarWidget.timeAndStatus": "Start/eindtijd en status",
  "calendarWidget.weekDay": "Weekdag",
  "calendarWidget.exceptionHeader": "Uitzonderingen",
  "calendarWidget.exceptionOpens": "Uitzonderlijk open op...",
  "calendarWidget.exceptionCloseds": "Uitzonderlijk gesloten op...",
  "calendarWidget.exceptionSoldouts": "Uitzonderlijk uitverkocht op...",
  "calendarWidget.exceptionCancelleds": "Uitzonderlijk afgelast op...",
  "account.areYouSureYouWantToDeleteThisAccount":
    "Weet je zeker dat je deze organisatie wilt verwijderen?",
  "user.areYouSureYouWantToDeleteThisUser":
    "Weet je zeker dat je deze gebruiker wilt verwijderen?",
  "account.couldNotDeleteAccount": "Kon organisatie niet verwijderen",
  "user.couldNotDeleteUser": "Kon gebruiker niet verwijderen",
  "account.newAccount": "Organisatie aanmaken",
  "account.theme": "Thema",
  "account.colorScheme": "Kleurenpallet",
  "account.primaryColor": "Primaire kleur",
  "account.secondaryColor": "Accentkleur 1 (licht)",
  "account.secondaryColorShade": "Accentkleur 2 (donker)",
  "account.backgrounds": "Achtergrondafbeeldingen",
  "account.categoryOntology": "Cagegory boom",
  "promotion.availablefrom": "Vanaf",
  "promotion.availableto": "Tot",
  "promotion.availablefromto": "Geldigheid",
  "promotion.promotion": "Promotie",
  "promotion.type": "Productinformatie (optioneel)",
  "promotion.shortDescription": "Korte beschrijving",
  "promotion.website": "Website(s)",
  "promotion.amount": "Bedrag",
  "promotion.percentage": "Percentage",
  "promotion.enabled": "Promotie gepubliceerd",
  "promotion.product": "Product",
  "promotion.promotionProducts": "Promotieproducten",
  "promotion.weekdays": "Weekdagen",
  "promotion.allDays": "alle",
  "promotion.nonStandardProduct": "Maak je eigen promotie",
  "promotion.warning.disabled": "Deze promotie is niet gepubliceerd",
  "promotion.warning.expired": "Deze promotie is verlopen",
  "promotion.warning.notYetStarted":
    "Deze promotie is in de toekomst beschikbaar",
  "root.back": "Terug",
  "root.backTo": "Terug naar",
  "root.backToAccounts": "Terug naar organisaties",
  "root.backToAccount": "Terug naar organisatie",
  "root.deleteAccount": "Verwijder organisatie",
  "root.accountSuccessfullyAdded": "Organisatie succesvol toegevoegd",
  "root.id": "Id",
  "root.name": "Naam",
  "root.filter": "Filter",
  "root.filter-userorganisation": "Userorganisation",
  "root.filter-extendedmarker": "Extendedmarker",
  "root.filter-markers": "Markers",
  "root.filter-optimizedImage": "Using optimized image",
  "root.filter-geo-distance-latlon": "LatLon",
  "root.filter-geo-distance": "Lat Lon afstand",
  "root.filter-transformer": "Transformer",
  "root.filter-wfstatus": "Status",
  "root.createdOn": "Aangemaakt op",
  "root.logoUrl": "logo url",
  "root.unknown": "Niet bekend",
  "root.newAccount": "Nieuwe Organisatie",
  "root.createAccount": "Organisatie aanmaken",
  "root.createAnotherAccount": "Nog een organisatie aanmaken",
  "root.lastChange": "Laatste wijziging",
  "root.lastActive": "Laatst actief",
  "root.noActivityKnown": "Geen activiteit bekend",
  "root.saveChanges": "Bewaar wijzigingen",
  "root.saving": "Opslaan",
  "root.copyEventButton": "Maak eigen kopie",
  "root.alreadyInOrganisation": "Evenement is al in eigen organisatie",
  "root.isCopy": "Kopie",
  "root.hasCopies": "Hoofditem",
  "root.changesSaved": "Wijzigingen opgeslagen",
  "root.couldNotSaveChanges": "Kon wijzigingen niet opslaan",
  "root.toUsers": "Naar gebruikers",
  "root.backToUsers": "Terug naar gebruikers",
  "root.userSuccessFullyAdded": "Gebruiker succesvol toegevoegd",
  "root.on": "Aan",
  "root.email": "Email",
  "root.temporaryPassword": "Tijdelijke wachtwoord",
  "root.password": "Wachtwoord",
  "root.newPassword": "Nieuwe wachtwoord",
  "root.repeatNewPassword": "Herhaal nieuwe wachtwoord",
  "root.passwordsDoNotMatch": "Wachtwoorden komen niet overeen",
  "root.changePassword": "Wijzig wachtwoord",
  "root.createAnotherUser": "Nog een gebruiker aanmaken",
  "root.newUser": "Nieuwe gebruiker",
  "root.createUser": "Gebruiker aanmaken",
  "root.role": "Role",
  "root.user": "Gebruiker",
  "root.deleteUser": "Verwijder gebruiker",
  "root.firstName": "Voornaam",
  "root.lastName": "Achternaam",
  "root.cancel": "Annuleer",
  "root.language": "Taal",
  "root.thisAccountDoesNotContainAnyUsers":
    "Er zijn nog geen gebruikers gekoppeld aan dit account",
  "root.noAccountsAvailable": "Er zijn nog geen accounts aangemaakt",
  "root.couldNotCreateAccount": "Kon organisatie niet aanmaken",
  "root.couldNotCreateUser": "Kon gebruiker niet aanmaken",
  "root.couldNotRetrieveUser": "Kon gebruiker niet ophalen",
  "root.invalidEmail": "Incorrect email-adres",
  "root.lastupdated": "Laatst aangepast",
  "root.creationdate": "Aanmaakdatum",
  "root.pageDoesNotExist": "Pagina bestaat niet",
  "root.all": "alle",
  "root.next": "volgende",
  "root.check": "Controleer",
  "root.items": "items",
  "root.editorialFlow.slug": "redactie-flow",
  "root.togo": "te gaan",
  "root.eventsAtThisLocation": "Actieve evenementen op deze locatie",
  "root.loadingLastEvents": "Laatst toegevoegde evenementen aan het inladen...",
  "root.noEventsAvailable": "Geen evenementen op deze locatie ingepland",
  "root.eventsInThisEventGroup": "Evenementen in deze groep",
  "root.eventGroupAddInfo":
    "Let op: Evenement moet eerst zijn aangemaakt voordat deze kan worden gekoppeld aan een evenementgroep",
  "root.noEventsAvailableInEventGroup": "Geen evenementen geselecteerd",
  "root.selectEventGroupItem": "Voeg evenement toe...",
  "root.ago": "geleden",
  "root.by": "door",
  "root.newComment": "Nieuwe notitie...",
  "root.comment": "notitie",
  "root.comments": "notities",
  "root.commentDeletePrompt": "Bevestig verwijdering",
  "root.editingExistingComment": "Geselecteerde notitie bewerken...",
  "root.myItems": "mijn-items",
  "root.locationSelect.desc":
    "Een masker duidt een bezoekerslocatie aan; een aktentas een congres- en vergaderlocatie.",
  "tokens.apiToken": "Account tokens (API)",
  "tokens.accessCount": "Aantal keer gebruikt",
  "tokens.lastSeen": "Laatst gebruikt",
  "tokens.noName": "Geen naam/toelichting",
  "tokens.created": "Aangemaakt op",
  "tokens.generateNew": "Nieuw token aanmaken",
  "tokens.left": "over",
  "tokens.newTokenName": "Naam/toelichting voor nieuw token",
  "tokens.roles": "Rollen",
  "tune.filter": "Filter",
  "tune.sort": "Sorteren",
  "tune.sortedBySearch": "gesorteerd op zoekterm",
  "tune.inbox": "Inbox",
  "tune.addCurrent": "Voeg huidige instellingen als snelfilter toe",
  "tune.export": "Exporteer alle (gefilterde) items",
  "tune.exportSelected": "Exporteer geselecteerde items",
  "tune.exportDefault": "Exporteer alle (gefilterde) items naar Excel",
  "tune.exportDefaultSelected": "Exporteer geselecteerde items naar Excel",
  "tune.viewType": "Ander aanzicht op data",
  "tune.presets": "Snelle filters",
  "tune.typeFilterName": "Kies naam voor snelfilter",
  "tune.removePresetFilterPrompt":
    "Weet je zeker dat je deze snelfilter wil verwijderen?",
  "tune.reset": "Filters uit",
  "tune.confirmExport": "Wil je deze export bevestigen?",
  "form.filter": "Filteropties",
  "form.filterOn": "Filter aan",
  "richText.bold": "Dikgedrukt",
  "richText.italic": "Cursief",
  "richText.underline": "Onderstreept",
  "richText.p": "Gewone tekst (P)",
  "richText.h1": "Grote koptekst (H1)",
  "richText.h2": "Middelgrote koptekst (H2)",
  "richText.h3": "Kleine koptekst (H3)",
  "richText.ul": "Lijst",
  "richText.ol": "Genummerde Lijst",
  "widget.addMedia": "Media toevoegen",
  "media.pasteYoutubeUrl": "Plak YouTube URL",
  "media.pasteVimeoUrl": "Plak Vimeo URL",
  "root.pasteUrl": "Plak URL",
  "form.filename": "Bestandsnaam",
  "form.filesize": "Grootte",
  "form.filedimensions": "Formaat",
  "form.unknownFileSize": "Onbekend",
  "form.description": "Beschrijving",
  "form.description.desc": "Voeg altijd een beschrijving aan de foto toe, zodat deze als alt-tekst gebruikt kan worden",
  "form.copyright.desc": "Voeg ook altijd de naam van de fotograaf of organisatie toe voor de copyright-info",
  // "form.description.desc":
  //   "Voor taalspecifieke descripties, gebruik de velden hieronder.",
  "form.location.address": "Adres",
  "form.selectLanguageHint": "Selecteer de primaire taal hierboven",
  "form.copyright": "Copyright",
  "form.unknownMediaType": "Onbekend mediaformaat",
  "form.noMediaAvailable": "Geen media beschikbaar",
  "form.uploadImage":
    "Sleep hier afbeeldingen naartoe of klik om bestanden te selecteren",
  "form.uploadImageTypes": "(alleen *.jpeg, *.png of *.webp, <10mb)",
  "form.uploadImageJpgType": "(alleen *.jpeg, <10mb)",
  "form.onlyJpg": "Alleen JPG",
  "form.imageNotAvailable": "Afbeelding niet (meer) beschikbaar",
  "form.uploading": "Aan het uploaden...",
  "form.unsupportedFileType":
    "Bestandstype wordt niet ondersteund of bestand is groter dan 10mb.",
  "form.incorrectImageResolution": "Bestand heeft niet de juiste resolutie.",
  "form.incorrectImageOrientation":
    "Bestand heeft niet de juiste oriëntatie (horizontaal/verticaal/vierkant).",
  "form.urlIncorrect": "Link incorrect",
  "form.chooseUrlTypePrompt": "Kies soort link...",
  "form.chooseLangPrompt": "Kies taal...",
  "form.urlAlreadyListed": "Url staat al in lijst; pas deze daar aan",
  "form.incorrectUrl": "Incorrecte url",
  "form.routeInfo.start": "Start locatie",
  "form.routeInfo.end": "Eind locatie",
  "form.image": "Upload afbeelding",
  "form.imageLibrary": "Kies in beeldbank",
  "form.imageLibraryTitle": "Beeldbank",
  "form.imageLibraryDesc": "Klik op de foto die je wil toevoegen",
  "form.youtube": "YouTube",
  "form.vimeo": "Vimeo",
  "form.delete": "Verwijderen",
  "form.select": "Selecteer",
  "form.createPrefix": "Maak",
  "form.createSuffix": "aan",
  "form.latestVersion": "laatste versie",
  "form.noUrlLang": "Geen taal opgegeven voor URL",
  "form.eventLinks": "Evenementen in deze groep",
  "form.addTranslation": "Voeg taal toe...",
  "urlServiceType.general": "Algemeen",
  "urlServiceType.booking": "Reserveren / tickets",
  "urlServiceType.review": "Recensie(s)",
  "urlServiceType.video": "Video",
  "urlServiceType.webshop": "Webshop",
  "urlServiceType.dmo": "DMO",
  "urlServiceType.socialmedia": "Sociale media",
  "urlServiceType.lastminute": "Last-minute",
  "urlServiceType.virtualtour": "Virtuele tour",
  "promotionType.free": "Gratis",
  "promotionType.discount": "Korting",
  "promotionType.allowance": "Toeslag",
  "promotionType.gift": "Cadeau",
  "wfStatus.draft": "Concept",
  "wfStatus.readyforvalidation": "Klaar voor validatie",
  "wfStatus.approved": "Goedgekeurd",
  "wfStatus.rejected": "Afgekeurd",
  "wfStatus.deleted": "Verwijderd",
  "wfStatus.archived": "Gearchiveerd",
  "entityType.EVENEMENT": "evenement",
  "entityType.LOCATIE": "locatie",
  "tuning.search": "Zoeken",
  "tuning.published": "Gepubliceerd",
  "tuning.deleted": "Verwijderd",
  "tuning.updatedSince": "Gewijzigd sinds",
  "tuning.owner": "Eigenaar",
  "tuning.validator": "Toegewezen aan",
  "tuning.wfstatus": "Status",
  "tuning.comment": "Notitie",
  "tuning.applyFilter": "Filter toepassen",
  "tuning.alluserorganisations": "Zoek ook buiten eigen organisatie",
  "tuning.feedId": "Feed",
  "tuning.eventDateRangeStart": "Evenementdata vanaf",
  "tuning.eventDateRangeEnd": "Evenementdata t/m",
  "tuning.eventStart": "Startdatum",
  "tuning.webformMarker": "Toon alleen webformulier items",
  "tuning.types": "Categorieën",
  "tuning.locations": "Locaties",
  "tuning.markers": "Markers",
  "tuning.keywords": "Tags",
  "export.inProgress": "Export aan het uitvoeren...",
  "export.failed": "Export mislukt",
  "selection.tag": "Voeg tag toe aan selectie",
  "selection.marker": "Voeg marker toe aan selectie",
  "selection.delete": "Verwijder selectie",
  "selection.confirmDeletePrompt":
    "Weet je zeker dat je de geselecteerde items wilt verwijderen?",
  "selection.approve": "Selectie goedkeuren",
  "selection.approvePrompt":
    "Weet je zeker dat je de geselecteerde items wilt goedkeuren?",
  "selection.validator": "Wijs selectie toe",
  "selection.category": "Verander categorie(ën) van selectie",
  "selection.batch-workflow": "Start focusmodus",
  "settings.customMarkersTags": "Eigen markers en tags",
  "settings.customMarkersTags.desc":
    "Normaal gesproken worden alle eerder gebruikte markers en tags getoond als opties. Door onderstaande velden in te vullen worden alleen de opgegeven markers en tags getoond.",
  "dashboard.timeSaved": "Tijd bespaard door feeds",
  "dashboard.hours": "uur",
  "dashboard.feed": "Via feed",
  "dashboard.manual": "Handmatig",
  "dashboard.form": "Via formulier",
  "dashboard.total": "Totaal",
  "dashboard.numberOfEvents": "Aantal evenementen",
  "dashboard.itemsLeftToValidate": "Klaar voor validatie",
  "dashboard.items": "evenementen",
  "dashboard.noDataError":
    "Geen data gevonden voor selectie. Probeer breder te zoeken.",
  "dashboard.everything": "Alle organisaties",
  "dashboard.infoBoxText":
    "Welkom op het nieuwe dashboard van The Feed Factory. Hier zie je een\n          overzicht van nieuw-aangemelde evenementen, voortgang van validatie en\n          de tijd die je bespaart door het gebruik van automatisering. We\n          blijven werken aan de kwaliteit van de data en de functionaliteit van\n          het dashboard. Heb je feedback? Laat het ons weten!",
  "dashboard.timeSavedDesc":
    "Berekening is gebaseerd op de tijd die het kost om een evenement handmatig aan te maken en te valideren (5 minuten per evenement).",
  "event.shortdescription.desc":
    "Gebruik platte tekst en geen HTML codes. Maak hier een of enkele korte levendige zinnen met leestekens om de lezer te activeren. Liefst max. 600 karakters.",
  "event.longdescription.desc":
    "Voeg platte tekst toe en gebruik dan de opmaakknoppen in de tool. Plak niet uit Microsoft Word/Outlook om verkeerde opmaak te voorkomen, dit kan wel uit kladblok/notepad. Zet hier een aanvulling op de Korte beschrijving en voeg links achter woorden in de tekst toe i.p.v. URL's. Liefst max. 15000 karakters.",
  "event.categories.desc":
    "De categorie is het soort item. Voeg een of enkele categorieën toe die het meest passend zijn.",
  "form.location.address.desc":
    "Voer alle velden zo volledig mogelijk in. Kies eventueel bij postcode voor de dichtstbijzijnde postcode van parkeerplaats/kruispunt/VVV (bij parken, bossen of festival).",
  "event.availableFromTo.desc":
    "Embargodatum is vanaf wanneer het item getoond mag worden. Vervaldatum is tot wanneer het item getoond mag worden.",
  "form.phoneNumber.desc":
    "Correcte invoer is: +31 20 1234567 (géén 0 tussen haakjes plaatsen) of 020-123 45 67.",
  "event.markers.desc":
    "Met een markering wordt een item aan een thema of groep gekoppeld (bijv. Koningsdag, Holland Festival). Op de site kun je het item onder deze selectie markeringen terugvinden. Druk op ENTER na elk markeringswoord.",
  "event.keywords.desc":
    "Dit zijn trefwoorden waarmee je op de site het item gemakkelijker kan terugvinden. Bijv. strand, zee, zon, water, zwemmen. Druk op ENTER na elk trefwoord.",
  "admin.rootElementSelection.desc":
    "A comma separated list of xpath expressions, allowed values are: 'calendar, contactinfo, trcitemcategories, performers, media, price, trcitemdetails, keywords, markers, languages, location, organiser, reservations, userorganisation, priceinformation'",
  "item.legalowner.desc":
    "Het partneraccount met onderstaand e-mailadres heeft bewerkrechten op deze locatie en alle bijbehorende evenementen.",
};
