import React, { useEffect, useState } from "react";
import classes from "./ItemForm.module.scss";
import LocationCard from "components/CardWidget/cards/LocationCard";
import BooleanInputPopover from "components/popovers/BooleanInputPopover";
import { ButtonType, PopupButton } from "components/buttons";
import { FaCopy, FaUnlink } from "react-icons/fa";
import { MdLocationOff } from "react-icons/md";
import { InternalLocationItemModel } from "models/Ndtrc";
import { Lang } from "models/Lang";
import {
  externalToInternal,
  feedFactoryAxios,
} from "helpers";
import { useTranslation } from "react-i18next";
import { LocationSelect } from "components/LocationSelect";

const LocationSelector = (props: {
  lang: Lang;
  value: string | null;
  myUserOrganisations: string[];
  onSelect?: (newLocation: InternalLocationItemModel | null) => any;
  onCopy?: (newLocation: InternalLocationItemModel | null) => any;
  locationUrl?: string | null;
  minimalCardDisplay?: boolean;
  filterOnUserOrganisations?: string[];
  additionalParams?: { key: string; value: string }[];
}) => {
  const { t } = useTranslation();
  const [locationItem, setLocationItem] =
    useState<InternalLocationItemModel | null>(null);

  const locationItemId = props.value;

  useEffect(() => {
    // Only set location if item type is event
    if (!locationItemId) {
      setLocationItem(null);
      return;
    }
    feedFactoryAxios.get(`/locations/${locationItemId}`).then((res) => {
      const myLocation = externalToInternal(res.data);
      setLocationItem(myLocation as InternalLocationItemModel);
    });
  }, [locationItemId]);

  const removeLocationLink = () => {
    if (!locationItem) {
      return;
    }
    setLocationItem(null);
    if (props.onSelect) {
      props.onSelect(null);
    }
  };

  return (
    <div className={classes.locationItemWrapper}>
      {!locationItem && (
        <LocationSelect
          lang={props.lang}
          filterOnUserOrganisations={props.filterOnUserOrganisations}
          value={locationItem}
          onChange={(myLocation) => {
            setLocationItem(myLocation);
            if (props.onSelect) {
              props.onSelect(myLocation);
            }
          }}
          additionalParams={props.additionalParams || undefined}
        />
      )}
      {locationItem && (
        <LocationCard
          className={classes.locationCard}
          lang={props.lang}
          entry={locationItem}
          openInNewTab
          userorganisations={props.myUserOrganisations}
          locationUrl={props.locationUrl}
          minimalDisplay={props.minimalCardDisplay}
        />
      )}
      {locationItem ? (
        <div className={classes.locationItemButtonsWrapper}>
          <BooleanInputPopover
            onBooleanSubmit={(deletion) => {
              if (deletion) removeLocationLink();
            }}
            prompt={t("form.unlinkLocationItemPrompt")}
            type={"danger"}
          >
            {(setPopover: any, onButtonClickHandler: any) => (
              <PopupButton
                type={ButtonType.Failure}
                action={onButtonClickHandler}
                className={classes.locationItemsButton}
              >
                <FaUnlink />{" "}
                <span className={classes.collapsable}>
                  {t("root.unlinkLocationItem")}
                </span>
                <span ref={setPopover} />
              </PopupButton>
            )}
          </BooleanInputPopover>
          {props.onCopy && (
            <BooleanInputPopover
              onBooleanSubmit={(copy) => {
                if (copy) props.onCopy && props.onCopy(locationItem);
              }}
              prompt={t("form.copyLocationTemplatePrompt")}
              type={"danger"}
            >
              {(setPopover: any, onButtonClickHandler: any) => (
                <PopupButton
                  action={() => {
                    // Commit 89015beb9a69d8d9fcb7a953bbbcfd412841d630 contains code to check if copy is novel
                    // It then prevents any dialogue opening if then items are identical
                    // Not worth the refactoring, however
                    onButtonClickHandler();
                  }}
                  className={classes.locationItemsButton}
                >
                  <FaCopy />
                  <span className={classes.collapsable}>
                    {t("root.copyLocationTemplate")}
                  </span>
                  <span ref={setPopover} />
                </PopupButton>
              )}
            </BooleanInputPopover>
          )}
        </div>
      ) : (
        <div className={classes.noLocationItemSelectedWrapper}>
          <div>
            <MdLocationOff />
            {t("root.noLocationItemSelected")}
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationSelector;
