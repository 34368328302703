import React, { ReactNode } from "react";
import classes from "./HoverDescNode.module.scss";
import { MdContactMail } from "react-icons/md";

export const HoverDescNode = (props: { children: ReactNode }) => {
  return (
    <>
      <div className={`${classes.HoverDesc}`}>
        <MdContactMail />
        <div className={classes.description}>{props.children}</div>
      </div>
    </>
  );
};
