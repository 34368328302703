import React from "react";
import classes from "./RoomPreview.module.scss";
import { ExtProperty } from "../../../../models/Ndtrc/Ndtrc";
import { AiFillWarning } from "react-icons/ai";
import { useTranslation } from "react-i18next";

// Source: https://www.iamsterdam.com/en/business/meetings/venues/venue-finder
const RoomPreview = (props: { properties: ExtProperty[] }) => {
  const { t } = useTranslation();

  if (!props.properties || props.properties.length < 1) {
    return (
      <div className={classes.noPreview}>
        <AiFillWarning />
        <span>
          <strong>{t("form.previewNotAvailable")}:</strong>{" "}
          {t("form.previewNotAvailableNoProps")}
        </span>
      </div>
    );
  }

  const preview = {
    capTheater: props.properties.find((prop) => prop.catid === "5.22")?.value,
    capU: props.properties.find((prop) => prop.catid === "5.24")?.value,
    capSchool: props.properties.find((prop) => prop.catid === "5.25")?.value,
    capDiner: props.properties.find((prop) => prop.catid === "5.20")?.value,
    capBlok: props.properties.find((prop) => prop.catid === "5.30")?.value,
    capRec: props.properties.find((prop) => prop.catid === "5.19")?.value,
    sunLight: props.properties.find((prop) => prop.catid === "44.4")?.value,
    oppervlak: props.properties.find((prop) => prop.catid === "5.1")?.value,
    hoogte: props.properties.find((prop) => prop.catid === "5.29")?.value,
    lengte: props.properties.find((prop) => prop.catid === "5.27")?.value,
    breedte: props.properties.find((prop) => prop.catid === "5.28")?.value,
  };

  if (!Object.values(preview).find((prop) => prop)) {
    return (
      <div className={classes.noPreview}>
        <AiFillWarning />
        <span>
          <strong>{t("form.previewNotAvailable")}:</strong>{" "}
          {t("form.previewNotAvailableNoVFProps")}
        </span>
      </div>
    );
  }

  return (
    <table cellPadding="0" cellSpacing="0" className={classes.VenueTable}>
      <thead>
        <tr>
          {preview.capTheater && (
            <th>
              <img
                src="/venue-finder-icons/icon-5.22.webp"
                alt="Capaciteit in theater opstelling"
                title="Capaciteit in theater opstelling"
              />
            </th>
          )}
          {preview.capU && (
            <th>
              <img
                src="/venue-finder-icons/icon-5.24.webp"
                alt="Capaciteit in Uvorm"
                title="Capaciteit in Uvorm"
              />
            </th>
          )}
          {preview.capSchool && (
            <th>
              <img
                src="/venue-finder-icons/icon-5.25.webp"
                alt="Capaciteit in school opstelling"
                title="Capaciteit in school opstelling"
              />
            </th>
          )}
          {preview.capDiner && (
            <th>
              <img
                src="/venue-finder-icons/icon-5.20.webp"
                alt="Capaciteit zittend diner"
                title="Capaciteit zittend diner"
              />
            </th>
          )}
          {preview.capBlok && (
            <th>
              <img
                src="/venue-finder-icons/icon-5.30.webp"
                alt="Capaciteit in blokopstelling"
                title="Capaciteit in blokopstelling"
              />
            </th>
          )}
          {preview.capRec && (
            <th>
              <img
                src="/venue-finder-icons/icon-5.19.webp"
                alt="Capaciteit in receptie opstelling"
                title="Capaciteit in receptie opstelling"
              />
            </th>
          )}
          {preview.sunLight && (
            <th>
              <img
                src="/venue-finder-icons/icon-44.4.webp"
                alt="Daglicht in ruimte"
                title="Daglicht in ruimte"
              />
            </th>
          )}
          {preview.oppervlak && (
            <th>
              <img
                src="/venue-finder-icons/icon-5.1.webp"
                alt="Oppervlakte"
                title="Oppervlakte"
              />
            </th>
          )}
          {preview.hoogte && (
            <th>
              <img
                src="/venue-finder-icons/icon-5.29.webp"
                alt="Hoogte"
                title="Hoogte"
              />
            </th>
          )}
          {preview.lengte && (
            <th>
              <img
                src="/venue-finder-icons/icon-5.27.webp"
                alt="Lengte"
                title="Lengte"
              />
            </th>
          )}
          {preview.breedte && (
            <th>
              <img
                src="/venue-finder-icons/icon-5.28.webp"
                alt="Breedte"
                title="Breedte"
              />
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        <tr>
          {preview.capTheater && <td>{preview.capTheater}</td>}
          {preview.capU && <td>{preview.capU}</td>}
          {preview.capSchool && <td>{preview.capSchool}</td>}
          {preview.capDiner && <td>{preview.capDiner}</td>}
          {preview.capBlok && <td>{preview.capBlok}</td>}
          {preview.capRec && <td>{preview.capRec}</td>}
          {preview.sunLight && <td>+</td>}
          {preview.oppervlak && <td>{preview.oppervlak}</td>}
          {preview.hoogte && <td>{preview.hoogte}</td>}
          {preview.lengte && <td>{preview.lengte}</td>}
          {preview.breedte && <td>{preview.breedte}</td>}
        </tr>
      </tbody>
    </table>
  );
};

export default RoomPreview;
