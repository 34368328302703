export interface CategorizationOntologyWrapper {
  categorizations: CategorizationOntology;
}

export type CategorizationOntology = CategorizationOntologyItem[];

export interface CategorizationOntologyItem {
  categorizationId: string;
  categorization: string;
  cnetID: string;
  deprecated?: boolean;
  entityType?: string;
  child?: CategorizationOntologyItem[];
}

export interface CategorizationOntologyTreeItem {
  categorizationId: string;
  name: string;
  id: string;
  deprecated?: boolean;
  entityType?: string;
  children?: CategorizationOntologyTreeItem[];
}

// Uses library-specific categorization ontology
// Changes: cnetID > id, categorization > name, child > children
export const treeifyCategorizationOntology = (
  categorizationOntologyArray: CategorizationOntology,
  isDeprecated?: boolean,
): CategorizationOntologyTreeItem[] => {
  return categorizationOntologyArray.map((categorization) => {
    return {
      categorizationId: categorization.categorizationId,
      name: categorization.categorization,
      id: categorization.cnetID,
      deprecated: isDeprecated || categorization.deprecated,
      entityType: categorization.entityType,
      children: categorization.child
        ? treeifyCategorizationOntology(
            categorization.child,
            categorization.deprecated,
          )
        : undefined,
    };
  });
};

// Forms ndtrc categorization towards
export const unTreeifyCategorizationOntology = (
  categorizationOntologyTree: CategorizationOntologyTreeItem[],
  isDeprecated?: boolean,
): CategorizationOntology => {
  return categorizationOntologyTree.map((categorization) => {
    return {
      categorizationId: categorization.categorizationId,
      categorization: categorization.name,
      cnetID: categorization.id,
      deprecated: isDeprecated || categorization.deprecated,
      entityType: categorization.entityType,
      child: categorization.children
        ? unTreeifyCategorizationOntology(
            categorization.children,
            categorization.deprecated,
          )
        : undefined,
    };
  });
};
