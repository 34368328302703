import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IconContext } from "react-icons";

const Loader = (props: any) => (
  <IconContext.Provider value={{ className: `ff-loader ${props.className}` }}>
    <AiOutlineLoading3Quarters />
  </IconContext.Provider>
);

export default Loader;
