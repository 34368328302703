import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NavigationButton from "../../../components/buttons/NavigationButton/NavigationButton";
import {
  performSignIn,
  selectError,
  selectLoading,
} from "../../../store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import LoginField from "./LoginField";
import { NavLink } from "react-router-dom";
import classes from "../Login.module.scss";

const LoginNewUser = (props: any) => {
  const { t } = useTranslation();
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [usernameOrEmailInvalid, setUsernameOrEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [submittedInvalid, setSubmittedInvalid] = useState(false);

  const error = useSelector(selectError);

  const isLoading = useSelector(selectLoading);

  const dispatch = useDispatch();

  const handleFieldChange = (updateValue: any, invalidateValue: any) => {
    return (newValue: string) => {
      invalidateValue(false);

      setSubmittedInvalid(false);

      updateValue(newValue);
    };
  };

  const performLogin = () => {
    if (usernameOrEmail.length < 1 || password.length < 1) {
      if (usernameOrEmail.length < 1) {
        setUsernameOrEmailInvalid(true);
      }

      if (password.length < 1) {
        setPasswordInvalid(true);
      }

      return;
    }

    setSubmittedInvalid(true);

    dispatch(
      performSignIn({
        usernameOrEmail: usernameOrEmail,
        password: password,
      })
    );
  };

  return (
    <section
      className={"ff-login-form"}
      onKeyPress={(e) => {
        e.key === "Enter" && performLogin();
      }}
    >
      {submittedInvalid && error && (
        <p className={"form-error-message"}>
          {t("login.usernameOrEmailOrPasswordInvalid")}
        </p>
      )}

      <header className={"form-header"}>
        <h2 className={"form-headline"}>{t("login.login")}</h2>
      </header>

      <LoginField
        autofocus={true}
        value={usernameOrEmail}
        setValue={handleFieldChange(
          setUsernameOrEmail,
          setUsernameOrEmailInvalid
        )}
        invalid={usernameOrEmailInvalid}
        label={t("login.usernameOrEmailLabel")}
        invalidLabel={t("login.usernameOrEmailRequired")}
      />

      <LoginField
        value={password}
        setValue={handleFieldChange(setPassword, setPasswordInvalid)}
        invalid={passwordInvalid}
        label={t("login.passwordLabel")}
        invalidLabel={t("login.passwordRequired")}
        type={"password"}
      />

      <NavigationButton action={performLogin} disabled={isLoading}>
        {t("login.submit")}
      </NavigationButton>

      <footer className={`form-footer ${classes.footer}`}>
        <NavLink to={`/${t("login.passwordReset.slug")}`}>
          <div>{t("login.passwordReset")}</div>
        </NavLink>
      </footer>
    </section>
  );
};

export default LoginNewUser;
