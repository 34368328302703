import axios, { AxiosInstance } from "axios";

const myAxios = axios.create({
  baseURL: "/api",
});

export const feedFactoryAxios: AxiosInstance = myAxios;

export const setAuthToken = (authToken: string) => {
  feedFactoryAxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${authToken}`;
};
export const resetAuthToken = () => {
  feedFactoryAxios.defaults.headers.common["Authorization"] = null;
};
