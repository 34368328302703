import React, { useEffect, useState } from "react";
import classes from "./CardSharedStyles.module.scss";
import { MdComment, MdMoreHoriz, MdPlace } from "react-icons/md";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Lang, langMetadata } from "models/Lang";
import MediaPreview from "components/MediaPreview";
import { InternalLocationItemModel } from "models/Ndtrc";
import { GoDotFill } from "react-icons/go";
import { AiFillCrown } from "react-icons/ai";
import { format } from "date-fns";
import {
  CategorizationSourceDictionary,
  feedFactoryAxios,
  generateItemApiUrl,
  getCategoryById,
  trimComment,
  Twemoji,
  useCategorizationSourceDictionary,
} from "helpers";
import { ItemComment } from "models/ItemFormComment";

const LocationCard = (props: {
  lang: Lang;
  entry: InternalLocationItemModel;
  active?: boolean;
  className?: string;
  openInNewTab?: boolean;
  userorganisations: string[];
  locationUrl?: string | null;
  minimalDisplay?: boolean;
}) => {
  const { t } = useTranslation();
  const entry: InternalLocationItemModel = props.entry;

  const [comments, setComments] = useState<ItemComment[]>([]);
  const categoryOntology: CategorizationSourceDictionary | null = useCategorizationSourceDictionary();

  // Check if preferred language is available. If not: show primary language of item.
  let trcItemDetails = null;
  if (
    entry.translations?.availableLanguages?.find(
      (avLang) => avLang === props.lang,
    ) &&
    entry.trcItemDetails[props.lang]
  ) {
    trcItemDetails = entry.trcItemDetails[props.lang];
  } else if (entry.translations?.primaryLanguage) {
    trcItemDetails = entry.trcItemDetails[entry.translations?.primaryLanguage];
  }

  // Get comments of item
  useEffect(() => {
    if (props.minimalDisplay) {
      setComments([]);
      return;
    }
    if (!props.entry.id) {
      setComments([]);
      return;
    }
    if (
      !entry.links?.find((item) => {
        return item.type === "comment"; // Careful: does not check alias label currently
      })
    ) {
      setComments([]);
      return;
    }

    const commentUrl = `${generateItemApiUrl(
      "LOCATIE",
      props.entry.id,
    )}/comments`;
    feedFactoryAxios.get(commentUrl).then((response) => {
      setComments(response.data);
    });
  }, [props.entry, entry.links, props.minimalDisplay]);

  const card = (
    <div
      className={[
        classes.Card,
        classes.hoverable,
        entry.wfstatus === "approved" ? classes.published : "",
        entry.wfstatus === "deleted" ? classes.deleted : "",
        entry.wfstatus === "archived" ? classes.archived : "",
        props.active ? classes.active : "",
      ].join(" ")}
    >
      <div className={classes.image}>
        <MediaPreview mediaItem={entry.files?.[0]} coverView />
      </div>
      <div className={classes.content}>
        {entry.userorganisation &&
          props.userorganisations.length > 0 &&
          !props.userorganisations.find(
            (org) => org === entry.userorganisation,
          ) && (
            <div className={classes.otherOwner}>
              <span>
                <AiFillCrown />{" "}
              </span>
              <span>{entry.userorganisation}</span>
            </div>
          )}
        <h2 className={classes.selectable}>
          {trcItemDetails?.title || <em>{t("location.noNameAvailable")}</em>}
        </h2>
        {!props.minimalDisplay && (
          <div className={classes.langDisplay}>
            {entry.translations?.availableLanguages?.map((lang) => {
              if (!langMetadata?.[lang]?.flag) {
                return null;
              }
              return <Twemoji key={lang}>{langMetadata[lang].flag}</Twemoji>;
            })}
            {entry.links?.find((item) => {
              return item.type === "alias"; // Careful: does not check alias label currently
            }) && (
              <span className={classes.printEmoji}>
                <Twemoji key={"print"}>📗</Twemoji>
              </span>
            )}
          </div>
        )}
        {entry.location?.address?.city && (
          <div className={[classes.badge, classes.city].join(" ")}>
            <MdPlace /> {entry.location?.address?.city}
          </div>
        )}
        {entry.trcItemCategories?.types?.slice(0, 4).map((cat) => {
          return (
            <div
              key={cat.catid}
              className={[classes.badge, classes.category].join(" ")}
            >
              <GoDotFill /> {categoryOntology !== null ?  getCategoryById(cat.catid, categoryOntology) : null}
              </div>
          );
        })}
        {entry.trcItemCategories?.types &&
          entry.trcItemCategories?.types.length > 4 && (
            <div
              className={[
                classes.badge,
                classes.category,
                classes.moreCategories,
              ].join(" ")}
            >
              <MdMoreHoriz />
            </div>
          )}
        {trcItemDetails?.shortdescription &&
        trcItemDetails?.shortdescription?.length > 0 ? (
          <p className={classes.selectable}>
            {trcItemDetails.shortdescription.length < 128
              ? trcItemDetails.shortdescription
              : `${trcItemDetails.shortdescription.substr(0, 128)}...`}
          </p>
        ) : (
          <p>
            <em>{t("event.noDescriptionAvailable")}</em>
          </p>
        )}
        {!props.minimalDisplay && (
          <div className={classes.cardFooter}>
            {entry.lastupdated && (
              <>
                <span>{t("root.lastupdated")}: </span>
                {format(new Date(entry.lastupdated), "dd-MM-yyyy HH:mm")}
              </>
            )}
            {entry.creationdate && (
              <>
                <br />
                <span>{t("root.creationdate")}: </span>
                {format(new Date(entry.creationdate), "dd-MM-yyyy HH:mm")}
              </>
            )}
            {entry.validator && (
              <>
                <br />
                <span>{t("tuning.validator")}: </span>
                {entry.validator}
              </>
            )}
          </div>
        )}
      </div>
      {comments && comments.length > 0 && (
        <div
          className={classes.comment}
          title={comments.map((c) => c.body).join(" //")}
        >
          <MdComment /> {trimComment(comments[0].body)}
        </div>
      )}
    </div>
  );

  if (!props.locationUrl) {
    return <div className={`noStyle ${props.className || ""}`}>{card}</div>;
  } else {
    return (
      <Link
        to={props.locationUrl}
        className={`noStyle ${props.className || ""}`}
        target={props.openInNewTab ? "_target" : "_self"}
      >
        {card}
      </Link>
    );
  }
};

export default LocationCard;
