import React from "react";
import classes from "./HoverDesc.module.scss";

import { BsFillInfoCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

export const HoverDesc = (props: {
  description: string;
  position?: "right";
}) => {
  const { t, i18n } = useTranslation();

  let hasDescription =
    props.description !== null && props.description !== undefined;
  let isTranslated = hasDescription && i18n.exists(props.description);

  return (
    <>
      {isTranslated && (
        <div className={`${classes.HoverDesc}`}>
          <BsFillInfoCircleFill />
          <div
            className={`${classes.description} ${
              props.position === "right" && classes.right
            }`}
          >
            {t(props.description)}
          </div>
        </div>
      )}
    </>
  );
};
