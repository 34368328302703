import React from "react";
import classes from "./TuneWidget.module.scss";
import Select from "react-select";
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { SortOrder } from "helpers";
import { SortFieldOption } from "../../models/TuningProps/TuningProps";
import { useTranslation } from "react-i18next";

interface SortValue {
  label: string;
  value: SortFieldOption;
}

interface SortWidgetProps {
  options: Array<SortValue>;
  value: SortFieldOption | null;
  setValue: (newVal: SortFieldOption) => void;
  sortOrder?: SortOrder | null;
  setSortOrder?: (newOrder: SortOrder | null) => void;
  disabled?: boolean;
}

const SortWidget = (props: SortWidgetProps) => {
  const { t } = useTranslation();

  const onSelectChange = (option: SortValue) => {
    props.setValue(option.value);
  };

  const onSortOrderChange = (e: any) => {
    props.setSortOrder?.(
      props.sortOrder === SortOrder.Ascending
        ? SortOrder.Descending
        : SortOrder.Ascending
    );
  };

  return (
    <div className={classes.SortWidget} title={t("tune.sort")}>
      {props.disabled && (
        <div className={classes.disabled}>{t("tune.sortedBySearch")}</div>
      )}
      <Select
        className={classes.sortSelect}
        components={{
          IndicatorSeparator: () => <></>,
        }}
        isClearable={false}
        onChange={(val) => {
          onSelectChange(val as SortValue);
        }}
        value={props.options.find((option) => option.value === props.value)}
        options={props.options}
      />
      {props.sortOrder && (
        <>
          <span className={classes.separator} />
          <div className={classes.OrderWidget} onClick={onSortOrderChange}>
            {props.sortOrder === SortOrder.Ascending && (
              <AiOutlineSortAscending />
            )}
            {props.sortOrder === SortOrder.Descending && (
              <AiOutlineSortDescending />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SortWidget;
