import React, { useState } from "react";
import classes from "./CategoryInputPopover.module.scss";
import { AiOutlineEnter } from "react-icons/ai";
import { usePopper } from "react-popper";
import popoverStyles from "./PopoverSharedStyle.module.scss";
import CategorySelectWidget from "../../FormWidgets/CategorySelectWidget/CategorySelectWidget";
import { EntityType, ItemType } from "../../../models/Ndtrc";

const CategoryInputPopover = (props: {
  children: any;
  onSubmit: (categories: ItemType[]) => void;
  placeholder?: string;
  prefillText?: string;
  entityType: EntityType;
}) => {
  const [popover, setPopover] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(popover, popperElement, {});
  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  const [categoryValue, setCategoryValue] = useState<ItemType[]>([]);

  const onSubmitHandler = () => {
    if (!categoryValue || categoryValue.length === 0) {
      return;
    }
    props.onSubmit(categoryValue);
    setCategoryValue([]);
    setPopoverActive(false);
  };

  const onButtonClickHandler = () => {
    if (update) {
      void update();
    }
    setPopoverActive(true);
  };

  return (
    <>
      {props.children(setPopover, onButtonClickHandler)}
      <div
        className={[
          popoverStyles.popover,
          classes.popover,
          popoverActive ? popoverStyles.active : "",
        ].join(" ")}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <CategorySelectWidget
          onChange={(categories) => {
            setCategoryValue(categories);
          }}
          value={categoryValue}
          entityType={props.entityType}
        />
        <button type="button" onClick={onSubmitHandler}>
          <AiOutlineEnter />
        </button>
      </div>
      <div
        className={`${popoverStyles.backdrop} ${
          popoverActive ? popoverStyles.active : ""
        }`}
        onClick={() => {
          setPopover(null);
          setPopoverActive(false);
        }}
      />
    </>
  );
};

export default CategoryInputPopover;
