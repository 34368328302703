import React from "react";
import ItemsBrowser from "./ItemsBrowser";
import {
  performFetchEvents,
  selectError,
  selectEvents,
  selectLoading,
  selectTuningProps,
} from "../../store/events/eventsSlice";
import { RiCalendarEventLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const EventsBrowser = (props: {}) => {
  const events = useSelector(selectEvents);
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);
  const tuningProps = useSelector(selectTuningProps);

  const { t } = useTranslation();
  const header = (
    <>
      <RiCalendarEventLine /> {t("root.events")}
    </>
  );
  return (
    <ItemsBrowser
      items={events}
      error={error}
      loading={loading}
      tuningProps={tuningProps}
      performFetchItems={performFetchEvents}
      header={header}
      entityType={"EVENEMENT"}
    />
  );
};

export default EventsBrowser;
