import React, { useState } from "react";
import classes from "../PromotionsWidget.module.scss";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { CurrencyInput } from "components/FormWidgets/inputs";
import { Discount, PromotionType } from "models/Ndtrc";
import _ from "lodash";

const DiscountValue = (props: {
  value: Discount;
  promotionType: PromotionType;
  onChange: (newValue: Discount) => any;
}) => {
  const { t } = useTranslation();

  const [discountType, setDiscountType] = useState<string | null>(null);

  // Determine initial discount type
  if (!discountType) {
    if (props.promotionType === "gift" || props.promotionType === "free") {
      setDiscountType("free");
    } else if (props.value.percentage) {
      setDiscountType("percentage");
    } else {
      // Default to amount if no value provided
      setDiscountType("amount");
    }
  }

  const updateValue = (key: string, value: any) => {
    const newValue = _.cloneDeep(props.value);
    // @ts-ignore
    newValue[key] = value;
    props.onChange(newValue);
  };

  return (
    <div className={classes.discountValue}>
      <label
        onClick={() => {
          setDiscountType("amount");
          props.onChange({});
        }}
      >
        <span>
          {discountType === "amount" ? (
            <MdRadioButtonChecked />
          ) : (
            <MdRadioButtonUnchecked />
          )}
        </span>
        <span className={classes.labelText}>{t("promotion.amount")}</span>

        {discountType === "amount" && (
          <CurrencyInput
            value={props.value.amount?.toString() || ""}
            onChange={(val) => {
              updateValue("amount", val);
            }}
          />
        )}
      </label>
      <label
        onClick={() => {
          setDiscountType("percentage");
          props.onChange({});
        }}
      >
        <span>
          {discountType === "percentage" ? (
            <MdRadioButtonChecked />
          ) : (
            <MdRadioButtonUnchecked />
          )}
        </span>
        <span className={classes.labelText}>{t("promotion.percentage")}</span>
        {discountType === "percentage" && (
          <div className={classes.percentageWrapper}>
            <input
              type="text"
              value={props.value.percentage?.toString() || ""}
              onChange={(e) => {
                updateValue("percentage", e.target.value);
              }}
            />
            <div className={classes.percentageSymbol}>%</div>
          </div>
        )}
      </label>
    </div>
  );
};

export default DiscountValue;
