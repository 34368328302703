import { MouseEvent, ReactNode, Ref } from "react";

enum ButtonType {
  Primary = "ff-button-primary",
  Alternate = "ff-button-alternate",
  Success = "ff-button-success",
  Warning = "ff-button-warning",
  Failure = "ff-button-failure",
  Gray = "ff-button-gray",
  Gray2 = "ff-button-gray2",
  Gray3 = "ff-button-gray3",
  Gray4 = "ff-button-gray4",
  Gray5 = "ff-button-gray5",
  Gray6 = "ff-button-gray6",
  White = "ff-button-white",
}

enum ButtonSize {
  Small = "small",
  Normal = "",
  Medium = "",
  Large = "",
  XLarge = "",
}

export default interface ButtonProps {
  className?: string;
  type?: ButtonType;
  size?: ButtonSize;
  disabled?: Boolean;
  action: (e?: MouseEvent) => void;
  children: ReactNode;
  myRef?: Ref<any>;
  expandTextOnHover?: Boolean;
}

export { ButtonType, ButtonSize };
