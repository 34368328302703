import React, { useCallback, useEffect, useState } from "react";
import {
  feedFactoryAxios,
  useNavTranslation,
} from "helpers";
import SettingsField, { SettingsLabel } from "./SettingsField";
import NavigationButton from "../../../components/buttons/NavigationButton/NavigationButton";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import TextButton from "../../../components/buttons/TextButton/TextButton";
import { MdChevronRight } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccountId,
  selectIsAdmin,
  selectIsEditor,
  selectIsSuperAdmin,
} from "../../../store/auth/authSlice";
import PopupButton from "../../../components/buttons/PopupButton/PopupButton";
import { BsTrash } from "react-icons/bs";
import {
  ButtonSize,
  ButtonType,
} from "../../../components/buttons/ButtonProps";
import { AccountModel } from "../../../models/AccountModel/AccountModel";
import Tokens from "./Tokens";
import assertValidResponse from "./helpers/assertValidResponse";
import { HoverDesc } from "../../../components/HoverDesc";
import DictionarySelectWidget, {
  DictionaryType,
} from "../../../components/FormWidgets/DictionarySelectWidget/DictionarySelectWidget";
import classes from "../Settings.module.scss";
import { ChromePicker } from "react-color";
import MediaWidget from "../../../components/FormWidgets/MediaWidget";
import {
  extPromotionsToIntPromotions,
  IntFileItem,
  intPromotionsToExtPromotions,
} from "../../../models/Ndtrc";
import { updateThemeFromAccountId } from "../../../store/theme/themeSlice";
import PromotionsWidget from "../../../components/FormWidgets/PromotionsWidget/PromotionsWidget";
import { useForm } from "react-hook-form";
import { Lang } from "../../../models/Lang";
import {
  globalAdminPermissions,
  globalEditorPermissions,
  globalSuperAdminPermissions,
  localReaderPermissions,
  PermissionObject,
} from "../../../models/PermissionObject/PermissionObject";
import CategorizationTree from "../../../components/CategorizationTree/CategorizationTree";
import { TreeApi } from "react-arborist";
import {
  CategorizationOntology,
  CategorizationOntologyTreeItem,
  unTreeifyCategorizationOntology,
} from "../../../models/CategorizationOntology/CategorizationOntology";
import { categorizationOntology } from "../../../data/categorizationOntology";

const Account = (props: {}) => {
  const { t } = useTranslation();
  const { accountsUrl, getAccountUsersUrl } = useNavTranslation();

  const isAdmin = useSelector(selectIsAdmin);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const isEditor = useSelector(selectIsEditor);

  // This is only necessary to render promotion widgets
  const determinePermissions = useCallback((): PermissionObject => {
    if (isSuperAdmin) {
      return globalSuperAdminPermissions;
    }
    if (isAdmin) {
      return globalAdminPermissions;
    }
    if (isEditor) {
      return globalEditorPermissions;
    }
    return localReaderPermissions;
  }, [isSuperAdmin, isAdmin, isEditor]);
  const [myPermissions, setMyPermissions] = useState<PermissionObject>(
    determinePermissions(),
  );

  const dispatch = useDispatch();

  const history = useHistory();
  const { accountId, accountSelfSlug } = useParams<{
    accountId: string;
    accountSelfSlug: string;
  }>();

  const userAccountId = useSelector(selectAccountId);

  const [account, setAccount] = useState<AccountModel>({} as AccountModel);

  const [error, setError] = useState<String | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [isSaving, setIsSaving] = useState(false);
  const [didSaveSucces, setDidSaveSucces] = useState(false);
  const [didSaveFailure, setDidSaveFailure] = useState(false);

  const isSelf = accountId === accountSelfSlug;
  const isSelfFromId = accountId === userAccountId;

  const [myEventMarkers, setMyEventMarkers] = useState("");
  const [myLocationMarkers, setMyLocationMarkers] = useState("");
  const [myEventTags, setMyEventTags] = useState("");
  const [myLocationTags, setMyLocationTags] = useState("");

  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [secondaryColorShade, setSecondaryColorShade] = useState("");

  const [backgrounds, setBackgrounds] = useState<IntFileItem[]>([]);

  const [categoryTreeInitial, setCategoryTreeInitial] =
    useState<CategorizationOntology>();
  const [categoryTreeRef, setCategoryTreeRef] = useState<
    TreeApi<CategorizationOntologyTreeItem> | null | undefined
  >(null);

  const formMethods = useForm();

  useEffect(() => {
    setMyEventMarkers(account.eventMarkers?.join(";") || "");
    setMyLocationMarkers(account.locationMarkers?.join(";") || "");
    setMyEventTags(account.eventKeywords?.join(";") || "");
    setMyLocationTags(account.locationKeywords?.join(";") || "");
    formMethods.reset({
      eventPromotionProducts: extPromotionsToIntPromotions(
        account.eventPromotionProducts,
      ),
      locationPromotionProducts: extPromotionsToIntPromotions(
        account.locationPromotionProducts,
      ),
    });

    const parsedAccountStyle = account.metaData?.style
      ? JSON.parse(account.metaData?.style)
      : null;
    setPrimaryColor(parsedAccountStyle?.primaryColor || "");
    setSecondaryColor(parsedAccountStyle?.secondaryColor || "");
    setSecondaryColorShade(parsedAccountStyle?.secondaryColorShade || "");
    setBackgrounds(parsedAccountStyle?.backgrounds || []);

    let parsedCategorizationOntology = categorizationOntology.categorizations
    if (account.metaData?.categorizationOntology) {
      try {
        parsedCategorizationOntology = JSON.parse(account.metaData?.categorizationOntology);
      } catch (e) {
        console.log(
          "Could not parse categorization ontology, using default instead",
        );
      }
    }
    setCategoryTreeInitial(parsedCategorizationOntology);
  }, [account]);

  const returnToAccounts = () => {
    history.push(accountsUrl);
  };

  const deleteAccount = () => {
    const performDelete = window.confirm(
      t("account.areYouSureYouWantToDeleteThisAccount"),
    );

    if (performDelete) {
      feedFactoryAxios
        .delete(`/accounts/${accountId}`)
        .then((response) => {
          returnToAccounts();
        })
        .catch((error) => {
          alert(t("account.couldNotDeleteAccount"));
        });
    }
  };

  useEffect(() => {
    setDidSaveSucces(false);
    setDidSaveFailure(false);
    dispatch(updateThemeFromAccountId(account));
  }, [account]);

  useEffect(() => {
    const accountUrl = isSelf ? "/accounts/me" : `/accounts/${accountId}`;

    feedFactoryAxios
      .get(accountUrl)
      .then((response) =>
        assertValidResponse(response, "No account found for this account id"),
      )
      .then((response: AxiosResponse<AccountModel>) => {
        setAccount(response.data);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accountId, isSelf]);

  const handleSaveClick = (evt: any) => {
    setIsSaving(true);
    setDidSaveSucces(false);
    setDidSaveFailure(false);

    // Generate style object
    const accountStyle = {
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
      secondaryColorShade: secondaryColorShade,
      backgrounds: backgrounds,
    };

    const categorizationOntology = categoryTreeRef?.props.data
      ? JSON.stringify(
          unTreeifyCategorizationOntology([...categoryTreeRef.props.data]),
        )
      : "unknown";

    feedFactoryAxios
      .put(`/accounts/${account.id}`, {
        name: account.name,
        // logoUrl: account.logoUrl, // temporary disabled per issue # 28
        userOrganisation: account.userOrganisation,
        eventMarkers: myEventMarkers ? myEventMarkers.split(";") : [],
        locationMarkers: myLocationMarkers ? myLocationMarkers.split(";") : [],
        eventKeywords: myEventTags ? myEventTags.split(";") : [],
        locationKeywords: myLocationTags ? myLocationTags.split(";") : [],
        metaData: {
          style: JSON.stringify(accountStyle),
          categorizationOntology: categorizationOntology,
        },
        eventPromotionProducts: intPromotionsToExtPromotions(
          formMethods.getValues("eventPromotionProducts"),
        ),
        locationPromotionProducts: intPromotionsToExtPromotions(
          formMethods.getValues("locationPromotionProducts"),
        ),
      })
      .then((response) => {
        setDidSaveSucces(true);
        setAccount(response.data);
      })
      .catch(() => {
        setDidSaveFailure(true);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      <header className={"settings-header"}>
        {!isSelf && (
          <div className={"settings-eyebrow"}>
            <span className={"settings-breadcrumb-label"}>
              {t("root.backTo")}:{" "}
            </span>
            <TextButton action={returnToAccounts}>
              {t("root.accounts")}
            </TextButton>
          </div>
        )}
        <h2 className={"settings-title"}>
          {isSelf
            ? t("root.myaccount")
            : `${t("root.account")} (${account.name ?? ""})`}
        </h2>
        {!isSelf && !error && (
          <PopupButton
            className={"settings-header-button"}
            action={deleteAccount}
            size={ButtonSize.Small}
            type={ButtonType.Failure}
          >
            <>
              <BsTrash /> {t("root.deleteAccount")}
            </>
          </PopupButton>
        )}
      </header>
      {error && <span>{error}</span>}
      {!error && !isLoading && (
        <div className={"settings-group"}>
          <div className={"settings-row"}>
            <SettingsField
              label={t("root.name")}
              setValue={(newValue: string) =>
                setAccount((oldValue) => ({ ...oldValue, name: newValue }))
              }
              value={account.name || ""}
            />
          </div>
          {/* disabled per issue #28 */}
          {/*<div className={"settings-row"}>*/}
          {/*  <SettingsField*/}
          {/*    label={"Logo Url"}*/}
          {/*    setValue={(newValue: string) => {*/}
          {/*      setAccount((oldValue) => ({ ...oldValue, logoUrl: newValue }));*/}
          {/*    }}*/}
          {/*    value={account.logoUrl || ""}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className={"settings-row"}>
            <SettingsField
              label={t("root.userOrganisation")}
              setValue={(newValue: string) => {
                setAccount((oldValue) => ({
                  ...oldValue,
                  userOrganisation: newValue,
                }));
              }}
              value={account.userOrganisation || ""}
            />
          </div>
          {isSuperAdmin && isAdmin && (
            <>
              <div className={"settings-row"}>
                <SettingsLabel>{t("root.id")}</SettingsLabel>
                <span className={"selectable"}>{account.id}</span>
              </div>
              <div className={"settings-row settings-hstack"}>
                <div>
                  <SettingsLabel>{t("root.createdOn")}</SettingsLabel>
                  {(() => {
                    const modifiedRaw = account.created;
                    let modified: string = t("root.unknown");
                    if (modifiedRaw) {
                      modified = moment(modifiedRaw).format("dd-MM-yyyy");
                    }

                    return modified;
                  })()}
                </div>
                <div>
                  <SettingsLabel>{t("root.lastChange")}</SettingsLabel>
                  {(() => {
                    const modifiedRaw = account.modified;
                    let modified: string = t("root.noActivityKnown");
                    if (modifiedRaw) {
                      modified = moment(modifiedRaw).fromNow();
                    }

                    return modified;
                  })()}
                </div>
              </div>
            </>
          )}
          {!isSelf && (
            <Link
              to={getAccountUsersUrl(accountId)}
              className={"users-action-frame ff-card"}
            >
              <FaUsers />
              <p>
                {t("root.toUsers")} <MdChevronRight />
              </p>
            </Link>
          )}
          {(isAdmin || isSuperAdmin) && (
            <div className={classes.ownMarkersTags}>
              <SettingsLabel>{t("settings.customMarkersTags")}</SettingsLabel>
              <div>
                <p>{t("settings.customMarkersTags.desc")}</p>
              </div>
              <div>
                <label>
                  {`${t("entityType.EVENEMENT")} ${t("event.markers")}`}
                  <HoverDesc description="event.markers.desc" />
                </label>
                <DictionarySelectWidget
                  value={myEventMarkers}
                  onChange={(newVal) => {
                    setMyEventMarkers(newVal);
                  }}
                  type={DictionaryType.EventMarker}
                  allowNewItems
                  allowRemovingItems
                />
              </div>

              <div>
                <label>
                  {`${t("entityType.LOCATIE")} ${t("event.markers")}`}
                  <HoverDesc description="event.markers.desc" />
                </label>
                <DictionarySelectWidget
                  value={myLocationMarkers}
                  onChange={(newVal) => {
                    setMyLocationMarkers(newVal);
                  }}
                  type={DictionaryType.LocationMarker}
                  allowNewItems
                  allowRemovingItems
                />
              </div>

              <div>
                <label>
                  {`${t("entityType.EVENEMENT")} ${t("event.keywords")}`}
                  <HoverDesc description="event.keywords.desc" />
                </label>
                <DictionarySelectWidget
                  value={myEventTags}
                  onChange={(newVal) => {
                    setMyEventTags(newVal);
                  }}
                  type={DictionaryType.EventTag}
                  allowNewItems
                  allowRemovingItems
                />
              </div>

              <div>
                <label>
                  {`${t("entityType.LOCATIE")} ${t("event.keywords")}`}
                  <HoverDesc description="event.keywords.desc" />
                </label>
                <DictionarySelectWidget
                  value={myLocationTags}
                  onChange={(newVal) => {
                    setMyLocationTags(newVal);
                  }}
                  type={DictionaryType.LocationTag}
                  allowNewItems
                  allowRemovingItems
                />
              </div>

              <div className={classes.saveButton}>
                <NavigationButton action={handleSaveClick}>
                  {t("root.saveChanges")}
                </NavigationButton>
                {isSaving && `${t("root.saving")}...`}
                {didSaveSucces && t("root.changesSaved")}
                {/* @todo perhaps we should have a popup here */}
                {didSaveFailure && t("root.couldNotSaveChanges")}
              </div>

              <div className={"EventForm"}>
                <div>
                  <label>
                    {`${t("entityType.EVENEMENT")} ${t(
                      "promotion.promotionProducts",
                    )}`}
                  </label>
                  <PromotionsWidget
                    creationAllowed={"newOnly"}
                    formMethods={formMethods}
                    namespace={"eventPromotionProducts"}
                    lang={Lang.NL}
                    myPermissions={myPermissions}
                    entityType={"EVENEMENT"}
                  />
                </div>
                <div>
                  <label>
                    {`${t("entityType.LOCATIE")} ${t(
                      "promotion.promotionProducts",
                    )}`}
                  </label>
                  <PromotionsWidget
                    creationAllowed={"newOnly"}
                    formMethods={formMethods}
                    namespace={"locationPromotionProducts"}
                    lang={Lang.NL}
                    myPermissions={myPermissions}
                    entityType={"LOCATIE"}
                  />
                </div>
              </div>
              <div className={classes.saveButton}>
                <NavigationButton action={handleSaveClick}>
                  {t("root.saveChanges")}
                </NavigationButton>
                {isSaving && `${t("root.saving")}...`}
                {didSaveSucces && t("root.changesSaved")}
                {/* @todo perhaps we should have a popup here */}
                {didSaveFailure && t("root.couldNotSaveChanges")}
              </div>
            </div>
          )}
          <SettingsLabel>{t("account.categoryOntology")}</SettingsLabel>
          {categoryTreeInitial && (
            <CategorizationTree
              initialValue={categoryTreeInitial}
              treeRef={(newTree) => {
                setCategoryTreeRef(newTree);
              }}
            />
          )}
          <SettingsLabel>{t("account.colorScheme")}</SettingsLabel>
          <div className={classes.colorWheels}>
            <div>
              <label>{t("account.primaryColor")}</label>
              <ChromePicker
                color={primaryColor}
                onChange={(color) => {
                  setPrimaryColor(color.hex);
                }}
              />
            </div>
            <div>
              <label>{t("account.secondaryColor")}</label>
              <ChromePicker
                color={secondaryColor}
                onChange={(color) => {
                  setSecondaryColor(color.hex);
                }}
              />
            </div>
            <div>
              <label>{t("account.secondaryColorShade")}</label>
              <ChromePicker
                color={secondaryColorShade}
                onChange={(color) => {
                  setSecondaryColorShade(color.hex);
                }}
              />
            </div>
          </div>
          <SettingsLabel>{t("account.backgrounds")}</SettingsLabel>
          <MediaWidget
            mediaArray={backgrounds}
            onChange={(newArray) => {
              setBackgrounds(newArray);
            }}
            hideYoutubeInput={true}
            hideVimeoInput={true}
          />
          <div className={classes.saveButton}>
            <NavigationButton action={handleSaveClick}>
              {t("root.saveChanges")}
            </NavigationButton>
            {isSaving && `${t("root.saving")}...`}
            {didSaveSucces && t("root.changesSaved")}
            {/* @todo perhaps we should have a popup here */}
            {didSaveFailure && t("root.couldNotSaveChanges")}
          </div>

          <SettingsLabel>{t("tokens.apiToken")}</SettingsLabel>
          {(isAdmin || isSuperAdmin) && (
            <Tokens accountId={accountId || userAccountId} />
          )}
        </div>
      )}
      <br />
    </>
  );
};

export default Account;
