import React, { useEffect, useState } from "react";
import {
  externalResultsToInternalResults,
  ExtPaginatedResponse,
  feedFactoryAxios,
  IntPaginatedResponse,
} from "helpers";
import CardWidget from "../../../components/CardWidget/CardWidget";
import { Lang } from "../../../models/Lang/Lang";
import { AxiosResponse } from "axios";
import classes from "./EventsByLocationWidget.module.scss";
import { MdEvent } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader/Loader";
import CardWidgetHeader from "../../../components/CardWidget/CardWidgetHeader/CardWidgetHeader";
import PaginationControls from "../../../components/PaginationControls/PaginationControls";
import { useSelector } from "react-redux";
import { selectIsEditor, selectIsPartner } from "../../../store/auth/authSlice";
import { SortFieldOption } from "../../../models/TuningProps/TuningProps";
import { WfStatus } from "../../../models/Ndtrc";

const EventsByLocationWidget = (props: { locationId: string }) => {
  const [items, setItems] = useState<IntPaginatedResponse>();
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);

  const [firstLoading, setFirstLoading] = useState(true);
  const [reloading, setReloading] = useState(false);

  const isEditor = useSelector(selectIsEditor);
  const isPartner = useSelector(selectIsPartner);

  useEffect(() => {
    setReloading(true);

    const sortField: SortFieldOption = "eventStart";
    let url = `/events?locationId=${props.locationId}&sortField=${sortField}&sortOrder=ASC&size=4&page=${page}`;
    if (isEditor || isPartner) {
      const wfStatus: WfStatus[] = ["readyforvalidation", "approved"];
      const wfStatusStr: string = wfStatus.join(";");
      url += `&wfstatus=${wfStatusStr}`;
    }

    feedFactoryAxios
      .get(url)
      .then((res: AxiosResponse<ExtPaginatedResponse>) => {
        setItems(externalResultsToInternalResults(res.data));
        setFirstLoading(false);
        setReloading(false);
      })
      .catch((e) => {
        setFirstLoading(false);
        setReloading(false);
        console.error(e);
      });
  }, [page, props.locationId]);

  return (
    <div className={classes.EventsByLocationWidget}>
      {items && (
        <div
          className={`floatingActionButtonsContainer ${classes.paginationContainer}`}
        >
          {!firstLoading && reloading && (
            <Loader className={classes.smallLoader} />
          )}
          {firstLoading && <Loader className={classes.Loader} />}
          <PaginationControls
            page={page}
            size={items.size}
            hits={items.hits}
            onChange={(newPage) => {
              setPage(newPage);
            }}
          />
        </div>
      )}
      <div className={classes.header}>
        {items && items.hits > 0 && (
          <CardWidgetHeader
            header={
              <>
                <MdEvent /> {t("root.eventsAtThisLocation")}
              </>
            }
            badge={items?.hits}
          />
        )}
      </div>
      <div className={classes.results}>
        {items && items.hits > 0 && (
          <CardWidget
            items={items.results}
            hits={items.hits}
            lang={Lang.NL}
            showBadge
          />
        )}
        {firstLoading && (
          <div className={`ff-loader-centered ${classes.Loader}`}>
            <Loader />
            <p>{t("root.loadingLastEvents")}</p>
          </div>
        )}
        {!firstLoading && (!items || items.hits === 0) && (
          <div
            className={`ff-loader-centered ${classes.Loader} ${classes.notAvailable}`}
          >
            <p>{t("root.noEventsAvailable")}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventsByLocationWidget;
