import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdTranslate } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  feedFactoryAxios,
  FormData,
  TranslationInterface,
  Twemoji,
} from "helpers";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { Lang, langMetadata } from "../../../models/Lang/Lang";
import {
  ExternalItemModel,
  InternalItemModel,
} from "../../../models/Ndtrc/Ndtrc";
import classes from "../ItemForm.module.scss";
import { HoverDesc } from "../../../components/HoverDesc/HoverDesc";
import { Controller } from "react-hook-form";
import { decodeHTMLEntities } from "helpers/decodeHtmlEntities/decodeHtmlEntities";

const ItemFormSecondaryLanguage = (props: {
  readonly primaryLanguage: Lang;
  readonly originalItem: ExternalItemModel;
  readonly intItem: InternalItemModel;
  readonly onSubmit: (formData: FormData) => void;
  readonly formMethods: any;
}) => {
  const { t } = useTranslation();
  const { version: langParam, id: itemId } =
    useParams<{ version: string; id: string }>();
  const formMethods = props.formMethods;
  const { register, setValue, getValues, reset, watch } = formMethods;

  if (!Object.values(Lang).includes(langParam.slice(0, 2) as Lang)) {
    console.error("Invalid language provided", langParam.slice(0, 2));
    throw new Error("Invalid language provided");
  }
  const lang: Lang = (langParam as string).slice(0, 2) as Lang;

  useEffect(() => {
    if (!props.intItem) {
      return;
    }
    reset({
      ndtrcEntry: { ...props.intItem },
    });
  }, [lang, props.intItem, props.primaryLanguage, reset, itemId]);

  const translateHandler = (sourceField: string, targetField: string) => {
    // console.log(sourceField, getValues()); // DEBUG
    feedFactoryAxios
      .post("translate", {
        text: getValues(sourceField),
        sourceLanguage: props.primaryLanguage,
        targetLanguage: lang,
      })
      .then((res: AxiosResponse) => {
        const translationObject: TranslationInterface = res.data;

        setValue(targetField, translationObject.text);
      })
      .catch((err) => {
        console.error("Translation call failed", err);
      });
  };

  const translateAllHandler = () => {
    // Copy item name without translation - as likely undesired
    setValue(
      `ndtrcEntry.trcItemDetails[${lang}].title`,
      getValues(`ndtrcEntry.trcItemDetails[${props.primaryLanguage}].title`)
    );

    const fieldsToTranslate: { sourceField: string; targetField: string }[] = [
      {
        sourceField: `ndtrcEntry.trcItemDetails[${props.primaryLanguage}].shortdescription`,
        targetField: `ndtrcEntry.trcItemDetails[${lang}].shortdescription`,
      },
      {
        sourceField: `ndtrcEntry.trcItemDetails[${props.primaryLanguage}].longdescription`,
        targetField: `ndtrcEntry.trcItemDetails[${lang}].longdescription`,
      },
    ];

    fieldsToTranslate.forEach((field) => {
      translateHandler(field.sourceField, field.targetField);
    });
  };

  return (
    <div className={"EventForm SecondaryLangForm"}>
      <form>
        {/* Submission happens in parent class on page change / save action */}
        <button
          type="button"
          className={"mainTranslationButton"}
          onClick={translateAllHandler}
        >
          <MdTranslate /> {t("form.translateAll")}
        </button>
        <section>
          <div className={`comparisonView ${classes.versionHeader}`}>
            <h2>
              <Twemoji>{langMetadata[props.primaryLanguage]?.flag}</Twemoji>{" "}
              {t(`language.${props.primaryLanguage}`)} ({t("language.primary")})
            </h2>
            <h2>
              <Twemoji>{langMetadata[lang]?.flag}</Twemoji>{" "}
              {t(`language.${lang}`)}
            </h2>
          </div>
          <label>
            {t("event.title")}
            <HoverDesc description="event.title.desc" />
          </label>
          <div className={"comparisonView"}>
            <input
              {...register(
                `ndtrcEntry.trcItemDetails[${props.primaryLanguage}].title`
              )}
              disabled
            />
            <input
              {...register(`ndtrcEntry.trcItemDetails[${lang}].title`)}
              defaultValue={""}
            />
          </div>
          <div className={"formSection"}>
            <label>
              {t("event.shortdescription")}
              <HoverDesc description="event.shortdescription.desc" />
            </label>
            <div className={"comparisonView"}>
              <textarea
                {...register(
                  `ndtrcEntry.trcItemDetails[${props.primaryLanguage}].shortdescription`
                )}
                disabled
              />
              <div>
                <textarea
                  {...register(
                    `ndtrcEntry.trcItemDetails[${lang}].shortdescription`
                  )}
                  defaultValue={""}
                />
                <p className={`${classes.characterCount} ${classes.fullWidth}`}>
                  {t("form.characters")}:{" "}
                  {watch(`ndtrcEntry.trcItemDetails[${lang}].shortdescription`)
                    ?.length || 0}
                </p>
              </div>
            </div>

            <button
              type="button"
              className={"translationButton"}
              onClick={() => {
                translateHandler(
                  `ndtrcEntry.trcItemDetails[${props.primaryLanguage}].shortdescription`,
                  `ndtrcEntry.trcItemDetails[${lang}].shortdescription`
                );
              }}
            >
              <MdTranslate />
            </button>
          </div>
          <div className={"formSection"}>
            <label>
              {t("event.longdescription")}
              <HoverDesc description="event.longdescription.desc" />
            </label>
            <div className={"comparisonView"}>
              <Controller
                name={`ndtrcEntry.trcItemDetails[${props.primaryLanguage}].longdescription`}
                control={props.formMethods.control}
                defaultValue={""}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <ReactQuill
                    readOnly
                    onChange={(value) => {
                      onChange(value);
                    }}
                    value={value}
                  />
                )}
              />
              <div>
                <Controller
                  name={`ndtrcEntry.trcItemDetails[${lang}].longdescription`}
                  control={props.formMethods.control}
                  defaultValue={""}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <ReactQuill
                      onChange={(value) => {
                        onChange(value);
                      }}
                      value={value}
                    />
                  )}
                />
                <p className={`${classes.characterCount} ${classes.fullWidth}`}>
                  {t("form.characters")}:{" "}
                  {decodeHTMLEntities(
                    watch(`ndtrcEntry.trcItemDetails[${lang}].longdescription`)
                  )?.length || 0}
                </p>
              </div>
            </div>
            <button
              type="button"
              className={"translationButton"}
              onClick={() => {
                translateHandler(
                  `ndtrcEntry.trcItemDetails[${props.primaryLanguage}].longdescription`,
                  `ndtrcEntry.trcItemDetails[${lang}].longdescription`
                );
              }}
            >
              <MdTranslate />
            </button>
          </div>
        </section>
      </form>
    </div>
  );
};

export default ItemFormSecondaryLanguage;
