import React, { MouseEvent } from "react";

import "../SharedButton.scss";
import "./PopupButton.scss";
import ButtonProps, { ButtonType } from "../ButtonProps";

/**
 * Use a PopupButton where the action will result in a popup action.
 * I.e. the dismissal of a popup, the closing of a form without saving.
 */
const PopupButton = (props: ButtonProps) => {
  const handleClick = (event: MouseEvent) => {
    props.disabled !== true && props.action();
  };

  return (
    <button
      className={`ff-component-popup-button ${props.className} ${
        props.disabled ? "ff-button-disabled" : "clickable"
      } ${props.type || ButtonType.Primary} ${props.size}`}
      onClick={handleClick}
      ref={props.myRef}
      type={"button"}
    >
      <span className={"displayFix"}>{props.children}</span>
    </button>
  );
};

export default PopupButton;
