import { IntPromotion } from "models/Ndtrc";

export type PromotionState =
  | "enabled"
  | "disabled"
  | "expired"
  | "notYetStarted";

export const getPromotionState = (promotion: IntPromotion): PromotionState => {
  if (!promotion.enabled) {
    return "disabled";
  } else if (promotion.endDate && new Date(promotion.endDate) < new Date()) {
    return "expired";
  } else if (
    promotion.startDate &&
    new Date(promotion.startDate) > new Date()
  ) {
    return "notYetStarted";
  } else {
    return "enabled";
  }
};
