import {
  globalAdminPermissions,
  globalEditorPermissions,
  globalSuperAdminPermissions,
  PermissionObject,
} from "models/PermissionObject";
import {
  selectIsAdmin,
  selectIsEditor,
  selectIsSuperAdmin,
} from "../store/auth/authSlice";
import { useSelector } from "react-redux";

const useGlobalPermissions = (): PermissionObject | undefined => {
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const isAdmin = useSelector(selectIsAdmin);
  const isEditor = useSelector(selectIsEditor);
  if (isSuperAdmin) {
    return globalSuperAdminPermissions;
  }
  if (isAdmin) {
    return globalAdminPermissions;
  }
  if (isEditor) {
    return globalEditorPermissions;
  }
  return undefined;
};

export default useGlobalPermissions;
