import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../store";
import axios, { AxiosError, CancelTokenSource } from "axios";
import {
  comparisonIgnoringUndefined,
  externalResultsToInternalResults,
  fetchApiItems,
  IntPaginatedResponse,
} from "helpers";
import {
  defaultTuningOptions,
  TuningProps,
} from "../../models/TuningProps/TuningProps";
import _ from "lodash";
import { ExternalEventGroupItemModel } from "../../models/Ndtrc";

// As a non-serializable object, needs to be stored outside of redux
let cancelTokenSource: CancelTokenSource | null;

interface EventGroupsSlice {
  eventGroups: IntPaginatedResponse | null; // also contains poge and size
  loading: boolean;
  error: string | null;
  tuningProps: TuningProps;
  nextProps: null | {
    page: number;
    size: number;
    tuningProps: TuningProps;
  };
}

const initialState: EventGroupsSlice = {
  eventGroups: null,
  loading: false,
  error: null,
  tuningProps: defaultTuningOptions,
  nextProps: null,
};

export const eventGroupsSlice = createSlice({
  name: "eventGroups",
  initialState,
  reducers: {
    fetchEventGroupsStart: (
      state,
      action: PayloadAction<{
        nextProps: {
          page: number;
          size: number;
          tuningProps: TuningProps;
        };
      }>
    ) => {
      if (state.loading) {
        fetchEventGroupsInterrupt({
          interruptReason: "Request canceled: event data to be replaced.",
        });
      }
      state.loading = true;
      state.error = null;
      state.nextProps = action.payload.nextProps;
      return state;
    },
    fetchEventGroupsInterrupt: (
      state,
      action: PayloadAction<{
        interruptReason: string;
      }>
    ) => {
      cancelTokenSource?.cancel(action.payload.interruptReason);

      state.nextProps = null;
      state.loading = false;
      state.error = null;
      return state;
    },
    fetchEventGroupsSuccess: (
      state,
      action: PayloadAction<{
        data: IntPaginatedResponse;
        tuningProps: TuningProps;
      }>
    ) => {
      console.log("EVENT GROUPS LOADED", action.payload.data.results); // DEBUG
      state.loading = false;
      state.eventGroups = action.payload.data;
      state.tuningProps = action.payload.tuningProps;
      return state;
    },
    fetchEventGroupsFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;

      return state;
    },
  },
});

export const {
  fetchEventGroupsStart,
  fetchEventGroupsSuccess,
  fetchEventGroupsFailure,
  fetchEventGroupsInterrupt,
} = eventGroupsSlice.actions;

export const performFetchEventGroups =
  (page = 0, size = 24, tuningProps: TuningProps): AppThunk =>
    async (dispatch, getState) => {
      // Check if query is not equal to previously completed query. If so, don't perform
      // TODO: Fix this so that page changes while loading don't cancel queries
      const tuningPropsSame = _.isEqualWith(
        tuningProps,
        getState().eventGroups.tuningProps,
        comparisonIgnoringUndefined
      );

      // Check if query is not equal to currently running query. If so, don't perform
      if (
        page === getState().eventGroups.nextProps?.page &&
        size === getState().eventGroups.nextProps?.size &&
        _.isEqualWith(
          tuningProps,
          getState().eventGroups.nextProps,
          comparisonIgnoringUndefined
        )
      ) {
        // console.log("EVENT SLICE INPUT WAS EQUAL TO CURRENT QUERY"); // DEBUG
        return;
      }

      // console.log("NEW EVENT SLICE", tuningProps, getState().events.tuningProps); // DEBUG

      if (!tuningPropsSame) {
        page = 0;
      }

      cancelTokenSource?.cancel("Request canceled: event data already present.");
      cancelTokenSource = axios.CancelToken.source();
      dispatch(
        fetchEventGroupsStart({
          nextProps: { page: page, size: size, tuningProps: tuningProps },
        })
      );

      fetchApiItems(
        "EVENEMENTGROEP",
        page,
        size,
        tuningProps,
        cancelTokenSource,
        "json",
        true
      )
        .then(async (response) => {
          response.data.results.forEach((eg: ExternalEventGroupItemModel) => {
            eg.entitytype = "EVENEMENTGROEP";
          });
          console.log("EVENT GROUPS RESPONSE", response.data); // DEBUG
          dispatch(
            fetchEventGroupsSuccess({
              data: externalResultsToInternalResults(response.data),
              tuningProps: tuningProps,
            })
          );
        })
        .catch((error: AxiosError) => {
          if (axios.isCancel(error)) {
            // console.log("Request canceled", error.message); // DEBUG
          } else {
            return dispatch(fetchEventGroupsFailure(error.message));
          }
        });
    };

export const selectLoading = (state: RootState) => state.eventGroups.loading;
export const selectError = (state: RootState) => state.eventGroups.error;
export const selectEventGroups = (state: RootState) =>
  state.eventGroups.eventGroups;
export const selectTuningProps = (state: RootState) =>
  state.eventGroups.tuningProps;

export default eventGroupsSlice.reducer;
