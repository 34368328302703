import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../store";
import { feedFactoryAxios } from "helpers";
import axios, { AxiosError, CancelTokenSource } from "axios";
import {
  externalResultsToInternalResults,
  IntPaginatedResponse,
} from "../../helpers/ndtrcModelHelper";
import { EntityType, ExternalItemModel } from "../../models/Ndtrc/Ndtrc";

// As a non-serializable object, needs to be stored outside of redux
let cancelTokenSource: CancelTokenSource | null;

interface ItemsSlice {
  items: IntPaginatedResponse | null; // also contains poge and size
  isCollapsed: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: ItemsSlice = {
  items: null,
  isCollapsed: false,
  loading: false,
  error: null,
};

export const itemsQueue = createSlice({
  name: "itemsQueue",
  initialState,
  reducers: {
    fetchItemsStart: (state, action: PayloadAction) => {
      if (state.loading) {
        fetchItems({
          interruptReason: "Request canceled: item queue data to be replaced.",
        });
      }
      state.items = null;
      state.loading = true;
      state.error = null;
      return state;
    },
    fetchItems: (
      state,
      action: PayloadAction<{
        interruptReason: string;
      }>
    ) => {
      cancelTokenSource?.cancel(action.payload.interruptReason);

      state.loading = false;
      state.error = null;
      return state;
    },
    fetchItemsSuccess: (
      state,
      action: PayloadAction<{
        data: IntPaginatedResponse;
      }>
    ) => {
      // console.log("ITEMS QUEUE LOADED", action.payload.data.results); // DEBUG
      state.loading = false;
      state.items = action.payload.data;
      return state;
    },
    fetchItemsFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;

      return state;
    },
    toggleIsCollapsed: (state) => {
      state.isCollapsed = !state.isCollapsed;
      return state;
    },
    removeItem: (state, action: PayloadAction<string>) => {
      if (!state.items) {
        console.error("Critical failure in nextItem function");
        return; // Should never happen
      }
      state.items.results = state.items.results.filter(
        (item) => item.id !== action.payload
      );
      state.items.hits = state.items.hits - 1;
      return state;
    },
    clearQueue: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  fetchItemsStart,
  fetchItemsSuccess,
  fetchItemsFailure,
  fetchItems,
  toggleIsCollapsed,
  removeItem,
  clearQueue,
} = itemsQueue.actions;

export const performIngestItems =
  (entityType: EntityType | null, selectedItems: string[]): AppThunk =>
  async (dispatch, getState) => {
    // TEMP: no way to select locations by ID, for now fail silently
    if (entityType === "LOCATIE") {
      return dispatch(fetchItemsFailure({}));
    }

    cancelTokenSource?.cancel("Request canceled: item data already present.");
    cancelTokenSource = axios.CancelToken.source();
    dispatch(fetchItemsStart());

    const urlStem = entityType === "EVENEMENT" ? "events" : "locations";
    const urlAttribute =
      entityType === "EVENEMENT" ? "eventIDs" : "locationIDs";
    feedFactoryAxios
      .get(
        `/${urlStem}?${urlAttribute}=${selectedItems.join(";")}&size=10000`,
        {
          cancelToken: cancelTokenSource.token,
        }
      )
      .then(async (response) => {
        // If empty, return
        if (response.data.results.length === 0) {
          return dispatch(fetchItemsFailure({ data: response.data }));
        }

        // align selection order with response order
        response.data.results.sort(
          (a: ExternalItemModel, b: ExternalItemModel) => {
            return (
              selectedItems.indexOf(a.id as string) -
              selectedItems.indexOf(b.id as string)
            );
          }
        );

        dispatch(
          fetchItemsSuccess({
            data: externalResultsToInternalResults(response.data),
          })
        );
      })
      .catch((error: AxiosError) => {
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error.message); // DEBUG
        } else {
          return dispatch(fetchItemsFailure(error.message));
        }
      });
  };

export const selectLoading = (state: RootState) => state.itemsQueue.loading;
export const selectError = (state: RootState) => state.itemsQueue.error;
export const selectItems = (state: RootState) => state.itemsQueue.items;
export const selectIsCollapsed = (state: RootState) =>
  state.itemsQueue.isCollapsed;

export default itemsQueue.reducer;
