import React, { useState } from "react";
import { LocationMapModel } from "../../models/LocationMapModel/LocationMapModel";
import classes from "./LocationMapper.module.scss";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { RiNumbersFill } from "react-icons/ri";
import { format, formatDistanceToNow } from "date-fns";
import { AiFillEye } from "react-icons/ai";
import nl from "date-fns/locale/nl";
import Loader from "../../components/Loader/Loader";
import { GoDotFill } from "react-icons/go";
import {
  CategorizationSourceDictionary,
  getCategoryById,
  useCategorizationSourceDictionary,
} from "helpers";

const LocationMap = (props: {
  locationMap: LocationMapModel,
  onChange: (locationMap: LocationMapModel) => Promise<void>,
  onSelfSelect: (locationMap: LocationMapModel) => any,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const categoryOntology: CategorizationSourceDictionary | null =
    useCategorizationSourceDictionary();

  const setBlocked = (blockStatus: boolean) => {
    // Edit source
    const newLocationMap = _.cloneDeep(props.locationMap);
    newLocationMap.blocked = blockStatus;

    setIsLoading(true);
    props
      .onChange(newLocationMap)
      .then(() => {
        setIsLoading(false);
      })
      .catch((res) => {
        console.error("Error updating location map", res);
        setIsLoading(false);
      });
  };

  return (
    <div
      className={`${classes.LocationMap} ${
        props.locationMap.blocked ? classes.isBlocked : ""
      }`}
    >
      <div className={classes.originalName}>
        {props.locationMap.locationName}
      </div>
      {props.locationMap.trcId ? (
        <>
          <div className={classes.linkedName}>
            {props.locationMap.ndtrcName}
          </div>
          <a
            target="_blank"
            rel={"noreferrer"}
            href={`/${t("root.locations.slug")}/${props.locationMap.trcId}`}
          >
            🔗
          </a>
        </>
      ) : (
        <p>💔 {t("locationMapper.noLink")}</p>
      )}
      <div className={classes.metaBarItem} title={t("tokens.accessCount")}>
        <RiNumbersFill />
        <span>{props.locationMap.usedCount}</span>
      </div>
      <div
        className={classes.metaBarItem}
        title={
          props.locationMap.lastUsed
            ? `${t("tokens.lastSeen")} ${format(
                new Date(props.locationMap.lastUsed),
                "yyyy-MM-dd HH:mm",
              )}`
            : "-"
        }
      >
        <AiFillEye />
        <span>
          {props.locationMap.lastUsed
            ? formatDistanceToNow(new Date(props.locationMap.lastUsed), {
                locale: nl,
              })
            : "-"}
        </span>
      </div>
      {props.locationMap.locationTypes?.slice(0, 4).map((cat) => {
        return (
          <div
            key={cat}
            className={[classes.metaBarItem, classes.category].join(" ")}
          >
            <GoDotFill />{" "}
            {categoryOntology !== null
              ? getCategoryById(cat, categoryOntology)
              : null}
          </div>
        );
      })}
      {props.locationMap.blocked && <p>⛔ BLOCKED</p>}
      <div className={classes.locationMapButtons}>
        {props.locationMap.blocked ? (
          <button
            onClick={() => {
              setBlocked(false);
            }}
          >
            ✅ {t("locationMapper.unblock")}
          </button>
        ) : (
          <button
            onClick={() => {
              setBlocked(true);
            }}
          >
            ⛔ {t("locationMapper.block")}
          </button>
        )}
        <button
          onClick={() => {
            props.onSelfSelect(props.locationMap);
          }}
        >
          ✒ {t("locationMapper.edit")}
        </button>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default LocationMap;
