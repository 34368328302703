import React from "react";
import ItemsBrowser from "./ItemsBrowser";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MdGroupWork } from "react-icons/md";
import {
  performFetchEventGroups,
  selectError,
  selectEventGroups,
  selectLoading,
  selectTuningProps,
} from "../../store/eventGroups/eventGroupsSlice";

const EventGroupsBrowser = (props: {}) => {
  const eventGroups = useSelector(selectEventGroups);
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);
  const tuningProps = useSelector(selectTuningProps);

  const { t } = useTranslation();
  const header = (
    <>
      <MdGroupWork /> {t("root.eventGroups")}
    </>
  );
  return (
    <ItemsBrowser
      items={eventGroups}
      error={error}
      loading={loading}
      tuningProps={tuningProps}
      performFetchItems={performFetchEventGroups}
      header={header}
      entityType={"EVENEMENTGROEP"}
    />
  );
};

export default EventGroupsBrowser;
