import classes from "./TuneWidget.module.scss";
import React from "react";

const Blanket = (props: any) => {
  return (
    <div
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: "fixed",
        zIndex: 1,
      }}
      onClick={props.onClick}
    />
  );
};

// @ts-ignore
export const Dropdown = ({ children, isOpen, target, onClose }) => {
  return (
    <div className={classes.Dropdown}>
      {target}
      {isOpen && (
        <div style={{ zIndex: 1000000, position: "relative" }}>{children}</div>
      )}
      {isOpen && <Blanket onClick={onClose} />}
    </div>
  );
};
