import React from "react";
import BooleanInputPopover from "../../../../components/popovers/BooleanInputPopover";
import classes from "../ItemFormLangTabs.module.scss";
import { Twemoji } from "../../../../helpers";
import { EntityType } from "../../../../models/Ndtrc";
import { useTranslation } from "react-i18next";
import { SingleSelectOption } from "../../../../models/SelectOption";
import { Lang } from "../../../../models/Lang";
import { AliasOption } from "../../../../models/NdtrcAlias/NdtrcAlias";

const ItemFormAliasTabs = (props: {
  entityType: EntityType;
  itemId: string;
  readOnly: boolean;
  activeAlias: string;
  onButtonClick: (version: string) => any;
  availableAliases: SingleSelectOption[];
  onDeletion: (versionRemoved: Lang | AliasOption) => any;
}) => {
  const { t } = useTranslation();

  const tabs = props.availableAliases.map((aliasOption, index) => {
    let myClass = "";
    if (aliasOption.label === props.activeAlias) {
      myClass = classes.active;
    }
    return (
      <BooleanInputPopover
        key={aliasOption.value}
        onBooleanSubmit={(deletion) => {
          if (deletion) props.onDeletion(aliasOption);
        }}
        prompt={t("form.removeAlias")}
        type={"danger"}
      >
        {(setPopover: any, onButtonClickHandler: any) => (
          <div
            className={`${classes.EventFormLangTab} ${myClass}`}
            key={aliasOption.value}
            onClick={() => {
              props.onButtonClick(aliasOption.label);
            }}
            ref={(e) => {
              setPopover(e);
            }}
            onContextMenu={(e) => {
              if (props.readOnly) {
                return;
              }
              e.preventDefault();
              onButtonClickHandler();
            }}
          >
            <Twemoji>📗</Twemoji> {aliasOption.label}
          </div>
        )}
      </BooleanInputPopover>
    );
  });
  return <div>{tabs}</div>;
};

export default ItemFormAliasTabs;
