import NavigationButton from "../buttons/NavigationButton/NavigationButton";
import { TiChevronLeft, TiChevronRight } from "react-icons/ti";
import TextInputPopover from "../popovers/TextInputPopover/TextInputPopover";
import React from "react";
import { useTranslation } from "react-i18next";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";

const PaginationControls = (props: {
  page: number;
  size: number;
  hits: number;
  onChange: (page: number) => any;
}) => {
  const { t } = useTranslation();

  const setPage = (page: number) => {
    props.onChange(page);
    console.log("set page", page);
  };

  const pageDecrement = () => {
    if (props.hits < 1 || props.page < 0) {
      alert(t("root.pageDoesNotExist"));
      return;
    }
    setPage(props.page - 1);
  };
  const pageIncrement = () => {
    if (props.hits < 1 || props.page * props.size >= props.hits) {
      alert(t("root.pageDoesNotExist"));
      return;
    }

    setPage(props.page + 1);
  };

  const pageMin = () => {
    setPage(0);
  };
  const pageMax = () => {
    setPage(Math.floor(props.hits / props.size));
  };

  const inputPage = (pageString: string) => {
    const pageNumber = parseInt(pageString);
    if (!pageNumber) {
      alert("Pagina bestaat niet");
      return;
    }

    if (props.hits < 1 || (pageNumber - 1) * props.size >= props.hits) {
      alert("Pagina bestaat niet");
      return;
    }
    setPage(pageNumber - 1);
  };

  return (
    <>
      <NavigationButton action={pageMin} disabled={props.page < 1}>
        <BiChevronsLeft />
      </NavigationButton>
      <NavigationButton action={pageDecrement} disabled={props.page < 1}>
        <TiChevronLeft />
      </NavigationButton>

      <TextInputPopover
        onTextSubmit={(string) => {
          inputPage(string);
        }}
        placeholder={"Pagina nummer"}
      >
        {(registerPopoverOrigin: any, onButtonClickHandler: any) => (
          <span
            className="paginationCount"
            ref={registerPopoverOrigin}
            onClick={onButtonClickHandler}
          >
            {props.page + 1}/{Math.ceil(props.hits / props.size)}
          </span>
        )}
      </TextInputPopover>

      <NavigationButton
        action={pageIncrement}
        disabled={(props.page + 1) * props.size >= props.hits}
      >
        <TiChevronRight />
      </NavigationButton>
      <NavigationButton
        action={pageMax}
        disabled={(props.page + 1) * props.size >= props.hits}
      >
        <BiChevronsRight />
      </NavigationButton>
    </>
  );
};

export default PaginationControls;
