import React from "react";
import ItemsBrowser from "./ItemsBrowser";
import {
  performFetchRoutes,
  selectError,
  selectLoading,
  selectRoutes,
  selectTuningProps,
} from "../../store/routes/routesSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TbRoute } from "react-icons/tb";

const RoutesBrowser = (props: {}) => {
  const routes = useSelector(selectRoutes);
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);
  const tuningProps = useSelector(selectTuningProps);

  const { t } = useTranslation();
  const header = (
    <>
      <TbRoute /> {t("root.routes")}
    </>
  );
  return (
    <ItemsBrowser
      items={routes}
      error={error}
      loading={loading}
      tuningProps={tuningProps}
      performFetchItems={performFetchRoutes}
      header={header}
      entityType={"ROUTE"}
    />
  );
};

export default RoutesBrowser;
