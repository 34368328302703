import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./AddressWidget.module.scss";
import { Controller } from "react-hook-form";
import { CountryCodes } from "models/Ndtrc";
import Select from "react-select";

interface AddressCustomTranslations {
  street?: string;
  housenr?: string;
  zipcode?: string;
  district?: string;
  neighbourhood?: string;
  city?: string;
  country?: string;
}
const AddressWidget = (props: {
  namespace: string;
  formMethods: any;
  hideCountryField?: boolean;
  customTranslations?: AddressCustomTranslations;
}) => {
  const { t } = useTranslation();
  const { register, control } = props.formMethods;

  return (
    <div className={`${classes.AddressWidget} ff-address-widget`}>
      <div className={classes.streetWithHousenr}>
        <div>
          <label>
            {props.customTranslations?.street
              ? props.customTranslations?.street
              : t("address.street")}
          </label>
          <input {...register(`${props.namespace}street`)} />
        </div>
        <div>
          <label>
            {props.customTranslations?.housenr
              ? props.customTranslations?.housenr
              : t("address.housenr")}
          </label>
          <input {...register(`${props.namespace}housenr`)} />
        </div>
      </div>
      <label>
        {props.customTranslations?.zipcode
          ? props.customTranslations?.zipcode
          : t("address.zipcode")}
      </label>
      <input {...register(`${props.namespace}zipcode`)} />
      <div className={classes.neighbourhoodAndDistrict}>
        <div>
          <label>
            {props.customTranslations?.district
              ? props.customTranslations?.district
              : t("address.district")}
          </label>
          <input {...register(`${props.namespace}district`)} />
        </div>
        <div>
          <label>
            {props.customTranslations?.neighbourhood
              ? props.customTranslations?.neighbourhood
              : t("address.neighbourhood")}
          </label>
          <input {...register(`${props.namespace}neighbourhood`)} />
        </div>
      </div>
      <label>
        {props.customTranslations?.city
          ? props.customTranslations?.city
          : t("address.city")}
      </label>
      <input {...register(`${props.namespace}city`)} />

      {!props.hideCountryField && (
        <>
          <label>
            {props.customTranslations?.country
              ? props.customTranslations?.country
              : t("address.country")}
          </label>
          <Controller
            name={`${props.namespace}country`}
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => {
              const currentSelection = CountryCodes.find(
                (item) => item.value === value,
              );
              return (
                <Select
                  className={classes.singleDateStatusSelect}
                  value={currentSelection}
                  isClearable
                  options={CountryCodes}
                  menuPlacement={"auto"}
                  placeholder={`${t("form.select")}...`}
                  styles={{
                    menu: (provided: any, state) => {
                      return { ...provided, zIndex: 2 };
                    },
                  }}
                  onChange={(item: any) => {
                    onChange(item?.value);
                  }}
                />
              );
            }}
          />
        </>
      )}
    </div>
  );
};

export default AddressWidget;
