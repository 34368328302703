import React from "react";
import {
  IntUrlDetails,
  UrlServiceType,
} from "models/Ndtrc";
import classes from "./UrlsWidget.module.scss";
import { IoMdAddCircle } from "react-icons/io";
import { Lang } from "models/Lang";
import UrlWidget from "./UrlWidget";
import UrlInputPopover from "./UrlIputPopover/UrlInputPopover";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { isValidUrl } from "helpers";

const UrlsWidget = (props: {
  urls: IntUrlDetails[];
  onChange: (value: IntUrlDetails[]) => void;
  className: string;
  lang?: Lang; // Otherwise, language is dynamically set
}) => {
  // Check if all URLs have id's
  const idError = props.urls.some((url) => !url.id);
  if (idError) {
    console.error(
      "URLs must have id's, currently missing",
      JSON.stringify(props.urls)
    );
  }
  const { t } = useTranslation();

  const urls: IntUrlDetails[] = props.urls || [];

  const handleUrlAddition = (
    url: string,
    urlType: UrlServiceType,
    lang?: Lang
  ) => {
    // Check if URL is valid; if not, attempt to fix
    const validWithSchema = isValidUrl(url);
    if (!validWithSchema) {
      const validWithoutSchema = isValidUrl(`https://${url}`);
      if (validWithoutSchema) {
        url = `https://${url}`;
      } else {
        alert(`${t("form.incorrectUrl")}: ${url}`);
        return;
      }
    }

    const myLang = lang || props.lang;
    if (!myLang) {
      // Should not occur
      alert(`${t("form.noUrlLang")}`);
      return;
    }

    const newUrlEntry: IntUrlDetails = {
      targetLanguage: myLang,
      url: url,
      urlServiceType: urlType,
      id: uuidv4(),
    };

    // Check if URL is not already in list. If so: block.
    if (urls.find((urlItem) => urlItem.url === newUrlEntry.url)) {
      alert(`${t("form.urlAlreadyListed")}: ${url}`);
      return;
    }

    props.onChange(urls.concat(newUrlEntry));
  };

  const handleUrlDeletion = (id: string) => {
    props.onChange(urls.filter((urlDetails) => urlDetails.id !== id));
  };

  const handleUrlTypeChange = (
    id: string,
    newType: { value: UrlServiceType | null; label: string } | null
  ) => {
    const transformedUrl = urls.find((url) => url.id === id);
    if (!transformedUrl) {
      return;
    }
    transformedUrl.urlServiceType = newType?.value || null;
    let transformedUrls = [...urls];
    transformedUrls[transformedUrls.findIndex((url) => url.id === id)] =
      transformedUrl;
    props.onChange(transformedUrls);
  };

  const handleUrlLangChange = (
    id: string,
    newLang: { value: Lang | null; label: string } | null
  ) => {
    const transformedUrl = urls.find((url) => url.id === id);
    if (!transformedUrl) {
      return;
    }
    transformedUrl.targetLanguage = newLang?.value || undefined;
    let transformedUrls = [...urls];
    transformedUrls[transformedUrls.findIndex((url) => url.id === id)] =
      transformedUrl;
    props.onChange(transformedUrls);
  };

  return (
    <div className={`${classes.UrlsWidget} ${props.className}`}>
      {urls.map((urlDetails: IntUrlDetails, index) => {
        return (
          <UrlWidget
            key={urlDetails.id}
            urlDetails={urlDetails}
            onUrlDeletion={handleUrlDeletion}
            onUrlTypeChange={handleUrlTypeChange}
            changeableLang
            onUrlLangChange={handleUrlLangChange}
          />
        );
      })}
      <UrlInputPopover
        onTextSubmit={handleUrlAddition}
        placeholder={t("root.pasteUrl")}
        defaultLang={props.lang}
      >
        {(registerPopoverOrigin: any, onButtonClickHandler: any) => (
          <div
            onClick={onButtonClickHandler}
            className={classes.add}
            ref={registerPopoverOrigin}
          >
            <IoMdAddCircle />
          </div>
        )}
      </UrlInputPopover>
    </div>
  );
};

export default UrlsWidget;
