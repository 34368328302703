import { ExtLangString } from "./Ndtrc";

// DOCUMENTATION: Original property types used
// export enum DutchPropertyDatatype {
//   Cijfer = "Cijfer",
//   Datum = "Datum",
//   Decimaal = "Decimaal",
//   Ja = "Ja",
//   JaNee = "Ja/Nee",
//   Keuze = "Keuze",
//   Meerkeuze = "Meerkeuze",
//   Text = "Text",
//   Text1Regel = "Text (1 regel)",
// }

export enum PropertyDatatype {
  integer = "integer",
  date = "date",
  decimal = "decimal",
  yes = "yes",
  yesno = "yesno",
  choice = "choice",
  multichoice = "multichoice",
  freetext = "freetext",
}

export interface GenericProperty {
  name: string;
  id: string;
  propertytranslations: Propertytranslations;
}

export interface PropertyCategory extends GenericProperty {
  properties: Property[];
} // Sometimes datatype is mentioned in options - this is ignored in NDTRC interface

export interface Property extends GenericProperty {
  datatype: PropertyDatatype;
  unit?: string;
  options?: Option[];
}

export interface Option extends GenericProperty {} // Sometimes datatype is mentioned in options - this is ignored in NDTRC interface

export interface Propertytranslations {
  Propertytranslation: ExtLangString; // Might become array in future; now converted as object
}
