import React, { useState } from "react";
import classes from "../TuneWidget/TuneWidget.module.scss";
import { FaDownload } from "react-icons/fa";
import { Dropdown } from "../Dropdown/Dropdown";
import { RiFileExcel2Fill } from "react-icons/ri";

const ExportUsersButton = (props: { onClick: () => any }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <button className={`${classes.Button}`} style={{ top: "5px" }}>
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <div onClick={toggleOpen} className={classes.DropdownContent}>
            <FaDownload />
          </div>
        }
      >
        <div
          className={`${classes.FilterInterface} ${classes.exportButtonDropdown}`}
        >
          <div
            className={`${classes.PresetButton}`}
            onClick={() => {
              toggleOpen();
              props.onClick();
            }}
          >
            <RiFileExcel2Fill style={{ color: "green" }} /> <span>Excel</span>
          </div>
        </div>
      </Dropdown>
    </button>
  );
};

export default ExportUsersButton;
