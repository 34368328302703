import React from "react";

import "./LoginAside.scss";
import { useSelector } from "react-redux";
import { selectAccountName } from "../../store/theme/themeSlice";
import { useTranslation } from "react-i18next";
import classes from "./Login.module.scss";
import { VERSION } from "../../version";

const LoginAside = (props: any) => {
  const accountName = useSelector(selectAccountName);

  const mailTo = "mailto:support@thefeedfactory.nl";

  const { t } = useTranslation();

  return (
    <aside className={"ff-login-aside"}>
      <figure className={"logo"}>
        <img src={"/merk-wit-nomargins.png"} alt={"The Feed Factory"} />
      </figure>
      <span className={classes.version} title={VERSION.raw}>
        v{VERSION.version}
      </span>{" "}
      {accountName && <p className={"organisation"}>{accountName}</p>}
      <span className={"spacer"} />
      <footer className={"footer"}>
        <ul className={"footer-links"}>
          <li className={"footer-link-item"}>
            <a
              className={"footer-link"}
              href={"https://support.thefeedfactory.nl/help/nl-nl"}
              target={"_blank"}
            >
              Help
            </a>
          </li>
          <li className={"footer-link-item"}>
            <a className={"footer-link"} href={mailTo}>
              Contact
            </a>
          </li>
        </ul>
        <ul className={"footer-links"}>
          <li className={"footer-link-item"}>
            <a
              className={"footer-link"}
              href={"https://thefeedfactory.nl"}
              target={"_blank"}
            >
              {t("login.whatIsTheFeedFactory")}
            </a>
          </li>
        </ul>
        <ul className={"footer-links"}>
          <li className={"footer-link-item"}>
            <a
              className={"footer-link"}
              href={"https://www.eventconnectors.nl"}
              target={"_blank"}
            >
              {t("login.eventConnectors")}
            </a>
          </li>
        </ul>
      </footer>
    </aside>
  );
};

export default LoginAside;
