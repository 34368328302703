import React from "react";
import {
  ExtLabelLangString,
  FileType,
  IntFileItem,
  IntFileItem as FileItem,
} from "models/Ndtrc";
import classes from "./MediaWidget.module.scss";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import ItemContainer from "./ItemContainer/ItemContainer";
import { useDraggableInPortal } from "helpers";
import MediaPreview from "components/MediaPreview";
import { useTranslation } from "react-i18next";
import AddMediaPane from "./AddMediaModal/AddMediaPane";
import { Lang } from "models/Lang";
import { MediaRequirements } from "models/MediaRequirements";

interface NewItem {
  filename: string;
  url: string;
  type: FileType;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "rgba(255,255,255,.2)",
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  position: "static",

  // styles we need to apply on draggables
  ...draggableStyle,
});

// Source: https://stackoverflow.com/a/8260383
const youtubeParser = (url: string): string | null => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : null;
};

// Source: https://stackoverflow.com/a/11660798
const vimeoParser = (url: string): string | null => {
  const regExp =
    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  const parseUrl = regExp.exec(url);
  return parseUrl ? parseUrl[5] : null;
};

const imageParser = (url: string): string | null => {
  const subStrings = url.split("/");
  return subStrings[subStrings.length - 1];
};

const MediaWidget = (props: {
  mediaArray: FileItem[];
  onChange: (value: FileItem[]) => void;
  imageFileType?: FileType;
  imageUploadConditionsText?: string;
  hideImageInput?: boolean;
  hideYoutubeInput?: boolean;
  hideVimeoInput?: boolean;
  requirements?: MediaRequirements;
  showCopyrightAsRequired?: boolean;
  maxItems?: number; // undefined or -0 is infinite
  readonly?: boolean;
  primaryLang?: Lang;
  standardImageLibrary?: IntFileItem[];
}) => {
  // Needed to deal with offset on dragged items
  // See https://github.com/atlassian/react-beautiful-dnd/issues/128
  const renderDraggable = useDraggableInPortal();
  const { t } = useTranslation();

  const onRemoveHandler = (id: string) => {
    const updatedMediaArray = props.mediaArray.filter((item) => {
      return item.hlink !== id;
    });
    props.onChange(updatedMediaArray);
  };

  const onChangeHandler = (
    id: string,
    key: string,
    value: string | ExtLabelLangString[] | null,
  ) => {
    const updatedMediaArray = props.mediaArray.map((item) => {
      if (item.hlink !== id) {
        return item;
      }

      // If field left empty, should return null instead of empty string
      if (value === "") {
        value = null;
      }

      if (key === "copyright" && typeof value === "string") {
        return { ...item, [key]: value };
      } else if (key === "title.label" && typeof value === "string") {
        return { ...item, title: { label: value || "" } };
      } else if (key === "title.titleTranslations") {
        return {
          ...item,
          title: {
            label: item.title?.label || "",
            titleTranslations: value || [],
          },
        };
      } else {
        console.error("Unknown field");
        return { ...item };
      }
    });

    // @ts-ignore
    props.onChange(updatedMediaArray);
  };

  const onAddHandler = (type: FileType, urls: string | string[]) => {
    const newItems: NewItem[] = [];

    if (typeof urls === "string") {
      urls = [urls];
    }

    // console.log(type, urls); // DEBUG

    switch (type) {
      case "youtube":
        urls.forEach((url) => {
          // filename is video id here
          const filename = youtubeParser(url);
          if (filename) {
            newItems.push({
              filename: filename,
              url: `https://www.youtube.com/watch?v=${filename}`,
              type: type,
            });
          } else {
            alert(`${t("form.urlIncorrect")}: "${url}"`);
          }
        });
        break;
      case "vimeo":
        urls.forEach((url) => {
          const filename = vimeoParser(url);
          if (filename) {
            newItems.push({
              filename: filename,
              url: `https://vimeo.com/${filename}`,
              type: type,
            });
          } else {
            alert(`${t("form.urlIncorrect")}: "${url}"`);
          }
        });
        break;
      case "jpeg":
      case "png":
      case "webp":
      case "jpegOrPngOrWebp":
        urls.forEach((url) => {
          const filename = imageParser(url);
          if (filename) {
            // Check file extension (jpeg or png)
            const splitUrl = url.split(".");

            newItems.push({
              filename: filename,
              url: url,
              type: splitUrl[splitUrl.length - 1].toLowerCase() as FileType,
            });
          } else {
            alert(`${t("form.urlIncorrect")}: "${url}"`);
          }
        });
        break;
      default:
        alert(`${t("form.unsupportedFileType")} ${type}`);
        return;
    }
    // console.log(newItems); // DEBUG

    const updatedMediaArray = Array.from(props.mediaArray);

    const newItemsTransformed: FileItem[] = newItems.map((item) => {
      return {
        copyright: null,
        filename: item.filename,
        filetype: item.type,
        hlink: item.url,
        main: null,
        mediatype: type === "youtube" || type === "vimeo" ? "video" : "photo",
        targetLanguage: null,
        title: null,
        trcid: null,
      };
    });

    // console.log(props.mediaArray, updatedMediaArray, newItemsTransformed); // DEBUG

    props.onChange(updatedMediaArray.concat(newItemsTransformed));
  };

  const onRawAddHandler = (newItems: FileItem[] | FileItem) => {
    if (!Array.isArray(newItems)) {
      newItems = [newItems];
    }
    const updatedMediaArray = Array.from(props.mediaArray);
    updatedMediaArray.push(...newItems);
    props.onChange(updatedMediaArray);
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const updatedMediaArray = reorder(
      props.mediaArray,
      result.source.index,
      result.destination.index,
    );

    props.onChange(updatedMediaArray);
  };

  // TODO: replace key with id once available
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="media-widget" direction="horizontal">
        {(provided, snapshot) => (
          <div
            className={classes.MediaWidget}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            {props.mediaArray.map((mediaItem, index) => (
              <Draggable
                key={mediaItem.hlink}
                draggableId={mediaItem.hlink}
                index={index}
                isDragDisabled={props.readonly}
              >
                {renderDraggable((provided: any, snapshot: any) => (
                  <ItemContainer
                    ref={provided.innerRef}
                    draggableProps={provided.draggableProps}
                    dragHandleProps={provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                    onRemove={onRemoveHandler}
                    key={mediaItem.hlink}
                    item={mediaItem}
                    isDragging={snapshot.isDragging}
                    handleItemChange={onChangeHandler}
                    showCopyrightAsRequired={props?.showCopyrightAsRequired}
                    disabled={props?.readonly}
                    primaryLang={props?.primaryLang}
                  >
                    <MediaPreview mediaItem={mediaItem} />
                  </ItemContainer>
                ))}
              </Draggable>
            ))}
            {(!props.maxItems || props.mediaArray.length < props.maxItems) &&
              !props.readonly && (
                <AddMediaPane
                  onAddMediaHandler={onAddHandler}
                  onRawAddMediaHandler={onRawAddHandler}
                  hidden={snapshot.isDraggingOver}
                  imageFileType={props.imageFileType}
                  hideImageInput={props.hideImageInput}
                  hideYoutubeInput={props.hideYoutubeInput}
                  hideVimeoInput={props.hideVimeoInput}
                  imageUploadConditionsText={props.imageUploadConditionsText}
                  requirements={props.requirements}
                  standardImageLibrary={props.standardImageLibrary}
                />
              )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default MediaWidget;
