import React from "react";
import classes from "./PricesWidget.module.scss";
import {
  ExtLangString,
  IntPriceElement,
  PriceDescriptionTypes,
} from "models/Ndtrc";
import { Controller, useFieldArray } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import { TiDelete } from "react-icons/ti";
import { RiDeleteBin3Line } from "react-icons/ri";
import { IoMdAddCircle } from "react-icons/io";

import { useTranslation } from "react-i18next";
import { CurrencyInput, LangInputWrapper } from "components/FormWidgets/inputs";
import { Lang } from "models/Lang";
import { HoverDesc } from "components/HoverDesc";
import { cleanCurrencyString } from "helpers";

const generateDefaultPriceElement = (): IntPriceElement => {
  return {
    freeentrance: false,
    priceValue: { from: null, until: null },
    id: uuidv4(),
  };
};

const PricesWidget = (props: {
  formMethods: any,
  namespace: string,
  lang: Lang,
}) => {
  const { t } = useTranslation();
  const control: any = props.formMethods.control; // required, as field array does not like input via props
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${props.namespace}`, // unique name for your Field Array
    keyName: "id", // default to "id", you can change the key name
  });

  // console.log(props.formMethods.formState.errors); // DEBUG

  return (
    <div className={classes.PricesWidget}>
      <h3>
        {t("item.priceElements")}
        <HoverDesc description="item.priceElements.desc" />
      </h3>
      {fields.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>{t("item.priceElements.free")}</th>
              <th>{t("item.priceElements.priceValue.from")}</th>
              <th>{t("item.priceElements.priceValue.until")}</th>
              <th>{t("item.priceElements.priceValue.description")}</th>
              <th>{t("item.priceElements.priceValue.comments")}</th>
              <th>
                <div
                  className={classes.deleteAll}
                  onClick={() => {
                    remove();
                  }}
                >
                  <div>
                    <RiDeleteBin3Line />
                    <span>{t("priceWidget.deleteAll")}</span>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              // @ts-ignore // Type SingleDate not allowed as description, but is correct
              fields.map((priceEl: IntPriceElement<ExtLangString[]>, index) => {
                // console.log(singleDate); // DEBUG

                const freeEntrance = props.formMethods.watch(
                  `${props.namespace}[${index}].freeentrance`,
                );
                return (
                  <tr key={priceEl.id}>
                    <td>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => {
                          return (
                            <input
                              type="checkbox"
                              checked={value}
                              onChange={() => {
                                onChange(!value);
                              }}
                            />
                          );
                        }}
                        name={`${props.namespace}[${index}].freeentrance`}
                        control={control}
                        defaultValue={priceEl.freeentrance || false}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`${props.namespace}[${index}].priceValue.from`}
                        defaultValue={priceEl.priceValue?.from || ""}
                        render={({ field: { onChange, value } }) => (
                          <CurrencyInput
                            value={value}
                            disabled={freeEntrance}
                            onChange={(val) => {
                              onChange(val);
                              const triggerList = [
                                `${props.namespace}[${index}].priceValue.until`,
                              ];
                              props.formMethods.trigger(triggerList);
                            }}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`${props.namespace}[${index}].priceValue.until`}
                        defaultValue={priceEl.priceValue?.until || ""}
                        render={({ field: { onChange, value } }) => (
                          <CurrencyInput
                            value={value}
                            disabled={freeEntrance}
                            onChange={(val) => {
                              onChange(val);
                              const triggerList = [
                                `${props.namespace}[${index}].priceValue.from`,
                              ];
                              props.formMethods.trigger(triggerList);
                            }}
                          />
                        )}
                        // @ts-ignore
                        validate={(val: any) => {
                          if (!val) {
                            return true;
                          }

                          const fromPrice = props.formMethods.getValues(
                            `${props.namespace}[${index}].priceValue.from`,
                          );
                          if (!fromPrice) {
                            return false;
                          }

                          return (
                            Number.parseFloat(
                              cleanCurrencyString(val.toString()),
                            ) >
                            Number.parseFloat(
                              cleanCurrencyString(fromPrice.toString()),
                            )
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Controller
                        name={`${props.namespace}[${index}].description.value`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => {
                          const currentSelection = PriceDescriptionTypes.find(
                            (item) => item.value === value,
                          );
                          return (
                            <Select
                              className={classes.singleDateStatusSelect}
                              value={currentSelection}
                              isClearable
                              options={PriceDescriptionTypes}
                              menuPlacement={"auto"}
                              placeholder={`${t("form.select")}...`}
                              styles={{
                                menu: (provided: any, state) => {
                                  return { ...provided, zIndex: 2 };
                                },
                              }}
                              onChange={(item: any) => {
                                onChange(item?.value);
                              }}
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <LangInputWrapper
                        lang={props.lang}
                        name={`${props.namespace}[${index}].comments[0]`}
                        formMethods={props.formMethods}
                        defaultValue={priceEl.comments?.[0]}
                        onlyText
                      />
                    </td>
                    <td>
                      <div
                        onClick={() => remove(index)}
                        className={classes.danger}
                      >
                        <TiDelete />
                      </div>
                    </td>
                    {props.formMethods.formState.errors.ndtrcEntry
                      ?.priceElements?.[index] && (
                      <td>
                        <div className={classes.errorInformation}>
                          {[
                            props.formMethods.formState.errors.ndtrcEntry
                              ?.priceElements[index]?.priceValue?.from &&
                              t("item.priceElements.priceValue.fromError"),
                            props.formMethods.formState.errors.ndtrcEntry
                              ?.priceElements[index]?.priceValue?.until &&
                              t("item.priceElements.priceValue.untilError"),
                          ]
                            .filter((item: any) => item)
                            .join(" & ")}
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      )}
      <div
        onClick={() => {
          append(generateDefaultPriceElement());
        }}
        className={classes.add}
      >
        <IoMdAddCircle />
      </div>
    </div>
  );
};

export default PricesWidget;
