import { useMemo, useState } from "react";

import { MoveHandler, RenameHandler, CreateHandler, DeleteHandler } from "react-arborist";
import { v4 } from 'uuid';
import { CategoryTree } from "./CategoryTree";

export function useCategorizationTree<T>(initialData: readonly T[]) {
    const [data, setData] = useState(initialData);
    const tree = useMemo(
        () =>
            new CategoryTree<// @ts-ignore
                T>(data),
        [data]
    );

    const onMove: MoveHandler<T> = (args: {
        dragIds: string[];
        parentId: null | string;
        index: number;
    }) => {
        for (const id of args.dragIds) {
            tree.move({ id, parentId: args.parentId, index: args.index });
        }
        setData(tree.data);
    };

    const onRename: RenameHandler<T> = ({ name, id }) => {
        tree.update({ id, changes: { name } as any });
        setData(tree.data);
    };

    const onCreate: CreateHandler<T> = ({ parentId, index, type }) => {
        const newId = v4();
        const newCatId = v4();

        const data = { id: newId, deprecated: false, categorizationId: newCatId, name: "<Empty>", parentId } as any;
        if (type === "internal") data.children = [];
        tree.create({ parentId, index, data });
        setData(tree.data);
        return data;
    };

    const addCategory = (type: string) => {
        const newId = v4();
        const newCatId = v4();

        const data = { id: newId, deprecated: false, categorizationId: newCatId, name: "<Empty>", entityType: type } as any;
        data.children = [];
        tree.create({ parentId: null, index: 0, data: data });
        setData(tree.data);
        return data;
    };

    const onDelete: DeleteHandler<T> = (args: { ids: string[] }) => {
        args.ids.forEach((id) => tree.drop({ id }));
        setData(tree.data);
    };

    const controller = { onMove, onRename, onCreate, onDelete };

    return [data, controller, addCategory] as const;
}
