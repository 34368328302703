import React from "react";
import classes from "./ToastContent.module.scss";
import { ButtonType, PopupButton } from "components/buttons";
import { ButtonSize } from "components/buttons/ButtonProps";

type ToastContentProps = {
  onOpenModal: () => void;
};

const ToastContent = ({ onOpenModal }: ToastContentProps) => {
  return (
    <div className={classes.container}>
      <p>There could be duplicate entries for this item</p>
      <PopupButton
        action={onOpenModal}
        type={ButtonType.Warning}
        size={ButtonSize.Small}
        className={classes.button}
      >
        View
      </PopupButton>
    </div>
  );
};

export default ToastContent;
