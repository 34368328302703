import React from "react";
import { useTranslation } from "react-i18next";
import ItemsBrowser from "./ItemsBrowser";
import {
  performFetchLocations,
  selectError,
  selectLoading,
  selectLocations,
  selectTuningProps,
} from "../../store/locations/locationsSlice";
import { MdPlace } from "react-icons/md";
import { useSelector } from "react-redux";

const LocationsBrowser = (props: {}) => {
  const locations = useSelector(selectLocations);
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);
  const tuningProps = useSelector(selectTuningProps);

  const { t } = useTranslation();
  const header = (
    <>
      <MdPlace /> {t("root.locations")}
    </>
  );
  return (
    <ItemsBrowser
      items={locations}
      error={error}
      loading={loading}
      tuningProps={tuningProps}
      performFetchItems={performFetchLocations}
      header={header}
      entityType={"LOCATIE"}
    />
  );
};

export default LocationsBrowser;
