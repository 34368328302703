export const cleanCurrencyString = (val: string) => {
  let cleanedValue = val
    .trim()
    .replace(/,/g, ".")
    .replace(/^0{2,1000}/g, "0");

  const isValidFloatValue =
    cleanedValue.length > 0 && !isNaN(parseFloat(cleanedValue));
  if (isValidFloatValue) {
    const alreadyHasDecimals = cleanedValue.includes(".");
    if (alreadyHasDecimals) {
      const [_, decimals] = cleanedValue.split(".");
      const hasOnlyOneDecimal = decimals.length === 1;
      if (hasOnlyOneDecimal) {
        cleanedValue += "0";
      }
    } else {
      cleanedValue += ".00";
    }
  }

  return cleanedValue;
};
