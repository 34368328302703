import React, { useEffect, useState } from "react";
import { InternalRouteItemModel } from "models/Ndtrc";
import classes from "./ListItem.module.scss";
import {
  MdApproval,
  MdArchive,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdComment,
  MdDelete,
  MdMoreHoriz,
} from "react-icons/md";
import {
  CategorizationSourceDictionary,
  getCategoryById,
  Twemoji,
  useCategorizationSourceDictionary,
} from "helpers";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Lang, langMetadata } from "models/Lang";
import { format } from "date-fns";
import ColorHash from "color-hash";

const RouteListItem = (props: {
  lang: Lang;
  entry: InternalRouteItemModel;
  active?: boolean;
  className?: string;
  openInNewTab?: boolean;
  userorganisations: string[];
  eventUrl?: string | null;
  locationUrl?: string | null;
  minimalDisplay?: boolean;
  isSelected: boolean;
  onSelection: (isSelected: boolean) => any;
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const colorHash = new ColorHash({ lightness: 0.8 });
  const categoryOntology: CategorizationSourceDictionary | null =
    useCategorizationSourceDictionary();

  useEffect(() => {
    setIsSelected(props.isSelected);
  }, [props.isSelected]);

  const { t } = useTranslation();

  const history = useHistory();
  const entry: InternalRouteItemModel = props.entry;

  const entryHasComment =
    entry.links?.find((link) => link.type === "comment") !== undefined;

  // Check if preferred language is available. If not: show primary language of item.
  let trcItemDetails = null;
  if (
    entry.translations?.availableLanguages?.find(
      (avLang) => avLang === props.lang
    ) &&
    entry.trcItemDetails[props.lang]
  ) {
    trcItemDetails = entry.trcItemDetails[props.lang];
  } else if (entry.translations?.primaryLanguage) {
    trcItemDetails = entry.trcItemDetails[entry.translations?.primaryLanguage];
  }

  const listItem = (
    <>
      <td
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className={classes.checkboxTd}
      >
        <div
          onClick={(e) => {
            props.onSelection(!isSelected);
          }}
        >
          {isSelected ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
        </div>
      </td>
      <td>
        <h2 className={`${classes.selectable} ${classes.eventTitle}`}>
          {trcItemDetails?.title || <em>{t("event.noTitleAvailable")}</em>}
          {entry.translations?.availableLanguages?.map((lang) => {
            if (!langMetadata?.[lang]?.flag) {
              return null;
            }
            return <Twemoji key={lang}>{langMetadata[lang].flag}</Twemoji>;
          })}
          {entry.links?.find((item) => {
            return item.type === "alias"; // Careful: does not check alias label currently
          }) && (
            <span className={classes.printEmoji}>
              <Twemoji key={"print"}>📗</Twemoji>
            </span>
          )}
        </h2>
      </td>
      <td>
        {entry.routeInfo?.start?.city && (
          <div>{entry.routeInfo?.start?.city}</div>
        )}
      </td>
      <td>
        {entry.routeInfo?.distanceInKilometers && (
          <div>{Math.round(entry.routeInfo?.distanceInKilometers)} km</div>
        )}
        {entry.routeInfo?.durationInMinutes && (
          <div>{entry.routeInfo?.durationInMinutes} min</div>
        )}
      </td>
      <td>
        {entry.trcItemCategories?.types?.slice(0, 4).map((cat) => {
          return categoryOntology !== null ? (
            <span
              key={cat.catid}
              className={[classes.badge, classes.category].join(" ")}
              style={{ backgroundColor: colorHash.hex(cat.catid) }}
            >
              {getCategoryById(cat.catid, categoryOntology)}
            </span>
          ) : null;
        })}
        {entry.trcItemCategories?.types &&
          entry.trcItemCategories?.types.length > 4 && (
            <div
              className={[
                classes.badge,
                classes.category,
                classes.moreCategories,
              ].join(" ")}
            >
              <MdMoreHoriz />
            </div>
          )}
      </td>
      <td className={classes.keywords}>
        {entry.keywords?.split(";").map((keyword) => {
          return (
            <span
              key={keyword}
              className={[classes.badge, classes.category].join(" ")}
              style={{ backgroundColor: colorHash.hex(keyword) }}
            >
              {keyword}
            </span>
          );
        })}
      </td>
      <td className={classes.markers}>
        {entry.markers?.split(";").map((marker) => {
          return (
            <span
              key={marker}
              className={[classes.badge, classes.category].join(" ")}
              style={{ backgroundColor: colorHash.hex(marker) }}
            >
              {marker}
            </span>
          );
        })}
      </td>
      <td>
        {entry.lastupdated && (
          <>{format(new Date(entry.lastupdated), "dd-MM-yyyy HH:mm")}</>
        )}
      </td>
      <td className={classes.validator}>{entry.validator}</td>
      <td
        className={
          classes.comment + ` ${entryHasComment ? classes.hasComment : ""}`
        }
      >
        {entryHasComment && <MdComment />}
      </td>
      <td
        className={
          classes.wfStatus +
          " " +
          [
            entry.wfstatus === "approved" ? classes.published : "",
            entry.wfstatus === "deleted" ? classes.deleted : "",
            entry.wfstatus === "archived" ? classes.archived : "",
            entry.wfstatus === "readyforvalidation"
              ? classes.readyforvalidation
              : "",
          ].join(" ")
        }
      >
        {entry.wfstatus == "approved" && <MdCheckBox />}
        {entry.wfstatus == "deleted" && <MdDelete />}
        {entry.wfstatus == "archived" && <MdArchive />}
        {entry.wfstatus == "readyforvalidation" && <MdApproval />}
      </td>
    </>
  );

  if (!props.eventUrl) {
    return <div className={`noStyle ${props.className || ""}`}>{listItem}</div>;
  } else {
    return (
      <tr
        className={[
          classes.ListItem,
          classes.hoverable,
          props.active ? classes.active : "",
        ].join(" ")}
      >
        <Link
          to={props.eventUrl}
          className={`noStyle ${props.className || ""}`}
          target={props.openInNewTab ? "_target" : "_self"}
        >
          {listItem}
        </Link>
      </tr>
    );
  }
};

export default RouteListItem;
