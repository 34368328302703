import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../store";
import { feedFactoryAxios } from "helpers";
import { AxiosError } from "axios";
import { Feed } from "models/Feed/Feed";

interface IFeedsSlice {
  feeds: Feed[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: IFeedsSlice = {
  feeds: null,
  loading: false,
  error: null,
};

export const FeedsSlice = createSlice({
  name: "Feeds",
  initialState,
  reducers: {
    fetchFeedsStart: (state) => {
      state.loading = true;
      state.error = null;

      return state;
    },
    fetchFeedsSuccess: (state, action: PayloadAction<Feed[]>) => {
      state.loading = false;
      state.feeds = action.payload;
      return state;
    },
    fetchFeedsFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;

      return state;
    },
  },
});

export const { fetchFeedsStart, fetchFeedsSuccess, fetchFeedsFailure } =
  FeedsSlice.actions;

export const performFetchFeeds = (): AppThunk => async (dispatch, getState) => {
  dispatch(fetchFeedsStart());

  feedFactoryAxios
    .get("/feeds")
    .then(async (response) => {
      dispatch(fetchFeedsSuccess(response.data));
    })
    .catch((error: AxiosError) => {
      return dispatch(fetchFeedsFailure(error.message));
    });
};

export const selectLoading = (state: RootState) => state.feeds.loading;
export const selectError = (state: RootState) => state.feeds.error;
export const selectFeeds = (state: RootState) => state.feeds.feeds;

export default FeedsSlice.reducer;
