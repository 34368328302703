import React, { useState } from "react";
import classes from "./MediaPreview.module.scss";
import { useTranslation } from "react-i18next";
import {
  ExtLabelLangString,
  FileType,
  MediaType,
  Title,
} from "models/Ndtrc";

interface ReducedFileItem {
  hlink?: string; // Required for images
  filename?: string; // Required for non-photos
  filetype?: FileType;
  mediatype: MediaType | "empty";
  targetLanguage?: string | null;
  title?: Title<ExtLabelLangString[]> | null;
}

const MediaPreview = (props: {
  mediaItem: ReducedFileItem | null | undefined;
  coverView?: boolean;
}) => {
  const [imageAvailable, setImageAvailable] = useState(true);
  const { t } = useTranslation();

  const mediaItem: ReducedFileItem = props.mediaItem
    ? { ...props.mediaItem }
    : { mediatype: "empty" };

  // Catch case where older items do not have a mediaItem set
  if (mediaItem) {
    const fileType = mediaItem.filetype;
    if (
      fileType === "jpeg" ||
      fileType === "png" ||
      fileType === "jpegOrPngOrWebp" ||
      fileType === "webp" ||
      fileType === "jpg"
    ) {
      // JPG is a legacy option
      mediaItem.mediatype = "photo";
    }
  }

  let mediaContent;
  switch (mediaItem?.mediatype || "empty") {
    case "photo":
      mediaContent = (
        <>
          <img
            className={[
              "media",
              !imageAvailable || !mediaItem.hlink ? classes.isHidden : null,
            ].join(" ")}
            src={mediaItem.hlink}
            alt={mediaItem.title?.label}
            onError={(e: any) => {
              setImageAvailable(false);
            }}
          />
          <div
            className={[
              classes.errorDisplay,
              !(!imageAvailable || !mediaItem.hlink) ? classes.isHidden : null,
            ].join(" ")}
          >
            {t("form.imageNotAvailable")}
          </div>
        </>
      );
      break;
    case "video":
      if (mediaItem.filetype === "youtube") {
        mediaContent = (
          <iframe
            title="Youtube Video"
            width="100%"
            height="auto"
            src={`https://www.youtube-nocookie.com/embed/${mediaItem.filename}`}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        );
      } else if (mediaItem.filetype === "vimeo") {
        mediaContent = (
          <iframe
            title="Vimeo Video"
            src={`https://player.vimeo.com/video/${mediaItem.filename}`}
            width="100%"
            height="auto"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        );
      } else {
        mediaContent = (
          <div className={classes.errorDisplay}>
            {t("form.unknownMediaType")}: {mediaItem.mediatype} /{" "}
            {mediaItem.filetype}
          </div>
        );
      }
      break;
    case "empty":
      mediaContent = (
        <div className={classes.errorDisplay}>{t("form.noMediaAvailable")}</div>
      );
      break;
    default:
      mediaContent = (
        <div className={classes.errorDisplay}>
          {t("form.unknownMediaType")}: {mediaItem.mediatype} /{" "}
          {mediaItem.filetype}
        </div>
      );
  }
  return (
    <div
      className={`${classes.MediaPreview} ${
        props.coverView ? classes.coverView : ""
      }`}
    >
      {mediaContent}
    </div>
  );
};

export default MediaPreview;
