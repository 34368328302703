import { PopupButton } from "components/buttons";
import React, { ChangeEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";
import classes from "./Tokens.module.scss";
import {
  createTokenFilter,
  parseTokenFilter,
} from "../../../helpers/queryConverter";
import { Token as TokenModel } from "../../../models/Token/Token";
import FloatingLabelInput from "./FloatingLabelInput";
export type FiltersUpdate = {
  roles?: string[],
  name?: string,
  markers?: string,
  extendedMarkers?: string,
  geoDistanceLatLon?: string,
  geoDistance?: string,
  userOrganisation?: string,
  checkboxName?: boolean,
  transformer?: string,
  wfstatus?: string,
};

interface TokenEditorProps {
  accountId: string | null;
  token: TokenModel;
  updateToken: (token: Partial<TokenModel>) => void;
}

const TokenEditor = (props: TokenEditorProps) => {
  const { markers, extendedMarkers, userOrganisation, transformer, geoDistance, geoDistanceLatLon, wfstatus } = parseTokenFilter(
    props.token.filter,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fields, setFields] =
    useState <
    FiltersUpdate >
    ({
      name: props.token.name,
      markers,
      extendedMarkers,
      userOrganisation,
      transformer,
      geoDistance,
      geoDistanceLatLon,
      wfstatus,
      checkboxName: props.token.useOptimizedImages,
    });

  const { t } = useTranslation();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.checked,
    });
  };

  const updateToken = () => {
    const newToken = {
      apiToken: props.token.apiToken,
      roles: props.token.roles,
      name: fields.name,
      filter: createTokenFilter(fields),
      useOptimizedImages: fields.checkboxName,
    };

    setIsLoading(true);
    props.updateToken(newToken);
    setFields({
      name: "",
      markers: "",
      extendedMarkers: "",
      userOrganisation: "",
      transformer: "",
      geoDistance: "",
      geoDistanceLatLon: "",
      wfstatus: "",
      checkboxName: false,
    });
    setIsLoading(false);
    setIsOpen(false);
  };

  useEffect(() => {
    const { markers, extendedMarkers, userOrganisation } = parseTokenFilter(
      props.token.filter,
    );

    setFields({
      name: props.token.name,
      markers,
      extendedMarkers,
      userOrganisation,
      transformer,
      geoDistance,
      geoDistanceLatLon,
      wfstatus,      
      checkboxName: props.token.useOptimizedImages,
    });
  }, [props.token]);

  return (
    <div>
      <button className={classes.editToken} onClick={() => setIsOpen(!isOpen)}>
        <MdEdit />
      </button>
      {isOpen && (
        <div
          style={{
            position: "absolute",
            background: "#fff",
            paddingTop: "3rem",
            paddingBottom: "1em",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          }}
          className={classes.TokenEditor}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0 1em",
            }}
          >
            <FloatingLabelInput
              type="text"
              name="name"
              label={t("tokens.newTokenName")}
              value={fields.name}
              onChange={handleInputChange}
            />
            <FloatingLabelInput
              type="text"
              name="markers"
              label={t("root.filter-markers")}
              value={fields.markers}
              onChange={handleInputChange}
            />
            <FloatingLabelInput
              type="text"
              name="extendedMarkers"
              label={t("root.filter-extendedmarker")}
              value={fields.extendedMarkers}
              onChange={handleInputChange}
            />
            <FloatingLabelInput
              type="text"
              name="userOrganisation"
              label={t("root.filter-userorganisation")}
              value={fields.userOrganisation}
              onChange={handleInputChange}
            />
            <FloatingLabelInput
              type="text"
              name="geoDistanceLatLon"
              label={t("root.filter-geo-distance-latlon")}
              value={fields.geoDistanceLatLon}
              onChange={handleInputChange}
            />
            <FloatingLabelInput
              type="text"
              name="geoDistance"
              label={t("root.filter-geo-distance")}
              value={fields.geoDistance}
              onChange={handleInputChange}
            />
            <FloatingLabelInput
              type="text"
              name="transformer"
              label={t("root.filter-transformer")}
              value={fields.transformer}
              onChange={handleInputChange}
            />
            <FloatingLabelInput
              type="text"
              name="wfstatus"
              label={t("root.filter-wfstatus")}
              value={fields.wfstatus}
              onChange={handleInputChange}
            />
            <FloatingLabelInput
              type="checkbox"
              name="checkboxName"
              checked={fields.checkboxName}
              label={t("root.filter-optimizedImage")}
              value={fields.checkboxName ? "checked" : ""}
              onChange={handleCheckboxChange}
            />
            <PopupButton disabled={isLoading} action={updateToken}>
              Update
            </PopupButton>
          </form>
        </div>
      )}
    </div>
  );
};

export default TokenEditor;
