import React, { useState } from "react";
import classes from "./DictionaryInputPopover.module.scss";
import { AiOutlineEnter } from "react-icons/ai";
import { usePopper } from "react-popper";
import popoverStyles from "./PopoverSharedStyle.module.scss";
import DictionarySelectWidget, {
  DictionaryType,
} from "../../FormWidgets/DictionarySelectWidget/DictionarySelectWidget";

const DictionaryInputPopover = (props: {
  children: any;
  onTextSubmit: (text: string) => void;
  placeholder?: string;
  prefillText?: string;
  type: DictionaryType;
}) => {
  const [popover, setPopover] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(popover, popperElement, {});
  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  const [dictionaryValue, setDictionaryValue] = useState<string | null>(null);

  const onSubmitHandler = () => {
    if (!dictionaryValue) {
      return;
    }
    props.onTextSubmit(dictionaryValue);
    setDictionaryValue("");
    setPopoverActive(false);
  };

  const onButtonClickHandler = () => {
    if (update) {
      void update();
    }
    setPopoverActive(true);
  };

  return (
    <>
      {props.children(setPopover, onButtonClickHandler)}
      <div
        className={[
          popoverStyles.popover,
          classes.popover,
          popoverActive ? popoverStyles.active : "",
        ].join(" ")}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className={classes.inputContainer}>
          <DictionarySelectWidget
            onChange={(option) => {
              setDictionaryValue(option);
            }}
            type={props.type}
            value={dictionaryValue}
            isSingle={true}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onSubmitHandler();
              }
            }}
            allowNewItems
          />
        </div>
        <button type="button" onClick={onSubmitHandler}>
          <AiOutlineEnter />
        </button>
      </div>
      <div
        className={`${popoverStyles.backdrop} ${
          popoverActive ? popoverStyles.active : ""
        }`}
        onClick={() => {
          setPopover(null);
          setPopoverActive(false);
        }}
      />
    </>
  );
};

export default DictionaryInputPopover;
