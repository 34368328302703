import React, { ReactNode, useState } from "react";
import classes from "./ItemFormLangTabs.module.scss";
import { Lang, langMetadata } from "../../../models/Lang/Lang";
import {
  Twemoji,
  useDraggableInPortal,
} from "helpers";
import Select from "react-select";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import BooleanInputPopover from "components/popovers/BooleanInputPopover/BooleanInputPopover";
import ItemFormAliasTabs from "./ItemFormAliasTabs/ItemFormAliasTabs";
import { EntityType } from "../../../models/Ndtrc";
import { ButtonType, NavigationButton } from "../../../components/buttons";
import { SingleSelectOption } from "../../../models/SelectOption";
import { AliasOption } from "../../../models/NdtrcAlias/NdtrcAlias";
import { useSelector } from "react-redux";
import {
  selectIsAdmin,
  selectIsCongressAlias,
  selectIsEditor,
  selectIsSuperAdmin,
} from "../../../store/auth/authSlice";

const selectStyles = {
  control: (provided: any) => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "transparent",
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
  const draggingStyle: any = {};
  if (isDragging) {
    draggingStyle.background = "gray";
  }
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    position: "static",
    ...draggingStyle,
    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const ItemFormVersionTabs = (props: {
  showAliases?: boolean;
  availableLangs: Lang[];
  availableAliases: SingleSelectOption[];
  activeVersion: string;
  primaryLang: Lang | null;
  onButtonClick: (version: Lang | string) => void;
  onRearrange: (availableLanguages: Lang[], langMoved: Lang) => any;
  onDeletion: (versionRemoved: Lang | AliasOption) => any;
  readOnly: boolean;
  itemId: string;
  entityType: EntityType;
}) => {
  const { t } = useTranslation();

  // Permissions
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const isAdmin = useSelector(selectIsAdmin);
  const isEditor = useSelector(selectIsEditor);
  const isCongressAlias = useSelector(selectIsCongressAlias);
  const hasPermissionForCongressAlias = isCongressAlias;
  const hasPermissionForAlias =
    isSuperAdmin || isAdmin || isEditor || isCongressAlias;

  // Needed to deal with offset on dragged items
  // See https://github.com/atlassian/react-beautiful-dnd/issues/128
  const renderDraggable = useDraggableInPortal();

  const [newDropdownOpen, setNewDropdownOpen] = useState(false);

  const langOptions = Object.values(Lang)
    .filter((lang: Lang) => {
      return props.availableLangs && !props.availableLangs.includes(lang);
    })
    .map((lang: Lang) => {
      return {
        value: lang,
        label: (
          <>
            <Twemoji>{langMetadata[lang]?.flag}</Twemoji> {lang}
          </>
        ),
      };
    });

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const updatedMediaArray = reorder(
      props.availableLangs,
      result.source.index,
      result.destination.index
    );

    props.onRearrange(
      updatedMediaArray,
      updatedMediaArray[result.destination.index]
    );
  };

  const deletionHandler = (lang: Lang) => {
    props.onDeletion(lang);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="lang-tab" direction="horizontal">
        {(provided, snapshot) => (
          <div
            className={classes.EventFormLangTabs}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {props.availableLangs?.map((lang, index) => {
              let myClass = "";
              if (lang === props.activeVersion) {
                myClass = classes.active;
              }

              return (
                <Draggable
                  key={lang}
                  draggableId={lang}
                  index={index}
                  isDragDisabled={props.readOnly}
                >
                  {renderDraggable((provided: any, snapshot: any) => (
                    <BooleanInputPopover
                      onBooleanSubmit={(deletion) => {
                        if (deletion) deletionHandler(lang);
                      }}
                      prompt={t("form.removeLanguage")}
                      type={"danger"}
                    >
                      {(setPopover: any, onButtonClickHandler: any) => (
                        <div
                          className={`${classes.EventFormLangTab} ${myClass}`}
                          key={lang}
                          onClick={() => props.onButtonClick(lang)}
                          ref={(e) => {
                            provided.innerRef(e);
                            setPopover(e);
                          }}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          onContextMenu={(e) => {
                            if (props.readOnly) {
                              return;
                            }
                            e.preventDefault();
                            onButtonClickHandler();
                          }}
                        >
                          <Twemoji>{langMetadata[lang]?.flag}</Twemoji> {lang}
                        </div>
                      )}
                    </BooleanInputPopover>
                  ))}
                </Draggable>
              );
            })}

            {props.itemId !== "nieuw" && hasPermissionForAlias && (
              <ItemFormAliasTabs
                itemId={props.itemId}
                entityType={props.entityType}
                readOnly={props.readOnly}
                activeAlias={props.activeVersion}
                onButtonClick={props.onButtonClick}
                availableAliases={props.availableAliases}
                onDeletion={props.onDeletion}
              />
            )}

            {!props.readOnly &&
              langOptions.length > 0 &&
              !snapshot.isDraggingOver && (
                <Dropdown
                  isOpen={newDropdownOpen}
                  onClose={() => {
                    setNewDropdownOpen(!newDropdownOpen);
                  }}
                  target={
                    <div
                      className={`${classes.EventFormLangTab}`}
                      key={"new"}
                      onClick={() => {
                        setNewDropdownOpen(!newDropdownOpen);
                      }}
                    >
                      +
                    </div>
                  }
                >
                  <>
                    {props.itemId === "nieuw" ? (
                      <p className={classes.aliasOnNew}>
                        {t("alias.aliasOnNew")}
                      </p>
                    ) : (
                      props.showAliases &&
                      hasPermissionForAlias && (
                        <>
                          {!props.availableAliases.some(
                            (a) => a.label === "print"
                          ) && (
                            <div className={classes.aliases}>
                              <NavigationButton
                                action={() => {
                                  props.onButtonClick("print");
                                }}
                                type={ButtonType.Gray6}
                              >
                                + <Twemoji>📗</Twemoji> print
                              </NavigationButton>
                            </div>
                          )}
                          {hasPermissionForCongressAlias &&
                            !props.availableAliases.some(
                              (a) => a.label === "congres"
                            ) && (
                              <div className={classes.aliases}>
                                <NavigationButton
                                  action={() => {
                                    props.onButtonClick("congres");
                                  }}
                                  type={ButtonType.Gray6}
                                >
                                  + <Twemoji>📗</Twemoji> congres
                                </NavigationButton>
                              </div>
                            )}
                        </>
                      )
                    )}
                    <Select
                      autoFocus
                      backspaceRemovesValue={false}
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      isClearable={false}
                      menuIsOpen
                      // @ts-ignore
                      onChange={(selection: {
                        value: string;
                        label: string;
                      }) => {
                        if (selection) {
                          props.onButtonClick(selection.value as Lang);
                        }
                      }}
                      options={langOptions as any} // Any is needed to allow a component rather than text to be passed
                      placeholder="Voeg taal toe..."
                      styles={selectStyles}
                      tabSelectsValue={false}
                      value={null}
                    />
                  </>
                </Dropdown>
              )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const DropdownIndicator = () => (
  <div className={classes.DropdownIndicator}>
    <svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </div>
);

const Dropdown = (props: {
  children: ReactNode;
  isOpen: boolean;
  target: ReactNode;
  onClose: () => void;
}) => (
  <div className={classes.Dropdown}>
    {props.target}
    {props.isOpen ? <Menu>{props.children}</Menu> : null}
    {props.isOpen ? <Blanket onClick={props.onClose} /> : null}
  </div>
);

const Menu = (props: any) => {
  return <div className={classes.Menu} {...props} />;
};
const Blanket = (props: any) => <div className={classes.Blanket} {...props} />;

export default ItemFormVersionTabs;
