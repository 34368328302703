import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../store";
import {
  AccountModel,
  AccountSlice,
  AccountStyle,
} from "../../models/AccountModel/AccountModel";
import { feedFactoryAxios } from "helpers";
import assertValidResponse from "../../containers/Settings/components/helpers/assertValidResponse";
import { AxiosResponse } from "axios";

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

// https://stackoverflow.com/a/5624139
const hexToRgb = (hex: string): { r: number; g: number; b: number } | null => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
};

const padZero = (str: string, len?: number) => {
  len = len || 2;
  const zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
};
const invertColor = (hex: string, bw: boolean) => {
  if (!hex) {
    console.error("No hex color provided");
    return "#000000";
  }
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  let r: string | number = parseInt(hex.slice(0, 2), 16),
    g: string | number = parseInt(hex.slice(2, 4), 16),
    b: string | number = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
};

const getThemeData = async (accountId: string): Promise<ThemeSlice> => {
  return new Promise(async (resolve, reject) => {
    const accountUrl = "/accounts/me";

    feedFactoryAxios
      .get(accountUrl)
      .then((response) =>
        assertValidResponse(response, "No account found for this account id")
      )
      .then((response: AxiosResponse<AccountModel>) => {
        // If no theme data is available, return the default theme
        if (!response.data.metaData?.style) {
          resolve(initialState);
          return;
        }
        const style: AccountStyle = JSON.parse(response.data.metaData.style);
        const primary =
          style.primaryColor ||
          (initialState.colorScheme["--FF-COLOR-PRIMARY"] as string);
        const primaryRgb = hexToRgb(primary);
        const secondary =
          style.secondaryColor ||
          (initialState.colorScheme["--FF-COLOR-SECONDARY"] as string);
        const secondaryRgb = hexToRgb(secondary);
        const secondaryShade =
          style.secondaryColorShade ||
          (initialState.colorScheme["--FF-COLOR-SECONDARY-SHADE"] as string);
        const secondaryShadeRgb = hexToRgb(secondaryShade);
        const backgrounds =
          style?.backgrounds.length > 0
            ? style?.backgrounds
            : initialState.backgrounds;
        resolve({
          accountName: response.data.name,
          backgrounds: backgrounds,
          currentBackgroundIndex: 0,
          colorScheme: {
            "--FF-COLOR-PRIMARY": primary,
            "--FF-COLOR-PRIMARY-RGB": `${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}`,
            "--FF-COLOR-PRIMARY-CONTRAST": invertColor(primary, true),
            "--FF-COLOR-SECONDARY": secondary,
            "--FF-COLOR-SECONDARY-RGB": `${secondaryRgb?.r}, ${secondaryRgb?.g}, ${secondaryRgb?.b}`,
            "--FF-COLOR-SECONDARY-CONTRAST": invertColor(secondary, true),
            "--FF-COLOR-SECONDARY-SHADE": secondaryShade,
            "--FF-COLOR-SECONDARY-SHADE-RGB": `${secondaryShadeRgb?.r}, ${secondaryShadeRgb?.g}, ${secondaryShadeRgb?.b}`,
            "--FF-COLOR-SECONDARY-SHADE-CONTRAST": invertColor(
              secondaryShade,
              true
            ),
          },
        });
        //   }
      })
      .catch((error) => {
        console.error("Theme slice: account retrieval failed", error);
        reject(error);
      });
  });
};

interface ColorScheme {
  "--FF-COLOR-PRIMARY"?: string;
  "--FF-COLOR-PRIMARY-RGB"?: string;
  "--FF-COLOR-PRIMARY-CONTRAST"?: string;
  "--FF-COLOR-SECONDARY"?: string;
  "--FF-COLOR-SECONDARY-RGB"?: string;
  "--FF-COLOR-SECONDARY-CONTRAST"?: string;
  "--FF-COLOR-SECONDARY-SHADE"?: string;
  "--FF-COLOR-SECONDARY-SHADE-RGB"?: string;
  "--FF-COLOR-SECONDARY-SHADE-CONTRAST"?: string;
}

interface MinIntFileItem {
  hlink: string;
}

interface ThemeSlice {
  accountName?: string;
  accountId?: string;

  // the (blob) url of the theme background image
  backgrounds: MinIntFileItem[];
  currentBackgroundIndex: number;

  // the colors of the account theme
  colorScheme: ColorScheme;
}

const initialState: ThemeSlice = {
  backgrounds: [{ hlink: "/backgrounds/ff.jpg" }],
  currentBackgroundIndex: 0,
  colorScheme: {
    "--FF-COLOR-PRIMARY": "#46BDFF",
    "--FF-COLOR-SECONDARY": "#6836E1",
    "--FF-COLOR-SECONDARY-SHADE": "#341B70",
  }, // Defaults already set in index.scss
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    themeUpdate: (state, action: PayloadAction<ThemeSlice>) => {
      state = action.payload;
      localStorage.setItem(
        "settings.accountStyle",
        JSON.stringify(action.payload)
      );
      return state;
    },
  },
});

export const { themeUpdate } = themeSlice.actions;

export const selectThemeBackground = (state: RootState) => {
  return state.theme.backgrounds[state.theme.currentBackgroundIndex];
};
export const selectThemeColorScheme = (state: RootState) =>
  state.theme.colorScheme;
export const selectAccountName = (state: RootState) => state.theme.accountName;

export const updateThemeFromAccountId =
  (account: AccountSlice): AppThunk =>
    (dispatch) => {
      // Get stored theme from back-end
      getThemeData(account.id).then((newTheme) => {
        dispatch(updateTheme(account, newTheme));
      });
    };

export const updateTheme =
  (account: AccountSlice, theme: ThemeSlice): AppThunk =>
    (dispatch) => {
      const randomBackground = randomIntFromInterval(
        0,
        theme.backgrounds.length - 1
      );
      dispatch(
        themeUpdate({ ...theme, currentBackgroundIndex: randomBackground })
      );
    };

export const initializeAccount = (): AppThunk => (dispatch) => {
  let storedAccountStyle: ThemeSlice | string =
    localStorage.getItem("settings.accountStyle") || "";
  if (storedAccountStyle) {
    storedAccountStyle = JSON.parse(storedAccountStyle) as ThemeSlice;
    dispatch(
      updateTheme(
        {
          id: storedAccountStyle?.accountId || "",
          name: storedAccountStyle?.accountName || "",
        },
        storedAccountStyle
      )
    );
  }
};

export default themeSlice.reducer;
