import {
  Filter,
  TuningProps,
  tuningPropsToQueryString,
} from "models/TuningProps";
import { feedFactoryAxios } from "./feedFactoryAxios";
import { AxiosPromise, CancelTokenSource } from "axios";
import { EntityType } from "models/Ndtrc";
import { ItemFormat } from "models/ItemFormat";

export const fetchApiItems = (
  entityType: EntityType,
  page: number,
  size: number,
  tuningProps: TuningProps,
  cancelTokenSource?: CancelTokenSource,
  format: ItemFormat = "json",
  showLinks?: boolean,
): AxiosPromise => {
  const url = fetchApiItemsUrl(
    entityType,
    page,
    size,
    tuningProps,
    format,
    showLinks,
  );

  return feedFactoryAxios.get(url, {
    cancelToken: cancelTokenSource?.token,
  });
};

export const fetchApiItemsUrl = (
  entityType: EntityType,
  page: number,
  size: number,
  tuningProps: TuningProps,
  format: ItemFormat = "json",
  showLinks?: boolean,
): string => {
  const apiType =
    entityType === "EVENEMENT"
      ? "events"
      : entityType === "LOCATIE"
        ? "locations"
        : entityType === "ROUTE"
          ? "routes"
          : "eventgroups";
  let queryString = tuningPropsToQueryString(tuningProps);
  if (showLinks) {
    queryString += `&showLinks=true`;
  }

  if (size !== 0 && size !== undefined) {
    queryString += `&size=${size}`;
  }

  if (page !== 0 && page !== undefined) {
    queryString += `&page=${page}`;
  }

  return `/${apiType}?format=${format}${queryString}`;
};

export const fetchApiExportSelectedEventsUrl = (
  itemIds: string[],
  format: ItemFormat = "json",
  tuningProps: TuningProps,
) => {
  const apiType = "events";

  const formData = new FormData();
  formData.append("eventIDs", itemIds.join(";"));
  formData.append("format", format);

  if (!tuningProps.search && tuningProps.sortField) {
    formData.append("sortField", tuningProps.sortField);
  }
  if (!tuningProps.search && tuningProps.sortOrder) {
    formData.append("sortOrder", tuningProps.sortOrder);
  }
  if (tuningProps.search) {
    formData.append("search", encodeURIComponent(tuningProps.search));
  }
  if (tuningProps.filters) {
    const filtersWithValue: Filter[] = tuningProps.filters.filter(
      (filter) => filter.value && filter.value !== "",
    );
    for (const filter of filtersWithValue) {
      if (filter.field === "userorganisation" && filter.value) {
        formData.append(filter.field, "*");
        continue;
      }

      formData.append(filter.field, filter.value as string);
    }
  }

  return {
    url: `/${apiType}/export`,
    body: formData,
  };
};


export const fetchApiExportSelectedRoutessUrl = (
  itemIds: string[],
  format: ItemFormat = "json",
  tuningProps: TuningProps,
) => {
  const apiType = "routes";

  const formData = new FormData();
  formData.append("routeIDs", itemIds.join(";"));
  formData.append("format", format);

  if (!tuningProps.search && tuningProps.sortField) {
    formData.append("sortField", tuningProps.sortField);
  }
  if (!tuningProps.search && tuningProps.sortOrder) {
    formData.append("sortOrder", tuningProps.sortOrder);
  }
  if (tuningProps.search) {
    formData.append("search", encodeURIComponent(tuningProps.search));
  }

  if (tuningProps.filters) {
    const filtersWithValue: Filter[] = tuningProps.filters.filter(
      (filter) => filter.value && filter.value !== "",
    );
    for (const filter of filtersWithValue) {
      if (filter.field === "userorganisation" && filter.value) {
        formData.append(filter.field, "*");
        continue;
      }

      formData.append(filter.field, filter.value as string);
    }
  }

  return {
    url: `/${apiType}/export`,
    body: formData,
  };
};

