import {
  ExtPromotion,
  ExtUrlDetails,
  FileType,
  IntPromotion,
  IntUrlDetails,
  MediaType,
  PropertyDatatype,
  Weekday,
} from ".";
import { Lang } from "models/Lang";

// Differences between models internal and external models:
// - External model uses array structure with lang field; internal model uses object structure with lang key
//   - This is only true for trcItemDetails at the moment.
// - Internal model has additional id field on single dates to allow list modification to work correctly

// META MODELS
export type ExternalItemModel =
  | ExternalEventItemModel
  | ExternalLocationItemModel
  | ExternalEventGroupItemModel
  | ExternalRouteItemModel;
export type ExternalTrcItemModel =
  | ExternalEventItemModel
  | ExternalLocationItemModel
  | ExternalEventGroupItemModel;
export type InternalItemModel =
  | InternalEventItemModel
  | InternalLocationItemModel
  | InternalEventGroupItemModel
  | InternalRouteItemModel;
export type InternalTrcItemModel =
  | InternalEventItemModel
  | InternalLocationItemModel
  | InternalEventGroupItemModel;
export type EntityType = "EVENEMENT" | "LOCATIE" | "EVENEMENTGROEP" | "ROUTE";

// COMPOSED ITEM MODELS
export interface ExternalEventItemModel
  extends GenericItemModel,
  GenericTrcItemExternalItemModel,
  GenericEventModel { }
export interface InternalEventItemModel
  extends GenericItemModel,
  GenericTrcItemInternalItemModel,
  GenericEventModel { }
export interface ExternalLocationItemModel
  extends GenericItemModel,
  GenericTrcItemExternalItemModel,
  GenericLocationModel { }
export interface InternalLocationItemModel
  extends GenericItemModel,
  GenericTrcItemInternalItemModel,
  GenericLocationModel { }
export interface ExternalEventGroupItemModel
  extends GenericItemModel,
  GenericTrcItemExternalItemModel,
  GenericEventGroupModel { }
export interface InternalEventGroupItemModel
  extends GenericItemModel,
  GenericTrcItemInternalItemModel,
  GenericEventGroupModel { }
export interface ExternalRouteItemModel
  extends GenericItemModel,
  GenericRouteExternalItemModel,
  GenericRouteModel { }
export interface InternalRouteItemModel
  extends GenericItemModel,
  GenericRouteInternalItemModel,
  GenericRouteModel { }

// Shared interface for all ndtrc items
export interface GenericItemModel {
  acl: AclObject;
  id: string | null;
  creationdate?: string | null;
  availablefrom?: string | null;
  availableto?: string | null;
  lastupdated?: string;
  lastimportedon?: string;
  createdby?: string;
  lastupdatedby?: string;
  owner?: string | null;
  legalowner?: string;
  externalid?: string;
  validator?: string | null;
  wfstatus?: WfStatus;
  cidn?: string;
  // published?: boolean; // deprecated
  // deleted?: boolean; // deprecated
  productiontrcid?: string;
  keywords?: string;
  markers?: string;
  // locationRef?: string; // deprecated
  location?: Location;
  userorganisation?: string | null;
  translations?: Translations;
  trcItemCategories?: TrcItemCategories;
  trcitemRelation?: TrcitemRelation;

  // these items may be converted to internalised language model at some point
  files?: FileItem<ExtLabelLangString[]>[];
  links?: ItemLink[];

  trcid?: string; // DEPRECATED
  // validatedby: string; // deprecated
  // offline: boolean; // deprecated
  // isprivate: boolean; // deprecated
}

export interface ItemLink {
  type: ItemLinkType;
  id: string;
}
export type ItemLinkType = "alias" | "comment" | "parent" | "child";

// ENTITY TYPE MODELS
export interface GenericEventModel {
  entitytype: "EVENEMENT";
  // performers?: Performer[]; // deprecated
  priceElements?: PriceElement<ExtLangString[]>[];
  extrapriceinformations?: ExtLangString[];
}
export interface GenericLocationModel {
  entitytype: "LOCATIE";
}
export interface GenericEventGroupModel {
  entitytype: "EVENEMENTGROEP";
  eventLinks?: EventLink[]; // Optional in typing to avoid breaking changes
}
export interface GenericRouteModel {
  entitytype: "ROUTE";
  routeInfo?: RouteInfo;
}

export interface EventLink {
  eventId?: string;
  locationId?: string;
}

export interface RouteInfo {
  type: RouteInfoType;
  url: string | null;
  distanceInKilometers: number | null; // Double
  durationInMinutes: number | null; // Integer
  start: Address | null;
  end: Address | null;
}

export type RouteInfoType = "route_maker" | null;
export const routeInfotypeList = ["route_maker"];

// LANGUAGE STORAGE MODELS
type LangStringType = IntLangStrings | ExtLangString[];
type TitleStringType = IntTitleTranslations | ExtLabelLangString[];
type SingleDateType = IntSingleDate | ExtSingleDate;
type PatternDateType = IntPatternDate | ExtPatternDate<ExtLangString[]>;

//////////////////////////////////////////////
// START INTERNAL ITEM MODEL
//////////////////////////////////////////////

export interface GenericInternalItemModel {
  trcItemDetails: IntTrcItemDetails;
  contactinfo: IntContactinfo;
}

export interface GenericTrcItemInternalItemModel
  extends GenericInternalItemModel {
  calendar?: IntCalendar;
  promotions?: IntPromotion[];
}
export interface GenericRouteInternalItemModel
  extends GenericInternalItemModel {
  // No additional fields
}

export interface IntSingleDate extends ExtSingleDate {
  id: string; // ID field added
}

export interface IntPatternDate extends ExtPatternDate<ExtLangString[]> {
  id: string;
}

export type IntLangStrings = {
  [lang in Lang]?: string;
};
export type IntTitleTranslations = {
  [lang in Lang]?: string;
};
export type IntTrcItemDetails = {
  [lang in Lang]: IntTrcItemDetail;
};
export type IntTrcItemDetail = {
  longdescription: string;
  shortdescription: string;
  title: string;
};

export type IntWhen = When<ExtLangString[]>;
export interface IntCalendar
  extends Calendar<
    ExtLangString[],
    IntSingleDate,
    IntPatternDate,
    IntSingleExceptionDate
  > { }
export type IntFileItem = FileItem<ExtLabelLangString[]>;

//////////////////////////////////////////////
// END INTERNAL ITEM MODEL
//////////////////////////////////////////////

//////////////////////////////////////////////
// START EXTERNAL ITEM MODEL
//////////////////////////////////////////////

export interface GenericExternalItemModel {
  trcItemDetails?: ExtTrcItemDetail[];
  contactinfo?: ExtContactinfo;
}
export interface GenericTrcItemExternalItemModel
  extends GenericExternalItemModel {
  calendar?: ExtCalendar;
  promotions?: ExtPromotion[];
}
export interface GenericRouteExternalItemModel
  extends GenericExternalItemModel {
  // No additional fields
}

export type ExtCalendar = Calendar<
  ExtLangString[],
  ExtSingleDate,
  ExtPatternDate<ExtLangString[]>,
  ExtSingleExceptionDate
>;

export interface ExtSingleDate {
  date: string | null;
  when: When<ExtLangString[]>[];
}

// Language-dependent items
export interface ExtLangString {
  lang: Lang;
  text: string;
}
export interface ExtLabelLangString {
  label: string;
  lang: Lang;
}
export interface ExtTrcItemDetail {
  lang: Lang;
  longdescription: string;
  shortdescription: string;
  title: string;
}

//////////////////////////////////////////////
// END EXTERNAL ITEM MODEL
//////////////////////////////////////////////

//////////////////////////////////////////////
// START SUBITEM MODELS
//////////////////////////////////////////////

export interface TrcItemCategories {
  // canceled?: boolean | null; // deprecated
  // soldout?: boolean | null; // deprecated
  categories?: ExtProperty[] | null;
  types?: ItemType[] | null;
}

export interface ExtProperty {
  categoryTranslations: ExtPropertyLangString[];
  categoryvalues: CategoryValue[];
  catid: string;
  datatype: PropertyDatatype;
  value: string;
  valueid: unknown;
  parentCategoryTranslations?: ExtPropertyTranslation[];
  valueCategoryTranslations?: ExtPropertyTranslation[];
}

export interface ExtPropertyTranslation {
  catid: string;
  lang: Lang;
  label: string;
  unit: string;
  value: unknown;
  explanation: string;
}

export interface CategoryValue {
  catid: string;
  categoryTranslations?: ExtLangString[];
}

export interface TrcitemRelation {
  subItemGroups: TrcitemRelationSubItem[];
}

export interface TrcitemRelationSubItem {
  trcid: string;
  type: {
    catid: string;
    isDefault: null | boolean;
    categoryTranslations?: ExtPropertyLangString[];
  };
  categories: ExtProperty[];
  subItemTranslations: ExtTitleLangString[];
  media: unknown;
}

export interface ExtPropertyLangString {
  explanation?: string;
  label?: string | null;
  lang: Lang;
  unit?: string; // e.g., "k.m."
  value: string | null;
}

export interface ExtTitleLangString {
  lang: Lang;
  title: string;
}

export interface ItemType {
  catid: string;
  isDefault?: boolean | null;
}

export interface When<T extends LangStringType> {
  timestart: string | null; // Can be null internally, not externally
  timeend: string | null;
  status?: string;
  statustranslations?: T;
  extrainformations?: T;
}

// Note that status "normal" is not explicitly encoded here.
// An empty field is considered "normal".
export const SingleDateStatus = [
  { value: "cancelled", label: "Geannuleerd" },
  { value: "soldout", label: "Uitverkocht" },
  { value: "movedto", label: "Verplaatst naar" },
  { value: "premiere", label: "Première" },
  { value: "reprise", label: "Reprise" },
];

export const PriceDescriptionTypes = [
  { value: "Adults", label: "Volwassenen" },
  { value: "Children", label: "Kinderen" },
  { value: "Groups", label: "Groepen" },
  { value: "CJP", label: "CJP" },
  { value: "Pasholders", label: "Pashouders" },
  { value: "Lastminute", label: "Last-minute" },
];

export const CountryCodes = [
  { value: "NL", label: "Nederland" },
  { value: "BE", label: "België" },
  { value: "DE", label: "Duitsland" },
];

export interface Open<T extends LangStringType> {
  month?: number; // month number (n-th month
  weeknumber?: number; // weeknumber (n-th week of the month) [1..5]
  daynumber?: number; // Daynumber (the n-th day of the month)
  day?: Weekday; // day in the week (number). Following mapping is applicable 1- Sunday 2- Monday 3- Tuesday 4- Wednesday 5- Thursday 6- Friday 7- Saturday
  whens: When<T>[];
  activated?: boolean; // used internally only
}

export interface ExtPatternDate<T extends LangStringType> {
  startdate: string | null;
  enddate: string | null;
  occurrence?: number; // optional parameter (int) indicating how many times the pattern is repeated. (e.g. a value of 2 in combination with a weekly pattern indicates a pattern that is valid for 2 weeks)
  recurrence?: number; // Optional parameter (int) indicating after how many times the pattern is repeated. (e.g a value of 2 in combination with a weekly pattern indicates a bi-weekly pattern)
  recurrencyType:
  | "daily"
  | "weekly"
  | "monthlySimple"
  | "monthlyComplex"
  | "yearly";
  opens: Open<T>[];
}

export interface CalendarComment {
  label: string;
  commentTranslations?: ExtLabelLangString[];
}

export type CalendarType =
  | "NONE"
  | "ALWAYSOPEN"
  | "ONREQUEST"
  | "OPENINGTIMES"
  | "PATTERNDATES"
  | "SINGLEDATES"
  | null;
interface Calendar<
  T extends LangStringType,
  S extends SingleDateType,
  P extends PatternDateType,
  E extends SingleDateExceptionType,
> {
  calendarType: CalendarType;
  singleDates?: S[];
  patternDates?: P[];
  opens?: Open<T>[];
  closeds?: E[];
  soldouts?: E[];
  cancelleds?: E[];
  excludeholidays?: boolean;
  cancelled?: boolean;
  soldout?: boolean;
  onrequest?: boolean | null;
  alwaysopen?: boolean | null;
  comment?: CalendarComment | null;
}

export interface Translations {
  primaryLanguage?: Lang | null;
  availableLanguages?: Lang[];
}

export interface GenericContactinfo {
  // address: Address; // deprecated
  // fax: any; // deprecated
  // label: string; deprecated
  mail: MailDetails;
  phone: PhoneDetails;
}
export interface ExtContactinfo {
  urls: ExtUrlDetails[];
}
export interface IntContactinfo {
  urls: IntUrlDetails[];
}

export interface Performer {
  roleid: string;
  label: string;
  rolelabel: string;
}

export interface Title<F extends TitleStringType> {
  label?: string; // Make optional for files; now deprecated value
  titleTranslations?: F;
}

export interface FileItem<F extends TitleStringType> {
  trcid: string | null;
  main: boolean | null;
  copyright: string | null;
  filename: string;
  hlink: string;
  filetype: FileType;
  mediatype: MediaType;
  targetLanguage: string | null;
  title: Title<F> | null;
}

export interface Location {
  locationItem?: { text: string; trcid: string; id: string };
  label: string;
  address: Address;
}

export interface Address {
  main?: boolean;
  reservation?: boolean;
  title?: string;
  city: string;
  citytrcid?: string;
  country: CountryCode;
  housenr: string;
  street: string;
  streettrcid?: string;
  zipcode: string;
  gisCoordinates?: GisCoordinate[];
}

export type CountryCode = "NL" | "BE" | "DE";

export interface GisCoordinate {
  xcoordinate: string; // should be a string with a comma, e.g., "5.232324"
  ycoordinate: string; // should be a string with a comma, e.g., "5.232324"
  label?: string; // currently not used in interface
}

export interface PriceValue {
  from: number | null;
  until: number | null;
}

export type PriceDescriptionType =
  | "Adults"
  | "Children"
  | "Groups"
  | "CJP"
  | "Pasholders"
  | "Lastminute";

export interface Description<T extends LangStringType> {
  value: PriceDescriptionType;
  descriptionTranslations: T;
}

export interface PriceElement<T extends LangStringType> {
  freeentrance: boolean | null;
  priceValue?: PriceValue;
  description?: Description<T> | null;
  comments?: T;
  extraPriceInformations?: T;
}

export interface IntPriceElement extends PriceElement<ExtLangString[]> {
  id: string; // ID field additional
}

export interface MailDetails {
  email: string;
  descriptioncode?: string;
  reservations?: boolean;
  descriptionTranslations?: ExtLangString[];
}

export interface PhoneDetails {
  number: string;
  descriptioncode?: string;
  reservations?: boolean;
  descriptionTranslations?: ExtLangString[];
}

type SingleDateExceptionType = IntSingleExceptionDate | ExtSingleExceptionDate;
export interface IntSingleExceptionDate extends ExtSingleExceptionDate {
  id: string; // ID field added
}
export interface ExtSingleExceptionDate {
  date: string | null;
  whens: When<ExtLangString[]>[]; // only diff from regular singleDates
}

export type WfStatus =
  | "draft"
  | "readyforvalidation"
  | "approved"
  | "rejected"
  | "deleted"
  | "archived";

export const WfStatusOptions: { value: WfStatus; label: string }[] = [
  // {value: "draft", label: "Concept"},
  { value: "readyforvalidation", label: "Klaar voor validatie" },
  { value: "approved", label: "Goedgekeurd" },
  // {value: "rejected", label: "Afgekeurd"},
  { value: "deleted", label: "Verwijderd" },
  { value: "archived", label: "Gearchiveerd" },
];

export interface AclObject {
  readers: string[];
  editors: string[];
  managers: string[];
}

//////////////////////////////////////////////
// END SUBITEM MODELS
//////////////////////////////////////////////
