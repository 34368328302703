import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { FaHashtag, FaMapSigns, FaQuestion, FaStar } from "react-icons/fa";
import { MdDateRange, MdPlace } from "react-icons/md";
import MediaWidget from "../../../components/FormWidgets/MediaWidget/MediaWidget";
import { useParams } from "react-router-dom";
import {
  EventLink,
  ExternalItemModel,
  InternalItemModel,
  InternalLocationItemModel,
} from "../../../models/Ndtrc/Ndtrc";
import { Lang } from "../../../models/Lang/Lang";
import { extractNdtrcStartEndDates, FormData } from "helpers";
import AddressWidget from "../../../components/FormWidgets/AddressWidget/AddressWidget";
import classes from "../ItemForm.module.scss";
import { DateWidgetWrapper } from "../../../components/FormWidgets/DateWidget/DateWidgetWrapper";
import UrlsWidget from "../../../components/FormWidgets/UrlsWidget/UrlsWidget";
import PricesWidget from "../../../components/FormWidgets/PricesWidget/PricesWidget";
import { BiMoney } from "react-icons/bi";
import CalendarWidget from "components/FormWidgets/CalendarWidget/CalendarWidget/CalendarWidget";
import { HoverDesc } from "../../../components/HoverDesc/HoverDesc";
import PropertiesWidget from "components/FormWidgets/PropertiesWidget/PropertiesWidget";
import { CgMoreVerticalO } from "react-icons/cg";
import CoordinatesWidget from "components/FormWidgets/CoordinatesWidget/CoordinatesWidget";
import { AiFillWarning } from "react-icons/ai";
import DictionarySelectWidget, {
  DictionaryType,
} from "../../../components/FormWidgets/DictionarySelectWidget/DictionarySelectWidget";
import RoomsWidget from "../../../components/FormWidgets/RoomsWidget/RoomsWidget";
import VenuePreview from "../../../components/FormWidgets/PropertiesWidget/VenuePreview/VenuePreview";
import LocationSelector from "../../../components/LocationSelector/LocationSelector";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CategorySelectWidget from "../../../components/FormWidgets/CategorySelectWidget/CategorySelectWidget";
import { ImKey, ImLink } from "react-icons/im";
import PromotionsWidget from "../../../components/FormWidgets/PromotionsWidget/PromotionsWidget";
import { decodeHTMLEntities } from "helpers/decodeHtmlEntities/decodeHtmlEntities";
import PermissionsWidget from "../../../components/FormWidgets/PermissionsWidget/PermissionsWidget";
import { ItemSpecificPermissions } from "../../../models/ItemSpecificPermissions/ItemSpecificPermissions";
import CalloutBanner from "../../../components/CalloutBanner/CalloutBanner";
import { PermissionObject } from "../../../models/PermissionObject/PermissionObject";
import EventsByEventGroupWidget from "../EventsByEventGroupWidget/EventsByEventGroupWidget";
import { generateBanners } from "./generateBanners";
import RouteInfoWidget from "../../../components/FormWidgets/RouteInfoWidget/RouteInfoWidget";
import { TbRoute } from "react-icons/tb";
const generateSectionClassName = (
  markerBasedPermissions: ItemSpecificPermissions[],
  mySection: string
) => {
  const classNames = [];
  const isBlocked = markerBasedPermissions.filter((marker) => {
    return marker.blockedSections.some((section) => section === mySection);
  });
  const isAnnotated = markerBasedPermissions.filter((marker) => {
    return marker.annotatedSections.some((section) => section === mySection);
  });

  if (isBlocked.length > 0) {
    classNames.push(classes.sectionBlocker);
  }
  if (isAnnotated.length > 0) {
    classNames.push(classes.sectionAnnotation);
  }

  // Determine highest priority level
  const allBanners = isBlocked.concat(isAnnotated);
  // console.log("allBanners", allBanners); // DEBUG
  let highestLevel = "info";
  for (const banner of allBanners) {
    if (banner.type === "error") {
      highestLevel = "error";
      break;
    } else if (banner.type === "warning") {
      highestLevel = "warning";
    }
  }

  if (highestLevel === "warning") {
    classNames.push(classes.warning);
  } else if (highestLevel === "error") {
    classNames.push(classes.error);
  } else if (highestLevel === "info") {
    classNames.push(classes.info);
  }

  return classNames.join(" ");
};

const ItemFormPrimaryLanguage = (props: {
  readonly lang: Lang;
  readonly originalItem: ExternalItemModel;
  readonly intItem: InternalItemModel;
  readonly onSubmit: (formData: FormData) => void;
  readonly formMethods: any;
  readonly localSaveTrigger: (extraData?: FormData | any) => any;
  readonly remoteSaveLongDescriptionTrigger: (
    extraData?: FormData | any
  ) => any;
  readonly userorganisations: string[];
  readonly myPermissions: PermissionObject;
}) => {
  const { t } = useTranslation();
  const { id: itemId } = useParams<{ id: string }>();
  const { register, control, reset, watch } = props.formMethods;

  props.formMethods.register(`ndtrcEntry.location.locationItem`);

  const myLocationItemId = props.formMethods.watch(
    "ndtrcEntry.location.locationItem.id"
  );

  const setLocationByTemplate = (template: InternalLocationItemModel) => {
    if (!template.id) {
      return;
    }

    props.formMethods.setValue(
      `ndtrcEntry.location.label`,
      template.location?.label
    );
    props.formMethods.setValue(
      `ndtrcEntry.location.address`,
      template.location?.address
    );
    props.formMethods.setValue(
      `ndtrcEntry.location.address.gisCoordinates`,
      template.location?.address.gisCoordinates
    );
    props.formMethods.setValue(`ndtrcEntry.location.locationItem`, {
      id: template.id,
      text: template.location?.label,
      trcid: template.trcid,
    });
  };

  // Initialise longDesc contents
  useEffect(() => {
    if (!props.intItem) {
      return;
    }
    reset({
      ndtrcEntry: { ...props.intItem },
    });
  }, [props.intItem, props.lang, reset, itemId]);

  const [itemSpecificPermissions, setItemSpecificPermissions] = useState<
    ItemSpecificPermissions[]
  >([]);

  useEffect(() => {
    generateBanners(props.intItem, props.myPermissions, t).then((banners) => {
      setItemSpecificPermissions(banners);
    });
  }, [props.intItem]);

  const [quillInitialised, setQuillInitialised] = useState(false);

  return (
    <div className={"EventForm"}>
      <form>
        {/* Submission happens in parent class on page change / save action */}
        {props.intItem.entitytype === "ROUTE" && (
          <section>
            <h2>
              <TbRoute />
              {t("form.routeInfo")}
            </h2>
            <Controller
              control={control}
              name="ndtrcEntry.routeInfo"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => <RouteInfoWidget value={value} onChange={onChange} />}
              defaultValue={{
                type: "route_maker",
                url: "",
                distanceInKilometers: 0, // Double
                durationInMinutes: 0, // Integer
              }}
            />
          </section>
        )}
        <section
          className={generateSectionClassName(itemSpecificPermissions, "what")}
        >
          <h2>
            <FaQuestion />
            {t("event.what")}
          </h2>
          {itemSpecificPermissions.some((marker) => {
            return (
              marker.annotatedSections.some((section) => section === "what") &&
              marker.description
            );
          }) && (
            <CalloutBanner
              data={
                itemSpecificPermissions.find((item) =>
                  item.annotatedSections.some((section) => section === "what")
                ) as ItemSpecificPermissions
              }
            />
          )}
          <label>
            {t("event.title")}
            <HoverDesc description="event.title.desc" />
          </label>
          <input
            {...register(`ndtrcEntry.trcItemDetails[${props.lang}].title`, {
              required: true,
            })}
            style={{ pointerEvents: "all" }} // overwrite in readonly mode
            disabled={props.myPermissions.generalPermission === "read"}
          />
          <div className={"formSection"}>
            <label>
              {t("event.shortdescription")}
              <HoverDesc description="event.shortdescription.desc" />
            </label>
            <textarea
              {...register(
                `ndtrcEntry.trcItemDetails[${props.lang}].shortdescription`,
                { required: true }
              )}
              style={{ pointerEvents: "all" }} // overwrite in readonly mode
              disabled={props.myPermissions.generalPermission === "read"}
            />
            <p className={classes.characterCount}>
              {t("form.characters")}:{" "}
              {watch(
                `ndtrcEntry.trcItemDetails[${props.lang}].shortdescription`
              )?.length || 0}
            </p>
          </div>
          <div className={"formSection"}>
            <label>
              {t("event.longdescription")}
              <HoverDesc description="event.longdescription.desc" />
            </label>
            <Controller
              name={`ndtrcEntry.trcItemDetails[${props.lang}].longdescription`}
              control={control}
              defaultValue={""}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <ReactQuill
                  onChange={(value) => {
                    // if (!quillInitialised) {
                    //   setQuillInitialised(true);
                    // }
                    onChange(value);
                  }}
                  value={value}
                  style={{ pointerEvents: "all" }} // overwrite in readonly mode
                  readOnly={props.myPermissions.generalPermission === "read"}
                />
              )}
            />
            <p className={classes.characterCount}>
              {t("form.characters")}:{" "}
              {decodeHTMLEntities(
                watch(
                  `ndtrcEntry.trcItemDetails[${props.lang}].longdescription`
                )
              )?.length || 0}
            </p>
          </div>

          <Controller
            name={`ndtrcEntry.trcItemCategories.types`}
            control={control}
            defaultValue={[]}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => {
              return (
                <div className={"categorySelect"}>
                  <label>
                    {t("event.categories")}
                    <HoverDesc description="event.categories.desc" />
                  </label>
                  <CategorySelectWidget
                    onChange={onChange}
                    value={value}
                    entityType={
                      props.intItem.entitytype === "LOCATIE"
                        ? "LOCATIE"
                        : props.intItem.entitytype === "EVENEMENT"
                        ? "EVENEMENT"
                        : props.intItem.entitytype === "EVENEMENTGROEP"
                        ? "EVENEMENT"
                        : "ROUTE"
                    }
                  />
                </div>
              );
            }}
          />

          <label>
            Media
            <HoverDesc description="event.media.desc" />
          </label>
          <Controller
            control={control}
            name="ndtrcEntry.files"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <MediaWidget
                mediaArray={value}
                onChange={onChange}
                primaryLang={props.lang}
              />
            )}
            defaultValue={[]}
          />
        </section>
        {props.intItem?.entitytype === "EVENEMENTGROEP" && (
          <Controller
            control={control}
            name="ndtrcEntry.eventLinks"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => {
              const calendar = props.formMethods.getValues(
                "ndtrcEntry.calendar"
              );
              const eventGroupDateRange = calendar
                ? extractNdtrcStartEndDates(calendar)
                : null;
              return (
                <EventsByEventGroupWidget
                  onEventAddition={(newEvent) => {
                    onChange(value.concat(newEvent));
                  }}
                  onEventDeletion={(deletedEvent) => {
                    onChange(
                      value.filter(
                        (event: EventLink) =>
                          event.eventId !== deletedEvent.eventId
                      )
                    );
                  }}
                  events={value}
                  userOrganisations={props.userorganisations}
                  eventGroupDateRange={
                    eventGroupDateRange || {
                      startdate: new Date().toISOString(),
                      enddate: null,
                    }
                  }
                />
              );
            }}
            defaultValue={[]}
          />
        )}
        <section>
          <h2>
            <ImLink />
            {t("form.contactInfo.urls")}
          </h2>
          <Controller
            control={control}
            name="ndtrcEntry.contactinfo.urls"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <UrlsWidget
                urls={value}
                onChange={onChange}
                className={classes.urlWidget}
                lang={props.lang}
              />
            )}
            defaultValue={[]}
          />
        </section>
        <section
          className={generateSectionClassName(itemSpecificPermissions, "where")}
        >
          <h2>
            <MdPlace />
            {t("event.where")}
          </h2>
          {itemSpecificPermissions.some((marker) => {
            return (
              marker.annotatedSections.some((section) => section === "where") &&
              marker.description
            );
          }) && (
            <CalloutBanner
              data={
                itemSpecificPermissions.find((item) =>
                  item.annotatedSections.some((section) => section === "where")
                ) as ItemSpecificPermissions
              }
            />
          )}
          {props.intItem.entitytype === "EVENEMENT" && (
            <>
              <h3>
                {t("root.locationItem")}
                <HoverDesc description="root.locationItem.desc" />
              </h3>
              <LocationSelector
                myUserOrganisations={props.userorganisations}
                value={myLocationItemId}
                locationUrl={`/${t("root.locations.slug")}/${myLocationItemId}`}
                lang={props.lang}
                onSelect={(myLocation) => {
                  if (!myLocation) {
                    props.formMethods.setValue(`ndtrcEntry.location`, {
                      ...props.intItem.location,
                      locationItem: null,
                    });
                  } else {
                    setLocationByTemplate(myLocation);
                  }
                }}
                onCopy={(myLocation) => {
                  if (myLocation) {
                    setLocationByTemplate(myLocation);
                  }
                }}
              />
            </>
          )}
          {props.intItem.entitytype !== "ROUTE" && (
            <div className={classes.locationContainer}>
              <div style={{ width: "45%" }}>
                <label>
                  {t("event.location.label")}
                  <HoverDesc description="event.location.desc" />
                </label>
                <input
                  {...register("ndtrcEntry.location.label", { required: true })}
                />
                <h3>
                  {t("form.location.address")}
                  <HoverDesc description="form.location.address.desc" />
                </h3>
                <AddressWidget
                  namespace="ndtrcEntry.location.address."
                  formMethods={props.formMethods}
                />
                <h3>{t("form.coordinates")}</h3>
                <Controller
                  control={control}
                  name="ndtrcEntry.location.address.gisCoordinates"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    let coords = null;
                    if (value?.[0]?.xcoordinate && value?.[0]?.ycoordinate) {
                      coords = {
                        lng: parseFloat(value[0].xcoordinate.replace(",", ".")),
                        lat: parseFloat(value[0].ycoordinate.replace(",", ".")),
                      };
                    }

                    return (
                      <>
                        <CoordinatesWidget
                          value={coords}
                          onChange={(val) => {
                            if (!val) {
                              onChange([]);
                            } else {
                              onChange([
                                {
                                  xcoordinate: val.lng
                                    .toString()
                                    .replace(".", ","),
                                  ycoordinate: val.lat
                                    .toString()
                                    .replace(".", ","),
                                },
                              ]);
                            }
                          }}
                        />
                        {value && value.length > 1 && (
                          <div className={classes.coordsWarning}>
                            <AiFillWarning />
                            <span>{t("form.noSupportMultipleCoords")}</span>
                          </div>
                        )}
                      </>
                    );
                  }}
                  defaultValue={[]}
                />
              </div>
            </div>
          )}

          {props.intItem.entitytype === "ROUTE" && (
            <div className={classes.locationContainer}>
              <div style={{ width: "45%" }}>
                <label style={{ fontWeight: "bold", marginBottom: "2rem" }}>
                  {t("form.routeInfo.start")}
                </label>
                <h3>
                  {t("form.location.address")}
                  <HoverDesc description="form.location.address.desc" />
                </h3>
                <AddressWidget
                  namespace="ndtrcEntry.routeInfo.start."
                  formMethods={props.formMethods}
                />
                <h3>{t("form.coordinates")}</h3>
                <Controller
                  control={control}
                  name="ndtrcEntry.routeInfo.start.gisCoordinates"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    let coords = null;
                    if (value?.[0]?.xcoordinate && value?.[0]?.ycoordinate) {
                      coords = {
                        lng: parseFloat(value[0].xcoordinate.replace(",", ".")),
                        lat: parseFloat(value[0].ycoordinate.replace(",", ".")),
                      };
                    }

                    return (
                      <>
                        <CoordinatesWidget
                          value={coords}
                          onChange={(val) => {
                            if (!val) {
                              onChange([]);
                            } else {
                              onChange([
                                {
                                  xcoordinate: val.lng
                                    .toString()
                                    .replace(".", ","),
                                  ycoordinate: val.lat
                                    .toString()
                                    .replace(".", ","),
                                },
                              ]);
                            }
                          }}
                        />
                        {value && value.length > 1 && (
                          <div className={classes.coordsWarning}>
                            <AiFillWarning />
                            <span>{t("form.noSupportMultipleCoords")}</span>
                          </div>
                        )}
                      </>
                    );
                  }}
                  defaultValue={[]}
                />
              </div>

              {props.intItem.entitytype === "ROUTE" && (
                <div style={{ width: "45%" }}>
                  <label style={{ fontWeight: "bold", marginBottom: "2rem" }}>
                    {t("form.routeInfo.end")}
                  </label>
                  <h3>
                    {t("form.location.address")}
                    <HoverDesc description="form.location.address.desc" />
                  </h3>
                  <AddressWidget
                    namespace="ndtrcEntry.routeInfo.end."
                    formMethods={props.formMethods}
                  />
                  <h3>{t("form.coordinates")}</h3>
                  <Controller
                    control={control}
                    name="ndtrcEntry.routeInfo.end.gisCoordinates"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      let coords = null;
                      if (value?.[0]?.xcoordinate && value?.[0]?.ycoordinate) {
                        coords = {
                          lng: parseFloat(
                            value[0].xcoordinate.replace(",", ".")
                          ),
                          lat: parseFloat(
                            value[0].ycoordinate.replace(",", ".")
                          ),
                        };
                      }

                      return (
                        <>
                          <CoordinatesWidget
                            value={coords}
                            onChange={(val) => {
                              if (!val) {
                                onChange([]);
                              } else {
                                onChange([
                                  {
                                    xcoordinate: val.lng
                                      .toString()
                                      .replace(".", ","),
                                    ycoordinate: val.lat
                                      .toString()
                                      .replace(".", ","),
                                  },
                                ]);
                              }
                            }}
                          />
                          {value && value.length > 1 && (
                            <div className={classes.coordsWarning}>
                              <AiFillWarning />
                              <span>{t("form.noSupportMultipleCoords")}</span>
                            </div>
                          )}
                        </>
                      );
                    }}
                    defaultValue={[]}
                  />
                </div>
              )}
            </div>
          )}
        </section>

        {props.intItem.entitytype !== "ROUTE" && (
          <section
            className={generateSectionClassName(
              itemSpecificPermissions,
              "when"
            )}
          >
            <h2>
              <MdDateRange />
              {t("event.when")}
            </h2>
            {itemSpecificPermissions.some((marker) => {
              return (
                marker.annotatedSections.some(
                  (section) => section === "when"
                ) && marker.description
              );
            }) && (
              <CalloutBanner
                data={
                  itemSpecificPermissions.find((item) =>
                    item.annotatedSections.some((section) => section === "when")
                  ) as ItemSpecificPermissions
                }
              />
            )}
            <CalendarWidget
              namespace={"ndtrcEntry.calendar"}
              formMethods={props.formMethods}
              // hideSingleDates={props.intItem.entitytype !== "EVENEMENT"}
              localSave={props.localSaveTrigger}
              intItem={props.intItem}
            />
            {props.intItem.entitytype === "EVENEMENT" && (
              <>
                <h3>
                  {t("event.availableFromTo")}
                  <HoverDesc description="event.availableFromTo.desc" />
                </h3>
                <div className={classes.availableFromTo}>
                  <div>
                    <label>{t("event.availablefrom")}</label>
                    <DateWidgetWrapper
                      name={`ndtrcEntry.availablefrom`}
                      formMethods={props.formMethods}
                      defaultValue={null}
                      hasError={
                        props.formMethods.formState.errors.ndtrcEntry
                          ?.availablefrom
                      }
                    />
                  </div>
                  <div>
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => {
                        if (value && value !== "2049-12-31T23:00:00.000Z") {
                          return (
                            <>
                              <label>{t("event.availableto")}</label>
                              <DatePicker
                                selected={value ? new Date(value) : null}
                                onChange={onChange}
                                locale="nl"
                                dateFormat="dd-MM-yyyy"
                                className={
                                  "date " +
                                  (props.formMethods.formState.errors.ndtrcEntry
                                    ?.availablefrom && "fieldError")
                                }
                                isClearable
                              />
                            </>
                          );
                        } else {
                          return (
                            <>
                              <label>{t("event.availabletoAutomatic")}</label>
                              <label className={classes.checkboxItem}>
                                <input
                                  type="checkbox"
                                  checked={!!value}
                                  onChange={() => {
                                    if (!value) {
                                      onChange("2049-12-31T23:00:00.000Z");
                                    } else {
                                      onChange(null);
                                    }
                                  }}
                                />
                                {t("event.noExpiryDate")}{" "}
                                <HoverDesc description="event.noExpiryDateTooltip" />
                                <span
                                  onClick={() => {
                                    const now = new Date().toISOString();
                                    onChange(now);
                                  }}
                                  className={classes.manualExpiryDate}
                                >
                                  {t("event.manualExpiryDate")}
                                </span>
                              </label>
                            </>
                          );
                        }
                      }}
                      name={`ndtrcEntry.availableto`}
                      control={props.formMethods.control}
                      defaultValue={null}
                    />
                  </div>
                </div>{" "}
              </>
            )}
          </section>
        )}

        {props.intItem.entitytype !== "ROUTE" && (
          <section
            className={generateSectionClassName(
              itemSpecificPermissions,
              "prices"
            )}
          >
            <h2>
              <BiMoney />
              {t("item.prices")}
            </h2>
            {itemSpecificPermissions.some((marker) => {
              return (
                marker.annotatedSections.some(
                  (section) => section === "prices"
                ) && marker.description
              );
            }) && (
              <CalloutBanner
                data={
                  itemSpecificPermissions.find((item) =>
                    item.annotatedSections.some(
                      (section) => section === "prices"
                    )
                  ) as ItemSpecificPermissions
                }
              />
            )}
            <PricesWidget
              formMethods={props.formMethods}
              namespace={"ndtrcEntry.priceElements"}
              lang={props.lang}
            />
          </section>
        )}

        {props.intItem.entitytype !== "ROUTE" && (
          <section>
            <div className={"formSection"}>
              <h2>
                <FaHashtag />
                {t("form.promotions")}
              </h2>
              <Controller
                control={control}
                name="ndtrcEntry.promotions"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <PromotionsWidget
                    creationAllowed={"both"}
                    formMethods={props.formMethods}
                    namespace={"ndtrcEntry.promotions"}
                    lang={props.lang}
                    myPermissions={props.myPermissions}
                    entityType={props.intItem.entitytype}
                  />
                )}
                defaultValue={[]}
              />
            </div>
          </section>
        )}

        <div className={classes.twoColumns}>
          <section>
            <h2>
              <MdPlace />
              {t("event.who")}
            </h2>
            <label>
              {t("root.email")}
              <HoverDesc description="form.email.desc" />
            </label>
            <input {...register("ndtrcEntry.contactinfo.mail.email")} />
            <label>
              {t("form.phoneNumber")}
              <HoverDesc description="form.phoneNumber.desc" />
            </label>
            <input {...register("ndtrcEntry.contactinfo.phone.number")} />
          </section>
          <section>
            <h2>
              <FaStar />
              {t("event.extra")}
            </h2>
            <Controller
              name={`ndtrcEntry.markers`}
              defaultValue={""}
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <>
                    <label>
                      {props.intItem.entitytype === "ROUTE"
                        ? t("event.routeMarkers")
                        : t("event.markers")}
                      <HoverDesc description="event.markers.desc" />
                    </label>
                    <DictionarySelectWidget
                      value={value}
                      onChange={onChange}
                      allowNewItems={props.myPermissions.mayModifyDictionaries}
                      allowRemovingItems={
                        props.myPermissions.mayRemoveTagsMarkers
                      }
                      type={
                        props.intItem.entitytype === "EVENEMENT"
                          ? DictionaryType.EventMarker
                          : props.intItem.entitytype === "EVENEMENTGROEP"
                          ? DictionaryType.EventMarker
                          : DictionaryType.LocationMarker
                      }
                    />
                  </>
                );
              }}
            />
            <Controller
              name={`ndtrcEntry.keywords`}
              defaultValue={""}
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <>
                    <label>
                      {t("event.keywords")}
                      <HoverDesc description={"event.keywords.desc"} />
                    </label>
                    <DictionarySelectWidget
                      value={value}
                      onChange={onChange}
                      allowNewItems={props.myPermissions.mayModifyDictionaries}
                      allowRemovingItems={
                        props.myPermissions.mayRemoveTagsMarkers
                      }
                      type={
                        props.intItem.entitytype === "EVENEMENT"
                          ? DictionaryType.EventTag
                          : props.intItem.entitytype === "EVENEMENTGROEP"
                          ? DictionaryType.EventTag
                          : DictionaryType.LocationTag
                      }
                    />
                  </>
                );
              }}
            />
          </section>
        </div>
        {props.myPermissions?.mayManageAccess &&
          props.myPermissions?.mayManageAccess.length > 0 && (
            <section>
              <h2>
                <ImKey />
                {t("item.permissions")}
              </h2>
              {props.myPermissions.mayManageAccess.map((level) => (
                <Controller
                  name={`ndtrcEntry.acl.${level}`}
                  key={`ndtrcEntry.acl.${level}`}
                  defaultValue={[]}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    return (
                      <>
                        <PermissionsWidget
                          value={value}
                          onChange={onChange}
                          level={level}
                          entityType={props.intItem.entitytype}
                        />
                      </>
                    );
                  }}
                />
              ))}
            </section>
          )}
        {props.myPermissions.mayEditAdvancedProperties && (
          <section>
            <h2>
              <CgMoreVerticalO />
              {t("item.properties")}
            </h2>
            <div className={"formSection"}>
              {props.intItem.entitytype === "LOCATIE" && (
                <VenuePreview
                  properties={
                    props.intItem.trcItemCategories?.categories || null
                  }
                  numberOfRooms={
                    props.intItem.trcitemRelation?.subItemGroups?.length || null
                  }
                />
              )}
              <PropertiesWidget
                properties={props.intItem.trcItemCategories?.categories || []}
                manualSave={(newProperties) => {
                  props.localSaveTrigger({
                    ndtrcEntry: {
                      trcItemCategories: { categories: newProperties },
                    },
                  });
                }}
                lang={props.lang}
              />
            </div>
          </section>
        )}
        {props.myPermissions.mayEditLocationRooms &&
          props.intItem.entitytype === "LOCATIE" && (
            <section>
              <h2>
                <FaMapSigns />
                {t("item.trcitemRelations")}
              </h2>
              <div className={"formSection"}>
                <RoomsWidget
                  rooms={props.intItem.trcitemRelation || null}
                  manualSave={props.localSaveTrigger}
                  lang={props.lang}
                />
              </div>
            </section>
          )}
      </form>
    </div>
  );
};

export default ItemFormPrimaryLanguage;
