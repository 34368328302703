import React, { useState } from "react";
import classes from "./Tokens.module.scss";
import { HiClipboardCopy } from "react-icons/hi";
import { RiNumbersFill } from "react-icons/ri";
import { format, formatDistanceToNow } from "date-fns";
import { MdCreate } from "react-icons/md";
import nl from "date-fns/locale/nl";
import { AiFillEye } from "react-icons/ai";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { Token as TokenModel } from "../../../models/Token/Token";
import { useSelector } from "react-redux";
import { selectIsSuperAdmin } from "../../../store/auth/authSlice";
import TokensEditor from "./TokenEditorForm";

const Token = (props: { token: TokenModel, accountId: string | null, updateToken: (token: Partial<TokenModel>) => void }) => {
  const { t } = useTranslation();
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const [clipboardSuccess, setClipboardSuccess] = useState(false);
  const token = props.token;

  return (
    <li key={token.apiToken} className={classes.tokenWrapper}>
      <div className={classes.apiToken}>
        {token.apiToken}{" "}
        <button
          className={`${classes.copyToClipboard} ${
            clipboardSuccess ? classes.success : ""
          }`}
          onClick={() => {
            navigator.clipboard.writeText(token.apiToken).then(() => {
              setClipboardSuccess(true);
              setTimeout(() => {
                setClipboardSuccess(false);
              }, 700);
            });
          }}
        >
          <HiClipboardCopy />
        </button>
        <TokensEditor token={token} accountId={props.accountId} updateToken={props.updateToken}/>
      </div>
      <div className={classes.name}>{token.name || t("tokens.noName")}</div>
      <div className={classes.filter}>{token.filter}</div>
      <div className={classes.metaDataBar}>
        <div className={classes.metaBarItem} title={t("tokens.accessCount")}>
          <RiNumbersFill />
          <span>{token.accessCount}</span>
        </div>
        <div
          className={classes.metaBarItem}
          title={`${t("tokens.created")} ${format(
            new Date(token.created),
            "yyyy-MM-dd HH:mm",
          )}`}
        >
          <MdCreate />
          <span>
            {formatDistanceToNow(new Date(token.created), {
              locale: nl,
            })}
          </span>
        </div>
        <div
          className={classes.metaBarItem}
          title={
            token.lastSeen
              ? `${t("tokens.lastSeen")} ${format(
                  new Date(token.lastSeen),
                  "yyyy-MM-dd HH:mm",
                )}`
              : "-"
          }
        >
          <AiFillEye />
          <span>
            {token.lastSeen
              ? formatDistanceToNow(new Date(token.lastSeen), {
                  locale: nl,
                })
              : "-"}
          </span>
        </div>
        <div className={classes.metaBarItem} title={t("tokens.roles")}>
          <BsFillPersonCheckFill />
          <span>{token.roles.join(", ")}</span>
        </div>
      </div>
    </li>
  );
};

export default Token;
