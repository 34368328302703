// Source: https://stackoverflow.com/a/43467144
export const isValidUrl = (string: string) => {
  // Don't allow top-level domains
  if (!string.includes(".")) {
    return false;
  }

  try {
    new URL(string);
  } catch (_) {
    return false;
  }
  return true;
};
