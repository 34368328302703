import React from "react";
import classes from "./AddMediaPane.module.scss";
import { FaFileImage, FaVimeo, FaYoutube } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { FileType, IntFileItem } from "models/Ndtrc";
import TextInputPopover from "components/popovers/TextInputPopover";
import FileUploadPopover from "components/popovers/FileUploadPopover";
import { MediaRequirements } from "models/MediaRequirements";
import StandardImageLibrary from "../StandardImageLibrary/StandardImageLibrary";
import { MdPhotoLibrary } from "react-icons/md";

const AddMediaPane = (props: {
  onAddMediaHandler: (type: FileType, url: string | string[]) => void,
  onRawAddMediaHandler: (type: IntFileItem) => void,
  hidden: boolean,
  imageFileType?: FileType,
  hideImageInput?: boolean,
  hideYoutubeInput?: boolean,
  hideVimeoInput?: boolean,
  hideImageLibraryInput?: boolean,
  imageUploadConditionsText?: string,
  requirements?: MediaRequirements,
  standardImageLibrary?: IntFileItem[],
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={[
          classes.AddItemPane,
          props.hidden ? classes.isHidden : "",
        ].join(" ")}
      >
        <div>{t("widget.addMedia")}</div>

        {!props.hideImageInput && (
          <FileUploadPopover
            onFilesSubmit={(urls: string[]) => {
              props.onAddMediaHandler(
                props.imageFileType ? props.imageFileType : "jpegOrPngOrWebp",
                urls,
              );
            }}
            fileType={props.imageFileType}
            uploadConditionsText={props.imageUploadConditionsText}
            requirements={props.requirements}
          >
            {(setPopover: any, onButtonClickHandler: any) => (
              <button
                type="button"
                ref={setPopover}
                onClick={onButtonClickHandler}
              >
                <FaFileImage />
                <div>{t("form.image")}</div>
              </button>
            )}
          </FileUploadPopover>
        )}

        {!props.hideImageLibraryInput &&
          props.standardImageLibrary &&
          props.standardImageLibrary.length > 0 && (
            <StandardImageLibrary
              onSubmit={(imageFile: IntFileItem) => {
                props.onRawAddMediaHandler(imageFile);
              }}
              standardImageLibrary={props.standardImageLibrary}
            >
              {(onButtonClickHandler: any) => (
                <button type="button" onClick={onButtonClickHandler}>
                  <MdPhotoLibrary />
                  <div>{t("form.imageLibrary")}</div>
                </button>
              )}
            </StandardImageLibrary>
          )}

        {!props.hideYoutubeInput && (
          <TextInputPopover
            onTextSubmit={(url) => props.onAddMediaHandler("youtube", url)}
            placeholder={t("media.pasteYoutubeUrl")}
          >
            {(setPopover: any, onButtonClickHandler: any) => (
              <button
                type="button"
                ref={setPopover}
                onClick={onButtonClickHandler}
              >
                <FaYoutube />
                <div>{t("form.youtube")}</div>
              </button>
            )}
          </TextInputPopover>
        )}

        {!props.hideVimeoInput && (
          <TextInputPopover
            onTextSubmit={(url) => props.onAddMediaHandler("vimeo", url)}
            placeholder={t("media.pasteVimeoUrl")}
          >
            {(setPopover: any, onButtonClickHandler: any) => (
              <button
                type="button"
                ref={setPopover}
                onClick={onButtonClickHandler}
              >
                <FaVimeo />
                <div>{t("form.vimeo")}</div>
              </button>
            )}
          </TextInputPopover>
        )}
      </div>
    </>
  );
};

export default AddMediaPane;
