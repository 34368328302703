import React from "react";

import "./Settings.scss";
import { NavLink, Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import User from "./components/User";
import Users from "./components/Users";
import NewUser from "./components/NewUser";
import Account from "./components/Account";
import { useSelector } from "react-redux";
import {
  selectHasAssociatedAccount,
  selectIsAdmin,
  selectIsSuperAdmin,
} from "../../store/auth/authSlice";
import Accounts from "./components/Accounts";
import NewAccount from "./components/NewAccount";
import { useNavTranslation } from "helpers";
import LocationMapper from "containers/LocationMapper/LocationMapper";

const Settings = () => {
  const { t } = useTranslation();
  const {
    accountsUrl,
    accountUrl,
    newAccountUrl,
    accountUserUrl,
    accountUsersUrl,
    accountNewUserUrl,
    myAccountUserUrl,
    locationMapperUrl,
    getAccountUrl,
    getAccountUsersUrl,
    getAccountNewUserUrl,
  } = useNavTranslation();
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const isAdmin = useSelector(selectIsAdmin);
  const hasAssociatedAccount = useSelector(selectHasAssociatedAccount);

  return (
    <div className={"ff-settings-component"}>
      <aside className={"settings-aside"}>
        {isSuperAdmin && isAdmin && (
          <div className={"settings-group"}>
            <span>Feed Factory</span>
            <ul>
              <li>
                <NavLink to={accountsUrl}>{t("root.accounts")}</NavLink>
              </li>
              <li>
                <a
                  href={"https://app.thefeedfactory.nl/feed-gui/"}
                  target={"_blank"}
                >
                  {t("root.feeds")}
                </a>
              </li>
              <li>
                <NavLink to={locationMapperUrl}>
                  {t("root.locationMapper")}
                </NavLink>
              </li>
            </ul>
          </div>
        )}
        {hasAssociatedAccount && isAdmin && (
          <div className={"settings-group"}>
            <span>{t("root.myaccount")}</span>
            <ul>
              {isAdmin && (
                <li>
                  <NavLink to={getAccountUrl()}>{t("root.settings")}</NavLink>
                </li>
              )}
              {isAdmin && (
                <li>
                  <NavLink to={getAccountUsersUrl()}>{t("root.users")}</NavLink>
                </li>
              )}
            </ul>
          </div>
        )}
      </aside>
      <div className={"settings-main"}>
        <Switch>
          {/* my organisation users */}
          {isAdmin && (
            <Route path={getAccountUsersUrl()} exact>
              <Users isSelf />
            </Route>
          )}

          {/* my organisation new user */}
          {isAdmin && (
            <Route path={getAccountNewUserUrl()} exact>
              <NewUser />
            </Route>
          )}

          {/* my organisation user */}
          {isAdmin && (
            <Route path={myAccountUserUrl} exact>
              <User />
            </Route>
          )}

          {/* my organisation information */}
          {isAdmin && (
            <Route path={getAccountUrl()} exact>
              <Account />
            </Route>
          )}

          {isSuperAdmin && (
            <Switch>
              <Route path={accountsUrl} exact>
                <Accounts />
              </Route>
              <Route path={locationMapperUrl} exact>
                <LocationMapper />
              </Route>
              <Route path={newAccountUrl} exact>
                <NewAccount />
              </Route>
              <Route path={accountUrl} exact>
                <Account />
              </Route>
              <Route path={accountUsersUrl} exact>
                <Users />
              </Route>
              <Route path={accountNewUserUrl} exact>
                <NewUser />
              </Route>
              <Route path={accountUserUrl} exact>
                <User />
              </Route>
              <Route path={accountUsersUrl} exact>
                <Users />
              </Route>
            </Switch>
          )}
        </Switch>
      </div>
    </div>
  );
};

export default Settings;
