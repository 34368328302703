import { internalToExternal } from "./ndtrcModelHelper";
import { feedFactoryAxios } from "./feedFactoryAxios";
import {
  ExternalItemModel,
  IntCalendar,
  InternalItemModel,
} from "models/Ndtrc";
import { AxiosResponse } from "axios";

export type FormData = {
  ndtrcEntry: InternalItemModel;
};

export type TranslationInterface = {
  sourceLanguage: string;
  targetLanguage: string;
  text: string;
};

export const mergeChangesIntoModel = (
  mainDataObject: InternalItemModel,
  changes: InternalItemModel,
): InternalItemModel => {
  if (!changes) {
    return mainDataObject;
  }

  let newItem = {
    ...mainDataObject,
    ...changes,
    contactinfo: {
      ...mainDataObject.contactinfo,
      ...changes.contactinfo,
    },
    trcItemDetails: {
      ...mainDataObject.trcItemDetails,
      ...changes.trcItemDetails,
    },
    trcItemCategories: {
      ...mainDataObject.trcItemCategories,
      ...changes.trcItemCategories,
    },
  };
  if (mainDataObject.entitytype !== "ROUTE") {
    newItem = {
      ...newItem,
      calendar: {
        ...mainDataObject.calendar,
        // @ts-ignore
        ...(changes.calendar as IntCalendar),
      },
    };
  }
  return newItem;
};

export const handleRemoteSubmission = (
  originalItem: ExternalItemModel | undefined,
  changedItem: InternalItemModel,
  itemId: string,
): Promise<AxiosResponse<ExternalItemModel>> => {
  if (!originalItem) {
    throw new Error("No originalItem provided");
  }

  const transformedChangedData: ExternalItemModel =
    internalToExternal(changedItem);

  // Merge original location with changed location to retain ID object
  // TODO: Remove; is temporary, until locations fully supported
  const locationMerged = {
    location: { ...originalItem.location, ...transformedChangedData.location },
  };

  const isNewItem = !originalItem.id;

  // Determine url based on item type
  let url;
  if (originalItem.entitytype === "EVENEMENT") {
    url = `/events`;
  } else if (originalItem.entitytype === "LOCATIE") {
    url = `/locations`;
  } else if (originalItem.entitytype === "EVENEMENTGROEP") {
    url = `/eventgroups`;
  } else if (originalItem.entitytype === "ROUTE") {
    url = `/routes`;
  } else {
    const et = (originalItem as any)?.entitytype;
    throw new Error("Item type unknown: " + et);
  }

  if (!isNewItem) {
    url += `/${itemId}`;
  }

  const payload = {
    ...originalItem,
    ...transformedChangedData,
    ...locationMerged,
  };
  console.log("PUT ITEM (PAYLOAD):", payload); // DEBUG

  // Merge original item with changes
  // Required until form entirely describes the object
  if (isNewItem) {
    return feedFactoryAxios.post(url, payload);
  } else {
    return feedFactoryAxios.put(url, payload);
  }
};
