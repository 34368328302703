import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import classes from "./TuneWidget.module.scss";
import Switch from "react-switch";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { format, parse } from "date-fns";
import {
  defaultTuningOptions,
  Filter,
  FilterChanges,
  FilterSelectOption,
} from "../../models/TuningProps/TuningProps";
import _ from "lodash";
import { RiFilterFill, RiFilterOffFill } from "react-icons/ri";
import { Dropdown } from "./Dropdown";
import { EntityType, ItemType } from "../../models/Ndtrc/Ndtrc";
import CategorySelectWidget from "../FormWidgets/CategorySelectWidget/CategorySelectWidget";
import LocationMultiSelect from "../LocationSelect/LocationMultiSelect";
import { Lang } from "../../models/Lang";
import DictionarySelectWidget, {
  DictionaryType,
} from "../FormWidgets/DictionarySelectWidget/DictionarySelectWidget";

const FilterWidget = (props: {
  filters: Filter[] | null;
  setFilter: Dispatch<SetStateAction<Filter[] | null>>;
  entityType: EntityType;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const [internalFilters, setInternalFilters] = useState<Filter[]>([]);
  const searchAllUserOrganisations: boolean = internalFilters.some(
    (filter: Filter) => filter.field === "userorganisation" && filter.value,
  );

  // Update UI representation
  const updateFiltersUI = useCallback(
    _.debounce(
      (externalFilters: Filter[], setFilter, internalFilters) => {
        // Check if props are not equal to current state. If so, don't perform
        if (_.isEqual(internalFilters, externalFilters)) {
          // console.log(
          //   "NEW OPTIONS WERE EQUAL TO PREVIOUS OPTIONS (EXT, INT)",
          //   externalFilters,
          //   internalFilters
          // ); // DEBUG
          return;
        }

        setFilter(internalFilters);
      },
      300,
      {
        leading: false,
        trailing: true,
      },
    ),
    [],
  );

  const updateFilterField = (
    filterName: string,
    filterChanges: FilterChanges,
  ) =>
    setInternalFilters((prevState) => {
      const myFieldIndex = prevState.findIndex((f) => filterName === f.field);
      const prevStateClone = [...prevState];
      prevStateClone[myFieldIndex] = {
        ...prevStateClone[myFieldIndex],
        ...filterChanges,
      };
      return prevStateClone;
    });

  useEffect(() => {
    // console.log("UI state updated"); // DEBUG
    setTimeout(() => {
      updateFiltersUI(props.filters || [], props.setFilter, internalFilters);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    internalFilters,
    updateFiltersUI,
    // props.filters, // Should not trigger push to store
    // props.setFilter, // Should not trigger push to store
  ]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Ensure that whatever is saved as preset, all
    // filter options are present in list IF this is
    // a LOCATIE or EVENEMENT.
    if (props.entityType) {
      const mergedFilters =
        defaultTuningOptions.filters?.map((filter) => {
          const myFilter = props.filters?.find((myFilter) => {
            return filter.field === myFilter.field;
          });
          return myFilter ? myFilter : filter;
        }) || null;

      setInternalFilters(mergedFilters || []);
    } else {
      setInternalFilters(props.filters || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // internalFilters, // Should not trigger update
    props.filters,
  ]);

  return (
    <div
      className={`${classes.FilterWidget} ${
        internalFilters.filter(
          (filter: Filter) => filter.value && filter.value !== "",
        ).length > 0
          ? classes.filtersActivated
          : ""
      }`}
    >
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <div
            onClick={toggleOpen}
            title={t("tune.filter")}
            className={classes.DropdownContent}
          >
            {internalFilters.filter(
              (filter: Filter) => filter.value && filter.value !== "",
            ).length > 0 ? (
              <RiFilterFill />
            ) : (
              <RiFilterOffFill />
            )}

            {t("tune.filter")}
          </div>
        }
      >
        <div className={classes.FilterInterface}>
          {internalFilters.map((filter) => {
            if (
              filter.entityTypeFilter &&
              filter.entityTypeFilter !== props.entityType
            ) {
              return <></>;
            }

            return (
              <div key={filter.field} className={classes.FilterOption}>
                <div className={classes.FilterLabel}>
                  {filter.translated ? t(filter.label) : filter.label}
                </div>
                <div className={classes.FilterValue}>
                  {filter.type === "boolean" && (
                    <Switch
                      checkedIcon={false}
                      uncheckedIcon={false}
                      handleDiameter={20}
                      height={12}
                      width={40}
                      onColor={"#0A6EBD"}
                      offHandleColor={"#e0e0e0"}
                      onHandleColor={"#e0e0e0"}
                      checked={(filter.value as boolean) || false} // false if undefined
                      onChange={(val) => {
                        updateFilterField(filter.field, { value: val });
                      }}
                    />
                  )}
                  {filter.type === "string" && (
                    <input
                      type={"text"}
                      value={(filter.value as string) || ""} // empty if undefined
                      onChange={(e) => {
                        updateFilterField(filter.field, {
                          value: e.target.value,
                        });
                      }}
                    />
                  )}
                  {filter.type === "date" && (
                    <div className={"noPadding"}>
                      <DatePicker
                        isClearable
                        placeholderText={filter.placeholder}
                        selected={
                          filter.value
                            ? parse(
                                filter.value as string,
                                "yyyy-MM-dd",
                                new Date(0),
                              )
                            : null
                        }
                        onChange={(res: Date) => {
                          const val = res
                            ? format(res as Date, "yyyy-MM-dd")
                            : undefined;
                          updateFilterField(filter.field, {
                            value: val,
                          });
                        }}
                        locale="nl"
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  )}
                  {filter.type === "select" && (
                    <Select
                      isClearable={true}
                      placeholder={`${t("form.select")}...`}
                      value={filter.options?.find(
                        (opt) => opt.value === filter.value,
                      )}
                      options={filter.options}
                      // @ts-ignore
                      onChange={(option: FilterSelectOption) => {
                        const val = option?.value || undefined;
                        updateFilterField(filter.field, {
                          value: val,
                        });
                      }}
                    />
                  )}
                  {filter.type === "multiselect" &&
                    (() => {
                      // First determine value already set. Need to be done prior as defaultValue parameter
                      // cannot be a function.
                      let defaultValue: FilterSelectOption[];
                      if (
                        !filter.value ||
                        (filter.value as string).length < 1
                      ) {
                        defaultValue = [];
                      } else {
                        defaultValue = (filter.value as string)
                          .split(";")
                          .map((val) => ({
                            value: val,
                            label:
                              filter.options?.find((opt) => opt.value === val)
                                ?.label || "",
                          })) as FilterSelectOption[];
                      }

                      return (
                        <Select
                          isMulti
                          isClearable={true}
                          placeholder={`${t("form.select")}...`}
                          defaultValue={defaultValue}
                          options={filter.options as any}
                          onChange={(options: any) => {
                            const val = options
                              .map((option: any) => option.value)
                              .join(";");
                            updateFilterField(filter.field, {
                              value: val,
                            });
                          }}
                        />
                      );
                    })()}
                  {filter.type === "types-multiselect" &&
                    (() => {
                      // First determine value already set. Need to be done prior as defaultValue parameter
                      // cannot be a function.
                      let defaultValue: ItemType[];
                      if (
                        !filter.value ||
                        (filter.value as string).length < 1
                      ) {
                        defaultValue = [];
                      } else {
                        defaultValue = (filter.value as string)
                          .split(";")
                          .map((val) => ({
                            catid: val,
                          })) as ItemType[];
                      }

                      return (
                        <CategorySelectWidget
                          value={defaultValue}
                          onChange={(options: ItemType[]) => {
                            const val = options
                              .map((option) => option.catid)
                              .join(";");
                            updateFilterField(filter.field, {
                              value: val,
                            });
                          }}
                          entityType={props.entityType}
                        />
                      );
                    })()}
                  {filter.type === "markers-multiselect" &&
                    (() => {
                      return (
                        <DictionarySelectWidget
                          value={(filter.value as string) || null}
                          onChange={(val) => {
                            updateFilterField(filter.field, {
                              value: val,
                            });
                          }}
                          type={
                            props.entityType === "EVENEMENT"
                              ? DictionaryType.EventMarker
                              : DictionaryType.LocationMarker
                          }
                          allowNewItems
                          allowRemovingItems
                        />
                      );
                    })()}
                  {filter.type === "keywords-multiselect" &&
                    (() => {
                      return (
                        <DictionarySelectWidget
                          value={(filter.value as string) || null}
                          onChange={(val) => {
                            updateFilterField(filter.field, {
                              value: val,
                            });
                          }}
                          type={
                            props.entityType === "EVENEMENT"
                              ? DictionaryType.EventTag
                              : DictionaryType.LocationTag
                          }
                          allowNewItems
                          allowRemovingItems
                        />
                      );
                    })()}
                  {filter.type === "location-multiselect" &&
                    (() => {
                      // First determine value already set. Need to be done prior as defaultValue parameter
                      // cannot be a function.
                      let defaultValue: any[];
                      if (
                        !filter.value ||
                        (filter.value as string).length < 1
                      ) {
                        defaultValue = [];
                      } else {
                        defaultValue = (filter.value as string)
                          .split(";")
                          .map((val) => ({
                            value: val,
                            label: val,
                          })) as any[];
                      }

                      return (
                        <LocationMultiSelect
                          lang={Lang.NL}
                          filterOnUserOrganisations={
                            searchAllUserOrganisations ? null : []
                          }
                          value={defaultValue}
                          onChange={(options: any[]) => {
                            updateFilterField(filter.field, {
                              value: options.map((opt) => opt.value).join(";"),
                            });
                          }}
                        />
                      );
                    })()}
                </div>
              </div>
            );
          })}
        </div>
      </Dropdown>
    </div>
  );
};

export default FilterWidget;
