import React, { ReactNode } from "react";
import classes from "./CardWidgetHeader.module.scss";

const CardWidgetHeader = (props: {
  header: ReactNode;
  badge?: number | string | null;
}) => (
  <div className={classes.header}>
    <h2 className={classes.headline}>{props.header}</h2>
    {props.badge && (
      <div className={classes.badge}>
        <span>{props.badge}</span>
      </div>
    )}
  </div>
);

export default CardWidgetHeader;
