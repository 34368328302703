import React, { useCallback, useEffect, useState } from "react";
import {
  EntityType,
  InternalEventGroupItemModel,
  InternalEventItemModel,
  InternalItemModel,
  InternalLocationItemModel,
  InternalRouteItemModel,
} from "../../models/Ndtrc/Ndtrc";
import classes from "./CardWidget.module.scss";
import { Lang } from "../../models/Lang/Lang";
import CardGrid from "./CardGrid/CardGrid";
import EventCard from "./cards/EventCard/EventCard";
import LocationCard from "components/CardWidget/cards/LocationCard/LocationCard";
import CardWidgetHeader from "./CardWidgetHeader/CardWidgetHeader";
import { useTranslation } from "react-i18next";
import { GiCardPickup } from "react-icons/gi";
import { feedFactoryAxios } from "helpers";
import assertValidResponse from "../../containers/Settings/components/helpers/assertValidResponse";
import { AxiosResponse } from "axios";
import { AccountModel } from "../../models/AccountModel/AccountModel";
import EventGroupCard from "./cards/EventGroupCard";
import { AiFillDelete } from "react-icons/ai";
import RouteCard from "./cards/RouteCard";

const CardWidget = <T extends InternalItemModel>(props: {
  items: T[];
  hits: number;
  header?: React.ReactNode;
  lang: Lang;
  showBadge?: boolean;
  activeItemId?: string | null;
  entityType?: EntityType;
  selectedItems?: string[];
  onSelectionChange?: (id: string[], isSelected: boolean) => any;
  onDeletion?: (id: string) => any;
}) => {
  const { t } = useTranslation();
  const [userOrganisations, setUserOrganisations] = useState<string[]>([]);

  useEffect(() => {
    const accountUrl = "/accounts/me";

    feedFactoryAxios
      .get(accountUrl)
      .then((response) =>
        assertValidResponse(response, "No account found for this account id"),
      )
      .then((response: AxiosResponse<AccountModel>) => {
        const account = response.data;
        setUserOrganisations(account?.userOrganisation?.split(";") || []);
        //   }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, []);

  const items = props.items.map((item: T, i: number) => {
    const isActiveItem = props.activeItemId === item.id;
    let isSelectedItem = null;
    if (props.selectedItems) {
      isSelectedItem = !!props.selectedItems.find((sel) => sel === item.id);
    }

    return (
      <div className={classes.cardContainer} key={item.id}>
        {item.entitytype === "EVENEMENT" ? (
          <>
            <EventCard
              entry={item as InternalEventItemModel}
              lang={props.lang}
              active={isActiveItem}
              userorganisations={userOrganisations}
              eventUrl={`/${t("root.events.slug")}/${item.id}`}
              locationUrl={
                item.location?.locationItem?.trcid
                  ? `/${t("root.locations.slug")}/${
                      item.location.locationItem.trcid
                    }`
                  : null
              }
            />
            {props.onDeletion && (
              <div
                className={classes.deleteItem}
                onClick={() => {
                  if (props.onDeletion && item.id) {
                    props.onDeletion(item.id);
                  }
                }}
              >
                <AiFillDelete />
              </div>
            )}
          </>
        ) : item.entitytype === "LOCATIE" ? (
          <LocationCard
            key={item.id}
            entry={item as InternalLocationItemModel}
            lang={props.lang}
            active={isActiveItem}
            userorganisations={userOrganisations}
            locationUrl={`/${t("root.locations.slug")}/${item.id}`}
          />
        ) : item.entitytype === "EVENEMENTGROEP" ? (
          <EventGroupCard
            key={item.id}
            entry={item as InternalEventGroupItemModel}
            lang={props.lang}
            active={isActiveItem}
            userorganisations={userOrganisations}
            eventUrl={`/${t("root.eventGroups.slug")}/${item.id}`}
            locationUrl={
              item.location?.locationItem?.trcid
                ? `/${t("root.locations.slug")}/${
                    item.location.locationItem.trcid
                  }`
                : null
            }
          />
        ) : item.entitytype === "ROUTE" ? (
          <RouteCard
            key={item.id}
            entry={item as InternalRouteItemModel}
            lang={props.lang}
            active={isActiveItem}
            userorganisations={userOrganisations}
            eventUrl={`/${t("root.routes.slug")}/${item.id}`}
            locationUrl={
              item.location?.locationItem?.trcid
                ? `/${t("root.locations.slug")}/${
                    item.location.locationItem.trcid
                  }`
                : null
            }
          />
        ) : (
          <div key={i}>Unknown entity type</div>
        )}

        {(item.entitytype === "EVENEMENT" || item.entitytype === "LOCATIE") &&
          props.onSelectionChange && (
            <div className={classes.cardCheckbox}>
              <input
                type={"checkbox"}
                checked={isSelectedItem || false}
                onChange={(e) => {
                  if (!props.onSelectionChange) {
                    return;
                  }
                  props.onSelectionChange(
                    [item.id as string],
                    e.currentTarget.checked,
                  );
                }}
              />
            </div>
          )}
      </div>
    );
  });

  const emptyDisplay = useCallback(
    () => (
      <>
        <GiCardPickup />
        <div>
          <p>{t("root.noResults")}</p>
        </div>
      </>
    ),
    [t],
  );

  return (
    <section className={classes.CardWidget}>
      {props.header && (
        <CardWidgetHeader
          header={props.header}
          badge={props.hits ? props.hits : null}
        />
      )}
      {items.length > 0 && <CardGrid>{items}</CardGrid>}
      {items.length === 0 && (
        <div className={classes.noItems}>{emptyDisplay}</div>
      )}
    </section>
  );
};

export default CardWidget;
