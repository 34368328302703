import React, { useEffect } from "react";
import {
  RouteInfo,
  routeInfotypeList,
} from "../../../models/Ndtrc";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import classes from "./RouteInfoWidget.module.scss";

const RouteInfoWidget = (props: {
  value: RouteInfo;
  onChange: (value: RouteInfo) => any;
}) => {
  const { t } = useTranslation();

  const [hasError, setHasError] = React.useState<boolean>(false);
  const typeOptions = routeInfotypeList.map((type) => {
    return {
      value: type,
      label: t(`routeInfo.type.${type}`),
    };
  });

  useEffect(() => {
    // Check validity of the values
    if (
      (props.value.distanceInKilometers !== null && props.value.distanceInKilometers <= 0) ||
      (props.value.durationInMinutes !== null && props.value.durationInMinutes <= 0)
    ) {
      setHasError(true);
      return;
    }
    setHasError(false);
  }, [props.value]);

  return (
    <div className={classes.RouteInfoWidget}>
      <label>{t("routeInfo.type")}</label>
      <Select
        value={typeOptions.find((option) => option.value === props.value.type)}
        isClearable
        options={typeOptions}
        menuPlacement={"auto"}
        placeholder={`${t("form.select")}...`}
        styles={{
          menu: (provided: any, state) => {
            return { ...provided, zIndex: 2 };
          },
        }}
        onChange={(item: any) => {
          if (item) {
            props.onChange({ ...props.value, type: item.value });
          }
        }}
        defaultValue={typeOptions.find(
          (option) => option.value === "route_maker",
        )}
      />

      <label>{t("routeInfo.url")}</label>
      <input
        value={props.value.url || ""}
        onChange={(e) => {
          props.onChange({ ...props.value, url: e.currentTarget.value });
        }}
      />
      {/* <div className={classes.buttonArray}>
        <TextButton action={importRouteInfo}>
          <LiaFileImportSolid /> {t("routeInfo.import")}
        </TextButton>
      </div> */}

      <div className={classes.twoColumns}>
        <div>
          <label>{t("routeInfo.distanceInKilometers")}</label>
          
          <input
            type="number"
            value={props.value.distanceInKilometers?.toString() || ""}
            onChange={(e) => {
              props.onChange({
                ...props.value,
                distanceInKilometers: e.currentTarget.value ? parseFloat(e.currentTarget.value) : null, 
              });
            }}
          />
        </div>
        <div>
          <label>{t("routeInfo.durationInMinutes")}</label>
          <input
            type="number"
            value={props.value.durationInMinutes?.toString() || ""}
            onChange={(e) => {
              props.onChange({
                ...props.value,
                durationInMinutes: e.currentTarget.value ? parseInt(e.currentTarget.value) : null, 
              });
            }}
          />
        </div>
      </div>

      {hasError && (
        <div className={classes.errorMessage}>{t("routeInfo.error")}</div>
      )}
    </div>
  );
};

export default RouteInfoWidget;
