import React, { useState } from "react";
import classes from "./TuneWidget.module.scss";
import { FaDownload } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectAccountId,
  selectIsAdmin,
  selectIsEditor,
  selectIsSuperAdmin,
} from "../../store/auth/authSlice";
import { Dropdown } from "./Dropdown";
import { ItemFormat } from "../../models/ItemFormat/ItemFormat";
import { HiNewspaper } from "react-icons/hi";
import { RiFileExcel2Fill } from "react-icons/ri";
import { VscJson } from "react-icons/vsc";
import { MdCheckBox } from "react-icons/md";
import BooleanInputPopover from "../popovers/BooleanInputPopover";

const ExportButton = (props: {
  onClick: (format: ItemFormat) => any;
  progressText: string | null;
  exportSelected?: boolean;
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const isAdmin = useSelector(selectIsAdmin);
  const isEditor = useSelector(selectIsEditor);
  const userorganisationId = useSelector(selectAccountId);
  const hasPermissionForUitkrantExport =
    isSuperAdmin ||
    ((isAdmin || isEditor) &&
      userorganisationId === "5f50d5966fac7e697452da37"); // ID: Amsterdam & Partners

  if (!hasPermissionForUitkrantExport) {
    return (
      <div
        className={`${classes.ExportButton} ${classes.Button} ${
          props.exportSelected ? classes.exportAltColor : ""
        }`}
      >
        <BooleanInputPopover
          onBooleanSubmit={(deletion) => {
            if (deletion) {
              props.onClick("excel");
            }
          }}
          prompt={t("tune.confirmExport")}
          type={"danger"}
        >
          {(setPopover: any, onButtonClickHandler: any) => (
            <div
              className={classes.Dropdown}
              ref={setPopover}
              onClick={onButtonClickHandler}
            >
              <div
                title={
                  props.exportSelected
                    ? t("tune.exportDefaultSelected")
                    : t("tune.exportDefault")
                }
                className={classes.DropdownContent}
              >
                <FaDownload />
                {props.exportSelected && (
                  <span className={classes.selectedExport}>
                    <MdCheckBox />
                  </span>
                )}
              </div>
              {props.progressText && (
                <span
                  className={
                    `${classes.progressText} ${props.exportSelected}`
                      ? classes.contrast
                      : ""
                  }
                >
                  {props.progressText}
                </span>
              )}
            </div>
          )}
        </BooleanInputPopover>
      </div>
    );
  } else {
    return (
      <div
        className={`${classes.ExportButton} ${classes.Button} ${
          props.exportSelected ? classes.exportAltColor : ""
        }`}
      >
        <Dropdown
          isOpen={isOpen}
          onClose={toggleOpen}
          target={
            <div
              onClick={toggleOpen}
              title={
                props.exportSelected
                  ? t("tune.exportSelected")
                  : t("tune.export")
              }
              className={classes.DropdownContent}
            >
              <FaDownload />
              {props.exportSelected && !props.progressText && (
                <span className={classes.selectedExport}>
                  <MdCheckBox />
                </span>
              )}
              {props.progressText && (
                <span
                  className={
                    `${classes.progressText} ${props.exportSelected}`
                      ? classes.contrast
                      : ""
                  }
                >
                  {props.progressText}
                </span>
              )}
            </div>
          }
        >
          <div
            className={`${classes.FilterInterface} ${classes.exportButtonDropdown}`}
          >
            <div
              className={`${classes.PresetButton}`}
              onClick={() => {
                props.onClick("excel");
                toggleOpen();
              }}
            >
              <RiFileExcel2Fill style={{ color: "green" }} /> <span>Excel</span>
            </div>
            <div
              className={`${classes.PresetButton}`}
              onClick={() => {
                props.onClick("uitkrant");
                toggleOpen();
              }}
            >
              <HiNewspaper style={{ color: "orangered" }} />{" "}
              <span>Uitkrant</span>
            </div>
            <div
              className={`${classes.PresetButton}`}
              onClick={() => {
                props.onClick("json");
                toggleOpen();
              }}
            >
              <VscJson /> <span>JSON</span>
            </div>
          </div>
        </Dropdown>
      </div>
    );
  }
};

export default ExportButton;
