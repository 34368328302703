import _ from "lodash";

// See https://stackoverflow.com/a/57894104
export const comparisonIgnoringUndefined = (a: any, b: any) => {
  if (_.isArray(a) || _.isArray(b)) return;
  if (!_.isObject(a) || !_.isObject(b)) return;

  // @ts-ignore
  if (!_.includes(a, undefined) && !_.includes(b, undefined)) return;

  // Call recursively, after filtering all undefined properties
  return _.isEqualWith(
    _.omitBy(a, (value) => value === undefined),
    _.omitBy(b, (value) => value === undefined),
    comparisonIgnoringUndefined
  );
};
