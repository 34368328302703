import { EntityType } from "models/Ndtrc";

export const generateItemApiUrl = (
  eventType: EntityType,
  itemId: string,
  rev?: string | null,
  showLinks?: boolean,
) => {
  // Determine url based on item type
  let url;
  if (eventType === "EVENEMENT") {
    url = `/events/${itemId}`;
  } else if (eventType === "LOCATIE") {
    url = `/locations/${itemId}`;
  } else if (eventType === "EVENEMENTGROEP") {
    url = `/eventgroups/${itemId}`;
  } else if (eventType === "ROUTE") {
    url = `/routes/${itemId}`;
  } else {
    throw new Error(`Unknown entity type: ${eventType}`);
  }
  const modifiers = [];
  if (showLinks) {
    modifiers.push("showLinks=true");
  }
  if (rev) {
    modifiers.push(`_rev=${rev}`);
  }

  if (modifiers.length > 0) {
    url += `?${modifiers.join("&")}`;
  }
  return url;
};
