import { InternalItemModel } from "models/Ndtrc";
import { Lang } from "models/Lang";

export const getItemTitle = (item: InternalItemModel, mainLang: Lang) => {
  if (mainLang && item.trcItemDetails?.[mainLang]?.title) {
    return item.trcItemDetails?.[mainLang]?.title;
  } else if (
    item.translations?.primaryLanguage &&
    item.trcItemDetails?.[item.translations.primaryLanguage]?.title
  ) {
    return item.trcItemDetails?.[item.translations.primaryLanguage]?.title;
  } else if (item.trcItemDetails?.[Lang.NL]?.title) {
    // Dutch as first fallback
    return item.trcItemDetails?.[Lang.NL]?.title;
  } else if (item.trcItemDetails?.[Lang.EN]?.title) {
    // English as second fallback
    return item.trcItemDetails?.[Lang.EN]?.title;
  } else {
    return "no title";
  }
};
