export enum Lang {
  NL = "nl",
  EN = "en",
  DE = "de",
  FR = "fr",
  IT = "it",
  ES = "es",
  PT = "pt",
  JA = "ja",
  ZH = "zh",
}

export const langMetadata: any = {
  [Lang.NL]: {
    flag: "🇳🇱",
  },
  [Lang.EN]: {
    flag: "🇬🇧",
  },
  [Lang.DE]: {
    flag: "🇩🇪",
  },
  [Lang.FR]: {
    flag: "🇫🇷",
  },
  [Lang.IT]: {
    flag: "🇮🇹",
  },
  [Lang.ES]: {
    flag: "🇪🇸",
  },
  [Lang.PT]: {
    flag: "🇵🇹",
  },
  [Lang.JA]: {
    flag: "🇯🇵",
  },
  [Lang.ZH]: {
    flag: "🇨🇳",
  },
};
