import React, { useEffect, useState } from "react";
import classes from "./Dashboard.module.scss";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { RiCalendarEventLine } from "react-icons/ri";
import { usePopper } from "react-popper";
import popoverStyles from "../../components/FormWidgets/UrlsWidget/UrlIputPopover/PopoverSharedStyle.module.scss";
import LineChart from "../../components/dashboard/LineChart";
import Select from "react-select";
import { SingleSelectOption } from "../../models/SelectOption";
import { DateAggType } from "../../models/IngressMethodDelta/IngressMethodDelta";
import { TimeSavedData } from "../../models/TimeSavedData/TimeSavedData";
import TimeSaved from "../../components/dashboard/TimeSaved"; // theme css file
import { addDays, differenceInDays, format } from "date-fns";
import { ItemsLeftToValidateData } from "../../models/ItemsLeftToValidateData/ItemsLeftToValidateData";
import ItemsLeftToValidate from "../../components/dashboard/ItemsLeftToValidate";
import { nl } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectIsSuperAdmin } from "../../store/auth/authSlice";
import { feedFactoryAxios } from "helpers";
import { AxiosResponse } from "axios";
import { AccountModel } from "../../models/AccountModel";
import { MdInfo } from "react-icons/md";

interface Range {
  startDate: Date;
  endDate: Date;
  // color: PropTypes.string;
  key: "selection";
  // autoFocus: PropTypes.bool;
  // disabled: PropTypes.bool;
  // showDateDisplay: PropTypes.bool;
}
interface RangeWrapper {
  [key: string]: Range;
}

const defaultSelectionRange: Range = {
  startDate: new Date("2022-01-01"),
  endDate: new Date(),
  key: "selection",
};

const renderRangeLabel = (range: Range) => {
  const { startDate, endDate } = range;
  return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
};

const fetchOrganisations = async () => {
  const response = await fetch(
    "https://app.thefeedfactory.nl/api/dashboard/all-orgs",
    {
      method: "GET",
    }
  );
  const data = await response.json();
  return data;
};

// fetch data from the API
const fetchIngressMethodDeltas = async (
  startDate: Date,
  endDate: Date,
  userOrg: string | undefined,
  dataAgg: DateAggType
) => {
  const response = await fetch(
    "https://app.thefeedfactory.nl/api/dashboard/org-date-agg-interval",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userorganisation: userOrg,
        interval_begin: format(startDate, "yyyy-MM-dd"),
        interval_end: format(endDate, "yyyy-MM-dd"),
        date_agg: dataAgg,
      }),
    }
  );
  const data = await response.json();
  return data;
};

const fetchTimeSavedData = async (userOrg: string | undefined) => {
  const response = await fetch(
    "https://app.thefeedfactory.nl/api/dashboard/org-sum-last-date",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userorganisation: userOrg,
      }),
    }
  );
  const data = await response.json();
  return data[0];
};

const fetchItemsLeftToValidate = async (userOrg: string | undefined) => {
  // TEMP call; this uses all user organisations tied to current account
  const response = await feedFactoryAxios.get(
    "events?wfstatus=readyforvalidation&lastupdated=2021-01-01"
  );
  return {
    index: 0, // always  0 - part of empty array
    userorganisation: "all",
    todays_data: Date.now().toString(), // Date string
    items_left_to_validate: response.data.hits as number,
  };
};

const Dashboard = (props: {}) => {
  const { t } = useTranslation();
  // DATE INFO
  const [previewedSelectionRange, setPreviewedSelectionRange] =
    useState<RangeWrapper>({
      selection: defaultSelectionRange,
    });
  const [selectionRange, setSelectionRange] = useState<RangeWrapper>({
    selection: defaultSelectionRange,
  });
  // END DATE INFO

  // ORG INFO
  // Determine which permissions the current user has
  const isSuperAdmin: boolean = useSelector(selectIsSuperAdmin);
  const [userOrgs, setUserOrgs] = useState<SingleSelectOption[]>([]); // Only needed for superadmin
  const [selectedUserOrg, setSelectedUserOrg] =
    useState<SingleSelectOption | null>(null);
  useEffect(() => {
    if (!isSuperAdmin) {
      const accountUrl = "/accounts/me";

      // Retrieve account, and thereby userorganisations
      feedFactoryAxios
        .get(accountUrl)
        .then((response: AxiosResponse<AccountModel>) => {
          console.log(response);
          const userOrgs = response.data.userOrganisation;
          // TEMP: only use first userorganisation
          const userOrg = userOrgs.split(";")[0];
          setSelectedUserOrg({
            value: userOrg,
            label: userOrg,
          });
        });
      // .catch((error) => {
      //   setError(error.message);
      // })
      // .finally(() => {
      //   setIsLoading(false);
      // });
    } else {
      fetchOrganisations().then((data) => {
        if (!data || data.length === 0) return;
        setUserOrgs(
          data.map((org: any) => ({
            value: org.userorganisation,
            label: org.userorganisation,
          }))
        );
      });
    }
  }, [isSuperAdmin]);
  // END ORG INFO

  // ERROR INFO
  const [ingressMethodDeltasError, setIngressMethodDeltasError] =
    useState<boolean>(false);
  // END ERROR INFO

  // GRANULARITY INFO
  // Set automatically based on date range
  let granularity: DateAggType = "Month";
  const dateRange = differenceInDays(
    selectionRange.selection.endDate,
    selectionRange.selection.startDate
  );
  if (dateRange < 125) {
    granularity = "Day";
  } else if (dateRange < 365) {
    granularity = "Week";
  } else {
    granularity = "Month";
  }

  // IngressMethodDelta[]
  const [ingressMethodDeltas, setIngressMethodDeltas] = useState<any[]>([]);
  useEffect(() => {
    fetchIngressMethodDeltas(
      selectionRange.selection.startDate,
      selectionRange.selection.endDate,
      selectedUserOrg?.value,
      granularity
    )
      .then((data) => {
        setIngressMethodDeltasError(false);
        if (!data || data.length === 0) return;
        setIngressMethodDeltas(data);
      })
      .catch((err) => {
        setIngressMethodDeltasError(true);
        console.log(err);
      });
  }, [selectionRange, selectedUserOrg, granularity]);
  // END IngressMethodDelta[]

  // TimeSaved
  const [timeSaved, setTimeSaved] = useState<null | TimeSavedData>(null);
  useEffect(() => {
    fetchTimeSavedData(selectedUserOrg?.value).then((data) => {
      if (!data) return;
      setTimeSaved(data);
    });
  }, [selectedUserOrg]);
  // END TimeSaved

  // Items left to validate
  const [itemsLeftToValidate, setItemsLeftToValidate] =
    useState<null | ItemsLeftToValidateData>(null);
  useEffect(() => {
    fetchItemsLeftToValidate(selectedUserOrg?.value).then((data) => {
      if (!data) return;
      setItemsLeftToValidate(data);
    });
  }, [selectedUserOrg]);

  // END Items left to validate

  // POPOVER START
  const [popover, setPopover] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(popover, popperElement, {});
  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  const onButtonClickHandler = () => {
    if (update) {
      void update();
    }
    setPopoverActive(true);
  };
  // POPOVER END

  return (
    <div className={classes.dashboard}>
      <div className={classes.header}>
        <h1>{t("root.dashboard")}</h1>
        <div className={classes.tuning}>
          {isSuperAdmin && (
            <div className={classes.userOrganisationSelection}>
              <Select
                value={selectedUserOrg}
                isClearable
                options={userOrgs}
                menuPlacement={"auto"}
                placeholder={t("dashboard.everything")}
                styles={{
                  menu: (provided, state) => {
                    return { ...provided, zIndex: 2 };
                  },
                }}
                // @ts-ignore // Is correct type
                onChange={(item: any) => {
                  setSelectedUserOrg(item);
                }}
              />
            </div>
          )}
          <div
            onClick={onButtonClickHandler}
            className={classes.popoverButton}
            // @ts-ignore
            ref={setPopover}
          >
            <RiCalendarEventLine />
            {renderRangeLabel(selectionRange.selection)}
          </div>
          <div
            className={[
              popoverStyles.popover,
              classes.popover,
              popoverActive ? popoverStyles.active : "",
            ].join(" ")}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div className={classes.date}>
              <DateRangePicker
                ranges={[previewedSelectionRange.selection]}
                // @ts-ignore
                onChange={(dateRange: RangeWrapper) => {
                  // console.log(dateRange);
                  setPreviewedSelectionRange({
                    ...selectionRange,
                    ...dateRange,
                  });
                }}
                minDate={new Date("2022-01-01")}
                maxDate={addDays(new Date(), -1)}
                moveRangeOnFirstSelection={false}
                months={2}
                direction="horizontal"
                locale={nl}
              />
            </div>
          </div>
          <div
            className={`${popoverStyles.backdrop} ${
              popoverActive ? popoverStyles.active : ""
            }`}
            onClick={() => {
              setSelectionRange(previewedSelectionRange);
              setPopover(null);
              setPopoverActive(false);
            }}
          />
        </div>
      </div>
      <div className={classes.infoBox}>
        <div className={classes.infoBoxItemIcon}>
          <MdInfo />
        </div>
        <div className={classes.infoBoxText}>{t("dashboard.infoBoxText")}</div>
      </div>
      <div className={classes.dashboardWidgets}>
        {!ingressMethodDeltasError ? (
          <LineChart data={ingressMethodDeltas} granularity={granularity} />
        ) : (
          <div className={classes.lineChartError}>
            <h2>{t("dashboard.noDataError")}</h2>
          </div>
        )}
        <div className={classes.compoundWidget}>
          <ItemsLeftToValidate data={itemsLeftToValidate} />
          <TimeSaved data={timeSaved} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
