import { ExtLangString } from "./Ndtrc";
import { v4 as uuidv4 } from "uuid";
import { Lang } from "models/Lang";

export type UrlServiceType =
  | "general"
  | "booking"
  | "review"
  | "video"
  | "webshop"
  | "socialmedia"
  | "lastminute"
  | "virtualtour"
  | "dmo";

export const UrlServiceTypes: { value: UrlServiceType; label: string }[] = [
  { value: "general", label: "urlServiceType.general" },
  { value: "booking", label: "urlServiceType.booking" },
  { value: "review", label: "urlServiceType.review" },
  { value: "video", label: "urlServiceType.video" },
  { value: "webshop", label: "urlServiceType.webshop" },
  { value: "socialmedia", label: "urlServiceType.socialmedia" },
  { value: "lastminute", label: "urlServiceType.lastminute" },
  { value: "virtualtour", label: "urlServiceType.virtualtour" },
  { value: "dmo", label: "urlServiceType.dmo" },
];

interface GenericUrlDetails {
  url: string;
  descriptioncode?: string;
  targetLanguage?: Lang;
  reservations?: boolean;
  urlServiceType: UrlServiceType | null;
  descriptionTranslations?: ExtLangString[];
}
export interface ExtUrlDetails extends GenericUrlDetails { }
export interface IntUrlDetails extends GenericUrlDetails {
  id: string;
}
export const extUrlsToIntUrls = (urls: ExtUrlDetails[]): IntUrlDetails[] => {
  return urls.map((url: ExtUrlDetails, index: number) => {
    const urlClone: any = { ...url };
    urlClone.id = uuidv4();
    return urlClone as IntUrlDetails;
  });
};
export const intUrlsToExtUrls = (urls: IntUrlDetails[]): ExtUrlDetails[] => {
  return urls.map((url: IntUrlDetails, index: number) => {
    const urlClone: any = { ...url };
    delete urlClone.id;
    return urlClone as ExtUrlDetails;
  });
};
