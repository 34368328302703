import React, { Ref, useEffect, useState } from "react";
import {
  ExtLabelLangString,
  ExtLangString,
  IntFileItem as FileItem,
} from "models/Ndtrc";
import classes from "./ItemContainer.module.scss";
import { useTranslation } from "react-i18next";
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";
import { HoverDesc } from "components/HoverDesc";
import { MultiLangInput } from "components/FormWidgets/inputs";
import { Lang } from "models/Lang";

interface ImageDimension {
  width: number;
  height: number;
}

const convertLabelToTextLangString = (
  label: ExtLabelLangString
): ExtLangString => {
  return {
    lang: label.lang,
    text: label.label,
  };
};
const convertLabelToTextLangStringArray = (
  labels: ExtLabelLangString[]
): ExtLangString[] => {
  return labels.map(convertLabelToTextLangString);
};
const convertTextToLabelLangString = (
  text: ExtLangString
): ExtLabelLangString => {
  return {
    lang: text.lang,
    label: text.text,
  };
};
const convertTextToLabelLangStringArray = (
  texts: ExtLangString[]
): ExtLabelLangString[] => {
  return texts.map(convertTextToLabelLangString);
};

// TODO: Refactor Media Item components with proto component
const ItemContainer = React.forwardRef(
  (
    props: {
      item: FileItem;
      onRemove: (id: string) => void;
      style: any;
      children: any;
      draggableProps: DraggableProvidedDraggableProps;
      dragHandleProps: DraggableProvidedDragHandleProps | undefined;
      isDragging: boolean;
      handleItemChange: (
        index: string,
        key: string,
        value: string | ExtLabelLangString[] | null
      ) => void;
      showCopyrightAsRequired?: boolean;
      disabled?: boolean;
      primaryLang?: Lang;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();

    const [imageDimensions, setImageDimensions] =
      useState<ImageDimension | null>(null);

    useEffect(() => {
      const image = new Image();
      const hlink = props.item.hlink;

      if (props.item.mediatype !== "photo") return;

      image.onload = () => {
        setImageDimensions({
          width: image.naturalWidth,
          height: image.naturalHeight,
        });

        // there must be backend support that gives the filesize
        // using the performance api returns a size of 0 if the
        // file is loaded from cache. It seems undesirable to
        // force the image to not be loaded from cache.
        // Thus, backend support is likely the best option
        // var iTime = performance.getEntriesByName(hlink)[0];
        // console.log(iTime);
      };
      image.src = hlink;
    }, [props.item.hlink, props.item.mediatype]);

    // TODO: Add placeholder image if image at url is not available
    return (
      <div
        className={classes.ItemContainer}
        ref={ref}
        style={props.style}
        {...props.draggableProps}
        {...props.dragHandleProps}
      >
        {props.children}
        <div className={classes.MetaData}>
          {/*<div>*/}
          {/*  {t("form.filename")}: {props.item.filename}*/}
          {/*</div>*/}
          {props.item.mediatype === "photo" && (
            <>
              {imageDimensions && (
                <div>
                  {t("form.filedimensions")}: {imageDimensions.width}x
                  {imageDimensions.height}
                </div>
              )}
            </>
          )}
          <div>
            {t("form.filesize")}: {/* @ts-ignore */}
            {props.item.filesize || t("form.unknownFileSize")}
          </div>
          <div>{props.item.targetLanguage}</div>
          <div>{props.item.trcid}</div>
        </div>
        {!props.isDragging && (
          <div className={classes.Interface}>
            <label>
              {t("form.description")}{" "}
              <HoverDesc description="form.description.desc" />{" "}
            </label>
            <MultiLangInput
              primaryLang={props.primaryLang}
              value={
                props.item.title?.titleTranslations
                  ? convertLabelToTextLangStringArray(
                      props.item.title?.titleTranslations
                    )
                  : []
              }
              onChange={(newVal: ExtLangString[]) => {
                props.handleItemChange(
                  props.item.hlink,
                  "title.titleTranslations",
                  newVal ? convertTextToLabelLangStringArray(newVal) : []
                );
              }}
            />
            <label>
              {t("form.copyright")} {props?.showCopyrightAsRequired && " (*)"}
              <HoverDesc description="form.copyright.desc" />
            </label>
            <input
              value={props.item.copyright ?? ""}
              onChange={(event) => {
                props.handleItemChange(
                  props.item.hlink,
                  "copyright",
                  event.currentTarget.value
                );
              }}
              disabled={props?.disabled}
            />

            {!props?.disabled && (
              <button
                type="button"
                className={classes.DeleteButton}
                onClick={() => {
                  props.onRemove(props.item.hlink);
                }}
              >
                {t("form.delete")}
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default ItemContainer;
