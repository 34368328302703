import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { SingleSelectOption } from "../../../models/SelectOption/SelectOption";
import { useTranslation } from "react-i18next";
import { feedFactoryAxios } from "helpers";
import classes from "./DictionarySelectWidget.module.scss";
import { commaSeparatedStringToOptionArray } from "../../../helpers/commaSeparatedStringToOptionArray";
import Select, { ActionMeta } from "react-select";

export interface ExtSelectOption {
  name: string;
  count: number;
}

export enum DictionaryType {
  EventTag,
  EventMarker,
  LocationTag,
  LocationMarker,
}

const getRightDictionaryUrl = (type: DictionaryType): string | null => {
  switch (type) {
    case DictionaryType.EventTag:
      return "/dictionary/event_keywords";
    case DictionaryType.EventMarker:
      return "/dictionary/event_markers";
    case DictionaryType.LocationTag:
      return "/dictionary/location_keywords";
    case DictionaryType.LocationMarker:
      return "/dictionary/location_markers";
    default:
      return null;
  }
};

const DictionarySelectWidget = (props: {
  value: string | null;
  onChange: (value: string) => void;
  type: DictionaryType;
  isSingle?: boolean;
  onKeyDown?: (event: any) => any;
  allowNewItems?: boolean;
  allowRemovingItems?: boolean;
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<SingleSelectOption[]>([]);

  useEffect(() => {
    const url = getRightDictionaryUrl(props.type);
    if (!url) {
      return;
    }
    feedFactoryAxios.get(url).then((res) => {
      const options = res.data.map((opt: ExtSelectOption) => {
        return {
          value: opt.name,
          label: opt.name,
        };
      });
      setOptions(options);
    });
  }, []);

  return (
    <div className={classes.DictionarySelectWidget}>
      {props.allowNewItems ? (
        <CreatableSelect
          className={`multiSelect ${classes.select}`}
          value={commaSeparatedStringToOptionArray(props.value)}
          isMulti={!props.isSingle}
          options={options}
          menuPlacement={"auto"}
          placeholder={`${t("form.select")}...`}
          formatCreateLabel={(inputText) =>
            `${t("form.createPrefix")} "${inputText}" ${t("form.createSuffix")}`
          }
          styles={{
            menu: (provided, state) => {
              return { ...provided, zIndex: 1000000 };
            },
            multiValue: (base, state) => {
              return !props.allowRemovingItems
                ? { ...base, backgroundColor: "gray" }
                : base;
            },
            multiValueLabel: (base, state) => {
              return !props.allowRemovingItems
                ? {
                    ...base,
                    fontWeight: "bold",
                    color: "white",
                    paddingRight: 6,
                  }
                : base;
            },
            multiValueRemove: (base, state) => {
              return !props.allowRemovingItems
                ? { ...base, display: "none" }
                : base;
            },
          }}
          isClearable={props.allowRemovingItems || false}
          onChange={(item: any, actionMeta: ActionMeta<any>) => {
            if (
              (actionMeta.action === "pop-value" ||
                actionMeta.action === "remove-value" ||
                actionMeta.action === "clear") &&
              !props.allowRemovingItems
            ) {
              return;
            }
            if (props.isSingle) {
              props.onChange(item?.value || null);
            } else {
              props.onChange(item?.map((item: any) => item.value).join(";"));
            }
          }}
          onKeyDown={(event) => {
            if (props.onKeyDown) {
              props.onKeyDown(event);
            }
          }}
        />
      ) : (
        <Select
          className={`multiSelect ${classes.select}`}
          value={commaSeparatedStringToOptionArray(props.value)}
          isMulti={!props.isSingle}
          options={options}
          menuPlacement={"auto"}
          placeholder={`${t("form.select")}...`}
          formatCreateLabel={(inputText: string) =>
            `${t("form.createPrefix")} "${inputText}" ${t("form.createSuffix")}`
          }
          styles={{
            menu: (provided, state) => {
              return { ...provided, zIndex: 1000000 };
            },
            multiValue: (base, state) => {
              return !props.allowRemovingItems
                ? { ...base, backgroundColor: "gray" }
                : base;
            },
            multiValueLabel: (base, state) => {
              return !props.allowRemovingItems
                ? {
                    ...base,
                    fontWeight: "bold",
                    color: "white",
                    paddingRight: 6,
                  }
                : base;
            },
            multiValueRemove: (base, state) => {
              return !props.allowRemovingItems
                ? { ...base, display: "none" }
                : base;
            },
          }}
          isClearable={props.allowRemovingItems || false}
          onChange={(item: any, actionMeta: ActionMeta<any>) => {
            if (
              (actionMeta.action === "pop-value" ||
                actionMeta.action === "remove-value" ||
                actionMeta.action === "clear") &&
              !props.allowRemovingItems
            ) {
              return;
            }

            if (props.isSingle) {
              props.onChange(item?.value || null);
            } else {
              props.onChange(item?.map((item: any) => item.value).join(";"));
            }
          }}
          onKeyDown={(event) => {
            if (props.onKeyDown) {
              props.onKeyDown(event);
            }
          }}
        />
      )}
    </div>
  );
};

export default DictionarySelectWidget;
