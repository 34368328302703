import React from "react";
import classes from "./CurrencyInput.module.scss";
import { cleanCurrencyString } from "helpers";

const CurrencyInput = (props: {
  value: string;
  disabled?: boolean;
  onChange: (value: string) => any;
  onClick?: (value: any) => any;
}) => {
  const value = props.value ? cleanCurrencyString(props.value.toString()) : "";

  return (
    <span className={classes.CurrencyInput}>
      <input
        onBlur={(event) => {
          event.currentTarget.value = cleanCurrencyString(
            event.currentTarget.value
          );
          props.onChange(event.currentTarget.value);
        }}
        onClick={props.onClick}
        defaultValue={value}
        type="text"
        disabled={props.disabled}
      />
    </span>
  );
};

export default CurrencyInput;
