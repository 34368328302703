import React from "react";
import { Lang } from "models/Lang";
import { Controller } from "react-hook-form";
import {
  ExtLabelLangString,
  ExtLangString,
} from "models/Ndtrc";
import LangInput from "./LangInput";

type TextfieldName = "label" | "text" | "description" | "url";

const LangInputWrapper = (props: {
  lang: Lang;
  name: string;
  formMethods: any;
  defaultValue:
    | ExtLangString
    | ExtLabelLangString
    | any
    | { text: string }
    | null;
  onlyText?: boolean;
  alternativeTextField?: TextfieldName; // use .label (ExtLabelLangString) instead of .text (ExtLangString)
  onBlur?: (e: any) => any;
  disabled?: boolean;
}) => {
  let defaultValue = props.defaultValue;
  if (!defaultValue) {
    if (props.alternativeTextField) {
      defaultValue = {
        lang: props.lang,
        [props.alternativeTextField]: "",
      };
    } else {
      defaultValue = {
        lang: props.lang,
        text: "",
      };
    }
  }

  return (
    <Controller
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => {
        const changeVal = (val: string, lang?: Lang) => {
          if (props.alternativeTextField) {
            onChange({ lang: lang, [props.alternativeTextField]: val || "" });
          } else {
            onChange({ lang: lang, text: val || "" });
          }
        };

        // If input language changes, force langString value to also change
        if (!props.onlyText && props?.lang !== value?.lang) {
          changeVal(
            value?.text ||
              (props.alternativeTextField &&
                value?.[props.alternativeTextField]),
            props.lang
          );
        }

        return (
          <LangInput
            onChange={(value) => {
              if (props.onlyText) {
                changeVal(value);
              } else {
                changeVal(value, props.lang);
              }
            }}
            onBlur={props.onBlur}
            value={
              props.alternativeTextField
                ? value?.[props.alternativeTextField]
                : value?.text || ""
            }
            lang={value?.lang || props.lang}
            disabled={props.disabled}
          />
        );
      }}
      name={props.name}
      control={props.formMethods.control}
      defaultValue={defaultValue}
    />
  );
};

export default LangInputWrapper;
