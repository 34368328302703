import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../store";
import axios, { CancelTokenSource } from "axios";
import {
  comparisonIgnoringUndefined,
  externalResultsToInternalResults,
  fetchApiItems,
  IntPaginatedResponse,
} from "helpers";
import {
  defaultTuningOptions,
  TuningProps,
} from "../../models/TuningProps/TuningProps";
import _ from "lodash";

// As a non-serializable object, needs to be stored outside of redux
let cancelTokenSource: CancelTokenSource | null;

interface RoutesSlice {
  routes: IntPaginatedResponse | null; // also contains page and size
  loading: boolean;
  error: string | null;
  tuningProps: TuningProps;
  nextProps: null | {
    page: number;
    size: number;
    tuningProps: TuningProps;
  };
}

const initialState: RoutesSlice = {
  routes: null,
  loading: false,
  error: null,
  tuningProps: defaultTuningOptions,
  nextProps: null,
};

export const routesSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    fetchRoutesStart: (
      state,
      action: PayloadAction<{
        nextProps: {
          page: number;
          size: number;
          tuningProps: TuningProps;
        };
      }>,
    ) => {
      if (state.loading) {
        fetchRoutesInterrupt({
          interruptReason: "Request canceled: route data to be replaced.",
        });
      }
      state.loading = true;
      state.error = null;
      state.nextProps = action.payload.nextProps;
      return state;
    },
    fetchRoutesInterrupt: (
      state,
      action: PayloadAction<{
        interruptReason: string;
      }>,
    ) => {
      cancelTokenSource?.cancel(action.payload.interruptReason);

      state.nextProps = null;
      state.loading = false;
      state.error = null;
      return state;
    },
    fetchRoutesSuccess: (
      state,
      action: PayloadAction<{
        data: IntPaginatedResponse;
        tuningProps: TuningProps;
      }>,
    ) => {
      console.log("ROUTES LOADED", action.payload.data.results); // DEBUG
      state.loading = false;
      state.routes = action.payload.data;
      state.tuningProps = action.payload.tuningProps;
      return state;
    },
    fetchRoutesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;

      return state;
    },
  },
});

export const {
  fetchRoutesStart,
  fetchRoutesSuccess,
  fetchRoutesFailure,
  fetchRoutesInterrupt,
} = routesSlice.actions;

export const performFetchRoutes =
  (page = 0, size = 24, tuningProps: TuningProps): AppThunk =>
    async (dispatch, getState) => {
      const tuningPropsSame = checkTuningPropsSame(tuningProps, getState().routes.tuningProps);
      const nextPropsSame = checkNextPropsSame(page, size, tuningProps, getState().routes.nextProps);

      if (nextPropsSame) {
        return;
      }

      if (!tuningPropsSame) {
        console.log("tuning props not same", tuningPropsSame)
        page = 0;
      }

      cancelTokenSource?.cancel("Request canceled: event data already present.");
      cancelTokenSource = axios.CancelToken.source();
      dispatch(fetchRoutesStart({ nextProps: { page, size, tuningProps } }));

      try {
        const response = await fetchApiItems(
          "ROUTE",
          page,
          size,
          tuningProps,
          cancelTokenSource,
          "json",
          true,
        );
        console.log("ROUTES LOADED", response.data.results); // DEBUG
        dispatch(fetchRoutesSuccess({
          data: externalResultsToInternalResults(response.data),
          tuningProps,
        }));
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          dispatch(fetchRoutesFailure(error.message));
        }
      }
    };

function checkTuningPropsSame(tuningProps: TuningProps, storedTuningProps: TuningProps): boolean {
  return _.isEqualWith(tuningProps, storedTuningProps, comparisonIgnoringUndefined);
}

function checkNextPropsSame(page: number, size: number, tuningProps: TuningProps, nextProps: any): boolean {
  return (
    page === nextProps?.page &&
    size === nextProps?.size &&
    _.isEqualWith(tuningProps, nextProps?.tuningProps, comparisonIgnoringUndefined)
  );
}

export const selectLoading = (state: RootState) => state.routes.loading;
export const selectError = (state: RootState) => state.routes.error;
export const selectRoutes = (state: RootState) => state.routes.routes;
export const selectTuningProps = (state: RootState) => state.routes.tuningProps;

export default routesSlice.reducer;
