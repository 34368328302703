import SettingsField, { SettingsLabel } from "./SettingsField";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import NavigationButton from "../../../components/buttons/NavigationButton/NavigationButton";
import { MdChevronLeft } from "react-icons/md";
import TextButton from "../../../components/buttons/TextButton/TextButton";
import { useTranslation } from "react-i18next";
import { feedFactoryAxios } from "helpers";
import {
  selectAccountId,
  selectIsAdmin,
  selectIsSuperAdmin,
} from "../../../store/auth/authSlice";
import { useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import { AccountModel } from "../../../models/AccountModel/AccountModel";
import assertValidResponse from "./helpers/assertValidResponse";

const SettingsHStack = (props: any) => {
  return (
    <div className={`settings-hstack ${props.className}`}>{props.children}</div>
  );
};

interface NewUserProps {
  isSelf?: boolean;
}

interface OrganisationSlice {
  name: string;
}

const NewUser = (props: NewUserProps) => {
  const isAdmin = useSelector(selectIsAdmin);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  useEffect(() => {
    const ALL_ROLES = ["PARTNER", "USER", "EDITOR"];
    if (isAdmin) {
      ALL_ROLES.push("ADMIN");
    }
    if (isSuperAdmin) {
      ALL_ROLES.push("SUPER_ADMIN");
    }
    setAvailableRoles(ALL_ROLES);
  }, [isAdmin, isSuperAdmin]);

  const { t } = useTranslation();
  const history = useHistory();
  const { accountId: accountIdParam } = useParams<{ accountId: string }>();
  const userAccountId = useSelector(selectAccountId);
  const accountId = accountIdParam ? accountIdParam : userAccountId;

  const [error, setError] = useState<String | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [didCreateUser, setDidCreateUser] = useState(false);
  const [didFailToCreateUser, setDidFailToCreateUser] = useState(false);
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);

  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    roles: ["USER"],
  });

  const [randomPassword, setRandomPassword] = useState<string>("");

  useEffect(() => {
    let randomPassword: string = (
      Math.random().toString(36) + "00000000000000000"
    ).slice(2, 10 + 2);
    setRandomPassword(randomPassword);
  }, []);

  const [organisation, setOrganisation] = useState<OrganisationSlice>(
    {} as OrganisationSlice
  );

  useEffect(() => {
    let accountUrl = props.isSelf ? "/accounts/me" : `/accounts/${accountId}`;
    feedFactoryAxios
      .get(accountUrl)
      .then((response) =>
        assertValidResponse(response, "No account found for this account id")
      )
      .then((response: AxiosResponse<AccountModel>) => {
        setOrganisation(response.data);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accountId, props.isSelf]);

  const toggleRole = (role: string) => {
    const roles = user.roles || [];

    if (roles.indexOf(role) === -1) {
      let newRoles = roles;

      // @note, due to immutability .push will not cause a rerender
      newRoles = newRoles.concat(role);

      setUser((oldValue) => ({ ...oldValue, roles: newRoles }));
    } else {
      let newRoles = roles.filter((_role) => _role !== role);

      setUser((oldValue) => ({ ...oldValue, roles: newRoles }));
    }
  };

  const setFirstName = (newValue: string) => {
    setUser((oldObject) => ({ ...oldObject, firstName: newValue }));
  };
  const setLastName = (newValue: string) => {
    setUser((oldObject) => ({ ...oldObject, lastName: newValue }));
  };
  const setEmail = (newValue: string) => {
    setUser((oldObject) => ({ ...oldObject, email: newValue }));
  };

  const handleSubmit = () => {
    if (user.email === "") {
      return;
    }
    if (user.firstName === "" || user.lastName === "") {
      return;
    }
    feedFactoryAxios
      .post(`/accounts/${accountId}/users`, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        theme: "",
        password: randomPassword,
        roles: user.roles,
      })
      .then((response) => {
        setDidCreateUser(true);
        setDidFailToCreateUser(false);
      })
      .catch((error) => {
        setDidFailToCreateUser(true);
      });
  };

  const returnToUsers = () => {
    history.push(`./`);
  };

  const createdInterface = (
    <>
      <header className={"settings-header"}>
        <div className={"settings-eyebrow"}>
          <TextButton action={returnToUsers}>
            <>
              <MdChevronLeft /> {t("root.backToUsers")}
            </>
          </TextButton>
        </div>
      </header>
      <div className={"settings-message"}>
        <h1 className={"settings-headline"}>
          {t("root.userSuccessFullyAdded")}
        </h1>
        {!props.isSelf && (
          <h2 className={"settings-subheadline"}>
            {t("root.on")} {organisation.name}
          </h2>
        )}
        <ul>
          <li>
            <span>
              {t("root.email")}: <em className={"selectable"}>{user.email}</em>
            </span>
          </li>
          <li>
            <span>
              {t("root.temporaryPassword")}:{" "}
              <em className={"selectable"}>{randomPassword}</em>
            </span>
          </li>
        </ul>
        <SettingsHStack className={"settings-success-options"}>
          <TextButton
            action={() => {
              setDidCreateUser(false);
            }}
          >
            {t("root.createAnotherUser")}
          </TextButton>
          <em>of</em>
          <NavigationButton action={returnToUsers}>
            {t("root.backToUsers")}
          </NavigationButton>
        </SettingsHStack>
      </div>
    </>
  );

  const createInterface = (
    <>
      <header className={"settings-header"}>
        <div className={"settings-eyebrow"}>
          <TextButton action={returnToUsers}>
            <>
              <MdChevronLeft /> {t("root.backToUsers")}
            </>
          </TextButton>
        </div>
        <h2 className={"settings-title"}>{t("root.newUser")}</h2>
      </header>
      {error && error}
      {!error && !isLoading && (
        <div className={"settings-group"}>
          <SettingsHStack className={"settings-row"}>
            <SettingsField
              label={t("root.firstName")}
              setValue={setFirstName}
              value={user.firstName || ""}
            />
            <SettingsField
              label={t("root.lastName")}
              setValue={setLastName}
              value={user.lastName || ""}
            />
          </SettingsHStack>
          <div className={"settings-row"}>
            <SettingsField
              label={t("root.email")}
              setValue={setEmail}
              value={user.email}
            />
          </div>
          {/*<div className={"settings-row"}>*/}
          {/*  <SettingsField*/}
          {/*    label={"Tijdelijk wachtwoord"}*/}
          {/*    value={password}*/}
          {/*    setValue={setPassword}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className={"settings-row"}>
            <SettingsLabel>{t("root.role")}</SettingsLabel>
            {availableRoles.map((role) => (
              <span
                className={`settings-role clickable ${
                  user.roles.indexOf(role) === -1
                    ? "settings-role-inactive"
                    : ""
                }`}
                onClick={() => toggleRole(role)}
                key={role}
              >
                {role}
              </span>
            ))}
          </div>
          <div className={"settings-row"}>
            <SettingsLabel>{t("root.account")}</SettingsLabel>
            <span>{organisation.name}</span>
          </div>
          <NavigationButton action={handleSubmit}>
            {t("root.createUser")}
          </NavigationButton>
          {didFailToCreateUser && t("root.couldNotCreateUser")}
        </div>
      )}
    </>
  );

  return (
    <>
      {!didCreateUser && createInterface}
      {didCreateUser && createdInterface}
    </>
  );
};

export default NewUser;
