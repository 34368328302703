import React, { useEffect, useState } from "react";
import {
  DateRange,
  externalResultsToInternalResults,
  ExtPaginatedResponse,
  feedFactoryAxios,
  IntPaginatedResponse,
} from "helpers";
import CardWidget from "../../../components/CardWidget/CardWidget";
import { Lang } from "../../../models/Lang/Lang";
import { AxiosResponse } from "axios";
import classes from "./EventsByEventGroupWidget.module.scss";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader/Loader";
import CardWidgetHeader from "../../../components/CardWidget/CardWidgetHeader/CardWidgetHeader";
import PaginationControls from "../../../components/PaginationControls/PaginationControls";
import { useSelector } from "react-redux";
import { selectIsEditor, selectIsPartner } from "../../../store/auth/authSlice";
import { SortFieldOption } from "../../../models/TuningProps/TuningProps";
import {
  EventLink,
  InternalEventItemModel,
  WfStatus,
} from "../../../models/Ndtrc";
import EventSelect from "../../../components/EventSelect/EventSelect";
import { MdGroupWork } from "react-icons/md";

const EventsByEventGroupWidget = (props: {
  eventGroupDateRange?: DateRange;
  events: EventLink[] | undefined;
  onEventAddition: (el: EventLink) => any;
  onEventDeletion: (el: EventLink) => any;
  userOrganisations?: string[];
}) => {
  const [items, setItems] = useState<IntPaginatedResponse>();
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);

  const [firstLoading, setFirstLoading] = useState(true);
  const [reloading, setReloading] = useState(false);

  const [eventSelectItem, setEventSelectItem] =
    useState<InternalEventItemModel | null>(null);

  useEffect(() => {
    if (eventSelectItem?.id) {
      props.onEventAddition({ eventId: eventSelectItem.id });
      setEventSelectItem(null);
    }
  }, [eventSelectItem]);

  const isEditor = useSelector(selectIsEditor);
  const isPartner = useSelector(selectIsPartner);

  useEffect(() => {
    if (!props.events) {
      return;
    }

    setReloading(true);

    const sortField: SortFieldOption = "eventStart";
    let url = `/events?eventIDs=${props.events
      .map((e) => e.eventId)
      .join(";")}&sortField=${sortField}&sortOrder=ASC&size=12&page=${page}`;
    if (isEditor || isPartner) {
      const wfStatus: WfStatus[] = ["readyforvalidation", "approved"];
      const wfStatusStr: string = wfStatus.join(";");
      url += `&wfstatus=${wfStatusStr}`;
    }

    feedFactoryAxios
      .get(url)
      .then((res: AxiosResponse<ExtPaginatedResponse>) => {
        setItems(externalResultsToInternalResults(res.data));
        setFirstLoading(false);
        setReloading(false);
      })
      .catch((e) => {
        setFirstLoading(false);
        setReloading(false);
        console.error(e);
      });
  }, [page, props.events]);

  return (
    <div className={classes.EventsByLocationWidget}>
      {items && items.hits > 0 && (
        <div
          className={`floatingActionButtonsContainer ${classes.paginationContainer}`}
        >
          {!firstLoading && reloading && (
            <Loader className={classes.smallLoader} />
          )}
          {firstLoading && <Loader className={classes.Loader} />}
          <PaginationControls
            page={page}
            size={items.size}
            hits={items.hits}
            onChange={(newPage) => {
              setPage(newPage);
            }}
          />
        </div>
      )}
      <div className={classes.header}>
        <CardWidgetHeader
          header={
            <>
              <MdGroupWork />
              {t("form.eventLinks")}
            </>
          }
          badge={items?.hits || ""}
        />
        <EventSelect
          className={classes.EventSelect}
          lang={Lang.NL}
          value={eventSelectItem}
          onChange={setEventSelectItem}
          filterOnUserOrganisations={props.userOrganisations}
          // @ts-ignore
          additionalParams={[
            { key: "wfstatus", value: "readyforvalidation;approved" },
            {
              key: "eventDateRangeStart",
              value: props.eventGroupDateRange?.startdate,
            },
            {
              key: "eventDateRangeEnd",
              value: props.eventGroupDateRange?.enddate,
            },
          ].filter((el) => {
            return el.value;
          })}
        />
      </div>
      <div className={classes.results}>
        {items && items.hits > 0 && (
          <CardWidget
            items={items.results}
            hits={items.hits}
            lang={Lang.NL}
            showBadge
            onDeletion={(id: string) => {
              props.onEventDeletion({ eventId: id });
            }}
          />
        )}
        {firstLoading && (
          <div className={`ff-loader-centered ${classes.Loader}`}>
            <Loader />
            <p>{t("root.loadingLastEvents")}</p>
          </div>
        )}
        {!firstLoading && (!items || items.hits === 0) && (
          <div
            className={`ff-loader-centered ${classes.Loader} ${classes.notAvailable}`}
          >
            <p>{t("root.noEventsAvailableInEventGroup")}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventsByEventGroupWidget;
