import { AxiosResponse } from "axios";

const assertValidResponse = (
  response: AxiosResponse,
  emptyResponseMessage: string
) => {
    return response;
};

export default assertValidResponse;
