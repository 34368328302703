import React from "react";
import {
  ExtLangString,
  Open,
  Weekday,
  weekDaysDisplayOrder,
} from "models/Ndtrc";
import { useTranslation } from "react-i18next";
import Checkbox from "react-three-state-checkbox";
import classes from "../PromotionsWidget.module.scss";

const WeekDaysValue = (props: {
  value: Open<ExtLangString[]>[];
  onChange: (value: Open<ExtLangString[]>[]) => any;
}) => {
  const { t } = useTranslation();

  const addWeekDay = (day: number) => {
    props.onChange(
      props.value.concat({
        day: day,
        whens: [],
      })
    );
  };
  const removeWeekDay = (day: number) => {
    props.onChange(props.value.filter((item) => item.day !== day));
  };

  const setAllWeekDays = () => {
    props.onChange(
      [1, 2, 3, 4, 5, 6, 7].map((i) => ({
        day: i,
        whens: [],
      }))
    );
  };
  const setNoWeekDays = () => {
    props.onChange([]);
  };

  return (
    <div className={classes.WeekDaysValue}>
      <div>
        <label className={classes.boldText}>
          <Checkbox
            checked={props.value.length === 7}
            indeterminate={props.value.length !== 0 && props.value.length !== 7}
            onChange={() => {
              if (props.value.length === 7) {
                setNoWeekDays();
              } else {
                setAllWeekDays();
              }
            }}
          />
          {t("promotion.allDays")}
        </label>
      </div>
      <div className={classes.boldText}>|</div>
      {weekDaysDisplayOrder.map((weekDay: number, index: number) => {
        const isChecked = props.value.some((item) => item.day === weekDay);
        return (
          <div key={weekDay}>
            <label>
              <Checkbox
                checked={isChecked}
                indeterminate={false}
                onChange={() => {
                  if (isChecked) {
                    removeWeekDay(weekDay);
                  } else {
                    addWeekDay(weekDay);
                  }
                }}
              />
              {Weekday[weekDay]}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default WeekDaysValue;
