import React from "react";
import classes from "./DateExceptionWidget.module.scss";
import { IntSingleExceptionDate } from "models/Ndtrc";
import { get, useFieldArray } from "react-hook-form";
import { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import { v4 as uuidv4 } from "uuid";

import "react-datepicker/dist/react-datepicker.css";
import { TiDelete } from "react-icons/ti";
import { RiDeleteBin3Line } from "react-icons/ri";
import { IoMdAddCircle } from "react-icons/io";

import { useTranslation } from "react-i18next";
import { DateWidgetWrapper } from "components/FormWidgets/DateWidget";
import WhenWidget from "components/FormWidgets/CalendarWidget/WhenWidget";

const generateDefaultEvent = (): IntSingleExceptionDate => {
  return {
    date: null, // new Date().toISOString(),
    whens: [{ timestart: null, timeend: null }], // "12:00"
    id: uuidv4(),
  };
};

const DateExceptionWidget = (props: {
  formMethods: any;
  namespace: string;
}) => {
  const { t } = useTranslation();
  const control: any = props.formMethods.control; // required, as field array does not like input via props
  const { fields, append, prepend, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${props.namespace}`, // unique name for your Field Array
    keyName: "id", // default to "id", you can change the key name
  });

  registerLocale("nl", nl);

  // console.log(props.formMethods.formState.errors); // DEBUG

  return (
    <div className={classes.CalendarWidget}>
      {fields.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>{t("calendarWidget.date")}</th>
              <th>{t("calendarWidget.time")}</th>
              <th>
                <div
                  className={classes.deleteAll}
                  onClick={() => {
                    remove();
                  }}
                >
                  <div>
                    <RiDeleteBin3Line />
                    <span>{t("calendarWidget.deleteAll")}</span>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {fields.map(
              // @ts-ignore // Type IntSingleExceptionDate not allowed as description, but is correct
              (SingleExceptionDate: IntSingleExceptionDate, index) => {
                // console.log(SingleExceptionDate); // DEBUG
                return (
                  <tr key={SingleExceptionDate.id}>
                    <td>
                      <DateWidgetWrapper
                        name={`${props.namespace}[${index}].date`}
                        formMethods={props.formMethods}
                        defaultValue={SingleExceptionDate.date}
                        hasError={get(
                          props.formMethods.formState.errors,
                          `${props.namespace}[${index}].date`
                        )}
                      />
                    </td>
                    <td>
                      <WhenWidget
                        defaultValue={SingleExceptionDate.whens?.[0]}
                        formMethods={props.formMethods}
                        namespace={`${props.namespace}[${index}].whens[0]`}
                        noStatus
                      />
                    </td>
                    <td>
                      <div
                        onClick={() => remove(index)}
                        className={classes.danger}
                      >
                        <TiDelete />
                      </div>
                    </td>
                    {get(
                      props.formMethods.formState.errors,
                      `${props.namespace}[${index}]`
                    ) && (
                      <td>
                        <div className={classes.errorInformation}>
                          {[
                            get(
                              props.formMethods.formState.errors,
                              `${props.namespace}[${index}].date`
                            ) && t("calendarWidget.dateRequiredError"),
                            get(
                              props.formMethods.formState.errors,
                              `${props.namespace}[${index}].whens[0].timestart`
                            ) && t("calendarWidget.timestartRequiredError"),
                          ]
                            .filter((item: any) => item)
                            .join(" & ")}
                        </div>
                      </td>
                    )}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      )}
      <div
        onClick={() => {
          append(generateDefaultEvent());
        }}
        className={classes.add}
      >
        <IoMdAddCircle />
      </div>
    </div>
  );
};

export default DateExceptionWidget;
