import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { useTranslation } from "react-i18next";
import { Lang } from "../../models/Lang/Lang";
import CardWidget from "../../components/CardWidget/CardWidget";
import _ from "lodash";
import TuneWidget from "../../components/TuneWidget/TuneWidget";
import FilterWidget from "../../components/TuneWidget/FilterWidget";
import SortWidget from "../../components/TuneWidget/SortWidget";
import SearchWidget from "../../components/TuneWidget/SearchWidget";
import classes from "./ItemsBrowser.module.scss";
import {
  defaultTuningOptions,
  Filter,
  SortFieldOption,
  TuningProps,
} from "../../models/TuningProps/TuningProps";
import {
  feedFactoryAxios,
  fetchApiExportSelectedEventsUrl,
  fetchApiExportSelectedRoutessUrl,
  fetchApiItemsUrl,
  IntPaginatedResponse,
  useQueryParams,
} from "helpers";
import PresetsButton from "../../components/TuneWidget/PresetsButton";
import ExportButton from "components/TuneWidget/ExportButton";
import FileDownload from "js-file-download";
import { selectIsAdmin, selectIsEditor } from "../../store/auth/authSlice";
import ButtonArray from "../../components/TuneWidget/ButtonArray";
import PaginationControls from "../../components/PaginationControls/PaginationControls";
import {
  CategoryValue,
  EntityType,
  ExternalItemModel,
  WfStatus,
} from "../../models/Ndtrc/Ndtrc";
import ListWidget from "../../components/ListWidget/ListWidget";
import { ViewType } from "../../models/ViewType/ViewType";
import ViewChangeButton from "../../components/ViewChangeButton/ViewChangeButton";
import { ButtonType, NavigationButton } from "../../components/buttons";
import { MdCategory, MdPerson } from "react-icons/md";
import { IoPricetagsOutline } from "react-icons/io5";
import DictionaryInputPopover from "../../components/popovers/DictionaryInputPopover/DictionaryInputPopover";
import { DictionaryType } from "../../components/FormWidgets/DictionarySelectWidget/DictionarySelectWidget";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ItemFormat } from "../../models/ItemFormat/ItemFormat";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { UserResponse } from "../../models/UserResponse";
import TextInputPopover from "../../components/popovers/TextInputPopover";
import { AiFillCheckCircle, AiFillDelete } from "react-icons/ai";
import BooleanInputPopover from "../../components/popovers/BooleanInputPopover";
import CategoryInputPopover from "../../components/popovers/CategoryInputPopover/CategoryInputPopover";
import { RiStackOverflowLine } from "react-icons/ri";
import {
  fetchItemsSuccess,
  performIngestItems,
} from "../../store/itemsQueue/itemsQueue";

type BulkAddAttributeType = "TAGS" | "MARKERS";

const ItemsBrowser = ({
  items,
  error,
  loading,
  performFetchItems,
  tuningProps,
  header,
  entityType,
}: {
  items: IntPaginatedResponse | null;
  error: string | null;
  loading: boolean;
  performFetchItems: (page: number, size: number, filter: TuningProps) => any;
  tuningProps: TuningProps;
  header: ReactNode;
  entityType: EntityType;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const history = useHistory();
  const queryParams = useQueryParams();

  // Permissions
  const isAdmin = useSelector(selectIsAdmin);
  const isEditor = useSelector(selectIsEditor);

  const sortOptions: { label: string; value: SortFieldOption }[] = [
    { label: t("root.lastupdated"), value: "modified" },
    { label: t("root.creationdate"), value: "created" },
  ];
  if (entityType === "EVENEMENT" || entityType === "EVENEMENTGROEP") {
    sortOptions.push({ label: t("tuning.eventStart"), value: "eventStart" });
  }

  const [querySortField, setQuerySortField] = useState(tuningProps.sortField);
  const [querySortOrder, setQuerySortOrder] = useState(tuningProps.sortOrder);
  const [querySearch, setQuerySearch] = useState(tuningProps.search);
  const [queryFilter, setQueryFilter] = useState(tuningProps.filters);
  const [page, setPage] = useState<number>(items?.page || 0);

  const [bulkOperationInProgress, setBulkOperationInProgress] = useState(false);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const handleSelectionChange = (
    ids: string[],
    isSelected: boolean,
    removeAll?: boolean
  ) => {
    if (removeAll) {
      setSelectedItems([]);
      return;
    }

    let newSelectedItems = _.cloneDeep(selectedItems);

    if (isSelected) {
      ids.forEach((id) => {
        // If is selected: append
        if (newSelectedItems.find((itemId) => itemId === id)) {
          return;
        }
        newSelectedItems.push(id);
      });
    } else {
      // If unselected: remove
      newSelectedItems = newSelectedItems.filter(
        (itemId) => !ids.some((i) => i === itemId)
      );
    }
    setSelectedItems(newSelectedItems);
  };

  const bulkAddAttribute = (
    attribute: BulkAddAttributeType,
    newValue: string
  ) => {
    if (!newValue) {
      return;
    }

    const itemsToAddTo = _.cloneDeep(selectedItems);
    const itemCount = itemsToAddTo.length;
    setBulkOperationInProgress(true);
    itemsToAddTo.map(async (itemId, index) => {
      try {
        const itemUrl = `/${
          entityType === "EVENEMENT"
            ? "events"
            : entityType === "LOCATIE"
            ? "locations"
            : entityType === "ROUTE"
            ? "routes"
            : "eventgroups"
        }/${itemId}`;
        const item: ExternalItemModel = (await feedFactoryAxios.get(itemUrl))
          .data;
        if (attribute === "TAGS") {
          const currentKeywords = item.keywords;
          if (!currentKeywords?.includes(newValue)) {
            if (currentKeywords && currentKeywords?.length !== 0) {
              item.keywords = `${item.keywords};${newValue}`;
            } else {
              item.keywords = newValue;
            }
            await feedFactoryAxios.put(itemUrl, item);
          }
        } else if (attribute === "MARKERS") {
          const currentMarkers = item.markers;
          if (!currentMarkers?.includes(newValue)) {
            if (currentMarkers && currentMarkers?.length !== 0) {
              item.markers = `${item.markers};${newValue}`;
            } else {
              item.markers = newValue;
            }
            await feedFactoryAxios.put(itemUrl, item);
          }
        }
      } catch (e) {
        console.error("Problem bulk adding tags or markers", e);
      }

      // Ensure page is refreshed when finishing operation
      // Timeout required given back-end listing requires refreshing after PUT requests
      setTimeout(() => {
        if (index === itemCount - 1) {
          setBulkOperationInProgress(false);
          fetchItems(
            querySortField === "modified" ? 0 : page, // If modified, items will shift; so reset page
            querySortField,
            querySearch,
            queryFilter,
            querySortOrder
          );
        }
      }, 1000);
    });
  };

  const bulkUpdateStatus = (updatedStatus: WfStatus) => {
    const itemsToUpdate = _.cloneDeep(selectedItems);
    const itemCount = itemsToUpdate.length;

    setBulkOperationInProgress(true);
    itemsToUpdate.map(async (itemId, index) => {
      try {
        const itemUrl = `/${
          entityType === "EVENEMENT"
            ? "events"
            : entityType === "LOCATIE"
            ? "locations"
            : entityType === "ROUTE"
            ? "routes"
            : "eventgroups"
        }/${itemId}`;
        const item: ExternalItemModel = (await feedFactoryAxios.get(itemUrl))
          .data;
        item.wfstatus = updatedStatus;
        await feedFactoryAxios.put(itemUrl, item);
      } catch (e) {
        console.error("Problem bulk updating items", e);
      }

      // Ensure page is refreshed when finishing operation
      // Timeout required given back-end listing requires refreshing after PUT requests
      setTimeout(() => {
        if (index === itemCount - 1) {
          setBulkOperationInProgress(false);
          fetchItems(
            querySortField === "modified" ? 0 : page, // If modified, items will shift; so reset page
            querySortField,
            querySearch,
            queryFilter,
            querySortOrder
          );
        }
      }, 1000);
    });
  };

  const bulkChangeCategory = (updatedCategories: CategoryValue[]) => {
    const itemsToUpdate = _.cloneDeep(selectedItems);
    const itemCount = itemsToUpdate.length;

    setBulkOperationInProgress(true);
    itemsToUpdate.map(async (itemId, index) => {
      try {
        const itemUrl = `/${
          entityType === "EVENEMENT"
            ? "events"
            : entityType === "LOCATIE"
            ? "locations"
            : entityType === "ROUTE"
            ? "routes"
            : "eventgroups"
        }/${itemId}`;
        const item: ExternalItemModel = (await feedFactoryAxios.get(itemUrl))
          .data;

        // Update category data
        if (item.trcItemCategories) {
          item.trcItemCategories.types = updatedCategories;
        }
        await feedFactoryAxios.put(itemUrl, item);
      } catch (e) {
        console.error("Problem bulk updating items", e);
      }

      // Ensure page is refreshed when finishing operation
      // Timeout required given back-end listing requires refreshing after PUT requests
      setTimeout(() => {
        if (index === itemCount - 1) {
          setBulkOperationInProgress(false);
          fetchItems(
            querySortField === "modified" ? 0 : page, // If modified, items will shift; so reset page
            querySortField,
            querySearch,
            queryFilter,
            querySortOrder
          );
        }
      }, 1000);
    });
  };

  const bulkAssignValidator = (newValue: string) => {
    // Simple check - real check is at back-end
    // Empty value also accepted
    let mailAddress: null | RegExpMatchArray = null;
    if (newValue) {
      mailAddress = newValue.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!mailAddress || !mailAddress[0]) {
        alert(t("root.invalidEmail"));
        return;
      }
    }
    const validator = mailAddress?.[0] || "";

    const itemsToAddTo = _.cloneDeep(selectedItems);
    const itemCount = itemsToAddTo.length;
    setBulkOperationInProgress(true);
    itemsToAddTo.map(async (itemId, index) => {
      try {
        const itemUrl = `/${
          entityType === "EVENEMENT"
            ? "events"
            : entityType === "LOCATIE"
            ? "locations"
            : entityType === "ROUTE"
            ? "routes"
            : "eventgroups"
        }/${itemId}`;
        const item: ExternalItemModel = (await feedFactoryAxios.get(itemUrl))
          .data;

        item.validator = validator;
        await feedFactoryAxios.put(itemUrl, item);
      } catch (e) {
        console.error("Problem bulk adding validator", e);
      }

      // Ensure page is refreshed when finishing operation
      // Timeout required given back-end listing requires refreshing after PUT requests
      setTimeout(() => {
        if (index === itemCount - 1) {
          setBulkOperationInProgress(false);
          fetchItems(
            querySortField === "modified" ? 0 : page, // If modified, items will shift; so reset page
            querySortField,
            querySearch,
            queryFilter,
            querySortOrder
          );
        }
      }, 1000);
    });
  };

  const loadingFinished = useSelector(fetchItemsSuccess);

  const initiateItemFlow = () => {
    setBulkOperationInProgress(true);
    dispatch(performIngestItems(entityType, selectedItems));
    const typeUrl =
      entityType === "EVENEMENT" ? "root.events.slug" : "root.locations.slug";
    history.push(`/${t(typeUrl)}/${selectedItems[0]}`);
    setBulkOperationInProgress(false);
  };

  const [viewType, setViewType] = useState<ViewType>("ListView");

  useEffect(() => {
    const locallyStored = window.localStorage.getItem("viewType") as string;
    if (locallyStored) {
      setViewType(locallyStored as ViewType);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("viewType", viewType);
  }, [viewType]);

  const [exportProgress, setExportProgress] = useState<null | string>(null);

  const fetchItems = useCallback(
    _.debounce(
      (page, querySort, querySearch, queryFilter, querySortOrder) => {
        dispatch(
          performFetchItems(page, 48, {
            sortField: querySort,
            search: querySearch,
            sortOrder: querySortOrder,
            filters: queryFilter,
          })
        );
      },
      300,
      {
        leading: false,
        trailing: true,
      }
    ),
    []
  );

  useEffect(() => {
    setQuerySortField(tuningProps.sortField);
    setQuerySortOrder(tuningProps.sortOrder);
    setQuerySearch(tuningProps.search);
    setQueryFilter(tuningProps.filters);
  }, [tuningProps]);

  useEffect(() => {
    fetchItems(page, querySortField, querySearch, queryFilter, querySortOrder);
  }, [
    queryFilter,
    querySearch,
    querySortOrder,
    querySortField,
    dispatch,
    fetchItems,
    page,
  ]);

  useEffect(() => {
    if (typeof items?.page === "number") {
      setPage(items.page);
    }
  }, [items]);

  const exportItems = (exportFormat: ItemFormat) => {
    const config: AxiosRequestConfig = {
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        if (!progressEvent.currentTarget) {
          return;
        }
        const current = progressEvent.loaded;
        setExportProgress(`${(current / 1000000).toFixed(2)}mb`);
      },
    };

    const onSuccess = (res: AxiosResponse) => {
      setExportProgress(null);

      let extension;
      switch (exportFormat) {
        case "excel":
          extension = ".xlsx";
          break;
        case "uitkrant":
          extension = ".txt";
          break;
        case "json":
          extension = ".json";
      }

      FileDownload(
        res.data,
        `thefeedfactory-export_${format(
          Date.now(),
          "yyyy-MM-dd-HHmm"
        )}${extension}`
      );
    };

    const onError = (e: AxiosError) => {
      setExportProgress(t("export.failed"));
      console.error(e);
    };

    if (entityType === "EVENEMENT" && selectedItems.length > 0) {
      // Only export selected items
      const { url, body } = fetchApiExportSelectedEventsUrl(
        selectedItems,
        exportFormat,
        tuningProps
      );
      setExportProgress(t("export.inProgress"));
      feedFactoryAxios.post(url, body, config).then(onSuccess).catch(onError);
    } else {
      if (entityType === "ROUTE" && selectedItems.length > 0) {
        // Only export selected items
        const { url, body } = fetchApiExportSelectedRoutessUrl(
          selectedItems,
          exportFormat,
          tuningProps
        );
        setExportProgress(t("export.inProgress"));
        feedFactoryAxios.post(url, body, config).then(onSuccess).catch(onError);
      } else {
        const url = fetchApiItemsUrl(
          entityType,
          page,
          0,
          tuningProps,
          exportFormat
        );
        setExportProgress(t("export.inProgress"));
        feedFactoryAxios.get(url, config).then(onSuccess).catch(onError);
      }
    }
  };

  const [showMyItems, setShowMyItems] = useState(false);
  const showMyItemsParam = !!queryParams.get(t("root.myItems"));
  if (showMyItemsParam !== showMyItems) {
    setShowMyItems(showMyItemsParam);
  }

  // If my items, filter on items assigned to own account
  useEffect(() => {
    if (!showMyItems) {
      return;
    }
    if (entityType === "EVENEMENT") {
      history.replace(`/${t("root.events.slug")}`);
    } else if (entityType === "LOCATIE") {
      history.replace(`/${t("root.locations.slug")}`);
    } else if (entityType === "ROUTE") {
      history.replace(`/${t("root.routes.slug")}`);
    } else {
      history.replace(`/${t("root.eventgroups.slug")}`);
    }

    const userUrl = `/auth/me`;
    feedFactoryAxios
      .get(userUrl)
      .then((response: AxiosResponse<UserResponse>) => {
        const myFilter: Filter[] = (
          defaultTuningOptions.filters as Filter[]
        ).map((f) => {
          if (f.field === "validator") {
            return {
              label: "tuning.validator",
              translated: true,
              field: "validator",
              type: "string",
              value: response.data.email,
            };
          }
          return f;
        });
        setQueryFilter(myFilter);
      });
  }, [showMyItems]);

  if (error) {
    return <div>{error}</div>;
  }

  let loader = null;
  if (loading) {
    loader = (
      <div className={"ff-loader-centered isFixed"}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      {!items && loader}
      {items && (
        <>
          <div className="floatingActionButtonsContainer">
            {loading && <Loader className={classes.Loader} />}
            <ViewChangeButton
              currentView={viewType}
              onClick={() => {
                if (viewType === "CardView") {
                  setViewType("ListView");
                } else {
                  setViewType("CardView");
                }
              }}
            />
            <PaginationControls
              page={page}
              size={items.size}
              hits={items.hits}
              onChange={(newPage) => {
                setPage(newPage);
              }}
            />
          </div>
          <TuneWidget>
            <SearchWidget value={querySearch} setValue={setQuerySearch} />
            <FilterWidget
              filters={queryFilter}
              setFilter={setQueryFilter}
              entityType={entityType}
            />
            <SortWidget
              options={sortOptions}
              value={querySortField}
              setValue={setQuerySortField}
              sortOrder={querySortOrder}
              setSortOrder={setQuerySortOrder}
              disabled={!(!querySearch || querySearch.length < 1)}
            />
            <ButtonArray>
              {(isAdmin || isEditor) && (
                <PresetsButton
                  onClick={(tuningProps) => {
                    fetchItems(
                      page,
                      tuningProps.sortField,
                      tuningProps.search,
                      tuningProps.filters,
                      tuningProps.sortOrder
                    );
                  }}
                  tuningProps={{
                    filters: queryFilter,
                    search: querySearch,
                    sortOrder: querySortOrder,
                    sortField: querySortField,
                  }}
                />
              )}
              <ExportButton
                exportSelected={
                  entityType === "EVENEMENT" && selectedItems.length > 0
                }
                progressText={exportProgress}
                onClick={(format: ItemFormat) => {
                  exportItems(format);
                }}
              />
            </ButtonArray>
          </TuneWidget>
          {viewType === "ListView" ? (
            <ListWidget
              items={items.results}
              hits={items.hits}
              header={header}
              lang={Lang.NL}
              selectedItems={selectedItems}
              onSelectionChange={handleSelectionChange}
              entityType={entityType}
            />
          ) : (
            <CardWidget
              items={items.results}
              hits={items.hits}
              header={header}
              lang={Lang.NL}
              selectedItems={selectedItems}
              onSelectionChange={handleSelectionChange}
            />
          )}
        </>
      )}
      {selectedItems.length > 0 && (
        <div className={`${classes.bulkEditor} ${classes.ButtonsContainer}`}>
          {entityType === "EVENEMENT" && (
            <NavigationButton
              action={(e) => {
                initiateItemFlow();
              }}
              type={ButtonType.Alternate}
              expandTextOnHover={true}
            >
              <RiStackOverflowLine />{" "}
              <span>{t("selection.batch-workflow")}</span>
            </NavigationButton>
          )}
          <CategoryInputPopover
            onSubmit={(categories) => {
              bulkChangeCategory(categories);
            }}
            entityType={entityType}
          >
            {(setPopover: any, onButtonClickHandler: any) => (
              <div ref={setPopover}>
                <NavigationButton
                  action={onButtonClickHandler}
                  expandTextOnHover={true}
                >
                  <MdCategory /> <span>{t("selection.category")}</span>
                </NavigationButton>
              </div>
            )}
          </CategoryInputPopover>
          <DictionaryInputPopover
            onTextSubmit={(newTag) => {
              bulkAddAttribute("TAGS", newTag);
            }}
            type={
              entityType === "EVENEMENT"
                ? DictionaryType.EventTag
                : DictionaryType.LocationTag
            }
          >
            {(setPopover: any, onButtonClickHandler: any) => (
              <div ref={setPopover}>
                <NavigationButton
                  action={onButtonClickHandler}
                  expandTextOnHover={true}
                >
                  <IoPricetagsOutline /> <span>{t("selection.tag")}</span>
                </NavigationButton>
              </div>
            )}
          </DictionaryInputPopover>
          <TextInputPopover
            onTextSubmit={(validator) => {
              bulkAssignValidator(validator);
            }}
            placeholder={t("form.toAssignEmail")}
            allowEmpty
          >
            {(setPopover: any, onButtonClickHandler: any) => (
              <div ref={setPopover}>
                <NavigationButton
                  action={onButtonClickHandler}
                  expandTextOnHover={true}
                >
                  <MdPerson /> <span>{t("selection.validator")}</span>
                </NavigationButton>
              </div>
            )}
          </TextInputPopover>

          {isAdmin && (
            <BooleanInputPopover
              onBooleanSubmit={(shouldApprove) => {
                if (shouldApprove) {
                  bulkUpdateStatus("approved");
                }
              }}
              prompt={t("selection.approvePrompt")}
              type={"normal"}
            >
              {(setPopover: any, onButtonClickHandler: any) => (
                <NavigationButton
                  action={(e) => {
                    onButtonClickHandler(e);
                  }}
                  type={ButtonType.Success}
                  expandTextOnHover={true}
                >
                  <AiFillCheckCircle /> <span>{t("selection.approve")}</span>
                </NavigationButton>
              )}
            </BooleanInputPopover>
          )}

          {isAdmin && (
            <BooleanInputPopover
              onBooleanSubmit={(deletion) => {
                if (deletion) {
                  bulkUpdateStatus("deleted");
                }
              }}
              prompt={t("selection.confirmDeletePrompt")}
              type={"danger"}
            >
              {(setPopover: any, onButtonClickHandler: any) => (
                <NavigationButton
                  action={(e) => {
                    onButtonClickHandler(e);
                  }}
                  type={ButtonType.Failure}
                  expandTextOnHover={true}
                >
                  <AiFillDelete /> <span>{t("selection.delete")}</span>
                </NavigationButton>
              )}
            </BooleanInputPopover>
          )}
        </div>
      )}
      {bulkOperationInProgress && (
        <div className={classes.bulkOperationInProgress}>
          <div className={"ff-loader-centered"}>
            <Loader />
          </div>
        </div>
      )}
    </>
  );
};

export default ItemsBrowser;
