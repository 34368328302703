import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../store";
import {
  feedFactoryAxios,
  resetAuthToken,
  setAuthToken,
} from "helpers";
import { AxiosError } from "axios";
import { updateThemeFromAccountId } from "../theme/themeSlice";

interface AuthData {
  usernameOrEmail: string;
  password: string;
}

// Warning: the name used here is NOT equal to userorganisation
interface AccountIdNameOnly {
  id: string;
  name: string;
}

interface AuthResponse {
  account: AccountIdNameOnly;
  roles: Array<string>;
  email: string;
  token: string;
  firstName: string;
  lastName: string;
  masqueradingUserToken: string | null;
}

interface AuthSlice {
  accountId: string | null;
  roles: Array<string>;
  token: string | null;
  booting: boolean;
  loading: boolean;
  expired: boolean;
  error: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  masqueradingUserToken: string | null;
}

const localGetter = (key: string) => {
  let value = localStorage.getItem(key);

  if (value && value.length > 0) {
    return value;
  }

  return null;
};

const initialState: AuthSlice = {
  accountId: null,
  roles: [],
  token: null, // do not load directly from localStorage as it might be invalid.
  booting: true,
  loading: false,
  error: null,
  expired: false,
  firstName: localGetter("user.firstName"),
  lastName: localGetter("user.lastName"),
  email: localGetter("user.email"),
  masqueradingUserToken: localGetter("user.meta-jwt"),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signInStart: (state) => {
      state.loading = true;
      state.error = null;

      return state;
    },
    // __signIn: (state, action: PayloadAction<any>) => {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    // Change state
    // },
    signInSuccess: (state, action: PayloadAction<AuthResponse>) => {
      state.token = action.payload.token;
      setAuthToken(action.payload.token);
      state.loading = false;
      state.error = null;

      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.expired = false;
      state.accountId = action.payload.account.id;
      state.roles = action.payload.roles;

      localStorage.setItem("user.jwt", action.payload.token);
      localStorage.setItem("user.firstName", action.payload.firstName);
      // localStorage.setItem("user.lastName", action.payload.lastName);
      localStorage.setItem("user.email", action.payload.email);

      document.title = "The Feed Factory";

      return state;
    },
    signInFailure: (state, action: PayloadAction<any>) => {
      state.token = null;
      resetAuthToken();
      state.loading = false;
      state.error = action.payload;

      return state;
    },

    signOut: (state) => {
      state.token = null;
      resetAuthToken();
      state.firstName = null;
      state.lastName = null;
      state.email = null;
      state.accountId = null;
      state.roles = [];

      localStorage.removeItem("user.jwt");
      localStorage.removeItem("user.firstName");
      // localStorage.removeItem("user.lastName");
      localStorage.removeItem("user.email");

      document.title = "Inloggen | The Feed Factory";

      // @todo document.cookie="jwt=;expired=expires=Thu, 18 Dec 2013 12:00:00 UTC;path=/"

      return state;
    },

    verificationStart: (state) => {
      state.error = null;
      state.loading = true;

      return state;
    },

    // in verificationSuccess and verificationFailure the booting state must always be set to false!!
    verificationSuccess: (state, action: PayloadAction<AuthResponse>) => {
      state.token = action.payload.token;
      setAuthToken(action.payload.token);
      state.booting = false;
      state.loading = false;
      state.expired = false;
      state.error = null;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.accountId = action.payload.account.id;
      state.roles = action.payload.roles;
      state.masqueradingUserToken = action.payload.masqueradingUserToken;

      localStorage.setItem("user.jwt", action.payload.token);
      localStorage.setItem("user.firstName", action.payload.firstName);
      // localStorage.setItem("user.lastName", action.payload.lastName);
      localStorage.setItem("user.email", action.payload.email);

      document.title = "The Feed Factory";

      return state;
    },
    verificationFailure: (state, action: PayloadAction<string>) => {
      if (state.booting === false) {
        state.error = action.payload;
      }
      state.token = null;
      resetAuthToken();
      state.booting = false;
      state.loading = false;

      // document.title = "Inloggen | The Feed Factory";

      localStorage.removeItem("user.jwt");

      return state;
    },
  },
});

export const {
  signInStart,
  signInSuccess,
  signInFailure,

  signOut,
  // confirmationStart,
  // confirmationFailure,
  // confirmationSuccess,

  verificationStart,
  verificationFailure,
  verificationSuccess,
} = authSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const performSignIn =
  (authData: AuthData): AppThunk =>
  (dispatch) => {
    dispatch(signInStart());

    feedFactoryAxios
      .post("/auth/signin", {
        usernameOrEmail: authData.usernameOrEmail.toLowerCase(),
        password: authData.password,
      })
      .then((response) => {
        const data = response.data;
        dispatch(
          signInSuccess({
            account: {
              id: data.user.account?.id,
              name: data.user.account.name,
            },
            token: data.token.accessToken,
            email: data.user.email,
            firstName: data.user.firstName,
            lastName: data.user.lastName,
            roles: data.user.roles,
            masqueradingUserToken: null,
          })
        );

        dispatch(updateThemeFromAccountId(data.user.account));
      })
      .catch((error: AxiosError) => {
        // if a 403 is returned the signin credentials were invalid
        dispatch(signInFailure(error.message));
      });
  };

export const performSignOut =
  (history: any): AppThunk =>
  (dispatch) => {
    dispatch(signOut());
    // dispatch(updateOrganisation());

    history.push("/");
  };

export const performCredentialsTokenCheck =
  (authToken: string | null, metaAuthToken?: string): AppThunk =>
  (dispatch) => {
    dispatch(verificationStart());

    // Add authorisation manually before committing it to the router after verification.
    feedFactoryAxios
      .get("/auth/me", { headers: { Authorization: `Bearer ${authToken}` } })
      .then((response) => {
        dispatch(
          verificationSuccess({
            account: {
              id: response.data.account.id,
              name: response.data.account.name,
            },
            token: authToken || "",
            email: response.data.email,
            firstName: response.data.firstName, // TODO: use data once implemented
            lastName: response.data.lastName, // TODO: use data once implemented
            roles: response.data.roles, // TODO: use data once implemented
            masqueradingUserToken: metaAuthToken || null,
          })
        );

        dispatch(updateThemeFromAccountId(response.data.account));
      })
      .catch((error: AxiosError) => {
        dispatch(verificationFailure(error.message));
      });
  };

export const performMasquerade =
  (authToken: string | null): AppThunk =>
  (dispatch) => {
    // Save the current user's token in local storage
    const myToken = localStorage.getItem("user.jwt");
    localStorage.setItem("user.meta-jwt", myToken || "");

    dispatch(performCredentialsTokenCheck(authToken, myToken || undefined));
  };

export const performUnMasquerade = (): AppThunk => (dispatch) => {
  // Remove the current user's token from local storage
  const orginalToken = localStorage.getItem("user.meta-jwt");
  if (!orginalToken) {
    return;
  }
  localStorage.removeItem("user.meta-jwt");

  dispatch(performCredentialsTokenCheck(orginalToken, undefined));
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.auth.value)`
export const selectToken = (state: RootState) => state.auth.token;
// state.booting should never be read directory
// export const selectBooting = (state: RootState) => state.auth.booting;
export const selectLoading = (state: RootState) => state.auth.loading;
export const selectError = (state: RootState) => state.auth.error;
export const selectFirstName = (state: RootState) => state.auth.firstName;
export const selectLastName = (state: RootState) => state.auth.lastName;
export const selectUsernameOrEmail = (state: RootState) => state.auth.email;

// @note: this account id differs from the account id in themeSlice. Here the account id refers
// to the account a user is currently signed in with whereas the theme account id refers to the account id a user was
// last signed into. Thus an account id here means the user is logged in to an account whereas that does not have to be
// the case in themeSlice
export const selectAccountId = (state: RootState) => state.auth.accountId;

export const selectUserMail = (state: RootState) => state.auth.email;

export const selectIsSuperAdmin = (state: RootState) => {
  return state.auth.roles.some((role) => /super_admin/i.test(role));
};

export const selectIsCongressAlias = (state: RootState) => {
  return state.auth.roles.some((role) => /congres_alias/i.test(role));
};

export const selectIsMasquerading = (state: RootState) => {
  return state.auth.masqueradingUserToken !== null;
};

export const selectHasAssociatedAccount = (state: RootState) => {
  const accountId = selectAccountId(state);

  return accountId && accountId.length > 0;
};

export const selectIsAdmin = (state: RootState) =>
  state.auth.roles.some((role) => /admin/i.test(role));
export const selectIsEditor = (state: RootState) =>
  state.auth.roles.some((role) => /editor/i.test(role));
export const selectIsPartner = (state: RootState) =>
  state.auth.roles.some((role) => /partner/i.test(role));
// only should return true whilst the initial credentials of the user are being verified
export const selectIsBooting = (state: RootState) => state.auth.booting;

// only true when the user should be on the Login screen
export const selectIsAuthenticating = (state: RootState) =>
  selectIsBooting(state) === false &&
  !state.auth.token &&
  state.auth.expired === false;

// only true when the user was logged in and somehow the credentials were turned invalid. Current work should not
// be lost and a password popup should appear to reauthenticate without losing work in progress.
export const selectIsConfirming = (state: RootState) =>
  selectIsBooting(state) === false && state.auth.expired === true;

// @note selectIsAuthenticated might be true even if selectIsAuthenticating is true also.
// is this expected behavior?
// Suppose someone is editing and the server is reverifying the user then he or she should
// not see the Login screen but a specific overlay suggesting the user is trying to be
// reauthenticated. If that fails, the user should get a new popup offering to reenter the
// password without losing work!

export default authSlice.reducer;
