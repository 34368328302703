import React, { useCallback, useEffect, useState } from "react";
import { feedFactoryAxios } from "helpers";
import { AxiosResponse } from "axios";
import { Token as TokenModel } from "../../../models/Token/Token";
import classes from "./Tokens.module.scss";
import { useTranslation } from "react-i18next";
import { MdError } from "react-icons/md";
import PopupButton from "../../../components/buttons/PopupButton/PopupButton";
import { useSelector } from "react-redux";
import {
  selectIsAdmin,
  selectIsSuperAdmin,
} from "../../../store/auth/authSlice";
import Loader from "../../../components/Loader/Loader";
import { SettingsLabel } from "./SettingsField";
import { TokenRole } from "../../../models/Token/TokenRole";
import Token from "./Token";
import { createTokenFilter } from "../../../helpers/queryConverter";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Filters } from "../../../models/Filters/Filters";

const maxTokenCount = 20;

const Tokens = (props: { accountId: string | null }) => {
  const { t } = useTranslation();
  const isAdmin = useSelector(selectIsAdmin);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  const [selectedRoles, setSelectedRoles] = useState<TokenRole[]>([]);

  const [error, setError] = useState<String | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tokens, setTokens] = useState<TokenModel[]>([]);

  const [newTokenName, setNewTokenName] = useState<string>("");
  const [newTokenFilter, setNewTokenFilter] = useState<Filters>({
    extendedMarkers: "",
    markers: "",
    userOrganisation: "",
  });
  const [usingOptimizedImage, setUsingOptimizedImage] = useState(false);

  const ALL_ROLES = ["USER", "ADMIN"] as TokenRole[];

  const toggleRole = (role: TokenRole) => {
    const roles = selectedRoles || [];

    if (roles.indexOf(role) === -1) {
      let newRoles = roles;

      // @note, due to immutability .push will not cause a rerender
      newRoles = newRoles.concat(role);

      setSelectedRoles(newRoles);
    } else {
      let newRoles = roles.filter((_role) => _role !== role);
      setSelectedRoles(newRoles);
    }
  };

  const createNewToken = () => {
    if (maxTokenCount - tokens.length <= 0) {
      setError("Max token count reached");
      return;
    }
    if (selectedRoles.length < 1) {
      setError("No role(s) selected");
      return;
    }

    const newToken = {
      roles: selectedRoles,
      name: newTokenName.trim() || null,
      filter: createTokenFilter(newTokenFilter),
      useOptimizedImages: usingOptimizedImage,
    };

    const tokensUrl = `/accounts/${props.accountId}/tokens`;

    feedFactoryAxios
      .post(tokensUrl, newToken)
      .then((response: AxiosResponse<TokenModel[]>) => {
        updateTokens();
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateTokens = useCallback(() => {
    const tokensUrl = `/accounts/${props.accountId}/tokens`;
    console.log("update tokens");
    feedFactoryAxios
      .get(tokensUrl)
      .then((response: AxiosResponse<TokenModel[]>) => {
        setTokens(response.data);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props.accountId]);

  useEffect(() => {
    updateTokens();
  }, [updateTokens, props.accountId]);

  const updateToken = (token: Partial<TokenModel>) => {
    const tokensUrl = `/accounts/${props.accountId}/tokens/${token.apiToken}`;

    setIsLoading(true);

    feedFactoryAxios
      .put(tokensUrl, token)
      .then((response: AxiosResponse<TokenModel[]>) => {
        updateTokens();
        toast.success(`Token ${token.apiToken} updated`);
      })
      .catch((error) => {
        setError(error.message);
        toast.error(
          `Token ${token.apiToken} update failed with error: ${error.message}`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!props.accountId) {
    return null;
  }

  return (
    <div className={classes.tokensWrapper}>
      {error && (
        <div className={classes.errorBox}>
          <MdError />
          {error}
        </div>
      )}
      <ul>
        {tokens.map((token) => {
          return (
            <Token
              key={token.apiToken}
              token={token}
              accountId={props.accountId}
              updateToken={updateToken}
            />
          );
        })}
      </ul>
      {isSuperAdmin && maxTokenCount - tokens.length > 0 && (
        <div
          className={`${classes.newTokenWrapper} ff-component-settings-field`}
        >
          <div className={"field-input-frame"}>
            <label style={{ fontWeight: 500 }}>
              {t("tokens.newTokenName")}
            </label>
            <input
              className={"field-input"}
              value={newTokenName}
              onChange={(event) => {
                setNewTokenName(event.currentTarget.value);
              }}
            />
          </div>
          <div className={"settings-row"}>
            <SettingsLabel>{t("root.role")}</SettingsLabel>
            {isAdmin && (
              <>
                {ALL_ROLES.map((role) => (
                  <span
                    className={`settings-role clickable ${
                      selectedRoles.indexOf(role) === -1
                        ? "settings-role-inactive"
                        : ""
                    }`}
                    onClick={() => toggleRole(role)}
                    key={role}
                  >
                    {role}
                  </span>
                ))}
              </>
            )}
            {!isAdmin && (
              <>
                {(selectedRoles || []).map((role) => (
                  <span key={role} className={"settings-role"}>
                    {role}
                  </span>
                ))}
              </>
            )}
          </div>
          <div className={"field-input-frame"}>
            <label style={{ fontWeight: 500 }}>
              {t("root.filter-markers")}
            </label>
            <input
              className={"field-input"}
              value={newTokenFilter.markers}
              onChange={(event) => {
                setNewTokenFilter({
                  ...newTokenFilter,
                  markers: event.currentTarget.value,
                });
              }}
            />
          </div>
          <div className={"field-input-frame"}>
            <label style={{ fontWeight: 500 }}>
              {t("root.filter-extendedmarker")}
            </label>
            <input
              className={"field-input"}
              value={newTokenFilter.extendedMarkers}
              onChange={(event) => {
                setNewTokenFilter({
                  ...newTokenFilter,
                  extendedMarkers: event.currentTarget.value,
                });
              }}
            />
          </div>
          <div className={"field-input-frame"}>
            <label style={{ fontWeight: 500 }}>
              {t("root.filter-userorganisation")}
            </label>
            <input
              className={"field-input"}
              value={newTokenFilter.userOrganisation}
              onChange={(event) => {
                setNewTokenFilter({
                  ...newTokenFilter,
                  userOrganisation: event.currentTarget.value,
                });
              }}
            />
          </div>
          <div className={"field-input-frame"}>
            <input
              type="checkbox"
              value={usingOptimizedImage ? "checked" : ""}
              onChange={(event) => {
                setUsingOptimizedImage(event.currentTarget.checked);
              }}
            />
            <label style={{ fontWeight: 500, marginLeft: "4px" }}>
              {t("root.filter-optimizedImage")}
            </label>
          </div>
          <PopupButton action={createNewToken}>
            {t("tokens.generateNew")} ({maxTokenCount - tokens.length}{" "}
            {t("tokens.left")})
          </PopupButton>
        </div>
      )}
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default Tokens;
