import React, { useEffect, useState } from "react";
import "./Login.scss";
import LoginAside from "./LoginAside";
import LoginNewUser from "./components/LoginNewUser";
import { Route, Switch, useLocation } from "react-router-dom";
import PasswordResetPrompt from "./PasswordReset/PasswordResetPrompt";
import { useTranslation } from "react-i18next";
import classes from "./Login.module.scss";

const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const passwordReset = new URLSearchParams(location.search).get(
    "password-reset"
  );

  // Get path on first load; allows redirecting to original page on nav to password reset
  const [origPath, setOrigPath] = useState<undefined | string>(undefined);
  useEffect(() => {
    if (location.pathname.includes(t("login.passwordReset.slug"))) {
      return;
    }
    setOrigPath(location.pathname);
  }, []);

  return (
    <div className={`ff-component-login`}>
      {passwordReset && (
        <div className={classes.resetNudge}>
          {t("login.passwordResetNudge")}
        </div>
      )}
      <figure className="login-background">
        <div className={"login-background-casual"} />
        <div className={"login-background-formal"} />
      </figure>
      <main className="login-main">
        <LoginAside />
        <Switch>
          <Route path={`/${t("login.passwordReset.slug")}`}>
            <PasswordResetPrompt origPath={origPath} />
          </Route>
          {/* Note, any path should show login page */}
          <Route path={"/"}>
            <LoginNewUser />
          </Route>
        </Switch>
      </main>
    </div>
  );
};

export default Login;
