import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import Toggle from "react-switch";
import ItemFormPrimaryLanguage from "./ItemFormPrimaryLanguage/ItemFormPrimaryLanguage";
import { useTranslation } from "react-i18next";
import ItemFormSecondaryLanguage from "./ItemFormSecondaryLanguage/ItemFormSecondaryLanguage";
import ItemFormVersionTabs from "./ItemFormLangTabs/ItemFormVersionTabs";
import ToastContent from "components/ToastContent/ToastContent";
import { Lang } from "../../models/Lang/Lang";
import classes from "./ItemForm.module.scss";
import "./ItemFormSharedStyle.scss";
import {
  externalToInternal,
  feedFactoryAxios,
  FormData,
  generateItemApiUrl,
  getEmptyExternalItem,
  handleRemoteSubmission,
  internalToExternal,
  mergeChangesIntoModel,
  useSaveShortcut,
} from "helpers";
import {
  AclObject,
  EntityType,
  ExternalItemModel,
  ExternalLocationItemModel,
  InternalItemModel,
} from "../../models/Ndtrc/Ndtrc";
import Loader from "../../components/Loader/Loader";
import { useForm, UseFormReturn } from "react-hook-form";
import NavigationButton from "../../components/buttons/NavigationButton/NavigationButton";
import { FaArrowUp } from "react-icons/fa";
import {
  AiFillCopy,
  AiFillCrown,
  AiFillDelete,
  AiFillForward,
  AiFillSave,
  AiFillWarning,
} from "react-icons/ai";
import TextInputPopover from "../../components/popovers/TextInputPopover/TextInputPopover";
import EventsByLocationWidget from "./EventsByLocationWidget/EventsByLocationWidget";
import ItemsQueue from "../ItemsQueue/ItemsQueue";
import { HiClipboardCopy } from "react-icons/hi";
import { useSelector } from "react-redux";
import { selectUsernameOrEmail } from "../../store/auth/authSlice";
import { GiBackwardTime, GiCardPickup } from "react-icons/gi";
import { HoverDescNode } from "components/HoverDesc/HoverDescNode";
import { formatDistanceToNow } from "date-fns";
import RevisionsWidget from "../../components/FormWidgets/RevisionsWidget/RevisionsWidget";
import { useQueryParams } from "helpers/useQueryParams";
import { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import { MdArchive, MdRestorePage, MdUnarchive } from "react-icons/md";
import { AxiosResponse } from "axios";
import { AccountModel } from "../../models/AccountModel/AccountModel";
import { IoCloudOffline } from "react-icons/io5";
import BooleanInputPopover from "../../components/popovers/BooleanInputPopover/BooleanInputPopover";
import ItemFormComments from "./ItemFormComments/ItemFormComments";
import { ButtonType } from "../../components/buttons";
import assertValidResponse from "../Settings/components/helpers/assertValidResponse";
import ItemFormAlias from "./ItemFormAlias/ItemFormAlias";
import { AliasOption } from "../../models/NdtrcAlias/NdtrcAlias";
import { ImKey } from "react-icons/im";
import { SiStatuspage } from "react-icons/si";
import {
  getRoleSlug,
  localEditorPermissions,
  localManagerPermissions,
  localReaderPermissions,
  PermissionObject,
} from "../../models/PermissionObject/PermissionObject";
import { diff } from "deep-object-diff";
import _ from "lodash";
import useGlobalPermissions from "../../statefulHelpers/useGlobalPermissions";
import DuplicateModal from "components/DuplicateModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsFillSkipStartFill } from "react-icons/bs";

const getActiveVersion = (currentUrl: string): string | null => {
  const lang = currentUrl.match(/\/.*?\/.*?\/([a-z]*)/);
  return lang && lang[1] ? lang[1] : null;
};

const entityTypeToApiUrlStem = (entityType: EntityType): string => {
  switch (entityType) {
    case "EVENEMENT":
      return "events";
    case "LOCATIE":
      return "locations";
    case "EVENEMENTGROEP":
      return "eventgroups";
    case "ROUTE":
      return "routes";
    default:
      console.error("Invalid entity type", entityType);
      return "events";
  }
};

const ItemForm = (props: { entityType: EntityType }) => {
  const { t } = useTranslation();
  const { id: itemId } = useParams<{ id: string }>();
  const queryParams = useQueryParams();
  const history = useHistory();
  const formMethods: UseFormReturn = useForm({ mode: "onChange" });

  formMethods.register("ndtrcEntry.translations.primaryLanguage");
  formMethods.register("ndtrcEntry.translations.availableLanguages");
  const primaryLanguage = formMethods.watch(
    "ndtrcEntry.translations.primaryLanguage"
  );
  const availableLanguages = formMethods.watch(
    "ndtrcEntry.translations.availableLanguages"
  );

  formMethods.register("ndtrcEntry.wfstatus");
  const wfStatus = formMethods.watch("ndtrcEntry.wfstatus");

  const [originalItem, setOriginalItem] = useState<ExternalItemModel>();
  const [intItem, setIntItem] = useState<InternalItemModel>();
  const [remoteSaveLoading, setRemoteSaveLoading] = useState<boolean>(false);
  const [remoteSaveSuccess, setRemoteSaveSuccess] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [clipboardSuccess, setClipboardSuccess] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);

  const [itemNotFound, setItemNotFound] = useState(false);

  const [userorganisations, setUserorganisations] = useState<string[]>([]);
  const [duplicates, setDuplicates] = useState<any[]>([]);

  const rev = queryParams.get("rev") || null;

  // Determine which permissions the current user has
  const myUsername = useSelector(selectUsernameOrEmail);

  const globalPermissions = useGlobalPermissions();

  const [myLocationPermissions, setMyLocationPermissions] =
    useState<AclObject | null>();
  // Read in location for event items, to see if it grants permissions to the user on this event
  useEffect(() => {
    if (props.entityType !== "EVENEMENT") {
      return;
    }
    const myLocationId = intItem?.location?.locationItem?.id;
    if (!myLocationId) {
      // console.warn("No location ID present");
      setMyLocationPermissions(null);
      return;
    }
    const url = generateItemApiUrl("LOCATIE", myLocationId);
    feedFactoryAxios.get(url).then((response) => {
      setMyLocationPermissions(
        (response.data as ExternalLocationItemModel).acl
      );
    });
  }, [intItem]);

  const determinePermissions = useCallback((): PermissionObject | undefined => {
    if (globalPermissions) {
      return globalPermissions;
    }

    // TODO: invent better fix for shifting permissions when trying to browse revisions
    if (rev) {
      return localEditorPermissions;
    }

    if (
      intItem?.acl.managers.some((user) => user === myUsername) ||
      myLocationPermissions?.managers.some((user) => user === myUsername)
    ) {
      return localManagerPermissions;
    } else if (
      intItem?.acl.editors.some((user) => user === myUsername) ||
      myLocationPermissions?.editors.some((user) => user === myUsername) ||
      itemId == t("root.new")
    ) {
      return localEditorPermissions;
    } else if (
      intItem?.acl.readers.some((user) => user === myUsername) ||
      myLocationPermissions?.readers.some((user) => user === myUsername)
    ) {
      return localReaderPermissions;
    } else {
      return undefined;
    }
  }, [globalPermissions, intItem, wfStatus, myLocationPermissions]);
  const [myPermissions, setMyPermissions] = useState<
    PermissionObject | undefined
  >(determinePermissions());

  useEffect(() => {
    setMyPermissions(determinePermissions());
  }, [globalPermissions, intItem, wfStatus, myLocationPermissions]);

  const urlSlug =
    props.entityType === "EVENEMENT"
      ? "root.events.slug"
      : props.entityType === "LOCATIE"
      ? "root.locations.slug"
      : props.entityType === "EVENEMENTGROEP"
      ? "root.eventGroups.slug"
      : "root.routes.slug";

  const version = getActiveVersion(history.location.pathname);

  useEffect(() => {
    const accountUrl = "/accounts/me";

    feedFactoryAxios
      .get(accountUrl)
      .then((response) =>
        assertValidResponse(response, "No account found for this account id")
      )
      .then((response: AxiosResponse<AccountModel>) => {
        const account = response.data;
        setUserorganisations(account?.userOrganisation?.split(";") || []);
        //   }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, []);

  useEffect(() => {
    if (props.entityType === "EVENEMENT") {
      feedFactoryAxios
        .get(`/events/${itemId}/duplicates`)
        .then((response: AxiosResponse) => {
          if (response.data.length > 0) {
            toast.warn(<ToastContent onOpenModal={handleOpenModal} />, {
              position: "bottom-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          setDuplicates(response.data);
        });
    }

    if (props.entityType === "LOCATIE") {
      feedFactoryAxios
        .get(`/locations/${itemId}/duplicates`)
        .then((response: AxiosResponse) => {
          if (response.data.length > 0) {
            toast.warn(<ToastContent onOpenModal={handleOpenModal} />, {
              position: "bottom-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          setDuplicates(response.data);
        });
    }
  }, [itemId]);

  const handleOpenModal = () => {
    setOpenDuplicateModal(true);
  };

  const handleCloseModal = () => {
    setOpenDuplicateModal(false);
  };

  const generateUpdateUrl = useCallback(
    (
      id: string | null,
      options?: { lang?: Lang; optionRev?: string; noRev?: boolean }
    ): string => {
      if (!id) {
        console.error("Invalid ID passed", id);
        return "";
      }

      let myRev = options?.optionRev
        ? `?rev=${options.optionRev}`
        : rev
        ? `?rev=${rev}`
        : "";
      if (options?.noRev) {
        myRev = "";
      }

      return `/${t(urlSlug)}/${id}${
        options?.lang ? `/${options.lang}` : ""
      }${myRev}`;
    },
    [rev, t, urlSlug]
  );

  useEffect(() => {
    if (!version) {
      return;
    }

    let activeLang: Lang;
    if (Object.values(Lang).includes(version as Lang)) {
      activeLang = getActiveVersion(history.location.pathname) as Lang;
    } else {
      activeLang = intItem?.translations?.primaryLanguage || Lang.NL;
    }

    const langAvailable = intItem?.translations?.availableLanguages?.find(
      (lang) => activeLang === lang
    );
    if (!langAvailable) {
      // Go to main page
      // console.log("GO TO MAIN PAGE, NO LANG AVAILABLE");
      history.replace(generateUpdateUrl(itemId));
    }
  }, [version, generateUpdateUrl, history, intItem, itemId, t]);

  const [aliasOptions, setAliasOptions] = useState<AliasOption[]>([]);
  const refreshAliases = () => {
    if (props.entityType === "EVENEMENTGROEP" || props.entityType === "ROUTE")
      return;
    if (itemId === t("root.new")) return;

    // Get all available aliases for item
    const urlStem = entityTypeToApiUrlStem(props.entityType);
    const url = `/${t(urlStem)}/${itemId}/aliases`;
    feedFactoryAxios.get(url).then((res) => {
      const myAliases = res.data.map((myAlias: any) => {
        return {
          value: myAlias.id,
          label: myAlias.aliasTitle,
        };
      });
      setAliasOptions(myAliases);
    });
  };

  useEffect(() => {
    refreshAliases();
  }, []);

  // This is required to automatically update the long description after quill has cleaned it
  // Only used on first form load and when the back-end version has not yet been cleaned.
  const onRemoteSaveLongDescription = (extraData?: FormData | null) => {
    // console.log("QUILL SAVE TRIGGER"); // DEBUG
    // Check if revision. If so, don't trigger save
    if (itemId === t("root.new") || rev || wfStatus === "deleted") {
      return;
    }
    // console.log("QUILL SAVE COMMENCE"); // DEBUG
    onRemoteSave();
  };

  const onRemoteSave = (extraData?: FormData | null) => {
    if (!primaryLanguage || !intItem) {
      alert("Primaire taal niet gevonden - kan formulier niet versturen.");
      return;
    }

    const latestItem = onLocalSave(extraData);

    if (!latestItem) {
      alert("Fout opgetreden tijdens het opslaan.");
      return;
    }

    if (
      !primaryLanguage ||
      !latestItem.trcItemDetails[primaryLanguage as Lang].title
    ) {
      alert(t("form.noTitleError"));
      return;
    }

    console.log('latestItem', latestItem);

    setRemoteSaveLoading(true);

    handleRemoteSubmission(originalItem, latestItem, itemId)
      .then((res) => {
        console.log("response data", res.data);
        // Is new item
        if (!originalItem?.id) {
          // console.log("IS NEW ITEM, UPDATE URL");
          history.replace(generateUpdateUrl(res.data.id));
        }

        // Update form with latest changes from back-end
        // HOTFIX: check if item is eventgroup, if so: patch in
        if (props.entityType === "EVENEMENTGROEP") {
          res.data.entitytype = "EVENEMENTGROEP";
        }

        if (props.entityType === "ROUTE") {
          res.data.entitytype = "ROUTE";
        }

        const intItem = externalToInternal(res.data);
        setIntItem(intItem);

        setRemoteSaveSuccess(true);
        setTimeout(() => {
          setRemoteSaveSuccess(false);
        }, 2000);
      })
      .catch((err) => {
        console.error(err);
        alert(`Fout opgetreden tijdens het opslaan: ${err}`);
      })
      .finally(() => {
        setRemoteSaveLoading(false);
      });
  };

  const onLocalSave = (
    extraData?: FormData | null
  ): InternalItemModel | void => {
    const formData = formMethods.getValues() as FormData;

    // Check if alias form data is present. If so, upload to endpoint.
    for (const [key, value] of Object.entries(formData)) {
      if (key === "ndtrcEntry") {
        continue;
      }

      let aliasItemModel: any = _.cloneDeep(value);
      // console.log(
      //   "PUTTING EXTERNAL FOR ALIAS",
      //   aliasItemModel.trcItem
      // );

      const hasAliasExtraData = extraData && key in extraData;
      if (hasAliasExtraData) {
        aliasItemModel.trcItem = mergeChangesIntoModel(
          aliasItemModel.trcItem,
          (extraData as any)[key].trcItem
        );
      }
      if (!aliasItemModel.trcItem) {
        continue;
      }
      aliasItemModel.trcItem = internalToExternal(aliasItemModel.trcItem);

      const urlStem = entityTypeToApiUrlStem(props.entityType);
      const url = `/${t(urlStem)}/${itemId}/aliases/${value.id}`;

      feedFactoryAxios.put(url, aliasItemModel);

      // console.log("PUT EXTERNAL FOR ALIAS", key, aliasItemModel);
    }

    // Integrate changes with full data object
    if (!intItem) {
      console.error("Internal item missing on local save");
      return;
    }
    let mergedItem = mergeChangesIntoModel(intItem, formData.ndtrcEntry);

    if (extraData) {
      mergedItem = mergeChangesIntoModel(mergedItem, extraData.ndtrcEntry);
    }

    setIntItem(mergedItem);
    return mergedItem;
  };

  useEffect(() => {
    // Reset
    formMethods.setValue("ndtrcEntry.translations.primaryLanguage", null);
    formMethods.setValue("ndtrcEntry.translations.availableLanguages", []);
    setOriginalItem(undefined);
    setIntItem(undefined);
    setIsPublished(false);
    formMethods.reset({ ndtrcEntry: getEmptyExternalItem(props.entityType) });

    const performFormInitialisation = async () => {
      let thisItem: ExternalItemModel;
      if (itemId === t("root.new")) {
        // This is a new entry - start with an empty form
        thisItem = getEmptyExternalItem(props.entityType);
      } else {
        const url = generateItemApiUrl(
          props.entityType as EntityType,
          itemId,
          rev,
          true
        );
        try {
          thisItem = (await feedFactoryAxios.get(url)).data;
          // TEMP ENTITY TYPE FIX
          if (props.entityType === "EVENEMENTGROEP") {
            thisItem.entitytype = "EVENEMENTGROEP";
          }
        } catch (error) {
          console.error("Indicated item not found", error);
          setItemNotFound(true);
          return;
        }
      }

      if (!thisItem) {
        console.error("Indicated item not found");
        setItemNotFound(true);
        return;
      }

      if (itemId.toString().includes("-")) {
        // old item id; replace with new item id
        // console.log("OLD ITEM ID (TRCID), REPLACE WITH NEW ITEM ID");
        history.replace(generateUpdateUrl(thisItem.id));
      }

      setOriginalItem(thisItem);

      const intItem = externalToInternal(thisItem);
      setIntItem(intItem);
      console.log("ORIGINAL", thisItem); // DEBUG
      console.log("INTERNAL", intItem); // DEBUG
      console.log("ROUNDTRIP", internalToExternal(intItem)); // DEBUG
    };
    performFormInitialisation();
  }, [generateUpdateUrl, history, itemId, props.entityType, rev, t]);

  useEffect(() => {
    if (!intItem || !intItem.translations) {
      formMethods.setValue("ndtrcEntry.translations.primaryLanguage", null);
      formMethods.setValue("ndtrcEntry.translations.availableLanguages", []);
      return;
    }
    // Check if main language is available
    const providedPrimaryLanguage = intItem.translations
      ?.primaryLanguage as Lang;
    if (!providedPrimaryLanguage) {
      alert("No main language provided by Event/Location object");
      return;
    }
    formMethods.setValue(
      "ndtrcEntry.translations.primaryLanguage",
      providedPrimaryLanguage
    );
    const providedAvailableLanguages = intItem.translations
      ?.availableLanguages as Lang[];
    formMethods.setValue(
      "ndtrcEntry.translations.availableLanguages",
      providedAvailableLanguages
    );

    setIsPublished(intItem.wfstatus === "approved");
  }, [intItem, props.entityType]);

  const keyIsPressed = useSaveShortcut();
  useEffect(() => {
    if (wfStatus !== "deleted" && !rev && !remoteSaveLoading && keyIsPressed) {
      const activeEl = document.activeElement as HTMLInputElement;
      // activeEl?.focus();
      activeEl?.blur();
      onRemoteSave();
    }
  }, [keyIsPressed]);

  // NOTE: FOR ALIAS, REQUIRES LABEL RATHER THAN ID
  const handleVersionAddition = (version: string) => {
    // Check if lang; if not, try as alias
    if (Object.values(Lang).includes(version as Lang)) {
      const lang = version;

      let primLang = primaryLanguage;
      let availableLangs = availableLanguages;
      let primLangChanged = false;
      if (!primLang) {
        primLang = lang as Lang;
        formMethods.setValue(
          "ndtrcEntry.translations.primaryLanguage",
          primLang
        );
        primLangChanged = true;
      }

      // Check if already an available language. If not, add.
      if (!availableLangs.find((l: any) => l === lang)) {
        const newAvailableLangs = [...availableLangs, lang as Lang];
        formMethods.setValue(
          "ndtrcEntry.translations.availableLanguages",
          newAvailableLangs
        );
        formMethods.setValue(`ndtrcEntry.trcItemDetails.${lang}`, {
          longdescription: "",
          shortdescription: "",
          title: intItem?.trcItemDetails[primLang as Lang]?.title,
        });
      }

      const options = primLang !== lang ? { lang: lang as Lang } : undefined;
      let url = generateUpdateUrl(itemId, options);
      if (primLangChanged || history.location.pathname !== url) {
        onLocalSave();
        // console.log("PRIMARY LANGUAGE CHANGED OR URL CHANGED");
        history.push(url);
      }
    } else {
      // Check if already available alias. If not, add.
      if (aliasOptions.some((a) => a.label === version)) {
        onLocalSave();
        history.push(`${generateUpdateUrl(itemId)}/${version}`);
      } else {
        const urlStem = entityTypeToApiUrlStem(props.entityType);
        const url = `/${t(urlStem)}/${itemId}/aliases`;
        feedFactoryAxios
          .post(url, {
            sourceLanguage: primaryLanguage,
            aliasTitle: version,
          })
          .then(() => {
            refreshAliases();
            onLocalSave();
            // console.log("PRIMARY LANGUAGE CHANGED OR URL CHANGED");
            history.push(`${generateUpdateUrl(itemId)}/${version}`);
          });
      }
    }
  };

  const handleLangRearrange = (newAvailableLangs: Lang[], langMoved: Lang) => {
    // First item in list should be primary
    formMethods.setValue(
      "ndtrcEntry.translations.primaryLanguage",
      newAvailableLangs[0]
    );
    formMethods.setValue(
      "ndtrcEntry.translations.availableLanguages",
      newAvailableLangs
    );

    let url;
    if (newAvailableLangs[0] === langMoved) {
      url = generateUpdateUrl(itemId);
    } else {
      url = generateUpdateUrl(itemId, { lang: langMoved as Lang });
    }

    onLocalSave();
    // console.log("LANGUAGE TABS REARRANGED");
    history.replace(url);
  };

  const handleVersionDeletion = (removedVersion: Lang | AliasOption) => {
    // Check if lang; if not, try as alias
    if (Object.values(Lang).includes(removedVersion as Lang)) {
      if (!intItem?.translations?.availableLanguages) {
        alert("Kritieke fout: Geen talen beschikbaar");
        return;
      }

      const newAvailableLangs =
        intItem?.translations?.availableLanguages.filter((item) => {
          return item !== removedVersion;
        });
      if (newAvailableLangs.length < 1) {
        alert(t("form.cannotDeleteLastLanguage"));
        return;
      }

      // First item in list should be primary
      formMethods.setValue(
        "ndtrcEntry.translations.primaryLanguage",
        newAvailableLangs[0]
      );
      formMethods.setValue(
        "ndtrcEntry.translations.availableLanguages",
        newAvailableLangs
      );

      // If currently on this language page: navigate to primary language
      onLocalSave();
      if (version === removedVersion) {
        history.replace(generateUpdateUrl(itemId));
      }
    } else {
      const alias = removedVersion as AliasOption;
      const urlStem = entityTypeToApiUrlStem(props.entityType);
      const url = `/${t(urlStem)}/${itemId}/aliases/${alias.value}`;
      feedFactoryAxios.delete(url).then(() => {
        console.log("DELETION DONE", version, alias);
        refreshAliases();
        if (version === alias.label) {
          history.replace(generateUpdateUrl(itemId));
        }
      });
    }
  };

  let saveButtonText: ReactNode;
  let saveButtonType = ButtonType.Alternate;
  if (remoteSaveSuccess) {
    // Has just saved (temporary state)
    saveButtonText = (
      <span className={classes.buttonText}>{t("event.remoteSaveSuccess")}</span>
    );
    saveButtonType = ButtonType.Success;
  } else if (remoteSaveLoading) {
    // Is saving
    saveButtonText = (
      <span className={classes.buttonText}>{t("form.isSaving")}</span>
    );
  } else {
    const modifierKey = window.navigator.platform.match("Mac") ? "⌘" : "Ctrl";

    // Can be saved
    saveButtonText = (
      <>
        <span className={classes.buttonText}>
          {t("event.submitEventToRemote")}
        </span>
        <span className={`${classes.shortcut} ${classes.buttonText}`}>
          ({modifierKey}+S)
        </span>
      </>
    );
  }

  const isFormDataModified = (): boolean => {
    if (
      !(
        intItem &&
        formMethods.getValues("ndtrcEntry") &&
        formMethods.getValues("ndtrcEntry").trcItemDetails &&
        intItem?.trcItemDetails
      )
    ) {
      return false;
    }
    const versionToBeSaved = internalToExternal(
      mergeChangesIntoModel(intItem, formMethods.getValues("ndtrcEntry"))
    );
    const dirtyFields = diff(internalToExternal(intItem), versionToBeSaved);
    // console.log(
    //   "DIRTY FIELDS",
    //   Object.keys(dirtyFields).length > 0,
    //   detailedDiff(internalToExternal(intItem), versionToBeSaved),
    //   internalToExternal(intItem),
    //   versionToBeSaved
    // ); // DEBUG
    return dirtyFields && Object.keys(dirtyFields).length > 0;
  };

  const eventIsInMyOrg = userorganisations.some((org) =>
    intItem?.userorganisation?.includes(org)
  );

  const onCopyEventButton = () => {
    // Check if not already part of own user organisation
    if (eventIsInMyOrg) {
      alert(t("root.alreadyInOrganisation"));
      return;
    }

    const url = "/events/" + itemId + "/copy";
    feedFactoryAxios.post(url).then((response) => {
      const newEventId = response.data.id;
      history.push(generateUpdateUrl(newEventId));
    });
  };

  registerLocale("nl", nl);

  return itemNotFound || (intItem && !myPermissions?.generalPermission) ? (
    <div className={classes.noItems}>
      <GiCardPickup />
      <div>
        <p>{t("root.itemDoesNotExist")}</p>
      </div>
    </div>
  ) : (
    <>
      {wfStatus === "deleted" &&
        !rev &&
        myPermissions?.generalPermission === "write" && (
          <div className={classes.eventFormBlocker}>
            <NavigationButton
              type={ButtonType.Warning}
              action={() => {
                if (!intItem) {
                  console.error("No intItem available to publish");
                  return;
                }
                formMethods.setValue(
                  "ndtrcEntry.wfstatus",
                  "readyforvalidation"
                );
                onRemoteSave();
              }}
              className={classes.restoreButton}
            >
              <AiFillWarning />
              {t("form.restoreDeletedItem")}
            </NavigationButton>
          </div>
        )}
      {rev && (
        <div className={classes.lookingAtRev}>
          <NavigationButton
            type={ButtonType.Warning}
            action={() => {}}
            className={classes.restoreButton}
          >
            <AiFillWarning />
            {t("form.lookingAtRev")}
          </NavigationButton>
        </div>
      )}
      <div
        className={`${classes.eventFormWrapper} ${rev ? classes.isRev : ""}  ${
          wfStatus === "deleted" ? classes.isDeleted : ""
        } ${
          myPermissions?.generalPermission === "read" ? classes.isReadOnly : ""
        }`}
      >
        {intItem && (
          <div
            className={`${classes.HoveringInterfaceContainer} ${
              wfStatus === "deleted" || rev ? classes.topBarActive : ""
            }`}
          >
            <div className={classes.ButtonsContainer}>
              {remoteSaveLoading && <Loader />}
              {myPermissions?.generalPermission === "write" &&
                itemId !== t("root.new") &&
                !(wfStatus === "deleted" && !rev) && (
                  <RevisionsWidget
                    id={intItem.id}
                    type={intItem?.entitytype}
                    onClick={(rev, isCurrent) => {
                      if (isCurrent) {
                        history.push(
                          generateUpdateUrl(itemId, { noRev: true })
                        );
                      } else {
                        history.push(
                          generateUpdateUrl(itemId, { optionRev: rev })
                        );
                      }
                    }}
                    currentRev={rev || null}
                  >
                    {(setPopover: any, onButtonClickHandler: any) => (
                      <NavigationButton
                        myRef={setPopover}
                        type={ButtonType.Gray2}
                        action={() => onButtonClickHandler()}
                      >
                        <GiBackwardTime />{" "}
                        <span className={classes.buttonText}>
                          {t("form.revisions")}
                        </span>
                      </NavigationButton>
                    )}
                  </RevisionsWidget>
                )}
              {myPermissions?.generalPermission === "write" && rev && (
                <NavigationButton
                  type={ButtonType.Alternate}
                  action={() => {
                    // Always undelete item if restored
                    formMethods.setValue(
                      "ndtrcEntry.wfstatus",
                      "readyforvalidation"
                    );
                    // TODO: Restore permissions as they were for last made save
                    // TODO: Restore publishing state as they were for the last made save
                    onRemoteSave();
                    history.push(generateUpdateUrl(itemId, { noRev: true }));
                  }}
                >
                  <MdRestorePage /> {t("form.restore")}
                </NavigationButton>
              )}
              {myPermissions?.generalPermission === "write" &&
                itemId !== t("root.new") &&
                wfStatus !== "deleted" &&
                !rev && (
                  <NavigationButton
                    type={ButtonType.Gray3}
                    action={() => {
                      if (!intItem) {
                        console.error("No intItem available");
                        return;
                      }
                      formMethods.setValue(
                        "ndtrcEntry.wfstatus",
                        wfStatus === "archived"
                          ? "readyforvalidation"
                          : "archived"
                      );
                      onRemoteSave();
                    }}
                  >
                    {wfStatus !== "archived" ? (
                      <>
                        <MdArchive />{" "}
                        <span className={classes.buttonText}>
                          {t("form.archiveItem")}
                        </span>
                      </>
                    ) : (
                      <>
                        <MdUnarchive />{" "}
                        <span className={classes.buttonText}>
                          {t("form.unarchiveItem")}
                        </span>
                      </>
                    )}
                  </NavigationButton>
                )}
              {myPermissions?.generalPermission === "write" &&
                itemId !== t("root.new") &&
                wfStatus !== "deleted" &&
                !rev && (
                  <NavigationButton
                    type={ButtonType.Failure}
                    action={() => {
                      if (!intItem) {
                        console.error("No intItem available to publish");
                        return;
                      }
                      formMethods.setValue("ndtrcEntry.wfstatus", "deleted");
                      onRemoteSave();
                    }}
                  >
                    <AiFillDelete />{" "}
                    <span className={classes.buttonText}>
                      {t("form.deleteItem")}
                    </span>
                  </NavigationButton>
                )}
              {myPermissions?.generalPermission === "write" &&
                wfStatus !== "deleted" &&
                itemId !== t("root.new") &&
                !rev && (
                  <TextInputPopover
                    onTextSubmit={(text) => {
                      if (!intItem) {
                        console.error("No intItem available to publish");
                        return;
                      }

                      if (!text) {
                        onRemoteSave({
                          ndtrcEntry: { validator: "" },
                        } as any);
                        return;
                      }

                      // Simple check - real check is at back-end
                      const mailAddress = text.match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      );
                      if (!mailAddress || !mailAddress[0]) {
                        alert(t("root.invalidEmail"));
                        return;
                      }
                      onRemoteSave({
                        ndtrcEntry: { validator: mailAddress[0] },
                      } as any);
                    }}
                    placeholder={t("form.toAssignEmail")}
                    prefillText={intItem.validator || ""}
                    allowEmpty
                  >
                    {(
                      registerPopoverOrigin: any,
                      onButtonClickHandler: any
                    ) => (
                      <NavigationButton
                        myRef={registerPopoverOrigin}
                        className={classes.assignButton}
                        action={onButtonClickHandler}
                        type={ButtonType.Primary}
                      >
                        <AiFillForward style={{ color: "white" }} />{" "}
                        <span
                          className={classes.buttonText}
                          style={{ color: "white" }}
                        >
                          {t("form.assign")}
                        </span>
                      </NavigationButton>
                    )}
                  </TextInputPopover>
                )}
              {wfStatus === "approved" &&
                !myPermissions?.mayModifyPublishedItems &&
                !rev && (
                  <BooleanInputPopover
                    onBooleanSubmit={(deletion) => {
                      if (deletion) {
                        if (!intItem) {
                          console.error("No intItem available");
                          return;
                        }
                        formMethods.setValue("ndtrcEntry.wfstatus", "archived");
                        onRemoteSave();
                      }
                    }}
                    prompt={t("form.retractItemPrompt")}
                    type={"danger"}
                  >
                    {(setPopover: any, onButtonClickHandler: any) => (
                      <NavigationButton
                        type={ButtonType.Gray3}
                        action={() => {
                          onButtonClickHandler();
                        }}
                      >
                        <>
                          <IoCloudOffline />{" "}
                          <span className={classes.buttonText} ref={setPopover}>
                            {t("form.retractItem")}
                          </span>
                        </>
                      </NavigationButton>
                    )}
                  </BooleanInputPopover>
                )}
              {
                // Available when read or write permission are present
                !eventIsInMyOrg &&
                  props.entityType === "EVENEMENT" &&
                  wfStatus !== "deleted" &&
                  !rev && (
                    <NavigationButton
                      type={saveButtonType}
                      action={() => {
                        onCopyEventButton();
                      }}
                    >
                      <AiFillCopy />{" "}
                      <span className={classes.buttonText}>
                        {t("root.copyEventButton")}
                      </span>
                    </NavigationButton>
                  )
              }
              {myPermissions?.generalPermission === "write" &&
                wfStatus !== "deleted" &&
                !rev && (
                  <NavigationButton
                    type={saveButtonType}
                    action={() => {
                      onRemoteSave();
                    }}
                    className={classes.saveButton}
                  >
                    <AiFillSave /> {saveButtonText}
                  </NavigationButton>
                )}
              {itemId !== t("root.new") && wfStatus !== "deleted" && !rev && (
                <label className={classes.publishedToggle}>
                  {t("form.published")}
                  <Toggle
                    onChange={(published, event) => {
                      if (!intItem) {
                        console.error("No intItem available to publish");
                        return;
                      }
                      if (published) {
                        formMethods.setValue("ndtrcEntry.wfstatus", "approved");
                        intItem.validator = null; // Reset validator
                      } else {
                        formMethods.setValue(
                          "ndtrcEntry.wfstatus",
                          "readyforvalidation"
                        );
                      }
                      onRemoteSave();
                    }}
                    checked={isPublished}
                    disabled={!myPermissions?.mayPublish}
                  />
                </label>
              )}
            </div>
          </div>
        )}

        <div className={classes.id}>
          {intItem &&
            intItem.userorganisation &&
            userorganisations.length > 0 &&
            !userorganisations.find(
              (org) => org === intItem.userorganisation
            ) && (
              <div className={classes.otherOwnerWrapper}>
                <div className={classes.otherOwner}>
                  <span>
                    <AiFillCrown />{" "}
                  </span>
                  <span>{intItem.userorganisation}</span>
                </div>
              </div>
            )}
          {wfStatus && (
            <div className={`${classes.wfStatus} ${classes[wfStatus]}`}>
              <SiStatuspage />
              {t(`wfStatus.${wfStatus}`)}
            </div>
          )}
          {intItem && (
            <div
              className={`${classes.myPermissions} ${
                classes[getRoleSlug(myPermissions?.accessType)]
              }`}
            >
              <ImKey />
              {t(`permissions.${getRoleSlug(myPermissions?.accessType)}`)}
            </div>
          )}
          {intItem?.links?.some((i) => i.type == "parent") && (
            <a
              href={`/${t("root.events.slug")}/${
                intItem.links.find((i) => i.type == "parent")?.id
              }`}
            >
              <div className={`${classes.isCopy}`}>
                <AiFillCopy />
                {t("root.isCopy")}
              </div>
            </a>
          )}
          {intItem?.links?.some((i) => i.type == "child") && (
            <div className={`${classes.hasCopies}`}>
              <BsFillSkipStartFill />
              {t("root.hasCopies")}
            </div>
          )}
          {/*id: {itemId} (*/}
          {/*{props.entityType && t("entityType." + props.entityType)})*/}
          {itemId !== t("root.new") && (
            <button
              className={`${classes.copyToClipboard} ${
                clipboardSuccess ? classes.success : ""
              }`}
              onClick={() => {
                navigator.clipboard.writeText(itemId).then(() => {
                  setClipboardSuccess(true);
                  setTimeout(() => {
                    setClipboardSuccess(false);
                  }, 700);
                });
              }}
            >
              <HiClipboardCopy />
            </button>
          )}
          {itemId !== t("root.new") && (
            <HoverDescNode>
              <div>
                {intItem?.userorganisation && (
                  <div>
                    <strong>{t("item.userorganisation")}:</strong>{" "}
                    {intItem.userorganisation}
                  </div>
                )}
                {intItem?.owner && (
                  <div>
                    <strong>{t("item.owner")}:</strong> {intItem.owner}
                  </div>
                )}
                {intItem?.createdby && (
                  <div>
                    <strong>{t("item.createdby")}:</strong> {intItem.createdby}
                  </div>
                )}
                {intItem?.lastupdatedby && (
                  <div>
                    <strong>{t("item.lastupdatedby")}</strong>{" "}
                    {intItem.lastupdatedby}
                  </div>
                )}
                {intItem?.lastupdated && (
                  <div>
                    <strong>{t("item.lastupdated")}</strong>{" "}
                    {formatDistanceToNow(new Date(intItem.lastupdated), {
                      locale: nl,
                    })}{" "}
                    {t("root.ago")}
                  </div>
                )}
                {intItem?.externalid && (
                  <div>
                    <strong>{t("item.externalid")}</strong> {intItem.externalid}
                  </div>
                )}
                {intItem?.trcid && (
                  <div>
                    <strong>{t("item.trcid")}</strong> {intItem.trcid}
                  </div>
                )}
              </div>
            </HoverDescNode>
          )}
          {intItem?.validator && (
            <p className={classes.currentEditor}>
              <span>{t("form.assigned")}:</span> {intItem.validator}
            </p>
          )}
        </div>
        {intItem && intItem.entitytype === "LOCATIE" && intItem.id && (
          <div>
            <EventsByLocationWidget locationId={intItem.id} />
          </div>
        )}
        {intItem && (
          <ItemFormVersionTabs
            showAliases={props.entityType !== "EVENEMENTGROEP"}
            availableLangs={availableLanguages}
            availableAliases={aliasOptions}
            activeVersion={version || primaryLanguage}
            onButtonClick={handleVersionAddition}
            primaryLang={primaryLanguage}
            onRearrange={handleLangRearrange}
            onDeletion={handleVersionDeletion}
            readOnly={
              !!rev ||
              wfStatus === "deleted" ||
              myPermissions?.generalPermission === "read"
            }
            itemId={itemId}
            entityType={props.entityType}
          />
        )}
        {intItem && primaryLanguage && myPermissions && (
          <>
            <Switch>
              <Route exact path={`/${t(urlSlug)}/:id`}>
                {originalItem && intItem && (
                  <ItemFormPrimaryLanguage
                    lang={primaryLanguage}
                    originalItem={originalItem}
                    intItem={intItem}
                    onSubmit={onLocalSave}
                    formMethods={formMethods}
                    localSaveTrigger={(extraData: FormData | null = null) => {
                      onLocalSave(extraData);
                    }}
                    remoteSaveLongDescriptionTrigger={(
                      extraData: FormData | null = null
                    ) => {
                      onRemoteSaveLongDescription(extraData);
                    }}
                    userorganisations={userorganisations}
                    myPermissions={myPermissions}
                  />
                )}
              </Route>
              <Route exact path={`/${t(urlSlug)}/:id/:version`}>
                {originalItem &&
                  intItem &&
                  (Object.values(Lang).includes(version as Lang) ? (
                    <ItemFormSecondaryLanguage
                      primaryLanguage={primaryLanguage}
                      originalItem={originalItem}
                      intItem={intItem}
                      onSubmit={onLocalSave}
                      formMethods={formMethods}
                    />
                  ) : (
                    <ItemFormAlias
                      primaryLanguage={primaryLanguage}
                      originalItem={originalItem}
                      intItem={intItem}
                      onSubmit={onLocalSave}
                      formMethods={formMethods}
                      localSaveTrigger={(extraData: FormData | null = null) => {
                        onLocalSave(extraData);
                      }}
                      myPermissions={myPermissions}
                    />
                  ))}
              </Route>
            </Switch>
          </>
        )}
        {!intItem && (
          <div className={"ff-loader-centered isFixed"}>
            <Loader />
          </div>
        )}
        {intItem && !intItem.translations && (
          <div className={classes.languageSelectHint}>
            <FaArrowUp />
            {t("form.selectLanguageHint").toLowerCase()}
          </div>
        )}
        {intItem && wfStatus === "approved" && duplicates.length > 0 && (
          <>
            <ToastContainer
              position="bottom-right"
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              toastClassName={classes.toastContainer}
            />
            <DuplicateModal
              type={props.entityType}
              duplicates={duplicates}
              handleClose={handleCloseModal}
              show={openDuplicateModal}
            />
          </>
        )}
      </div>
      {
        // TODO: remove exception on route item from mocking stage
        itemId !== t("root.new") && props.entityType !== "ROUTE" && (
          <ItemFormComments entityType={props.entityType} itemId={itemId} />
        )
      }
      {props.entityType === "EVENEMENT" && (
        <ItemsQueue activeItemId={intItem?.id} />
      )}
    </>
  );
};

export default ItemForm;
