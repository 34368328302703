import { useCallback, useEffect, useRef, useState } from "react";
import {
  feedFactoryAxios,
  generateItemApiUrl,
} from "helpers";
import { EntityType } from "../../../models/Ndtrc/Ndtrc";
import { ItemComment } from "../../../models/ItemFormComment/ItemFormComments";
import classes from "./ItemFormComments.module.scss";
import { format, formatDistanceToNowStrict } from "date-fns";
import nl from "date-fns/locale/nl";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader/Loader";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { MdCancel, MdSend } from "react-icons/md";

const ItemFormComments = (props: {
  entityType: EntityType;
  itemId: string;
}) => {
  const { t } = useTranslation();

  const [comments, setComments] = useState<ItemComment[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const [newCommentBody, setNewCommentBody] = useState("");

  const itemFormCommentsView = useRef<HTMLDivElement | null>(null);

  const [editedComment, setEditedComment] = useState<null | string>(null);
  const [toDeleteComment, setToDeleteComment] = useState<null | string>(null);

  const generateCommentSummary = (count: number) => {
    return count === 1
      ? `${count} ${t("root.comment")}`
      : `${count} ${t("root.comments")}`;
  };

  const handleSubmit = () => {
    if (!newCommentBody) {
      return;
    }
    if (editedComment) {
      modifyComment(editedComment);
    } else {
      putNewComment();
    }
  };

  const modifyComment = (commentId: string) => {
    setIsLoading(true);
    const newComment = newCommentBody;
    setNewCommentBody("");
    const commentUrl = `${generateItemApiUrl(
      props.entityType,
      props.itemId,
    )}/comments/${commentId}`;
    feedFactoryAxios
      .put(commentUrl, { body: newComment })
      .then((response) => {
        updateComments();
        setEditedComment(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const putNewComment = () => {
    setIsLoading(true);
    const newComment = newCommentBody;
    setNewCommentBody("");
    const commentUrl = `${generateItemApiUrl(
      props.entityType,
      props.itemId,
    )}/comments`;
    feedFactoryAxios
      .post(commentUrl, { body: newComment })
      .then((response) => {
        updateComments();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateComments = useCallback(() => {
    const commentUrl = `${generateItemApiUrl(
      props.entityType,
      props.itemId,
    )}/comments`;
    feedFactoryAxios.get(commentUrl).then((response) => {
      setComments(response.data.reverse());
      const comments = itemFormCommentsView?.current?.children;
      if (comments && comments.length > 1) {
        setIsCollapsed(false);
        comments[comments.length - 1].scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    });
  }, [props.entityType, props.itemId]);

  const deleteComment = useCallback(
    (commentId) => {
      if (commentId === editedComment) {
        setEditedComment(null);
        setNewCommentBody("");
      }
      const commentUrl = `${generateItemApiUrl(
        props.entityType,
        props.itemId,
      )}/comments/${commentId}`;
      feedFactoryAxios.delete(commentUrl).then((response) => {
        console.log(response);
        updateComments();
      });
    },
    [editedComment, props.entityType, props.itemId, updateComments],
  );

  useEffect(() => {
    updateComments();
  }, [updateComments]);

  return (
    <div
      className={`${classes.ItemFormCommentsWrapper} ${
        isCollapsed ? classes.isCollapsed : ""
      }`}
      onClick={() => {
        setToDeleteComment(null);
      }}
    >
      <div className={classes.ItemFormComments} ref={itemFormCommentsView}>
        {comments.map((comment) => (
          <div
            key={comment.id}
            className={`${classes.ItemFormComment} ${
              editedComment === comment.id ? classes.editedComment : ""
            }`}
          >
            <div className={classes.body}>{comment.body}</div>
            <div className={classes.optionsButton}>
              {editedComment !== comment.id ? (
                <span
                  onClick={() => {
                    setEditedComment(comment.id);
                    setNewCommentBody(comment.body);
                  }}
                >
                  <AiFillEdit />
                </span>
              ) : (
                <span
                  onClick={() => {
                    setEditedComment(null);
                    setNewCommentBody("");
                  }}
                >
                  <MdCancel />
                </span>
              )}
              <span
                className={
                  toDeleteComment === comment.id ? classes.toDeleteComment : ""
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setToDeleteComment(comment.id);
                }}
              >
                <AiFillDelete />
                {toDeleteComment === comment.id && (
                  <span
                    onClick={() => {
                      deleteComment(comment.id);
                    }}
                  >
                    {t("root.commentDeletePrompt")}
                  </span>
                )}
              </span>
            </div>
            <div className={classes.metaInfo}>
              <span
                title={format(new Date(comment.created), "dd-MM-yyyy HH:mm")}
              >
                {formatDistanceToNowStrict(new Date(comment.created), {
                  locale: nl,
                })}{" "}
                {t("root.ago")}
              </span>{" "}
              {t("root.by")}{" "}
              <span className={classes.author}>{comment.author}</span>
            </div>
          </div>
        ))}
      </div>
      <textarea
        value={newCommentBody}
        onChange={(event) => {
          setNewCommentBody(event.currentTarget.value);
        }}
        placeholder={
          !editedComment
            ? t("root.newComment")
            : t("root.editingExistingComment")
        }
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            e.currentTarget.blur();
            handleSubmit();
          }
        }}
        disabled={isLoading}
      />
      {!isCollapsed && (
        <button
          className={classes.submitButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.currentTarget.blur();
            handleSubmit();
          }}
        >
          <MdSend />
        </button>
      )}
      {isLoading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      <div
        className={classes.collapseButton}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        {isCollapsed ? (
          <div>
            <span>
              <BiExpand />
            </span>
            <span>{generateCommentSummary(comments.length)}</span>
          </div>
        ) : (
          <BiCollapse />
        )}
      </div>
    </div>
  );
};

export default ItemFormComments;
