import React from "react";
import { ItemSpecificPermissions } from "../../models/ItemSpecificPermissions/ItemSpecificPermissions";
import { MdError, MdInfo, MdWarning } from "react-icons/md";
import classes from "./CalloutBanner.module.scss";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";

const LinkRenderer = (props: any) => {
  return <Link to={props.href}>{props.children}</Link>;
};

const CalloutBanner = (props: { data: ItemSpecificPermissions }) => {
  let myIcon;
  switch (props.data.type) {
    case "info":
      myIcon = <MdInfo />;
      break;
    case "warning":
      myIcon = <MdWarning />;
      break;
    case "error":
      myIcon = <MdError />;
      break;
    default:
      myIcon = <MdInfo />;
  }

  return (
    <div className={`${classes.CalloutBanner} ${classes[props.data.type]}`}>
      <div className={classes.icon}>{myIcon}</div>
      <div className={classes.text}>
        <div style={{ pointerEvents: "all" }}>
          <span>
            <strong>{props.data.title}</strong>:{" "}
          </span>
          <Markdown components={{ a: LinkRenderer }}>
            {props.data.description}
          </Markdown>
        </div>
      </div>
    </div>
  );
};

export default CalloutBanner;
