import {
  CategorizationSource,
  CategorizationSourceBase,
  TopLevelCategorizationSource,
} from "models/CategorizationSource";
import * as _ from "lodash";
import { EntityType } from "models/Ndtrc";
import { useEffect, useState } from "react";
import { CategorizationOntologyWrapper } from "../models/CategorizationOntology/CategorizationOntology";
import { AccountModel } from "../models/AccountModel";
import {
  feedFactoryAxios,
} from "helpers";
import assertValidResponse from "../containers/Settings/components/helpers/assertValidResponse";
import { AxiosResponse } from "axios";
import { categorizationOntology as defaultCategorizationOntology } from "data/categorizationOntology";

export const useCategorizationOntologyWrapper = (): CategorizationOntologyWrapper => {
  const [categoryOntology, setCategoryOntology] = useState<CategorizationOntologyWrapper>(defaultCategorizationOntology);

  const [account, setAccount] = useState<AccountModel>({} as AccountModel);

  useEffect(() => {
    const accountUrl = "/accounts/me";

    feedFactoryAxios
      .get(accountUrl)
      .then((response) =>
        assertValidResponse(response, "No account found for this account id"),
      )
      .then((response: AxiosResponse<AccountModel>) => {
        setAccount(response.data);
      })
      .catch((error) => {
        // TODO: Handle error
      })
  }, []);

  useEffect(() => {
    if (account && account.metaData?.categorizationOntology) {
      try {
        setCategoryOntology({ categorizations: JSON.parse(account.metaData?.categorizationOntology) });
      } catch (e) {
        console.error(
          "Could not parse categorization ontology, using default instead",
        );
        setCategoryOntology(defaultCategorizationOntology);
      }
    } else {
      setCategoryOntology(defaultCategorizationOntology);
    }
  }, [account]);

  return categoryOntology;
}

export interface CategorizationSourceDictionary {
  [catid: string]: CategorizationSourceBase;
}

export const useCategorizationSourceDictionary = (): CategorizationSourceDictionary => {
  const categorizationOntology: CategorizationOntologyWrapper = useCategorizationOntologyWrapper();

  return getCategorizationSourceDictionary(categorizationOntology);
}

// The categorizationSourceDictionary contains ALL items, including deprecated items
// and of any entityType value. Filtering occurs in other functions.
export const getCategorizationSourceDictionary =
  (categorizationOntology: CategorizationOntologyWrapper): CategorizationSourceDictionary => {

    const children = _.chain(
      categorizationOntology.categorizations as TopLevelCategorizationSource[]
    )
      .map("child")
      .flatten()
      .value() as CategorizationSource[];
    const parents = _.cloneDeep(categorizationOntology.categorizations);
    parents.forEach((parent) => {
      delete parent.child;
    });
    const flattenedCategorizationSource = (
      children as CategorizationSourceBase[]
    )
      .concat(parents as CategorizationSourceBase[])
      .filter((item) => item?.categorization);

    let catDictionary: CategorizationSourceDictionary = {};
    flattenedCategorizationSource.forEach((cat) => {
      const catClone: any = { ...cat };
      const id = catClone.cnetID;
      delete catClone.cnetID;
      catDictionary[id] = catClone;
    });

    return catDictionary;
  };

// Looks at all possible item types, including deprecated and all entityTypes
// Returns only category label
export const getCategoryById = (id: string | null, categorizationOntology: CategorizationSourceDictionary): string | null =>
  getItemById(id, categorizationOntology)?.categorization || null;

// Looks at all possible item types, including deprecated and all entityTypes
// Returns all item data
export const getItemById = (
  id: string | null,
  categorizationOntology: CategorizationSourceDictionary,
): CategorizationSourceBase | null => {
  if (!id) {
    console.error(`Requested category with invalid ID: ${id}`);
    return null;
  }

  return categorizationOntology[id] || null;
};

interface SingleSelectOption {
  value: string;
  label: string;
}
export interface SelectGroupedOptions {
  label: string;
  options: SingleSelectOption[];
}

// Filters on deprecated and entityType status
export const GroupedOptions = (
  entityType: EntityType | undefined = undefined,
  showDeprecated = false,
  categorizationOntology: CategorizationOntologyWrapper,
): SelectGroupedOptions[] => {
  const groupedOptions = (
    categorizationOntology.categorizations as TopLevelCategorizationSource[]
  )
    .filter((cat) => showDeprecated || !cat.deprecated)
    .filter((cat) => !cat.entityType || cat.entityType === entityType)
    .filter((topLevelCat) => !!topLevelCat.child)
    .map((topLevelCat) => {
      const transformedOptions = (topLevelCat.child as CategorizationSource[])
        .filter((cat) => showDeprecated || !cat.deprecated)
        .map((cat) => {
          return {
            value: cat.cnetID,
            label: cat.categorization,
          };
        })
        .sort((a, b) => (a.label < b.label ? -1 : 1));
      return {
        label: topLevelCat.categorization,
        options: transformedOptions,
      };
    })
    .sort((a, b) => (a.label < b.label ? -1 : 1));

  return groupedOptions;
};
