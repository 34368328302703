import { TuningProps } from "models/TuningProps";
import { useState } from "react";

export interface TuningPreset {
  name: string;
  props: TuningProps;
}

const useFilterPresets = (): {
  presets: TuningPreset[];
  addPreset: (filterName: string, filterQuery: TuningProps | null) => any;
  removeFilter: (filterName: string) => any;
} => {
  const [hasInit, setHasInit] = useState(false);
  const [presets, setPresets] = useState<TuningPreset[]>([]);

  const addPreset = (filterName: string, tuningProps: TuningProps | null) => {
    if (!tuningProps) {
      return;
    }

    const newPresets = presets.concat({
      name: filterName,
      props: tuningProps,
    });
    setPresets(newPresets);
    localStorage.setItem("filterPresets", JSON.stringify(newPresets));
  };

  const removeFilter = (filterName: string) => {
    const newPresets = presets.filter((filter) => filter.name !== filterName);
    setPresets(newPresets);
  };

  if (!hasInit) {
    // Retrieve presets from localstorage
    const rawPresets = localStorage.getItem("filterPresets");
    setPresets(rawPresets ? JSON.parse(rawPresets) : []);
    setHasInit(true);
  }

  return { presets: presets, addPreset, removeFilter };
};

export default useFilterPresets;
