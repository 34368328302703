export function commaSeparatedStringToOptionArray(
  value: string | null
): { value: string; label: string }[] | null {
  // Value is comma-separated list
  let transformedValue;
  if (!value || value === "") {
    transformedValue = null;
  } else {
    const splicedValue: string[] = (value as string)?.trim().split(";");
    transformedValue = splicedValue?.map((v: string) => {
      return { value: v, label: v };
    });
  }
  return transformedValue;
}
