import {
  EntityType,
  ExtLangString,
  IntPromotion,
  IntUrlDetails,
  PromotionTranslation,
  PromotionTypeOptions,
} from "models/Ndtrc";
import classes from "./PromotionsWidget.module.scss";
import Select from "react-select";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import DiscountValue from "./DiscountValue/DiscountValue";
import WeekDaysValue from "./WeekDaysValue/WeekDaysValue";
import UrlsWidget from "components/FormWidgets/UrlsWidget";
import React, { useState } from "react";
import { PermissionObject } from "models/PermissionObject";
import { GoLinkExternal } from "react-icons/go";
import { MultiLangInput } from "components/FormWidgets/inputs";
import { DateWidget } from "components/FormWidgets/DateWidget";
import { getPromotionState } from "helpers";
import { MdDisabledByDefault, MdSkipNext } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa";

const convertDescriptionToTextLangString = (
  label: PromotionTranslation,
): ExtLangString => {
  return {
    lang: label.lang,
    text: label.description,
  };
};
const convertDescriptionToTextLangStringArray = (
  labels: PromotionTranslation[],
): ExtLangString[] => {
  return labels.map(convertDescriptionToTextLangString);
};
const convertTextToDescriptionLangString = (
  text: ExtLangString,
): PromotionTranslation => {
  return {
    lang: text.lang,
    description: text.text,
  };
};
const convertTextToDescriptionLangStringArray = (
  texts: ExtLangString[],
): PromotionTranslation[] => {
  return texts.map(convertTextToDescriptionLangString);
};

const PromotionWidget = (props: {
  value: IntPromotion;
  onChange: (newValue: IntPromotion) => any;
  index: number;
  readonly myPermissions: PermissionObject;
  entityType: EntityType;
  readOnlyProduct?: boolean;
  errors?: any;
}) => {
  const { t } = useTranslation();

  const promoTypesTranslations = PromotionTypeOptions.map((type) => {
    const newType = _.cloneDeep(type);
    newType.label = t(type.label);
    return newType;
  });

  const [discountType, setDiscountType] = useState<string | null>(null);

  // Determine initial discount type
  if (!discountType) {
    if (
      props.value.promotionType === "gift" ||
      props.value.promotionType === "free"
    ) {
      setDiscountType("free");
    } else if (props.value.discount?.percentage) {
      setDiscountType("percentage");
    } else {
      // Default to amount if no value provided
      setDiscountType("amount");
    }
  }

  const [readOnlySubtitle, setReadOnlySubtitle] = React.useState<string | null>(
    null,
  );
  React.useEffect(() => {
    if (!props.value) return;

    if (!props.value.promotionType) {
      setReadOnlySubtitle(null);
      return;
    }

    const promoTrans = promoTypesTranslations.find(
      (t) => t.value === props.value.promotionType,
    );
    let promoSpecs = "";
    switch (props.value.promotionType) {
      case "discount":
      case "allowance":
        // Check if amount specified
        switch (discountType) {
          case "amount":
            promoSpecs += "€" + props.value.discount?.amount + " ";
            break;
          case "percentage":
            promoSpecs += props.value.discount?.percentage + "% ";
            break;
        }
        break;
      case "gift":
      case "free":
      default:
        break;
    }
    promoSpecs += promoTrans?.label?.toLowerCase();

    setReadOnlySubtitle(promoSpecs || null);
  }, [props.value]);

  const updateValue = (key: string, value: any) => {
    const newValue = _.cloneDeep(props.value);

    if (key === "urlDetails") {
      const idError = (value as IntUrlDetails[]).some((url) => !url.id);
      if (idError) {
        console.error(
          "OnChange error: URLs must have id's, currently missing",
          JSON.stringify(props.value),
        );
      }
      return; // Refuse to update
    }

    // @ts-ignore
    newValue[key] = value;
    props.onChange(newValue);
  };

  const warningText = (() => {
    const promoState = getPromotionState(props.value);
    switch (promoState) {
      case "disabled":
        return (
          <div className={`${classes.warning} ${classes.disabled}`}>
            <MdDisabledByDefault />
            {t("promotion.warning.disabled")}
          </div>
        );
      case "expired":
        return (
          <div className={`${classes.warning} ${classes.expired}`}>
            <FaCalendarCheck />
            {t("promotion.warning.expired")}
          </div>
        );
      case "notYetStarted":
        return (
          <div className={`${classes.warning} ${classes.notYetStarted}`}>
            <MdSkipNext />
            {t("promotion.warning.notYetStarted")}
          </div>
        );
      default:
        return null;
    }
  })();

  return (
    <div className={classes.PromotionWidget}>
      <h3>
        {props.readOnlyProduct
          ? props.value.product
          : `${t("promotion.promotion")} ${props.index + 1}`}
        {props.readOnlyProduct && props.value?.detailsUrls?.[0]?.url && (
          <a
            className={classes.readOnlyMoreInfo}
            href={props.value.detailsUrls?.[0]?.url}
            target={"_blank"}
          >
            <GoLinkExternal />
          </a>
        )}
      </h3>
      {warningText}
      {!props.readOnlyProduct && <label>{t("promotion.product")}</label>}
      <input
        className={"promotionProduct"}
        type={props.readOnlyProduct ? "hidden" : "text"}
        value={props.value.product || ""}
        onChange={(e) => {
          updateValue("product", e.target.value);
        }}
      />
      {!props.readOnlyProduct ? (
        <label>{t("promotion.type")}</label>
      ) : (
        <p>{readOnlySubtitle}</p>
      )}
      <Select
        className={`${classes.promotionTypeSelect} ${
          props.readOnlyProduct ? ` ${classes.hideFormItem}` : ""
        }`}
        value={
          props.value.promotionType
            ? {
                value: props.value.promotionType,
                label:
                  promoTypesTranslations.find(
                    (t) => t.value === props.value.promotionType,
                  )?.label || "[???]",
              }
            : null
        }
        options={promoTypesTranslations}
        placeholder={t("form.select")}
        menuPlacement={"auto"}
        isClearable
        styles={{
          menu: (provided: any, state) => {
            return { ...provided, zIndex: 2 };
          },
        }}
        onChange={(newVal: any) => {
          updateValue("promotionType", newVal?.value || null);
        }}
      />
      {(props.value?.promotionType === "discount" ||
        props.value?.promotionType === "allowance") && (
        <div className={props.readOnlyProduct ? classes.hideFormItem : ""}>
          <DiscountValue
            promotionType={props.value.promotionType}
            value={props.value.discount || {}}
            onChange={(newVal) => {
              updateValue("discount", newVal);
            }}
          />
        </div>
      )}
      {!props.readOnlyProduct && (
        <label>{t("promotion.shortDescription")}</label>
      )}
      <div className={classes.multiLangList}>
        <MultiLangInput
          disabled={props.readOnlyProduct}
          value={
            props.value.translations
              ? convertDescriptionToTextLangStringArray(
                  props.value.translations,
                )
              : []
          }
          onChange={(newVal) => {
            updateValue(
              "translations",
              newVal ? convertTextToDescriptionLangStringArray(newVal) : [],
            );
          }}
        />
      </div>
      {!props.readOnlyProduct && <label>{t("promotion.website")}</label>}

      <div className={props.readOnlyProduct ? classes.hideFormItem : ""}>
        <UrlsWidget
          urls={props.value.detailsUrls || []}
          onChange={(newVal) => {
            updateValue("detailsUrls", newVal);
          }}
          className={""}
        />
      </div>
      <label>{t("promotion.availablefromto")}</label>
      <div>
        <label className={classes.smallLabel}>{t("promotion.weekdays")}</label>
        <WeekDaysValue
          value={props.value.opens ?? []}
          onChange={(val) => {
            updateValue("opens", val);
          }}
        />
      </div>
      <div className={classes.availableFromTo}>
        <div>
          <label>{t("promotion.availablefrom")}</label>
          <DateWidget
            value={props.value.startDate}
            onChange={(val) => {
              updateValue("startDate", val);
            }}
            hasError={props.errors?.startDate}
          />
        </div>
        <div>
          <label>{t("promotion.availableto")}</label>
          <DateWidget
            value={props.value.endDate}
            onChange={(val) => {
              updateValue("endDate", val);
            }}
            hasError={props.errors?.endDate}
          />
        </div>
      </div>
      <div className={classes.promotionEnabled}>
        <label>
          <input
            type={props.readOnlyProduct ? "hidden" : "checkbox"}
            checked={props.value.enabled}
            onChange={() => {
              updateValue("enabled", !props.value.enabled);
            }}
          />
          {!props.readOnlyProduct && t("promotion.enabled")}
        </label>
      </div>
    </div>
  );
};

export default PromotionWidget;
