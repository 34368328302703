import React from "react";
import { HoverDesc } from "../../HoverDesc";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import classes from "../DictionarySelectWidget/DictionarySelectWidget.module.scss";
import { SingleSelectOption } from "../../../models/SelectOption";
import { EntityType } from "../../../models/Ndtrc";

// TODO: offer auto-complete for users
const options: SingleSelectOption[] = [];

const PermissionsWidget = (props: {
  value: string[];
  onChange: (value: string[]) => any;
  level: string;
  entityType: EntityType;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <>
        <label>
          {t(`permissions.${props.level}`)}
          <HoverDesc
            description={`permissions.${props.level}.${props.entityType}.desc`}
          />
        </label>
        <CreatableSelect
          className={`multiSelect ${classes.select}`}
          value={props.value?.map((item) => ({
            value: item,
            label: item,
          }))}
          options={options}
          isMulti
          menuPlacement={"auto"}
          placeholder={`${t("form.select")}...`}
          // formatCreateLabel={(inputText) =>
          //   `${t("form.createPrefix")} "${inputText}" ${t("form.createSuffix")}`
          // }
          styles={{
            menu: (provided, state) => {
              return { ...provided, zIndex: 1000000 };
            },
          }}
          onChange={(item: any) => {
            props.onChange(item?.map((item: any) => item.value));
          }}
        />
      </>
    </div>
  );
};

export default PermissionsWidget;
