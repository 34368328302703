import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../store";
import axios, { AxiosError, CancelTokenSource } from "axios";
import {
  comparisonIgnoringUndefined,
  externalResultsToInternalResults,
  fetchApiItems,
  IntPaginatedResponse,
} from "helpers";
import {
  defaultTuningOptions,
  TuningProps,
} from "../../models/TuningProps/TuningProps";
import _ from "lodash";

// As a non-serializable object, needs to be stored outside of redux
let cancelTokenSource: CancelTokenSource | null;

interface LocationsSlice {
  locations: IntPaginatedResponse | null; // also contains poge and size
  loading: boolean;
  error: string | null;
  tuningProps: TuningProps;
  nextProps: null | {
    page: number;
    size: number;
    tuningProps: TuningProps;
  };
}

const initialState: LocationsSlice = {
  locations: null,
  loading: false,
  error: null,
  tuningProps: defaultTuningOptions,
  nextProps: null,
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    fetchLocationsStart: (
      state,
      action: PayloadAction<{
        nextProps: {
          page: number;
          size: number;
          tuningProps: TuningProps;
        };
      }>
    ) => {
      if (state.loading) {
        fetchLocationsInterrupt({
          interruptReason: "Request canceled: location data to be replaced.",
        });
      }
      state.loading = true;
      state.error = null;
      state.nextProps = action.payload.nextProps;
      return state;
    },
    fetchLocationsInterrupt: (
      state,
      action: PayloadAction<{
        interruptReason: string;
      }>
    ) => {
      cancelTokenSource?.cancel(action.payload.interruptReason);

      state.nextProps = null;
      state.loading = false;
      state.error = null;
      return state;
    },
    fetchLocationsSuccess: (
      state,
      action: PayloadAction<{
        data: IntPaginatedResponse;
        tuningProps: TuningProps;
      }>
    ) => {
      console.log("LOCATIONS LOADED", action.payload);
      state.loading = false;
      state.locations = action.payload.data;
      state.tuningProps = action.payload.tuningProps;
      return state;
    },
    fetchLocationsFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;

      return state;
    },
  },
});

export const {
  fetchLocationsStart,
  fetchLocationsSuccess,
  fetchLocationsFailure,
  fetchLocationsInterrupt,
} = locationsSlice.actions;

export const performFetchLocations =
  (page = 0, size = 24, tuningProps: TuningProps): AppThunk =>
  async (dispatch, getState) => {
    // Check if query is not equal to previously completed query. If so, don't perform
    // TODO: Fix this so that page changes while loading don't cancel queries
    const tuningPropsSame = _.isEqualWith(
      tuningProps,
      getState().locations.tuningProps,
      comparisonIgnoringUndefined
    );

    // Check if query is not equal to currently running query. If so, don't perform
    if (
      page === getState().locations.nextProps?.page &&
      size === getState().locations.nextProps?.size &&
      _.isEqualWith(
        tuningProps,
        getState().locations.nextProps,
        comparisonIgnoringUndefined
      )
    ) {
      // console.log("LOCATION SLICE INPUT WAS EQUAL TO CURRENT QUERY"); // DEBUG
      return;
    }

    // console.log(
    //   "NEW LOCATION SLICE",
    //   tuningProps,
    //   getState().locations.tuningProps
    // ); // DEBUG

    if (!tuningPropsSame) {
      page = 0;
    }

    cancelTokenSource?.cancel(
      "Request canceled: location data already present."
    );
    cancelTokenSource = axios.CancelToken.source();
    dispatch(
      fetchLocationsStart({
        nextProps: { page: page, size: size, tuningProps: tuningProps },
      })
    );
    fetchApiItems(
      "LOCATIE",
      page,
      size,
      tuningProps,
      cancelTokenSource,
      "json",
      true
    )
      .then(async (response) => {
        dispatch(
          fetchLocationsSuccess({
            data: externalResultsToInternalResults(response.data),
            tuningProps: tuningProps,
          })
        );
      })
      .catch((error: AxiosError) => {
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error.message); // DEBUG
        } else {
          return dispatch(fetchLocationsFailure(error.message));
        }
      });
  };

export const selectLoading = (state: RootState) => state.locations.loading;
export const selectError = (state: RootState) => state.locations.error;
export const selectLocations = (state: RootState) => state.locations.locations;
export const selectTuningProps = (state: RootState) =>
  state.locations.tuningProps;

export default locationsSlice.reducer;
