import React, { useCallback, useState } from "react";
import classes from "./FileUploadPopover.module.scss";
import popoverStyles from "./PopoverSharedStyle.module.scss";
import { usePopper } from "react-popper";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import { postAssets } from "helpers";
import { FileType } from "models/Ndtrc";
import { MediaRequirements } from "models/MediaRequirements";

const FileUploadPopover = ({
  children,
  onFilesSubmit,
  fileType,
  uploadConditionsText,
  requirements,
}: {
  children: any;
  onFilesSubmit: (urls: string[]) => void;
  fileType?: FileType;
  uploadConditionsText?: string;
  requirements?: MediaRequirements;
}) => {
  const { t } = useTranslation();
  const [popoverActive, setPopoverActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const isImageResolutionValid = async (file: File) => {
    // TODO: Check if file is actually an image. If not, simply skip this check.
    const filePreviewUrl: string = URL.createObjectURL(file);
    const img = new Image();
    img.src = filePreviewUrl;
    await img.decode();
    const imgIsCorrectWidth =
      !requirements?.image?.minWidth ||
      (requirements.image?.exactSize
        ? img.width === requirements.image?.minWidth
        : img.width >= requirements.image?.minWidth);
    const imgIsCorrectHeight =
      !requirements?.image?.minHeight ||
      (requirements.image?.exactSize
        ? img.height === requirements.image?.minHeight
        : img.height >= requirements.image?.minHeight);
    const imgIsCorrectResolution = imgIsCorrectWidth && imgIsCorrectHeight;
    return imgIsCorrectResolution;
  };
  const isImageOrientationValid = async (file: File) => {
    const filePreviewUrl: string = URL.createObjectURL(file);
    const img = new Image();
    img.src = filePreviewUrl;
    await img.decode();
    const imgIsCorrectOrientation =
      !requirements?.image?.orientation ||
      requirements.image?.orientation.includes(
        img.width > img.height
          ? "landscape"
          : img.width < img.height
          ? "portrait"
          : "square",
      );
    return imgIsCorrectOrientation;
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: any) => {
      if (acceptedFiles.length < 1) {
        alert(t("form.unsupportedFileType"));
        return;
      }

      for (const acceptedFile of acceptedFiles) {
        const resolutionIsValid = await isImageResolutionValid(acceptedFile);
        if (!resolutionIsValid) {
          alert(t("form.incorrectImageResolution"));
          return;
        }
        const orientationIsValid = await isImageOrientationValid(acceptedFile);
        if (!orientationIsValid) {
          alert(t("form.incorrectImageOrientation"));
          return;
        }
      }

      setIsLoading(true);

      postAssets(acceptedFiles).then((res) => {
        const onlyUri = res.map((item) => {
          return item.uri;
        });

        setIsLoading(false);
        setPopoverActive(false);
        onFilesSubmit(onlyUri);
      });
    },
    [onFilesSubmit, setPopoverActive, t],
  );

  let acceptedFileTypes: string = "image/jpeg, image/png, image/webp";
  if (fileType && fileType !== "jpegOrPngOrWebp") {
    acceptedFileTypes = "image/" + fileType;
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    maxSize: 10485760,
  });

  const [popperOrigin, registerPopperOrigin] =
    useState<HTMLButtonElement | null>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes, update } = usePopper(
    popperOrigin,
    popperElement,
    {
      placement: "auto",
    },
  );

  const onButtonClickHandler = () => {
    if (update) {
      void update();
    }
    setPopoverActive(true);
  };

  return (
    <>
      {children(registerPopperOrigin, onButtonClickHandler)}
      <div
        className={[
          popoverStyles.popover,
          classes.popover,
          popoverActive ? popoverStyles.active : "",
        ].join(" ")}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className={isLoading ? "isHidden" : ""} {...getRootProps()}>
          <input {...getInputProps()} />
          <p>{t("form.uploadImage")}</p>
          <p>
            <em>
              {uploadConditionsText
                ? uploadConditionsText
                : t("form.uploadImageTypes")}
            </em>
          </p>
        </div>
        <div
          className={[classes.loader, !isLoading ? "isHidden" : ""].join(" ")}
        >
          <Loader />
          <p>{t("form.uploading")}</p>
        </div>
      </div>
      <div
        className={`${popoverStyles.backdrop} ${
          popoverActive ? popoverStyles.active : ""
        }`}
        onClick={() => {
          setPopoverActive(false);
        }}
      />
    </>
  );
};

export default FileUploadPopover;
