import { feedFactoryAxios } from "./feedFactoryAxios";

export const postAssets = async (assets: File[]) => {
  const uploadedAssets = assets.map((asset) => {
    return postAsset(asset);
  });

  const resArray = await Promise.all(uploadedAssets);
  return resArray.map((res) => {
    const data = res.data;

    // TODO: fix relative namespace at back-end
    if (!res.data.uri.match("app.thefeedfactory")) {
      data.uri = "https://app.thefeedfactory.nl" + data.uri;
    }

    return data;
  });
};

export const postAsset = (asset: File) => {
  return postAssetPromise(asset);
};

const postAssetPromise = (asset: File) => {
  const formData = new FormData();
  formData.append("file", asset);
  return feedFactoryAxios.post("assets", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
