import React from "react";
import { TimeSavedData } from "../../models/TimeSavedData/TimeSavedData";
import Loader from "../Loader";
import classes from "./TimeSaved.module.scss";
import { useTranslation } from "react-i18next";
import { HoverDesc } from "../HoverDesc";

const TimeSaved = (props: { data: TimeSavedData | null }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.TimeSaved}>
      <h2>{t("dashboard.timeSaved")}</h2>
      <div>
        {props.data ? props.data.time_saved.toFixed(0) : <Loader />}{" "}
        {t
        ("dashboard.hours")}
        <HoverDesc description={"dashboard.timeSavedDesc"} position={"right"} />
      </div>
    </div>
  );
};

export default TimeSaved;
