import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  feedFactoryAxios,
  useNavTranslation,
} from "helpers";
import TextButton from "../../../components/buttons/TextButton/TextButton";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import SettingsField, { SettingsLabel } from "./SettingsField";
import { useSelector } from "react-redux";
import {
  selectAccountId,
  selectIsAdmin,
  selectIsSuperAdmin,
} from "../../../store/auth/authSlice";
import NavigationButton from "../../../components/buttons/NavigationButton/NavigationButton";
import { UserResponse } from "../../../models/UserResponse/UserResponse";
import { AxiosResponse } from "axios";
import moment from "moment";
import {
  ButtonSize,
  ButtonType,
} from "../../../components/buttons/ButtonProps";
import PopupButton from "../../../components/buttons/PopupButton/PopupButton";
import { AccountModel } from "../../../models/AccountModel/AccountModel";
import assertValidResponse from "./helpers/assertValidResponse";

interface UserProps {
  isMe?: boolean;
}

interface UserSlice {
  firstName?: string;
  lastName?: string;
  email: string;

  lastSeen?: string;
  dateCreated?: string;

  theme?: string;
  language?: string;

  roles: Array<string>;
}

const GeneralUser = (props: UserProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { accountId: accountIdParam, userId } =
    useParams<{ accountId: string | undefined; userId: string }>();
  const myAccountId = useSelector(selectAccountId);
  const { accountsUrl, getAccountUsersUrl, getAccountUrl } =
    useNavTranslation();

  // Determine if in own account menu
  let inOwnMenu = false;
  let accountId: string | null = accountIdParam || null;
  if (!accountId) {
    inOwnMenu = true;
    accountId = myAccountId || null;
  }

  const [initialEmail, setInitialEmail] = useState("");
  const [user, setUser] = useState<UserSlice>({} as UserSlice);
  const [account, setAccount] = useState<AccountModel>({} as AccountModel);

  const [error, setError] = useState<String | null>(null);

  const [shouldUpdatePassword, setShouldUpdatePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [newPasswordMismatch, setNewPasswordMismatch] = useState(false);

  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoadingAccount, setIsLoadingAccount] = useState(true);

  const isLoading = isLoadingUser || isLoadingAccount;

  const [availableRoles, setAvailableRoles] = useState<string[]>([]);

  const [isSaving, setIsSaving] = useState(false);
  const [didSaveSuccess, setDidSaveSuccess] = useState(false);
  const [didSaveFailure, setDidSaveFailure] = useState(false);

  const isAdmin = useSelector(selectIsAdmin);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  useEffect(() => {
    const ALL_ROLES = ["PARTNER", "USER", "EDITOR"];
    if (isAdmin) {
      ALL_ROLES.push("ADMIN");
    }
    if (isSuperAdmin) {
      ALL_ROLES.push("SUPER_ADMIN");
      ALL_ROLES.push("CONGRES_ALIAS");
    }
    setAvailableRoles(ALL_ROLES);
  }, [isAdmin, isSuperAdmin]);

  useEffect(() => {
    const accountUrl = props.isMe ? `/accounts/me` : `/accounts/${accountId}`;
    const userUrl = props.isMe
      ? `/auth/me`
      : `/accounts/${accountId}/users/${userId}`;

    feedFactoryAxios
      .get(userUrl)
      .then((response: AxiosResponse<UserResponse>) => {
        setInitialEmail(response.data.email);
        setUser(response.data);
      })
      .catch((error) => {
        setError(t("root.couldNotRetrieveUser"));
      })
      .finally(() => {
        setIsLoadingUser(false);
      });

    feedFactoryAxios
      .get(accountUrl)
      .then((response) =>
        assertValidResponse(
          response,
          `No account found for ${props.isMe ? "this user" : "this account id"}`
        )
      )
      .then((response) => {
        setAccount(response.data);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setIsLoadingAccount(false);
      });
  }, [accountId, props.isMe, t, userId]);

  const returnToUsers = () => {
    history.push(getAccountUsersUrl(accountIdParam));
  };

  const returnToAccount = () => {
    history.push(getAccountUrl(accountIdParam));
  };
  const returnToAccounts = () => {
    history.push(accountsUrl);
  };

  useEffect(() => {
    if (newPasswordConfirmation.length > 0) {
      if (newPassword !== newPasswordConfirmation) {
        return setNewPasswordMismatch(true);
      }
    }

    setNewPasswordMismatch(false);
  }, [newPassword, newPasswordConfirmation]);

  const handleSaveClick = (evt: any) => {
    setIsSaving(true);
    setDidSaveSuccess(false);
    setDidSaveFailure(false);

    if (account && account.id !== "") {
      let updatedUserData = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        theme: user.theme,
        roles: user.roles,
      };

      if (shouldUpdatePassword) {
        if (newPassword === newPasswordConfirmation) {
          // @ts-ignore
          updatedUserData["password"] = newPassword;
        } else {
          setNewPasswordMismatch(true);
          return;
        }
      }

      feedFactoryAxios
        .put(`/accounts/${account.id}/users/${initialEmail}`, updatedUserData)
        .then((response) => {
          setDidSaveSuccess(true);
          setShouldUpdatePassword(false);
          setNewPassword("");
          setNewPasswordConfirmation("");

          // since the url is the identifier of the user we have to make sure to update it
          // in the browser to represent the new email adres
          history.replace(`./${response.data.email}`);
          setInitialEmail(response.data.email);
        })
        .catch(() => {
          setDidSaveFailure(true);
        })
        .finally(() => {
          setIsSaving(false);
        });

      // setOriginalEmail(email);
    } else {
      setIsSaving(false);
      setDidSaveSuccess(false);
    }
  };

  const setFirstName = (newValue: string) => {
    setUser((oldObject) => ({ ...oldObject, firstName: newValue }));
  };
  const setLastName = (newValue: string) => {
    setUser((oldObject) => ({ ...oldObject, lastName: newValue }));
  };
  const setEmail = (newValue: string) => {
    setUser((oldObject) => ({ ...oldObject, email: newValue.trim() }));
  };

  const handleCancelNewPassword = () => {
    setNewPassword("");
    setNewPasswordConfirmation("");
    setShouldUpdatePassword(false);
  };

  const deleteUser = () => {
    const performDelete = window.confirm(
      t("user.areYouSureYouWantToDeleteThisUser")
    );

    if (performDelete) {
      feedFactoryAxios
        .delete(`/accounts/${account.id}/users/${initialEmail}`)
        .then((response) => {
          returnToUsers();
        })
        .catch((error) => {
          alert(t("root.couldNotDeleteUser"));
        });
    }
  };

  const toggleRole = (role: string) => {
    const roles = user.roles || [];

    if (roles.indexOf(role) === -1) {
      let newRoles = roles;

      // @note, due to immutability .push will not cause a rerender
      newRoles = newRoles.concat(role);

      setUser((oldValue) => ({ ...oldValue, roles: newRoles }));
    } else {
      let newRoles = roles.filter((_role) => _role !== role);

      setUser((oldValue) => ({ ...oldValue, roles: newRoles }));
    }
  };

  interface TypedRole {
    label: string;
    active: boolean;
  }

  useEffect(() => {
    setDidSaveSuccess(false);
    setDidSaveFailure(false);
  }, [user]);

  return (
    <>
      <header className={"settings-header"}>
        {!props.isMe && (
          <div className={"settings-eyebrow"}>
            {!inOwnMenu && (
              <>
                <span className={"settings-breadcrumb-label"}>
                  {t("root.backTo")}:{" "}
                </span>
                <TextButton action={returnToAccounts}>
                  {t("root.accounts")}
                </TextButton>
                <MdChevronRight style={{ verticalAlign: "middle" }} />
                <TextButton action={returnToAccount}>
                  {t("root.account")}
                </TextButton>{" "}
                <MdChevronRight style={{ verticalAlign: "middle" }} />
                <TextButton action={returnToUsers}>
                  {t("root.users")}
                </TextButton>
              </>
            )}
            {inOwnMenu && (
              <TextButton action={returnToUsers}>
                <>
                  <MdChevronLeft /> {t("root.backToUsers")}
                </>
              </TextButton>
            )}
          </div>
        )}
        <h2 className={"settings-title"}>
          {props.isMe ? t("root.myprofile") : t("root.user")}
        </h2>
        {!props.isMe && (
          <PopupButton
            className={"settings-header-button"}
            action={deleteUser}
            size={ButtonSize.Small}
            type={ButtonType.Failure}
          >
            <>
              <BsTrash /> {t("root.deleteUser")}
            </>
          </PopupButton>
        )}
      </header>
      {error && error}
      {!error && !isLoading && (
        <div className={"settings-group"}>
          <div className={"settings-hstack settings-row"}>
            <SettingsField
              label={t("root.firstName")}
              setValue={setFirstName}
              value={user.firstName || ""}
            />
            <SettingsField
              label={t("root.lastName")}
              setValue={setLastName}
              value={user.lastName || ""}
            />
          </div>
          <div className={"settings-row"}>
            {isAdmin && (
              <SettingsField
                label={t("root.email")}
                setValue={setEmail}
                value={user.email || ""}
              />
            )}
            {!isAdmin && (
              <>
                <SettingsLabel>{t("root.email")}</SettingsLabel>
                {user.email}
              </>
            )}
          </div>
          <div className={"settings-row"}>
            {shouldUpdatePassword && (
              <>
                <SettingsLabel>{t("root.password")}</SettingsLabel>
                <div className={"settings-block"}>
                  <SettingsField
                    label={t("root.newPassword")}
                    value={newPassword}
                    setValue={setNewPassword}
                    type={"password"}
                  />
                  <SettingsField
                    label={t("root.repeatNewPassword")}
                    value={newPasswordConfirmation}
                    setValue={setNewPasswordConfirmation}
                    type={"password"}
                  />
                  {newPasswordMismatch && (
                    <>
                      <span>{t("root.passwordsDoNotMatch")}</span>
                      <br />
                    </>
                  )}
                  <TextButton action={handleCancelNewPassword}>
                    {t("root.cancel")}
                  </TextButton>
                </div>
              </>
            )}
            {!shouldUpdatePassword && (
              <>
                <SettingsLabel>{t("root.password")}</SettingsLabel>
                <TextButton action={() => setShouldUpdatePassword(true)}>
                  {t("root.changePassword")}
                </TextButton>
              </>
            )}
          </div>
          <div className={"settings-row"}>
            <SettingsLabel>{t("root.language")}</SettingsLabel>
            <div className={"settings-language-select"}>{user.language}</div>
          </div>
          <div className={"settings-row"}>
            <SettingsLabel>{t("root.role")}</SettingsLabel>
            {isAdmin && (
              <>
                {availableRoles.map((role) => (
                  <span
                    className={`settings-role clickable ${
                      user.roles.indexOf(role) === -1
                        ? "settings-role-inactive"
                        : ""
                    }`}
                    onClick={() => toggleRole(role)}
                    key={role}
                  >
                    {role}
                  </span>
                ))}
              </>
            )}
            {!isAdmin && (
              <>
                {(user.roles || []).map((role) => (
                  <span className={"settings-role"}>{role}</span>
                ))}
              </>
            )}
          </div>
          {/*<div className={"settings-row"}>*/}
          {/*  <SettingsLabel>Organisatie</SettingsLabel>*/}
          {/*  {account.name || "Onbekend"}*/}
          {/*</div>*/}
          <div className={"settings-row settings-hstack"}>
            <div>
              <SettingsLabel>{t("root.account")}</SettingsLabel>
              {account.name || t("root.unknown")}
            </div>
            {!props.isMe && (
              <div>
                <SettingsLabel>{t("root.lastActive")}</SettingsLabel>
                {user.lastSeen
                  ? moment(user.lastSeen).fromNow()
                  : t("root.noActivityKnown")}
              </div>
            )}
          </div>
          <NavigationButton action={handleSaveClick}>
            {t("root.saveChanges")}
          </NavigationButton>
          {isSaving && `${t("root.saving")}...`}
          {didSaveSuccess && t("root.changesSaved")}
          {/* @todo perhaps we should have a popup here */}
          {didSaveFailure && t("root.couldNotSaveChanges")}
        </div>
      )}
    </>
  );
};

export { GeneralUser };

export default GeneralUser;
