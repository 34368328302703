import React, { useEffect, useState } from "react";
import { feedFactoryAxios } from "helpers";
import { EntityType } from "../../../models/Ndtrc/Ndtrc";
import { useTranslation } from "react-i18next";
import { format, formatDistanceToNowStrict } from "date-fns";
import classes from "./RevisionsWidget.module.scss";
import { usePopper } from "react-popper";
import popoverStyles from "../../popovers/PopoverSharedStyle.module.scss";
import nl from "date-fns/locale/nl";
import { registerLocale } from "react-datepicker";
import Loader from "../../Loader/Loader";

export interface Revision {
  _rev: string;
  author: string;
  created: string;
}

const RevisionsWidget = (props: {
  id: string | null;
  type: EntityType;
  onClick: (rev: string, isCurrent: boolean) => any;
  children: any;
  currentRev: string | null;
}) => {
  const [revs, setRevs] = useState<Revision[]>([]);
  const { t } = useTranslation();

  const [hasInit, setHasInit] = useState(false);

  const [popover, setPopover] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(popover, popperElement, {
    placement: "bottom",
  });
  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  const onOpenPopover = () => {
    if (update) {
      void update();
    }
    setPopoverActive(!popoverActive);
  };

  useEffect(() => {
    if (!popoverActive) {
      return;
    }
    if (!props.id) {
      console.error("No id received by revisions widget");
      return;
    }
    const urlStem =
      props.type === "EVENEMENT"
        ? "events"
        : props.type === "LOCATIE"
        ? "locations"
        : props.type === "ROUTE"
        ? "routes"
        : "eventgroups";
    const url = `/${t(urlStem)}/${props.id}/revisions`;
    feedFactoryAxios.get(url).then((res) => {
      const revs = res.data.reverse();
      // console.log(res.data); // DEBUG
      setRevs(revs);
      setHasInit(true);
    });
  }, [props.id, props.type, t, popoverActive]);

  registerLocale("nl", nl);

  const fallback = !hasInit ? (
    <div className={"ff-loader-centered"}>
      <Loader />
    </div>
  ) : (
    <p>{t("form.noRevisions")}</p>
  );

  return (
    <>
      {props.children(setPopover, onOpenPopover)}
      <div
        className={[
          popoverStyles.popover,
          classes.popover,
          popoverActive ? popoverStyles.active : "",
        ].join(" ")}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className={classes.RevisionsWidget}>
          {revs && revs.length > 1 ? (
            <ol>
              {revs.map((rev, index) => {
                return (
                  <li
                    className={
                      rev._rev === props.currentRev ||
                      (!props.currentRev && index === 0)
                        ? classes.currentRev
                        : ""
                    }
                    key={`${rev._rev}_${rev.created}`}
                    onClick={() => {
                      props.onClick(rev._rev, index === 0);
                    }}
                    title={format(
                      new Date(rev.created),
                      "dd-MM-yyyy, hh:mm:ss"
                    )}
                  >
                    <div className={classes.date}>
                      {formatDistanceToNowStrict(new Date(rev.created), {
                        locale: nl,
                      })}{" "}
                      {t("root.ago")}
                    </div>
                    {index === 0 && <div>({t("form.latestVersion")})</div>}
                    <div className={classes.author}>{rev.author}</div>
                  </li>
                );
              })}
            </ol>
          ) : (
            fallback
          )}
        </div>
      </div>
      <div
        className={`${popoverStyles.backdrop} ${
          popoverActive ? popoverStyles.active : ""
        } ${classes.backdrop}`}
        onClick={() => {
          setPopover(null);
          setPopoverActive(false);
        }}
      />
    </>
  );
};

export default RevisionsWidget;
