export const en = {
  "login.login": "Inloggen",
  "login.submit": "Inloggen",
  "login.usernameOrEmailLabel": "E-mailadres",
  "login.passwordLabel": "Wachtwoord",
  "login.usernameOrEmailRequired": "E-mailadres is vereist",
  "login.passwordRequired": "Wachtwoord is vereist",
  "login.usernameOrEmailOrPasswordInvalid":
    "De ingevoerde gegevens zijn onjuist.",
  "login.passwordInvalid": "De ingevoerde gegevens zijn onjuist.",
  "login.selectOrganisation": "Selecteer je organisatie",
  "login.switchOrganisation": "Inloggen voor andere organisatie",
  "login.whatIsTheFeedFactory": "Wat is The Feed Factory?",
  "login.eventConnectors": "Event Connectors",
  "login.passwordReset": "Wachtwoord vergeten",
  "login.passwordReset.slug": "wachtwoord-vergeten",
  "login.passwordResetButton": "Verstuur herstellink",
  "login.passwordResetSuccess":
    "Je ontvangt binnen enkele minuten een e-mail met herstellink.",
  "login.submitNewPassword": "Nieuw wachtwoord instellen",
  "login.passwordSetFail":
    "De gebruikte resetlink werkt (niet) meer. Vraag een nieuwe link aan.",
  "login.passwordResetNudge":
    "Je hebt succesvol een nieuw wachtwoord ingesteld. Log nu opnieuw in.",
  "login.emailUnknown": "Het opgegeven e-mailadres is niet bekend.",
  "root.dashboard": "Statistieken",
  "root.dashboard.slug": "dashboard",
  "root.inbox": "Inbox",
  "root.inbox.slug": "inbox",
  "root.events": "Evenementen",
  "root.events.slug": "evenementen",
  "root.feeds": "Bronnen",
  "root.locations": "Locaties",
  "root.locations.slug": "locaties",
  "root.users": "Gebruikers",
  "root.users.slug": "gebruikers",
  "root.new": "nieuw",
  "root.nextItem": "volgende item",
  "root.backToDashboard": "terug naar dashboard",
  "root.stopQueue": "Stop redactie workflow",
  "root.settings": "Instellingen",
  "root.settings.slug": "instellingen",
  "root.account": "Organisatie",
  "root.account.slug": "organisatie",
  "root.accounts": "Organisaties",
  "root.accounts.slug": "organisaties",
  "root.myaccount": "Mijn Organisatie",
  "root.myaccount.slug": "mijn-organisatie",
  "root.myprofile": "Mijn Profiel",
  "root.myprofile.slug": "mijn-profiel",
  "root.logOut": "Uitloggen",
  "root.support": "Support",
  "root.help": "Helpcentrum",
  "root.save": "Opslaan",
  "root.yes": "Ja",
  "root.no": "Nee",
  "root.loggingIntoZendesk": "Aan het inloggen bij Zendesk...",
  "root.makeNewEvent": "Nieuw evenement aanmaken",
  "root.makeNewLocation": "Nieuwe locatie aanmaken",
  "root.allEventsChecked": "Alle evenementen zijn gecontroleerd",
  "root.allLocationsChecked": "Alle locaties zijn gecontroleerd",
  "root.visitLocationsMainPage": "Klik hier om locaties bij te werken",
  "root.visitEventsMainPage": "Klik hier om evenementen bij te werken",
  "root.userOrganisation": "User organisation",
  "root.userorganisation": "Beheerdersorganisatie",
  "root.noResults": "No results for this search",
  "root.copyLocationTemplate": "Copy information from registered location",
  "root.locationItem": "Registered location",
  "root.selectLocationItem": "Select a location",
  "root.unlinkLocationItem": "Unlink the selected location",
  "root.noLocationItemSelected": "No location currently selected",
  "locationSelect.promptMinChar": "Type at least {{ charCount }} letters...",
  "locationSelect.noOptions": "No locations found for '{{ inputString }}'",
  "locationSelect.loading": "Loading...",
  "root.personal": "Persoonlijke",
  "root.itemDoesNotExist":
    "Het opgevraagde item bestaat niet of is niet toegankelijk voor je",
  "root.locationMapper": "Locatie-mapper",
  "root.locationmapper.slug": "locatie-mapper",
  "locationMapper.edit": "Bewerken",
  "locationMapper.block": "Blokkeren",
  "locationMapper.unblock": "Toelaten",
  "locationMapper.noLink": "Geen link",
  "locationMapper.onlyShowUnlinkedItems": "Alleen ongelinkte items",
  "locationMapper.noMappingsAvailable": "Geen mappings beschikbaar",
  "form.copyLocationTemplatePrompt":
    "This overwrites the current address information - do you want to continue? (Website links remain untouched.)",
  "form.unlinkLocationItemPrompt":
    "Are you sure you want to unlink this location? (The currently filled-in address information will not be removed.)",
  "event.noDescriptionAvailable": "No description available",
  "event.noTitleAvailable": "No title available",
  "location.noNameAvailable": "No name available",
  "event.what": "What",
  "event.where": "Where",
  "event.when": "When",
  "event.who": "Wie (contactinformatie)",
  "event.instructions": "Instructions",
  "event.title": "Title",
  "event.shortdescription": "Short description",
  "event.longdescription": "Long description",
  "event.location.label": "Naam locatie",
  "event.locationName": "Location name",
  "event.locationType": "Location type",
  "event.capacity": "Capacity",
  "event.whoWhatWhereWhyHow": "Who, what, where, why and how?",
  "event.online": "Online",
  "event.hybrid": "Hybrid",
  "event.physical": "Physical",
  "event.eventContactInfo": "Contact information event",
  "event.applicantContactInfo": "Contact information applicant",
  "event.contactinfo": "Contactinformatie",
  "event.contactinfo.label": "Organisatie of persoon",
  "event.extra": "Extra's",
  "event.keywords": "Tags",
  "event.markers": "Markeringen",
  "event.routeMarkers": "Hier zichtbaar",
  "event.categories": "Categories",
  "event.categories.events": "Events",
  "event.categories.music": "Music",
  "event.categories.theater": "Stage",
  "event.categories.exhibitions": "Exhibitions",
  "event.categories.festivals": "Festivals",
  "event.categories.film": "Film",
  "event.categories.sport": "Sport",
  "event.type.fair": "Fair",
  "event.type.braderie": "Braderie",
  "event.type.festival": "Festival",
  "event.type.circus": "Circus",
  "event.type.culinary": "Culinary",
  "event.type.culturalFestival": "Cultural Festival ",
  "event.type.filmFestival": "Film Festival",
  "event.type.youthFestival": "Youth Festival",
  "event.type.danceFestival": "Dancefestival",
  "event.type.funFair": "Funfair",
  "event.type.lectureDebate": "Lecture / Debate",
  "event.type.musicFestival": "Music Festival",
  "event.type.openDay": "Open Day",
  "event.type.sportsEvent": "Sports Event",
  "event.type.theatreFestival": "Theatre Festival",
  "event.type.workshop": "Workshop",
  "event.type.danceMusic": "Dance Music",
  "event.type.jazzWorldMusic": "Jazz or World Music",
  "event.type.jazz": "Jazz",
  "event.type.worldMusic": "World Music",
  "event.type.classicalMusic": "Classical Music",
  "event.type.musicOther": "Music other",
  "event.type.popRock": "Pop & Rock",
  "event.type.RBSoul": "R&B & Soul",
  "event.type.comedy": "Comedy",
  "event.type.dance": "Dance",
  "event.type.youthTheatre": "Youth theatre",
  "event.type.musical": "Musical",
  "event.type.excursion": "Excursion",
  "event.type.film": "Film",
  "event.type.market": "Market",
  "event.type.guidedTour": "Guided Tour",
  "event.type.opera": "Opera",
  "event.type.theatre": "Theatre",
  "event.type.theatreOther": "Theatre other",
  "event.type.nightlife": "Nightlife",
  "event.category": "Category",
  "event.submitEventToRemote": "Submit",
  "event.submitEventToRemoteAndPublish": "Publiceren",
  "event.remoteSaveSuccess": "Opgeslagen",
  "event.availablefrom": "Embargodatum",
  "event.availableto": "Vervaldatum",
  "event.availabletoAutomatic": "Vervalt automatisch",
  "event.availableFromTo": "Publicatie",
  "event.calendar.type.singleDates": "Specific dates",
  "event.calendar.type.patternDates": "Period(s)",
  "event.calendar.type.none": "No information",
  "event.calendar.type.alwaysOpen": "Always open",
  "event.calendar.type.onRequest": "On request",
  "event.calendar.type.openingTimes": "Opening times",
  "event.calendar.patternDates.startdate": "Start date",
  "event.calendar.patternDates.enddate": "End date",
  "event.noExpiryDate": "Niet automatisch laten vervallen",
  "event.noExpiryDateTooltip":
    "Standaard vervallen evenementen de dag na de laatst opgegeven datum. Deze worden dan automatisch gearchiveerd. Als deze checkbox aanstaat blijft het evenement op actief staan.",
  "event.manualExpiryDate": "Of kies handmatig",
  "calendar.excludeholidays": "Closed on sundays and holidays",
  "calendar.cancelled": "Cancelled",
  "calendar.soldout": "Sold out",
  "calendar.onrequest": "On request",
  "calendar.alwaysopen": "Always open",
  "calendar.general": "General",
  "calendar.comment": "Additional explanation (free text)",
  "priceWidget.deleteAll": "Verwijder alle tickets",
  "item.priceElements.free": "Free?",
  "item.priceElements.freeEvent": "This is a free event",
  "item.priceElements.priceValue.from": "From",
  "item.priceElements.priceValue.until": "To",
  "item.priceElements.priceValue.description": "Omschrijving",
  "item.priceElements.priceValue.comments": "Toelichting",
  "item.priceElements": "Tickets",
  "item.priceElements.priceValue.fromError": "Invalid price in 'from' field",
  "item.priceElements.priceValue.untilError":
    "Invalid price in 'to' field (or lower price than in 'from' field)",
  "item.prices": "Prices",
  "item.legalowner": "E-mailadres van partner met bewerkingsrechten",
  "item.properties": "Eigenschappen",
  "item.userorganisation": "Account",
  "item.createdby": "Aangemaakt door",
  "item.owner": "Eigenaar",
  "item.lastupdated": "Laatst aangepast",
  "item.externalid": "Externe ID",
  "item.trcid": "TRC ID",
  "item.lastupdatedby": "Laatst aangepast door",
  "item.trcitemRelations": "Kamers",
  "address.street": "Street name",
  "address.housenr": "House number",
  "address.zipcode": "Postal code",
  "address.city": "Place",
  "address.country": "Country",
  "address.neighbourhood": "Neighbourhood",
  "address.district": "District",
  "form.characters": "Karakters",
  "form.phoneNumber": "Phone number",
  "form.translateAll": "Vertaal alles",
  "form.contactInfo.urls": "Websites",
  "form.promotions": "Promotions",
  "form.removeLanguage": "Deze taal verwijderen?",
  "form.cannotDeleteLastLanguage":
    "Je hebt minstens één taal nodig op dit item.",
  "form.isSaving": "Aan het opslaan...",
  "form.published": "Gepubliceerd",
  "form.restoreDeletedItem": "Herstel verwijderd / afgekeurd item",
  "form.deleteItem": "Verwijderen",
  "form.archiveItem": "Archiveren",
  "form.unarchiveItem": "Dearchiveren",
  "form.retractItem": "Zet item offline",
  "form.retractItemPrompt":
    "Weet je dit zeker? Het item wordt hierdoor gearchiveerd. Deze kan daarna opnieuw ingediend worden voor herpublicatie.",
  "form.assign": "Toewijzen",
  "form.assigned": "Toegewezen",
  "form.toAssignEmail": "E-mail adres van volgende bewerker",
  "form.removePropertyPrompt":
    "Weet je zeker dat je deze categorie wil verwijderen?",
  "form.addPropertyCategory": "Voeg eigenschappencategorie toe",
  "form.addPropertyCategoryPrompt": "Voeg categorie toe...",
  "form.coordinates": "Coördinaten",
  "form.noSupportMultipleCoords":
    "Dit item bevat meer dan één set coördinaten. Dit wordt nog niet ondersteund.",
  "form.revisions": "Vorige versies",
  "form.noRevisions": "Geen vorige versies beschikbaar",
  "form.restore": "Herstel deze versie",
  "form.lookingAtRev":
    "Je kijkt naar een eerdere versie. Herstel deze om verder te bewerken.",
  "form.seeEditAllProperties": "Zie en bewerk (alle) eigenschappen",
  "form.removeRoomPrompt": "Weet je zeker dat je deze kamer wil verwijderen?",
  "form.addRoom": "Voeg kamer toe",
  "form.addRoomPrompt": "Wat is de naam van de kamer?",
  "form.changeRoomNamePrompt": "Wat is de nieuwe naam van de kamer?",
  "form.previewNotAvailable": "Preview niet beschikbaar",
  "form.previewNotAvailableNoProps": "deze kamer heeft geen eigenschappen",
  "form.previewNotAvailableNoVFProps":
    "deze kamer heeft geen Venue Finder eigenschappen",
  "form.hideEditAllProperties": "Verberg eigenschappen",
  "form.noPropsToShow": "Dit item bevat geen eigenschappen",
  "form.suggestAddProps": "voeg toe met bovenstaande knop",
  "form.categoryRequired": "Category is required",
  "form.nameRequired": "Name is required",
  "form.nameInvalid": "Name is invalid",
  "form.phoneRequired": "Phone number is required",
  "form.phoneInvalid": "Phone number is invalid",
  "form.typeRequired": "Type is required",
  "form.eventTitleRequired": "Title of event is required",
  "form.websiteRequired": "Website is required",
  "form.ticketLinkRequired": "Ticket link is required",
  "form.shortDescriptionRequired": "Short description is required",
  "form.shortDescriptionMaxLength":
    "Short description must be less than {{maxLength}} characters",
  "form.longDescriptionRequired": "Long description is required",
  "form.locationTypeRequired": "Location type is required",
  "form.calendarRequired": "At least one date and time is required",
  "form.emailRequired": "E-mail address is required",
  "form.emailInvalid": "Provided e-mailaddress is invalid",
  "form.onlyCopyrightFreeImages": "Only copyright-free images",
  "form.imageRequired": "Select at least one image",
  "form.copyrightInfoRequired": "Every image requires copyright information",
  "form.languageNoProblem": "Language no problem",
  "form.languageNoProblem.label": "Suitable for non-Dutch speakers",
  "form.addEnglishShortDescription":
    "I want to add an English short description",
  "form.addEnglishLongDescription": "I want to add an English long description",
  "form.eventDescriptionPlaceholder":
    "More information about event, background artist or line-up (between 75 and 100 words).",
  "form.hybridEventInfo":
    "For online participation, please add the event website in the Website section",
  "form.onlineEventInfo": "Please add the event website in the Website section",
  "form.eventTypeInfo": "Please select the type of event first",
  "form.permissionRequired": "Permission is required",
  "form.manuallyEnterAddress": "Enter location yourself",
  "form.selectRegisteredLocation": "Select an existing location",
  "form.saveForNextEvent": "Yes, save data for the next event",
  "calendarWidget.date": "Date",
  "calendarWidget.timestart": "Start time",
  "calendarWidget.timeend": "End time",
  "calendarWidget.dateRequiredError": "Date is required",
  "calendarWidget.timestartRequiredError": "Start time is required",
  "calendarWidget.timeendRequiredError": "Eindtijd is vereist",
  "calendarWidget.deleteAll": "Delete all dates",
  "calendarWidget.status": "Status",
  "calendarWidget.time": "Start/end time",
  "calendarWidget.timeAndStatus": "Start/end time and status",
  "calendarWidget.weekDay": "weekday",
  "calendarWidget.exceptionHeader": "Exceptions",
  "calendarWidget.exceptionOpens": "Exceptionally open on...",
  "calendarWidget.exceptionCloseds": "Exceptionally closed on...",
  "calendarWidget.exceptionSoldouts": "Exceptionally sold out on...",
  "calendarWidget.exceptionCancelleds": "Exceptionally cancelled on...",
  "account.areYouSureYouWantToDeleteThisAccount":
    "Weet je zeker dat je deze organisatie wilt verwijderen?",
  "user.areYouSureYouWantToDeleteThisUser":
    "Weet je zeker dat je deze gebruiker wilt verwijderen?",
  "account.couldNotDeleteAccount": "Kon organisatie niet verwijderen",
  "user.couldNotDeleteUser": "Kon gebruiker niet verwijderen",
  "account.newAccount": "Organisatie aanmaken",
  "promotion.availablefrom": "From",
  "promotion.availableto": "To",
  "promotion.availablefromto": "Validity",
  "promotion.promotion": "Promotion",
  "promotion.type": "Product information (optional)",
  "promotion.shortDescription": "Short description",
  "promotion.website": "Website(s)",
  "promotion.amount": "Amount",
  "promotion.percentage": "Percentage",
  "promotion.enabled": "Promotion published",
  "promotion.product": "Product",
  "promotion.promotionProducts": "Promotion products",
  "promotion.weekdays": "Weekdays",
  "promotion.allDays": "all",
  "promotion.nonStandardProduct": "Non-standard product",
  "root.back": "Terug",
  "root.backTo": "Terug naar",
  "root.backToAccounts": "Terug naar organisaties",
  "root.backToAccount": "Terug naar organisatie",
  "root.deleteAccount": "Verwijder organisatie",
  "root.accountSuccessfullyAdded": "Organisatie succesvol toegevoegd",
  "root.id": "Id",
  "root.name": "Naam",
  "root.filter": "Filter",
  "root.filter-userorganisation": "Userorganisation",
  "root.filter-extendedmarker": "Extendedmarker",
  "root.filter-markers": "Markers",
  "root.filter-optimizedImage": "Using optimized image",
  "root.filter-geo-distance-latlon": "LatLon",
  "root.filter-geo-distance": "Lat Lon distance",
  "root.filter-transformer": "Transformer",
  "root.filter-wfstatus": "Status",
  "root.createdOn": "Aangemaakt op",
  "root.logoUrl": "logo url",
  "root.unknown": "Niet bekend",
  "root.newAccount": "Nieuwe Organisatie",
  "root.createAccount": "Organisatie aanmaken",
  "root.createAnotherAccount": "Nog een organisatie aanmaken",
  "root.lastChange": "Laatste wijziging",
  "root.lastActive": "Laatst actief",
  "root.noActivityKnown": "Geen activiteit bekend",
  "root.saveChanges": "Bewaar wijzigingen",
  "root.saving": "Opslaan",
  "root.changesSaved": "Wijzigingen opgeslagen",
  "root.couldNotSaveChanges": "Kon wijzigingen niet opslaan",
  "root.toUsers": "Naar gebruikers",
  "root.backToUsers": "Terug naar gebruikers",
  "root.userSuccessFullyAdded": "Gebruiker succesvol toegevoegd",
  "root.on": "Aan",
  "root.email": "Email",
  "root.temporaryPassword": "Tijdelijke wachtwoord",
  "root.password": "Wachtwoord",
  "root.newPassword": "Nieuwe wachtwoord",
  "root.repeatNewPassword": "Herhaal nieuwe wachtwoord",
  "root.passwordsDoNotMatch": "Wachtwoorden komen niet overeen",
  "root.changePassword": "Wijzig wachtwoord",
  "root.createAnotherUser": "Nog een gebruiker aanmaken",
  "root.newUser": "Nieuwe gebruiker",
  "root.createUser": "Gebruiker aanmaken",
  "root.role": "Role",
  "root.user": "Gebruiker",
  "root.deleteUser": "Verwijder gebruiker",
  "root.firstName": "Voornaam",
  "root.lastName": "Achternaam",
  "root.cancel": "Annuleer",
  "root.language": "Taal",
  "root.thisAccountDoesNotContainAnyUsers":
    "Er zijn nog geen gebruikers gekoppeld aan dit account",
  "root.noAccountsAvailable": "Er zijn nog geen accounts aangemaakt",
  "root.couldNotCreateAccount": "Kon organisatie niet aanmaken",
  "root.couldNotCreateUser": "Kon gebruiker niet aanmaken",
  "root.couldNotRetrieveUser": "Kon gebruiker niet ophalen",
  "root.invalidEmail": "Incorrect email-adres",
  "root.lastupdated": "Laatst aangepast",
  "root.creationdate": "Aanmaakdatum",
  "root.pageDoesNotExist": "Pagina bestaat niet",
  "root.all": "alle",
  "root.next": "volgende",
  "root.check": "Controleer",
  "root.items": "items",
  "root.editorialFlow.slug": "redactie-flow",
  "root.togo": "te gaan",
  "root.eventsAtThisLocation": "Active events at this location",
  "root.loadingLastEvents": "Laatst toegevoegde evenementen aan het inladen...",
  "root.noEventsAvailable": "Geen evenementen op deze locatie ingepland",
  "root.ago": "geleden",
  "root.by": "door",
  "root.newComment": "Nieuwe notitie...",
  "root.comment": "notitie",
  "root.comments": "notities",
  "root.commentDeletePrompt": "Bevestig verwijdering",
  "root.editingExistingComment": "Geselecteerde notitie bewerken...",
  "tokens.apiToken": "Account tokens (API)",
  "tokens.accessCount": "Aantal keer gebruikt",
  "tokens.lastSeen": "Laatst gebruikt",
  "tokens.noName": "Geen naam/toelichting",
  "tokens.created": "Aangemaakt op",
  "tokens.generateNew": "Nieuw token aanmaken",
  "tokens.left": "over",
  "tokens.newTokenName": "Naam/toelichting voor nieuw token",
  "tokens.roles": "Rollen",
  "tune.filter": "Filter",
  "tune.sort": "Sorteren",
  "tune.sortedBySearch": "gesorteerd op zoekterm",
  "tune.inbox": "Inbox",
  "tune.addCurrent": "Voeg huidige instellingen als snelfilter toe",
  "tune.export": "Exporteer items naar Excel",
  "tune.presets": "Snelle filters",
  "tune.typeFilterName": "Kies naam voor snelfilter",
  "tune.removePresetFilterPrompt":
    "Weet je zeker dat je deze snelfilter wil verwijderen?",
  "tune.reset": "Filters uit",
  "form.filter": "Filteropties",
  "form.filterOn": "Filter aan",
  "richText.bold": "Dikgedrukt",
  "richText.italic": "Cursief",
  "richText.underline": "Onderstreept",
  "richText.p": "Gewone tekst (P)",
  "richText.h1": "Grote koptekst (H1)",
  "richText.h2": "Middelgrote koptekst (H2)",
  "richText.h3": "Kleine koptekst (H3)",
  "richText.ul": "Lijst",
  "richText.ol": "Genummerde Lijst",
  "widget.addMedia": "Add media",
  "media.pasteYoutubeUrl": "Paste YouTube URL",
  "media.pasteVimeoUrl": "Paste Vimeo URL",
  "root.pasteUrl": "Paste URL",
  "form.filename": "Filename",
  "form.filesize": "Size",
  "form.filedimensions": "Dimensions",
  "form.unknownFileSize": "Unknown",
  "form.title": "Description",
  "form.location.address": "Adres",
  "form.selectLanguageHint": "Select the primary language above",
  "form.copyright": "Copyright",
  "form.unknownMediaType": "Unknown media format",
  "form.noMediaAvailable": "No media available",
  "form.uploadImage": "Drag images here or click to select files",
  "form.uploadImageTypes": "(only *.jpeg and *.png, <10mb)",
  "form.uploadImageJpgType": "(only *.jpeg, <10mb)",
  "form.onlyJpg": "Only JPG",
  "form.imageNotAvailable": "Image not available (anymore)",
  "form.uploading": "Uploading...",
  "form.unsupportedFileType":
    "File type is not supported or file is larger than 10mb.",
  "form.incorrectImageResolution": "File resolution is incorrect.",
  "form.incorrectImageOrientation": "File orientation is incorrect.",
  "form.urlIncorrect": "Link incorrect",
  "form.chooseUrlTypePrompt": "Choose url type...",
  "form.urlAlreadyListed": "Url staat al in lijst; pas deze daar aan",
  "form.incorrectUrl": "Incorrecte url",
  "form.image": "Upload image(s)",
  "form.imageLibrary": "Pick from image library",
  "form.imageLibraryTitle": "Image library",
  "form.imageLibraryDesc": "Click on the image you want to add",
  "form.youtube": "YouTube",
  "form.vimeo": "Vimeo",
  "form.delete": "Delete",
  "form.select": "Select",
  "form.createPrefix": "Maak",
  "form.createSuffix": "aan",
  "form.latestVersion": "laatste versie",
  "form.noUrlLang": "No language selected for URL",
  "form.eventLinks": "Events in this group",
  "form.addTranslation": "Add language...",
  "urlServiceType.general": "General",
  "urlServiceType.booking": "Reservation / tickets",
  "urlServiceType.review": "Review(s)",
  "urlServiceType.video": "Video",
  "urlServiceType.webshop": "Webshop",
  "urlServiceType.dmo": "DMO",
  "urlServiceType.socialmedia": "Social media",
  "urlServiceType.lastminute": "Last-minute",
  "urlServiceType.virtualtour": "Virtual tour",
  "wfStatus.draft": "Concept",
  "wfStatus.readyforvalidation": "Klaar voor validatie",
  "wfStatus.approved": "Goedgekeurd",
  "wfStatus.rejected": "Afgekeurd",
  "wfStatus.deleted": "Verwijderd",
  "wfStatus.archived": "Gearchiveerd",
  "entityType.EVENEMENT": "evenement",
  "entityType.LOCATIE": "locatie",
  "tuning.search": "Zoeken",
  "tuning.published": "Gepubliceerd",
  "tuning.deleted": "Verwijderd",
  "tuning.updatedSince": "Gewijzigd sinds",
  "tuning.owner": "Eigenaar",
  "tuning.validator": "Validator",
  "tuning.wfstatus": "Status",
  "tuning.comment": "Notitie",
  "tuning.applyFilter": "Filter toepassen",
  "tuning.alluserorganisations": "Zoek ook buiten eigen organisatie",
  "tuning.feedId": "Feed",
  "tuning.eventDateRangeStart": "Evenementdata vanaf",
  "tuning.eventDateRangeEnd": "Evenementdata t/m",
  "tuning.webformMarker": "Toon alleen webformulier items",
  "export.inProgress": "Export aan het uitvoeren...",
  "export.failed": "Export mislukt",
  "event.shortdescription.desc":
    "Gebruik platte tekst en geen HTML codes. Maak hier een of enkele korte levendige zinnen met leestekens om de lezer te activeren. Liefst max. 600 karakters.",
  "event.longdescription.desc":
    "Voeg platte tekst toe en gebruik dan de opmaakknoppen in de tool. Plak niet uit Microsoft Word/Outlook om verkeerde opmaak te voorkomen, dit kan wel uit kladblok/notepad. Zet hier een aanvulling op de Korte beschrijving en voeg links achter woorden in de tekst toe i.p.v. URL's. Liefst max. 15000 karakters.",
  "event.categories.desc":
    "De categorie is het soort item. Voeg een of enkele categorieën toe die het meest passend zijn.",
  "form.location.address.desc":
    "Voer alle velden zo volledig mogelijk in. Kies eventueel bij postcode voor de dichtstbijzijnde postcode van parkeerplaats/kruispunt/VVV (bij parken, bossen of festival).",
  "event.availableFromTo.desc":
    "Embargodatum is vanaf wanneer het item getoond mag worden. Vervaldatum is tot wanneer het item getoond mag worden.",
  "form.phoneNumber.desc":
    "Correcte invoer is: +31 20 1234567 (géén 0 tussen haakjes plaatsen) of 020-123 45 67.",
  "event.markers.desc":
    "Met een markering wordt een item aan een thema of groep gekoppeld (bijv. Koningsdag, Holland Festival). Op de site kun je het item onder deze selectie markeringen terugvinden. Druk op ENTER na elk markeringswoord.",
  "event.keywords.desc":
    "Dit zijn trefwoorden waarmee je op de site het item gemakkelijker kan terugvinden. Bijv. strand, zee, zon, water, zwemmen. Druk op ENTER na elk trefwoord.",
  "admin.rootElementSelection.desc":
    "A comma separated list of xpath expressions, allowed values are: 'calendar, contactinfo, trcitemcategories, performers, media, price, trcitemdetails, keywords, markers, languages, location, organiser, reservations, userorganisation, priceinformation'",
  "item.legalowner.desc":
    "Het partneraccount met onderstaand e-mailadres heeft bewerkrechten op deze locatie en alle bijbehorende evenementen.",
  "selection.delete": "Delete selected items",
  "selection.confirmDeletePrompt":
    "Weet je zeker dat je de geselecteerde items wilt verwijderen?",
  "selection.approve": "Selectie goedkeuren",
  "selection.approvePrompt":
    "Weet je zeker dat je de geselecteerde items wilt goedkeuren?",
};
