import React from "react";
import classes from "./RoomsWidget.module.scss";
import {
  ExtTitleLangString,
  TrcitemRelation,
  TrcitemRelationSubItem,
} from "../../../models/Ndtrc/Ndtrc";
import { Lang } from "models/Lang/Lang";
import PropertiesWidget from "../PropertiesWidget/PropertiesWidget";
import RoomPreview from "./RoomPreview/RoomPreview";
import _ from "lodash";
import BooleanInputPopover from "../../popovers/BooleanInputPopover/BooleanInputPopover";
import { AiFillDelete } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import PopupButton from "../../buttons/PopupButton/PopupButton";
import { v4 as uuidv4 } from "uuid";
import TextInputPopover from "../../popovers/TextInputPopover/TextInputPopover";
import { RiAddBoxFill } from "react-icons/ri";
import { BiRename } from "react-icons/bi";
import { ButtonType } from "../../buttons";

const generateRoomLangStrings = (roomName: string): ExtTitleLangString[] => {
  const langs = [Lang.DE, Lang.EN, Lang.FR, Lang.NL, Lang.IT, Lang.ES];
  return langs.map((lang) => {
    return {
      lang: lang,
      title: roomName,
    };
  });
};

const generateNewRoom = (roomName: string): TrcitemRelationSubItem => ({
  trcid: uuidv4(),
  type: {
    catid: "5.2", // is conference room
    isDefault: null,
  },
  categories: [],
  media: null,
  subItemTranslations: generateRoomLangStrings(roomName),
});

const RoomsWidget = (props: {
  rooms: TrcitemRelation | null;
  manualSave: (formData: FormData | any) => any;
  lang: Lang;
}) => {
  const { t } = useTranslation();

  if (!props.rooms) {
    return null;
  }

  const removeRoom = (roomId: string) => {
    const newRooms = _.cloneDeep(props.rooms?.subItemGroups)?.filter(
      (room) => room.trcid !== roomId
    );
    props.manualSave({
      ndtrcEntry: {
        trcitemRelation: { subItemGroups: newRooms },
      },
    });
  };

  const addRoom = (roomName: string) => {
    const newRooms = _.cloneDeep(props.rooms?.subItemGroups);
    if (!newRooms) {
      console.error("Empty room object found");
      return;
    }
    newRooms.push(generateNewRoom(roomName));
    props.manualSave({
      ndtrcEntry: {
        trcitemRelation: { subItemGroups: newRooms },
      },
    });
  };

  const changeRoomName = (roomId: string, newRoomName: string) => {
    const newRooms = _.cloneDeep(props.rooms?.subItemGroups);
    if (!newRooms) {
      console.error("Empty room object found");
      return;
    }
    newRooms.map((room) => {
      if (room.trcid !== roomId) {
        return;
      }
      room.subItemTranslations = generateRoomLangStrings(newRoomName);
    });
    props.manualSave({
      ndtrcEntry: {
        trcitemRelation: { subItemGroups: newRooms },
      },
    });
  };

  const rooms = props.rooms.subItemGroups.map((subItem, index) => {
    const itemName = subItem.subItemTranslations.find(
      (item) => item.lang === props.lang
    )?.title;

    return (
      <div className={classes.roomContainer}>
        <h3>
          {itemName}{" "}
          <TextInputPopover
            onTextSubmit={(text: string) => {
              if (text) changeRoomName(subItem.trcid, text);
            }}
            placeholder={t("form.changeRoomNamePrompt")}
          >
            {(setPopover: any, onButtonClickHandler: any) => (
              <span ref={setPopover}>
                <BiRename
                  className={`${classes.icon} ${classes.nameChange}`}
                  onClick={onButtonClickHandler}
                />
              </span>
            )}
          </TextInputPopover>
          <BooleanInputPopover
            onBooleanSubmit={(deletion) => {
              if (deletion) removeRoom(subItem.trcid);
            }}
            prompt={t("form.removeRoomPrompt")}
            type={"danger"}
          >
            {(setPopover: any, onButtonClickHandler: any) => (
              <span ref={setPopover}>
                <AiFillDelete
                  className={`${classes.icon} ${classes.danger}`}
                  onClick={onButtonClickHandler}
                />
              </span>
            )}
          </BooleanInputPopover>
        </h3>
        <RoomPreview properties={subItem.categories} />
        <PropertiesWidget
          properties={subItem.categories || []}
          manualSave={(newProperties) => {
            const subItemGroupsClone = _.cloneDeep(props.rooms?.subItemGroups);
            if (!subItemGroupsClone) {
              return;
            }
            subItemGroupsClone[index].categories = newProperties;

            props.manualSave({
              ndtrcEntry: {
                trcitemRelation: { subItemGroups: subItemGroupsClone },
              },
            });
          }}
          lang={props.lang}
        />
      </div>
    );
  });

  return (
    <div>
      <div>{rooms}</div>
      <div className={classes.addRoomWrapper}>
        <TextInputPopover
          onTextSubmit={(text: string) => {
            if (text) addRoom(text);
          }}
          placeholder={t("form.addRoomPrompt")}
        >
          {(setPopover: any, onButtonClickHandler: any) => (
            <PopupButton
              myRef={setPopover}
              action={onButtonClickHandler}
              type={ButtonType.Success}
            >
              <RiAddBoxFill /> {t("form.addRoom")}
            </PopupButton>
          )}
        </TextInputPopover>
      </div>
    </div>
  );
};

export default RoomsWidget;
