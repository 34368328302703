import React, { useState } from "react";
import classes from "./PromotionPopover.module.scss";
import { usePopper } from "react-popper";
import popoverStyles from "./PopoverSharedStyle.module.scss";
import {
  EntityType,
  ExtPromotion,
} from "models/Ndtrc";
import { useTranslation } from "react-i18next";
import ObjectSelectWidget, {
  ObjectType,
} from "components/FormWidgets/ObjectSelectWidget";
import { IoMdAddCircle } from "react-icons/io";
import { CreationTypeAllowed } from "../PromotionsWidget";

const PromotionPopover = (props: {
  children: any;
  onSubmit: (promotion: ExtPromotion | null) => void;
  placeholder?: string;
  entityType: EntityType;
  creationAllowed?: CreationTypeAllowed;
}) => {
  const { t } = useTranslation();

  const [popover, setPopover] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(popover, popperElement, {});
  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  const onSubmit = (promotion: ExtPromotion | null) => {
    props.onSubmit(promotion);
    setPopoverActive(false);
  };

  const onButtonClickHandler = () => {
    if (update) {
      void update();
    }
    setPopoverActive(true);
  };

  return (
    <>
      {props.children(setPopover, onButtonClickHandler)}
      <div
        className={[
          popoverStyles.popover,
          classes.popover,
          popoverActive ? popoverStyles.active : "",
        ].join(" ")}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {(props.creationAllowed === "templateOnly" ||
          props.creationAllowed === "both") && (
          <div className={classes.langSelect}>
            <ObjectSelectWidget
              value={null}
              onChange={(val) => {
                onSubmit(val.data);
              }}
              type={
                props.entityType === "EVENEMENT"
                  ? ObjectType.EventPromotionProduct
                  : ObjectType.LocationPromotionProduct
              }
            />
          </div>
        )}
        {(props.creationAllowed === "newOnly" ||
          props.creationAllowed === "both") && (
          <div
            onClick={() => {
              onSubmit(null);
            }}
            className={classes.add}
          >
            <IoMdAddCircle /> {t("promotion.nonStandardProduct")}
          </div>
        )}
      </div>
      <div
        className={`${popoverStyles.backdrop} ${
          popoverActive ? popoverStyles.active : ""
        }`}
        onClick={() => {
          setPopover(null);
          setPopoverActive(false);
        }}
      />
    </>
  );
};

export default PromotionPopover;
