import { ItemSpecificPermissions } from "../../../models/ItemSpecificPermissions/ItemSpecificPermissions";
import { feedFactoryAxios } from "helpers";
import { ExternalItemModel, InternalItemModel } from "../../../models/Ndtrc";
import { PermissionObject } from "../../../models/PermissionObject";

export const generateBanners = async (
  intItem: InternalItemModel,
  myPermissions: PermissionObject,
  t: any, // translation function i18n
) => {
  const newCallouts: ItemSpecificPermissions[] = [];
  // Based on Markers
  if (
    intItem.markers?.includes("vergunning:toegekend") ||
    intItem.markers?.includes("abckalender") // DEPRECATED, keep for backwards compatibility
  ) {
    if (myPermissions.mayOverrideMarkerBasedPermissions) {
      newCallouts.push({
        id: "permitWarning",
        type: "warning",
        title: t("callout.permit"),
        description: t("callout.permit.desc.override"),
        annotatedSections: ["where", "when"],
        blockedSections: [],
      });
    } else {
      newCallouts.push({
        id: "permitWarning",
        type: "info",
        title: t("callout.permit"),
        description: t("callout.permit.desc"),
        annotatedSections: ["where", "when"],
        blockedSections: ["where", "when"],
      });
    }
  }

  // Based on copied condition
  const parentLink = intItem.links?.find((link) => link.type === "parent");
  if (parentLink) {
    newCallouts.push({
      id: "copiedItemBlock",
      type: "warning",
      title: t("callout.copiedItemBlock"),
      description: t("callout.copiedItemBlock.desc", {
        parentUrl: parentLink.id,
      }),
      annotatedSections: ["where", "when", "prices"],
      blockedSections: ["where", "when", "prices"],
    });
    newCallouts.push({
      id: "copiedItemWarning",
      type: "info",
      title: t("callout.copiedItemWarning"),
      description: t("callout.copiedItemWarning.desc", {
        parentUrl: parentLink.id,
      }),
      annotatedSections: ["what"],
      blockedSections: [],
    });
  }

  const childLinks = intItem.links
    ?.filter((link) => link.type === "child")
    .map((link) => link.id);

  if (childLinks && childLinks.length > 0) {
    // Fetch data for child items
    const urlStem = intItem.entitytype === "EVENEMENT" ? "events" : "locations";
    const urlAttribute =
      intItem.entitytype === "EVENEMENT" ? "eventIDs" : "locationIDs";
    const itemDetailsListMd = await feedFactoryAxios
      .get(`/${urlStem}?${urlAttribute}=${childLinks.join(";")}&size=10000`)
      .then(async (response) => {
        interface itemDetails {
          id: string;
          userOrganisation: string;
        }
        const itemDetails: itemDetails[] = response.data.results.map(
          (item: ExternalItemModel) => {
            return {
              id: item.id,
              userOrganisation: item.userorganisation,
            };
          },
        );
        return itemDetails.map((i) => {
          return `[${i.userOrganisation}](/${t("root.events.slug")}/${i.id})`;
        });
      });
    newCallouts.push({
      id: "childItemsInfo",
      type: "info",
      title: t("callout.childItems"),
      description: `${t("callout.childItems.desc")}: ${itemDetailsListMd.join(
        ", ",
      )}`,
      annotatedSections: ["what"],
      blockedSections: [],
    });
  }

  return newCallouts;
};
