import React, { useState } from 'react';
import './FloatingLabelInput.scss';
import { set } from 'lodash';

interface FloatingLabelInputProps {
	label?: string;
	type?: string;
	name?: string;
	placeholder?: string;
	value?: string;
	checked?: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const FloatingLabelInput = ({ label, ...props }: FloatingLabelInputProps) => {
  const [isFocused, setIsFocused] = useState(Boolean(props.value));
  const handleFocus = () => setIsFocused(true);
	const handleBlur = () => {
		if (props.value) {
			setIsFocused(true);
		} else {
			setIsFocused(false);
		}
	};
	return props.type === 'checkbox' ? (
		<label className="floating-label-checkbox">
			<input
				{...props}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onChange={props.onChange}
			/>
			{label}
		</label>
	) : (
		<div className={`floating-label-input ${isFocused ? 'focused' : ''}`}>
			<input
				name={props.name}
				id={props.name}
				{...props}
				onFocus={handleFocus}
				onBlur={handleBlur}
			/>
			<label htmlFor={props.name}>{label}</label>
		</div>
	);
};

export default FloatingLabelInput;