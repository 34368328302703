import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import {
  CalendarType,
  IntPatternDate as PatternDate,
} from "models/Ndtrc";
import PatternDateWidget from "./PatternDateWidget/PatternDateWidget";
import classes from "./PatternDatesWidget.module.scss";
import { IoMdAddCircle } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";

const generateDefaultPattern = (): PatternDate => {
  return {
    startdate: null,
    enddate: null,
    recurrencyType: "weekly",
    opens: [],
    id: uuidv4(),
  };
};

const PatternDatesWidget = (props: {
  namespace: string;
  formMethods: any;
  calendarType: CalendarType;
  hideSecondWhen?: boolean;
}) => {
  const { t } = useTranslation();
  const control: any = props.formMethods.control; // required, as field array does not like input via props
  const { fields, append, prepend, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${props.namespace}`, // unique name for your Field Array
    keyName: "id", // default to "id", you can change the key name
  });

  useEffect(() => {
    if (!(fields && fields.length > 0)) {
      append(generateDefaultPattern());
    }
  }, []);

  if (props.calendarType === "OPENINGTIMES") {
    return (
      <PatternDateWidget
        formMethods={props.formMethods}
        namespace={`${props.namespace}[0]`}
        defaultValue={generateDefaultPattern()}
        onRemove={() => {
          // No action is performed
        }}
        calendarType={props.calendarType}
        hideSecondWhen={props.hideSecondWhen}
      />
    );
  }

  return fields ? (
    <div className={classes.PatternDatesWidget}>
      {
        // @ts-ignore
        fields.map((patternDate: PatternDate, index) => {
          return (
            <PatternDateWidget
              key={patternDate.id}
              formMethods={props.formMethods}
              namespace={`${props.namespace}[${index}]`}
              defaultValue={patternDate}
              onRemove={() => {
                remove(index);
                if (fields.length === 1) {
                  setTimeout(() => {
                    append(generateDefaultPattern());
                  }, 0);
                }
              }}
              calendarType={props.calendarType}
              hideSecondWhen={props.hideSecondWhen}
            />
          );
        })
      }
      <div
        onClick={() => {
          append(generateDefaultPattern());
        }}
        className={classes.add}
      >
        <IoMdAddCircle />
      </div>
    </div>
  ) : null;
};

export default PatternDatesWidget;
