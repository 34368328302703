export type LocalPermission = "readers" | "editors" | "managers";
export type RoleGlobal =
  | "global.superadmin"
  | "global.admin"
  | "global.editor"
  | "global.partner"
  | "global.user";
export type RoleLocal = "local.reader" | "local.editor" | "local.manager";
export type Role = RoleGlobal | RoleLocal;

export interface PermissionObject {
  accessType: Role; // This is only descriptive
  generalPermission: "read" | "write";
  mayPublish?: boolean;
  mayManageAccess?: LocalPermission[];
  mayModifyDictionaries?: boolean;
  mayRemoveTagsMarkers?: boolean;
  mayModifyPublishedItems?: boolean;
  mayEditAdvancedProperties?: boolean;
  mayEditLocationRooms?: boolean;
  mayOverrideMarkerBasedPermissions?: boolean; // E.g., items with municipal permits
  mayCreateNewLocations?: boolean;
  mayAccessDashboard?: boolean;
  mayAccessSettings?: boolean;
  mayAccessEventGroups?: boolean;
  mayAccessRoutes?: boolean;
}

export const globalEditorPermissions: PermissionObject = {
  accessType: "global.editor",
  generalPermission: "write",
  mayPublish: true,
  mayCreateNewLocations: true,
  mayManageAccess: ["editors"],
  mayModifyDictionaries: false,
  mayRemoveTagsMarkers: true,
  mayModifyPublishedItems: true,
  mayEditLocationRooms: true,
  mayEditAdvancedProperties: true,
  mayOverrideMarkerBasedPermissions: true,
  mayAccessEventGroups: true,
  mayAccessRoutes: true,
};

export const globalAdminPermissions: PermissionObject = {
  ...globalEditorPermissions,
  accessType: "global.admin",
  generalPermission: "write",
  mayManageAccess: ["editors"], // Currently only this field is used; might be expanded in the future
  mayModifyDictionaries: true,
  mayAccessDashboard: true,
  mayAccessSettings: true,
};

export const globalSuperAdminPermissions: PermissionObject = {
  ...globalAdminPermissions,
  accessType: "global.superadmin",
  mayManageAccess: ["readers", "editors", "managers"],
};

export const localReaderPermissions: PermissionObject = {
  accessType: "local.reader",
  generalPermission: "read",
};

export const localEditorPermissions: PermissionObject = {
  accessType: "local.editor",
  generalPermission: "write",
  mayModifyPublishedItems: true,
};

export const localManagerPermissions: PermissionObject = {
  accessType: "local.manager",
  generalPermission: "write",
  mayModifyPublishedItems: true,
  mayManageAccess: ["editors"],
};

export const getRoleSlug = (role: string | undefined) => {
  if (!role) {
    return "none";
  } else {
    return role.replace(new RegExp("\\.", "g"), "-");
  }
};
