import React, { MouseEvent } from "react";

import ButtonProps from "../ButtonProps";
import "../SharedButton.scss";
import "./TextButton.scss";

const TextButton = (props: ButtonProps) => {
  const handleClick = (event: MouseEvent) => {
    event.preventDefault();

    props.disabled !== true && props.action();
  };

  return (
    <button
      className={`ff-component-text-button ${
        props.disabled ? "ff-button-disabled" : ""
      } ${props.className || ""}`}
      onClick={handleClick}
      ref={props.myRef}
    >
      {props.children}
    </button>
  );
};

export default TextButton;
