import React, { ChangeEvent, useLayoutEffect, useRef } from "react";

import "./LoginField.scss";

interface LoginFieldProps<T> {
  label: string;
  type?: string;
  placeholder?: string;
  invalid?: boolean;
  invalidLabel?: string;
  value: T;
  setValue: (newValue: T) => void;
  autofocus?: boolean;
}

const LoginField = (props: LoginFieldProps<string>) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.setValue(event.target.value);
  };

  const fieldRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    if (props.autofocus === true && fieldRef.current !== null) {
      fieldRef.current.focus();
    }
  }, [props.autofocus]);

  return (
    <div
      className={`ff-component-login-field ${
        props.invalid ? "login-field-invalid" : ""
      }`}
    >
      <div className={"field-label"}>
        <span className={"field-label-headline"}>{props.label}</span>
        {props.invalid && (
          <span className={"field-label-invalid"}>{props.invalidLabel}</span>
        )}
      </div>
      <div className={"field-input-frame"}>
        <input
          ref={fieldRef}
          className={"field-input"}
          value={props.value}
          type={props.type || "text"}
          placeholder={props.placeholder || ""}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default LoginField;
