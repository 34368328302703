import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import "minireset.css";
import "./index.scss";
import Boot from "./Boot";
import { store } from "./store/store";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Transition, TransitionGroup } from "react-transition-group";

import "./translation/i18n";
import { BrowserRouter } from "react-router-dom";
import Loader from "components/Loader/Loader";
import {
  initializeAccount,
  selectThemeBackground,
  selectThemeColorScheme,
} from "./store/theme/themeSlice";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import GitInfo from "react-git-info/macro";

import PiwikPro from "@piwikpro/react-piwik-pro";

PiwikPro.initialize(
  "69e34e97-7a7d-4802-98dc-81f9d8ffb0af",
  "https://eventconnectors.containers.piwik.pro"
);

const gitInfo = GitInfo();

Sentry.init({
  dsn: "https://25d8df11fcf3412793b806a00e46b2b8@o783841.ingest.sentry.io/5798932",
  integrations: [new Integrations.BrowserTracing()],
  release: `ff-gui@${gitInfo.commit.hash}`,
  environment: `${gitInfo.branch}`,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const Wrapper = (props: any) => {
  const themeColourScheme = useSelector(selectThemeColorScheme);
  const themeBackground = useSelector(selectThemeBackground);

  const dispatch = useDispatch();

  const duration = 2500;
  //
  const defaultStyle = {
    transition: `opacity ${duration}ms ease`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  useEffect(() => {
    for (const [key, value] of Object.entries(themeColourScheme)) {
      document.documentElement.style.setProperty(key, value);
    }
  }, [themeColourScheme]);

  useEffect(() => {
    dispatch(initializeAccount());
  }, []);

  // Pass nodeRef to adhere to strict mode
  // See https://github.com/reactjs/react-transition-group/blob/1fd4a65ac45edd2aea3dec18eeb8b9c07c7eb93f/CHANGELOG.md#features
  const nodeRef = React.useRef(null);

  return (
    <div className={"app-window"}>
      <TransitionGroup>
        <Transition
          key={themeBackground.hlink}
          timeout={duration}
          nodeRef={nodeRef}
        >
          {(state) => (
            <figure
              ref={nodeRef}
              style={{
                ...defaultStyle,
                // @ts-ignore
                ...transitionStyles[state],
                backgroundImage: `url(${themeBackground.hlink})`,
              }}
              className={"app-background"}
            />
          )}
        </Transition>
      </TransitionGroup>
      {props.children}
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Wrapper
          children={
            <Suspense
              fallback={
                <div className={"app-loading"}>
                  <Loader />
                </div>
              }
            >
              <Boot />
            </Suspense>
          }
        />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
