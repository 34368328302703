import React, { useState } from "react";
import SingleDatesWidget from "components/FormWidgets/CalendarWidget/SingleDatesWidget";
import "./CalendarWidget.scss";
import PatternDatesWidget from "components/FormWidgets/CalendarWidget/PatternDatesWidget";
import {
  ButtonType,
  NavigationButton,
} from "components/buttons";
import { Controller } from "react-hook-form";
import classes from "./CalendarWidget.module.scss";
import { useTranslation } from "react-i18next";
import DateExceptionWidget from "components/FormWidgets/CalendarWidget/DateExceptionWidget";
import { HoverDesc } from "components/HoverDesc";
import { Lang } from "models/Lang";
import {
  CalendarType,
  IntCalendar,
  InternalTrcItemModel,
} from "models/Ndtrc";
import {
  cleanupCalendarModel,
  FormData,
} from "helpers";
import _ from "lodash";
import { LangInputWrapper } from "components/FormWidgets/inputs";
import { SingleSelectOption } from "models/SelectOption";

// https://stackoverflow.com/questions/6393943/convert-a-javascript-string-in-dot-notation-into-an-object-reference
const indexByDotString = (obj: any, idx: any, value?: any): any => {
  // TODO: Allows for using nested namespace strings (e.g., ndtrcEntry.calendar). Find alternative (array-based?) approach.
  if (typeof idx == "string")
    return indexByDotString(obj, idx.split("."), value);
  else if (idx.length === 1 && value !== undefined) return (obj[idx[0]] = value);
  else if (idx.length === 0) return obj;
  else return indexByDotString(obj[idx[0]], idx.slice(1), value);
};

const CalendarWidget = (props: {
  namespace: string;
  formMethods: any;
  hideCalendarTypes?: CalendarType[];
  hideExceptions?: boolean;
  hideGeneral?: boolean;
  endTimesRequired?: boolean;
  overrideSingleDateOptions?: SingleSelectOption[];
  localSave: (extraData?: FormData | any) => any;
  readonly intItem: InternalTrcItemModel;
  hideSecondWhenInPatternDate?: boolean;
}) => {
  const { t } = useTranslation();

  const [hasComment, setHasComment] = useState<boolean>(
    !!props.intItem.calendar?.comment,
  );

  const [unrenderUI, setUnrenderUI] = useState(false);

  const calendarTypeIsShown = (calendarType: CalendarType): boolean => {
    return (
      !props?.hideCalendarTypes ||
      !props.hideCalendarTypes.includes(calendarType)
    );
  };

  // Back-up mechanic is required to display desired behaviour (see issue ff-gui #250).
  // Every type of calendar pane is hereby made fully independent from other calendar panes.
  // Changes on, e.g., pattern dates do not interact with opening times.
  // Note that this is all local: on leaving the page, all panes but the remotely saved one are lost.
  const [backups, setBackups] = useState<Record<string, IntCalendar>>({}); // dictionary
  const makeBackup = () => {
    // Get current calendar item
    if (props.namespace.includes(".")) {
    }
    let calItem = _.cloneDeep(
      indexByDotString(props.formMethods.getValues(), props.namespace),
    );
    // console.log("BACKUP OF", calItem); // DEBUG
    if (!calItem) {
      return;
    }

    setBackups((prev) => {
      prev[calItem.calendarType as string] = cleanupCalendarModel(calItem);
      return prev;
    });
    // console.log("BACKUPS", backups); // DEBUG
  };
  const restoreBackup = (type: CalendarType) => {
    const backup = _.cloneDeep(backups[type as string]);
    // console.log("RESTORATION OF", type, JSON.stringify(backup)); // DEBUG

    const currentFormData = _.cloneDeep(props.formMethods.getValues());

    setUnrenderUI(true);
    if (backup) {
      indexByDotString(currentFormData, props.namespace, backup);

      props.formMethods.reset(currentFormData, true);
      // console.log(props.formMethods.getValues()); // DEBUG
    } else {
      // console.log("NO BACKUP AVAILABLE"); // DEBUG
      indexByDotString(currentFormData, props.namespace, {});
      indexByDotString(
        currentFormData,
        props.namespace + ".calendarType",
        type,
      );

      props.formMethods.reset(currentFormData, true);
    }

    // Unrendering UI required to avoid issues with Activation field when switching between
    // pattern dates and opening dates options. Otherwise, activation is copied over rather than unique
    // per pane.
    setTimeout(() => {
      setUnrenderUI(false);
    }, 0);
  };

  return (
    <div>
      <div>
        <Controller
          name={`${props.namespace}.calendarType`}
          control={props.formMethods.control}
          defaultValue=""
          render={({
            field: { onChange, onBlur, value: calendarType, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <>
              <div className={classes.dateTypePicker}>
                {calendarTypeIsShown("NONE") && (
                  <NavigationButton
                    type={
                      calendarType === "NONE"
                        ? ButtonType.Primary
                        : ButtonType.Gray3
                    }
                    action={() => {
                      makeBackup();
                      restoreBackup("NONE");
                    }}
                  >
                    {t("event.calendar.type.none")}
                  </NavigationButton>
                )}

                {calendarTypeIsShown("ALWAYSOPEN") && (
                  <NavigationButton
                    type={
                      calendarType === "ALWAYSOPEN"
                        ? ButtonType.Primary
                        : ButtonType.Gray3
                    }
                    action={() => {
                      makeBackup();
                      restoreBackup("ALWAYSOPEN");
                    }}
                  >
                    {t("event.calendar.type.alwaysOpen")}
                  </NavigationButton>
                )}

                {calendarTypeIsShown("ONREQUEST") && (
                  <NavigationButton
                    type={
                      calendarType === "ONREQUEST"
                        ? ButtonType.Primary
                        : ButtonType.Gray3
                    }
                    action={() => {
                      makeBackup();
                      restoreBackup("ONREQUEST");
                    }}
                  >
                    {t("event.calendar.type.onRequest")}
                  </NavigationButton>
                )}

                {calendarTypeIsShown("OPENINGTIMES") && (
                  <NavigationButton
                    type={
                      calendarType === "OPENINGTIMES"
                        ? ButtonType.Primary
                        : ButtonType.Gray3
                    }
                    action={() => {
                      makeBackup();
                      restoreBackup("OPENINGTIMES");
                    }}
                  >
                    {t("event.calendar.type.openingTimes")}
                  </NavigationButton>
                )}

                {calendarTypeIsShown("PATTERNDATES") && (
                  <NavigationButton
                    type={
                      calendarType === "PATTERNDATES"
                        ? ButtonType.Primary
                        : ButtonType.Gray3
                    }
                    action={() => {
                      makeBackup();
                      restoreBackup("PATTERNDATES");
                    }}
                  >
                    {t("event.calendar.type.patternDates")}
                  </NavigationButton>
                )}

                {calendarTypeIsShown("SINGLEDATES") && (
                  <NavigationButton
                    type={
                      calendarType === "SINGLEDATES"
                        ? ButtonType.Primary
                        : ButtonType.Gray3
                    }
                    action={() => {
                      makeBackup();
                      restoreBackup("SINGLEDATES");
                    }}
                  >
                    {t("event.calendar.type.singleDates")}
                  </NavigationButton>
                )}

                <HoverDesc description="calendar.dateTypePicker.desc" />
              </div>
              {!unrenderUI && (
                <>
                  {calendarType === "SINGLEDATES" && (
                    <SingleDatesWidget
                      formMethods={props.formMethods}
                      namespace={`${props.namespace}.singleDates`}
                      overrideSingleDateOptions={
                        props?.overrideSingleDateOptions
                      }
                      endTimesRequired={props.endTimesRequired}
                    />
                  )}
                  {(calendarType === "PATTERNDATES" ||
                    calendarType === "OPENINGTIMES") && (
                    <>
                      <PatternDatesWidget
                        formMethods={props.formMethods}
                        namespace={`${props.namespace}.patternDates`}
                        calendarType={calendarType}
                        hideSecondWhen={props.hideSecondWhenInPatternDate}
                      />
                    </>
                  )}
                  {(calendarType === "PATTERNDATES" ||
                    calendarType === "OPENINGTIMES" ||
                    calendarType === "ALWAYSOPEN") &&
                    !props.hideExceptions && (
                      <>
                        <h3>{t("calendarWidget.exceptionHeader")}</h3>
                        {calendarType !== "ALWAYSOPEN" && (
                          <>
                            <h4>{t("calendarWidget.exceptionOpens")}</h4>
                            <DateExceptionWidget
                              formMethods={props.formMethods}
                              namespace={`${props.namespace}.opens`}
                            />
                          </>
                        )}
                        <h4>{t("calendarWidget.exceptionCloseds")}</h4>
                        <DateExceptionWidget
                          formMethods={props.formMethods}
                          namespace={`${props.namespace}.closeds`}
                        />
                        <h4>{t("calendarWidget.exceptionSoldouts")}</h4>
                        <DateExceptionWidget
                          formMethods={props.formMethods}
                          namespace={`${props.namespace}.soldouts`}
                        />
                        <h4>{t("calendarWidget.exceptionCancelleds")}</h4>
                        <DateExceptionWidget
                          formMethods={props.formMethods}
                          namespace={`${props.namespace}.cancelleds`}
                        />
                      </>
                    )}
                  {!props.hideGeneral && (
                    <div className={classes.patternDatesMeta}>
                      <h3>
                        {t("calendar.general")}
                        <HoverDesc description="calendar.general.desc" />
                      </h3>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => {
                          return (
                            <label>
                              <input
                                type="checkbox"
                                checked={value}
                                onChange={() => {
                                  onChange(!value);
                                }}
                              />
                              {t("calendar.excludeholidays")}
                            </label>
                          );
                        }}
                        name={`${props.namespace}.excludeholidays`}
                        control={props.formMethods.control}
                        defaultValue={false}
                      />
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => {
                          return (
                            <label>
                              <input
                                type="checkbox"
                                checked={value}
                                onChange={() => {
                                  onChange(!value);
                                }}
                              />
                              {t("calendar.cancelled")}
                            </label>
                          );
                        }}
                        name={`${props.namespace}.cancelled`}
                        control={props.formMethods.control}
                        defaultValue={false}
                      />
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => {
                          return (
                            <label>
                              <input
                                type="checkbox"
                                checked={value}
                                onChange={() => {
                                  onChange(!value);
                                }}
                              />
                              {t("calendar.soldout")}
                            </label>
                          );
                        }}
                        name={`${props.namespace}.soldout`}
                        control={props.formMethods.control}
                        defaultValue={false}
                      />
                      <label>
                        <input
                          type="checkbox"
                          checked={hasComment}
                          onChange={() => {
                            setHasComment(!hasComment);
                          }}
                        />
                        {t("calendar.comment")}
                      </label>
                      {hasComment && (
                        <LangInputWrapper
                          lang={
                            props.intItem.translations?.primaryLanguage ||
                            Lang.NL
                          }
                          name={`${props.namespace}.comment.commentTranslations[0]`}
                          formMethods={props.formMethods}
                          defaultValue={
                            props.intItem.calendar?.comment
                              ?.commentTranslations?.[0] || null
                          }
                          alternativeTextField={"label"}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
};

export default CalendarWidget;
