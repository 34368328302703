import React from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { isValid } from "date-fns";
import { BiReset } from "react-icons/bi";

export const DateWidget = (props: {
  value: string | null;
  onChange: (value: Date | null) => any;
  hasError?: boolean;
}) => {
  const { t } = useTranslation();

  // Check if date is within range
  if (props.value && !isValid(new Date(props.value))) {
    return (
      <div className={"invalidDate"}>
        <p style={{ color: "red", fontWeight: "bold", userSelect: "text" }}>
          {t("calendarWidget.dateRangeError", { value: props.value })}
        </p>
        <button
          onClick={() => {
            props.onChange(null);
          }}
          style={{
            color: "white",
            background: "red",
            display: "flex",
            padding: "2px 9px",
            alignItems: "center",
          }}
        >
          <BiReset style={{ fontSize: "1em", marginRight: "2px" }} />{" "}
          {t("calendarWidget.dateRangeErrorButton")}
        </button>
      </div>
    );
  }
  return (
    <DatePicker
      selected={props.value ? new Date(props.value) : null}
      onChange={props.onChange}
      locale="nl"
      dateFormat="dd-MM-yyyy"
      className={"date " + (props.hasError && "fieldError")}
    />
  );
};

export default DateWidget;
