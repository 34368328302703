import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import classes from "./ItemForm.module.scss";
import { SingleSelectOption } from "models/SelectOption";
import { useTranslation } from "react-i18next";
import { Lang } from "models/Lang";
import { fetchLocationsByQuery } from "./fetchLocationsByQuery";
import _ from "lodash";
import {
  externalToInternal,
  feedFactoryAxios,
} from "helpers";
import { ExternalLocationItemModel } from "models/Ndtrc";

let locationLabelCache: SingleSelectOption[] = [];

const LocationMultiSelect = (props: {
  lang: Lang;
  filterOnUserOrganisations?: string[] | null;
  value: SingleSelectOption[];
  onChange: (locs: SingleSelectOption[]) => any;
}) => {
  const [value, setValue] = useState<SingleSelectOption[] | null>(null);
  const [cacheVersion, setCacheVersion] = useState<number>(0);
  const { t } = useTranslation();

  const propsToValue = async () => {
    const transformedValuePromise = props.value.map(async (val) => {
      const cacheVersion = locationLabelCache.find(
        (c) => c.value === val.value
      );
      if (cacheVersion) {
        return {
          value: val.value,
          label: cacheVersion?.label || val.value,
        };
      }

      const locItem: ExternalLocationItemModel = (
        await feedFactoryAxios.get(`/locations/${val.value}`)
      ).data;
      const intLocItem = externalToInternal(locItem);
      const label =
        (intLocItem.trcItemDetails &&
          intLocItem.trcItemDetails[props.lang] &&
          intLocItem.trcItemDetails[props.lang].title) ||
        intLocItem.trcItemDetails?.nl?.title ||
        intLocItem.trcItemDetails?.en?.title ||
        val.value;
      locationLabelCache.push({
        value: val.value,
        label: label,
      });
      return {
        value: val.value,
        label: label,
      };
    });
    setValue(await Promise.all(transformedValuePromise));
  };

  useEffect(() => {
    if (!props.value || props.value.length === 0) {
      setValue(null);
      return;
    }
    propsToValue();
  }, [props.value]);

  const updateLocations = async (inputValue: string) => {
    return fetchLocationsByQuery(
      inputValue,
      props.lang,
      props.filterOnUserOrganisations
    );
  };

  useEffect(() => {
    // When toggling search through all user organisations, we need to clear previously cached options
    setCacheVersion(cacheVersion + 1);
  }, [props.filterOnUserOrganisations]);

  return (
    // @ts-ignore
    <AsyncSelect
      cacheOptions={cacheVersion}
      defaultOptions
      isMulti
      value={value}
      loadOptions={updateLocations}
      className={classes.asyncSelect}
      onChange={(options) => {
        locationLabelCache = _.cloneDeep(options) as SingleSelectOption[];
        props.onChange(locationLabelCache);
      }}
      onInputChange={(inputValue) => {
        // Do nothing; but needed for async refresh
      }}
      placeholder={t("root.selectLocationItem")}
    />
  );
};

export default LocationMultiSelect;
