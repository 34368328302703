import React, { useEffect, useState } from "react";
import { feedFactoryAxios } from "helpers";
import { LocationMapModel } from "../../models/LocationMapModel/LocationMapModel";
import LocationMap from "./LocationMap";
import classes from "./LocationMapper.module.scss";
import Loader from "../../components/Loader/Loader";
import _ from "lodash";
import LocationMapEditor from "./LocationMapEditor";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import SearchWidget from "../../components/TuneWidget/SearchWidget";
import FilterWidget from "../../components/TuneWidget/FilterWidget";
import TuneWidget from "../../components/TuneWidget/TuneWidget";
import { Filter, FilterField } from "../../models/TuningProps/TuningProps";
import { Feed } from "../../models/Feed/Feed";
import { useDispatch, useSelector } from "react-redux";
import {
  performFetchFeeds,
  selectError,
  selectFeeds,
} from "../../store/feeds/feedsSlice";

export const locationMapperDefaultTuningProps: Filter[] = [
  {
    label: "tuning.feedId",
    translated: true,
    field: "feedId" as FilterField,
    type: "select",
    options: [],
  },
  {
    label: "locationMapper.onlyShowUnlinkedItems",
    translated: true,
    field: "blockedFilter" as FilterField,
    type: "boolean",
    value: false,
  },
];

const LocationMapper = (props: {}) => {
  const { t } = useTranslation();

  const feeds: Feed[] | null = useSelector(selectFeeds);
  const error = useSelector(selectError);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(performFetchFeeds());
  }, [dispatch]);

  const [locationMaps, setLocationMaps] = useState<LocationMapModel[]>([]);
  const [filteredLocationMaps, setFilteredLocationMaps] = useState<
    LocationMapModel[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const [querySearch, setQuerySearch] = useState("");
  const [queryFilter, setQueryFilter] = useState<Filter[] | null>(
    locationMapperDefaultTuningProps
  );

  const [selectedLocation, setSelectedLocation] =
    useState<LocationMapModel | null>(null);

  useEffect(() => {
    const feedOptions = feeds?.map((feed) => {
      return { value: feed.id, label: feed.name };
    });
    setQueryFilter((prevState) => {
      const filterIndex = prevState?.findIndex(
        (filter) => filter.field === ("feedId" as FilterField)
      );
      if (filterIndex === undefined) {
        return prevState;
      }
      const newState = _.cloneDeep(prevState);
      if (!newState) {
        return prevState;
      }

      const updatedFilter = newState[filterIndex];
      updatedFilter.options = feedOptions;
      newState[filterIndex] = updatedFilter;
      return newState;
    });
  }, [feeds]);

  useEffect(() => {
    const filteredLocationMaps = locationMaps
      // .slice(0, 100) // DEBUG
      .filter((map) => {
        // blockedFilter
        const blockedFilterOn = queryFilter?.find(
          (filter) =>
            filter.field === ("blockedFilter" as FilterField) && filter.value
        );
        if (blockedFilterOn) {
          return !map.trcId;
        } else {
          return true;
        }
      })
      .filter((map) => {
        if (!querySearch) {
          return true;
        }
        // Search
        return !!(
          map.ndtrcName?.toLowerCase().includes(querySearch.toLowerCase()) ||
          map.locationName?.toLowerCase().includes(querySearch.toLowerCase())
        );
      });
    setFilteredLocationMaps(filteredLocationMaps);
  }, [locationMaps, queryFilter, querySearch]);

  const [prevUrl, setPrevUrl] = useState<string | null>(null);
  useEffect(() => {
    const feedId = queryFilter?.find(
      (filter) => filter.field === ("feedId" as FilterField)
    )?.value;
    let url = "/locationmap" + (feedId ? `?feedId=${feedId}` : "");

    if (prevUrl === url) {
      return;
    }
    setPrevUrl(url as string);

    setIsLoading(true);
    feedFactoryAxios
      .get(url as string)
      .then((res) => {
        setLocationMaps(
          (res.data as LocationMapModel[]).sort((mapA, mapB) => {
            if (mapA.locationName > mapB.locationName) {
              return 1;
            } else {
              return -1;
            }
          })
        );
        setIsLoading(false);
      })
      .catch((res) => {
        setIsLoading(false);
      });
  }, [queryFilter]);

  const handleLocationMapUpdate = (
    newLocationMap: LocationMapModel,
    setSelectedLocationToNew?: boolean
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      // Update remotely
      feedFactoryAxios
        .put(`/locationmap/${newLocationMap.id}`, newLocationMap)
        .then((res) => {
          // Update locally
          const index = locationMaps.findIndex(
            (map) => map.id === newLocationMap.id
          );
          const newLocationMaps = _.cloneDeep(locationMaps);
          newLocationMaps[index] = newLocationMap;
          setLocationMaps(newLocationMaps);
          if (setSelectedLocationToNew) {
            setSelectedLocation(newLocationMap);
          }
          resolve();
        })
        .catch((res) => {
          reject();
        });
    });
  };

  return (
    <div className={classes.locationMapper}>
      {selectedLocation && (
        <div className={classes.editorSidePanel}>
          <IoClose
            onClick={() => {
              setSelectedLocation(null);
            }}
            className={classes.closeButton}
          />
          <LocationMapEditor
            locationMap={selectedLocation}
            onChange={(loc) => {
              handleLocationMapUpdate(loc, true);
            }}
          />
        </div>
      )}

      <h2>{t("root.locationMapper")}</h2>
      <TuneWidget>
        <SearchWidget value={querySearch} setValue={setQuerySearch} />
        <FilterWidget
          filters={queryFilter}
          setFilter={setQueryFilter}
          entityType={"LOCATIE"}
        />
      </TuneWidget>
      <div className={classes.locationsView}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {filteredLocationMaps.map((map) => (
              <LocationMap
                locationMap={map}
                key={map.id}
                onChange={(newLocation) => {
                  return handleLocationMapUpdate(newLocation);
                }}
                onSelfSelect={(myLocation) => {
                  setSelectedLocation(myLocation);
                }}
              />
            ))}
            {filteredLocationMaps.length < 1 && (
              <div className={classes.noItemsAvailable}>
                {t("locationMapper.noMappingsAvailable")}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LocationMapper;
