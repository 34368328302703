import {
  EntityType,
  WfStatusOptions,
} from "models/Ndtrc";
import { format } from "date-fns";

export enum SortOrder {
  Ascending = "ASC",
  Descending = "DESC",
}

export interface TuningProps {
  sortField: SortFieldOption | null;
  sortOrder: SortOrder | null;
  search: string | null;
  filters: Filter[] | null;
}

export type FilterField =
  | "published"
  | "deleted"
  | "lastupdated"
  | "owner"
  | "validator"
  | "userorganisation"
  | "wfstatus"
  | "eventDateRangeStart"
  | "eventDateRangeEnd"
  | "markers"
  | "webformMarker"
  | "types"
  | "keywords"
  | "locationId";

export type FilterValue = string | boolean;
export type FilterType =
  | "boolean"
  | "date"
  | "string"
  | "select"
  | "multiselect"
  | "types-multiselect"
  | "markers-multiselect"
  | "keywords-multiselect"
  | "location-multiselect";

export interface Filter {
  label: string;
  translated: boolean;
  field: FilterField;
  type: FilterType;
  options?: FilterSelectOption[];
  value?: FilterValue;
  entityTypeFilter?: EntityType;
  placeholder?: string;
}
export interface FilterChanges {
  label?: string;
  translated?: boolean;
  type?: FilterType;
  options?: FilterSelectOption[];
  value?: FilterValue;
}

export interface FilterSelectOption {
  label: string;
  value: string;
}

const today = new Date();

export const defaultFilterOptions: Filter[] = [
  {
    label: "tuning.wfstatus",
    translated: true,
    field: "wfstatus",
    type: "multiselect",
    options: WfStatusOptions,
  },
  {
    label: "tuning.locations",
    translated: true,
    field: "locationId",
    type: "location-multiselect",
    entityTypeFilter: "EVENEMENT",
  },
  {
    label: "tuning.markers",
    translated: true,
    field: "markers",
    type: "markers-multiselect",
  },
  {
    label: "tuning.keywords",
    translated: true,
    field: "keywords",
    type: "keywords-multiselect",
  },
  {
    label: "tuning.types",
    translated: true,
    field: "types",
    type: "types-multiselect",
  },
  {
    label: "tuning.eventDateRangeStart",
    translated: true,
    field: "eventDateRangeStart",
    type: "date",
    entityTypeFilter: "EVENEMENT",
    placeholder: format(today.setMonth(today.getMonth() - 13), "dd-MM-yyyy"),
  },
  {
    label: "tuning.eventDateRangeEnd",
    translated: true,
    field: "eventDateRangeEnd",
    type: "date",
    entityTypeFilter: "EVENEMENT",
  },
  {
    label: "tuning.updatedSince",
    translated: true,
    field: "lastupdated",
    type: "date",
  },
  {
    label: "tuning.alluserorganisations",
    translated: true,
    field: "userorganisation",
    type: "boolean",
  },
  {
    label: "tuning.validator",
    translated: true,
    field: "validator",
    type: "string",
  },
];

const inboxFilterOptions: Filter[] = [
  {
    label: "tuning.wfstatus",
    translated: true,
    field: "wfstatus",
    type: "multiselect",
    value: "readyforvalidation",
    options: WfStatusOptions,
  },
  {
    label: "tuning.updatedSince",
    translated: true,
    field: "lastupdated",
    type: "date",
    value: "2021-01-01",
  },
  {
    label: "tuning.alluserorganisations",
    translated: true,
    field: "userorganisation",
    type: "boolean",
    value: false,
  },
];
export const inboxTuningOptions: TuningProps = {
  sortField: "modified",
  sortOrder: SortOrder.Descending,
  search: "",
  filters: inboxFilterOptions,
};

export type SortFieldOption = "modified" | "created" | "eventStart";

export const defaultTuningOptions: TuningProps = {
  sortField: "modified",
  sortOrder: SortOrder.Descending,
  search: "",
  filters: defaultFilterOptions,
};

export const tuningPropsToQueryString = (tuningProps: TuningProps): string => {
  let queryString = "";
  if (!tuningProps.search && tuningProps.sortField) {
    queryString += `&sortField=${tuningProps.sortField}`;
  }
  if (!tuningProps.search && tuningProps.sortOrder) {
    queryString += `&sortOrder=${tuningProps.sortOrder}`;
  }
  if (tuningProps.search) {
    queryString += `&search=${encodeURIComponent(tuningProps.search)}`;
  }
  if (tuningProps.filters) {
    const filtersString = filtersObjectToQueryString(tuningProps.filters);
    queryString += filtersString ? `&${filtersString}` : "";
  }
  return queryString;
};

export const filtersObjectToQueryString = (filters: Filter[]) =>
  filters
    .filter((filter) => filter.value && filter.value !== "")
    .map((filter) => {
      // Custom behaviour for userorganisation: turn boolean into string
      if (filter.field === "userorganisation") {
        return filter.value ? `${filter.field}=*` : null;
      }
      return `${filter.field}=${encodeURIComponent(filter.value || "")}`;
    })
    .filter((filterString) => {
      return filterString && filterString !== "";
    })
    .join("&");
