import { Lang } from "models/Lang";
import { ExtLangString, Open } from "./Ndtrc";
import { v4 as uuidv4 } from "uuid";
import {
  ExtUrlDetails,
  extUrlsToIntUrls,
  IntUrlDetails,
  intUrlsToExtUrls,
} from "./Urls";

interface GenericPromotion {
  product: string | null;
  promotionType?: PromotionType | null;
  discount?: Discount;
  translations: PromotionTranslation[];
  startDate: string | null; // date
  endDate: string | null; // date
  enabled: boolean;
  opens: Open<ExtLangString[]>[];
}

export interface ExtPromotion extends GenericPromotion {
  detailsUrls?: ExtUrlDetails[];
}

export interface IntPromotion extends GenericPromotion {
  id: string;
  detailsUrls?: IntUrlDetails[];
}

export const intPromotionsToExtPromotions = (
  promotions: IntPromotion[]
): ExtPromotion[] => {
  // console.log("intPromotionsToExtPromotions", promotions); // DEBUG
  if (!promotions) return [];
  return promotions.map((promotion: IntPromotion) => {
    const promoClone: any = { ...promotion };
    delete promoClone.id;

    // If no promotion type set, remove discountType and discount information
    if (!promotion.promotionType) {
      delete promoClone.discount;
    } else if (
      promotion.promotionType === "free" ||
      promotion.promotionType === "gift"
    ) {
      if (promoClone.discount) {
        promoClone.discount.free = true;
        delete promoClone.discount.percentage;
        delete promoClone.discount.amount;
      }
    } else {
      if (promoClone.discount) {
        delete promoClone.discount.free;
      }
      if (promoClone.discount.percentage) {
        delete promoClone.discount.amount;
      } else {
        delete promoClone.discount.percentage;
      }
    }
    delete promoClone.discountType; // Is internal variable, not to be sent to back-end

    if (promotion.detailsUrls) {
      promoClone.urls = intUrlsToExtUrls(promotion.detailsUrls);
    }
    return promoClone;
  }) as ExtPromotion[];
};

export const extPromotionsToIntPromotions = (
  promotions: ExtPromotion[]
): IntPromotion[] => {
  // console.log("extPromotionsToIntPromotions", promotions); // DEBUG
  if (!promotions) return [];
  return promotions.map((promotion: ExtPromotion) => {
    const promoClone: any = { ...promotion };
    promoClone.id = uuidv4();

    if (promotion.detailsUrls) {
      promoClone.detailsUrls = extUrlsToIntUrls(promotion.detailsUrls);
    }

    return promoClone;
  }) as IntPromotion[];
};

export type PromotionType = "free" | "discount" | "gift" | "allowance";
export const PromotionTypeOptions: { value: PromotionType; label: string }[] = [
  { value: "free", label: "promotionType.free" },
  { value: "discount", label: "promotionType.discount" },
  { value: "gift", label: "promotionType.gift" },
  { value: "allowance", label: "promotionType.allowance" },
];

// Should contain one of three
export interface Discount {
  free?: boolean;
  percentage?: number; // integer
  amount?: number; // double
}
export interface PromotionTranslation {
  lang: Lang;
  description: string;
}

export type DiscountType = "amount" | "percentage" | "free";
