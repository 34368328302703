import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import themeReducer from "./theme/themeSlice";
import eventReducer from "./events/eventsSlice";
import feedsReducer from "./feeds/feedsSlice";
import eventGroupsReducer from "./eventGroups/eventGroupsSlice";
import locationsReducer from "./locations/locationsSlice";
import routesReducer from "./routes/routesSlice";
import itemsQueueReducer from "./itemsQueue/itemsQueue";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themeReducer,
    events: eventReducer,
    feeds: feedsReducer,
    locations: locationsReducer,
    routes: routesReducer,
    eventGroups: eventGroupsReducer,
    itemsQueue: itemsQueueReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
