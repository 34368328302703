import { useTranslation } from "react-i18next";

export const useNavTranslation = () => {
  const { t } = useTranslation();

  const SETTINGS = `/${t("root.settings.slug")}`;

  const ACCOUNTS = `${SETTINGS}/${t("root.accounts.slug")}`;
  const FEEDS = `${SETTINGS}/${t("root.feeds.slug")}`;
  const LOCATIONMAPPER = `${SETTINGS}/${t("root.locationmapper.slug")}`;
  const ACCOUNT = `${ACCOUNTS}/:accountId`;
  const NEWACCOUNT = `${ACCOUNTS}/${t("root.new")}`;

  const ACCOUNT_USERS = `${ACCOUNT}/${t("root.users.slug")}`;
  const ACCOUNT_USER = `${ACCOUNT_USERS}/:userId`;
  const ACCOUNT_NEWUSER = `${ACCOUNT_USERS}/${t("root.new")}`;

  const MY_ACCOUNT = `${SETTINGS}/${t("root.myaccount.slug")}`;
  const MY_ACCOUNT_USERS = `${MY_ACCOUNT}/${t("root.users.slug")}`;
  const MY_ACCOUNT_USER = `${MY_ACCOUNT_USERS}/:userId`;
  const MY_ACCOUNT_NEWUSER = `${MY_ACCOUNT_USERS}/${t("root.new")}`;
  const ACCOUNT_SELF = t("root.myaccount.slug");

  const getAccountUrl = (accountId: string = ACCOUNT_SELF) => {
    const baseUrl = accountId === ACCOUNT_SELF ? MY_ACCOUNT : ACCOUNT;
    return baseUrl.replace(":accountId", accountId);
  };
  const getAccountUsersUrl = (accountId: string = ACCOUNT_SELF) => {
    const baseUrl =
      accountId === ACCOUNT_SELF ? MY_ACCOUNT_USERS : ACCOUNT_USERS;

    return baseUrl.replace(":accountId", accountId);
  };
  const getAccountNewUserUrl = (accountId: string = ACCOUNT_SELF) => {
    const baseUrl =
      accountId === ACCOUNT_SELF ? MY_ACCOUNT_NEWUSER : ACCOUNT_NEWUSER;

    return baseUrl.replace(":accountId", accountId);
  };
  const getAccountUserUrl = (
    accountId: string = ACCOUNT_SELF,
    userId: string
  ) => {
    const baseUrl = accountId === ACCOUNT_SELF ? MY_ACCOUNT_USER : ACCOUNT_USER;

    return baseUrl.replace(":accountId", accountId).replace(":userId", userId);
  };

  return {
    accountsUrl: ACCOUNTS,
    accountUrl: ACCOUNT,
    newAccountUrl: NEWACCOUNT,
    accountUsersUrl: ACCOUNT_USERS,
    accountUserUrl: ACCOUNT_USER,
    accountNewUserUrl: ACCOUNT_NEWUSER,
    accountSelfSlug: ACCOUNT_SELF,
    myAccountUserUrl: MY_ACCOUNT_USER,
    feedsUrl: FEEDS,
    locationMapperUrl: LOCATIONMAPPER,

    getAccountUrl: getAccountUrl,
    getAccountUsersUrl: getAccountUsersUrl,
    getAccountUserUrl: getAccountUserUrl,
    getAccountNewUserUrl: getAccountNewUserUrl,
  };
};
