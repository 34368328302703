import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearQueue,
  removeItem,
  selectError,
  selectIsCollapsed,
  selectItems,
  selectLoading,
  toggleIsCollapsed,
} from "../../store/itemsQueue/itemsQueue";
import CardWidget from "../../components/CardWidget/CardWidget";
import { Lang } from "../../models/Lang";
import classes from "./ItemsQueue.module.scss";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { BiSkipNext } from "react-icons/bi";
import { FaFlagCheckered } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const ItemsQueue = (props: { activeItemId?: string | null }) => {
  const items = useSelector(selectItems);
  const isCollapsed = useSelector(selectIsCollapsed);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  if (!items) {
    return null;
  }

  const goToNextItem = () => {
    if (!props.activeItemId) {
      return;
    }
    if (items.results.length === 1) {
      dispatch(clearQueue());
      history.push(`/`);
      return;
    }

    let nextItemIndex =
      items.results.findIndex((item) => item.id === props.activeItemId) + 1;
    if (nextItemIndex > items.results.length - 1) {
      nextItemIndex = items.results.length - 2; // prev item
    }
    const nextItemId = items.results[nextItemIndex].id;
    const nextItemEntitytype = items.results[nextItemIndex].entitytype;
    dispatch(removeItem(props.activeItemId));
    const typeUrl =
      nextItemEntitytype === "EVENEMENT"
        ? "root.events.slug"
        : "root.locations.slug";
    history.push(`/${t(typeUrl)}/${nextItemId}`);
  };

  return (
    <div
      className={`${classes.ItemsQueue} ${
        isCollapsed ? classes.isCollapsed : ""
      }`}
    >
      <div
        className={classes.collapseButton}
        onClick={() => {
          dispatch(toggleIsCollapsed());
        }}
      >
        {!isCollapsed ? <BsArrowBarRight /> : <BsArrowBarLeft />}
      </div>
      <div className={classes.collapsedNextButton} onClick={goToNextItem}>
        {!isCollapsed ? null : <BiSkipNext />}
      </div>
      {items && (
        <div>
          <button
            disabled={!props.activeItemId}
            className={`${classes.nextItem} ${
              items.results.length === 1 ? classes.lastItem : ""
            }`}
            onClick={goToNextItem}
          >
            {items.results.length > 1 ? (
              <span>
                <BiSkipNext />{" "}
                <span>
                  {t("root.nextItem")} ({items.results.length} {t("root.togo")})
                </span>
              </span>
            ) : (
              <span>
                <FaFlagCheckered
                  style={{
                    fontSize: "20px",
                    paddingRight: "5px",
                  }}
                />{" "}
                <span>{t("root.backToDashboard")}</span>
              </span>
            )}
          </button>
          <div className={classes.stopQueueButton}>
            <span>
              <span
                onClick={() => {
                  dispatch(clearQueue());
                }}
              >
                {t("root.stopQueue")}
              </span>
            </span>
          </div>
          <CardWidget
            items={items.results}
            hits={items.hits}
            lang={Lang.NL}
            activeItemId={props.activeItemId}
          />
        </div>
      )}
    </div>
  );
};

export default ItemsQueue;
