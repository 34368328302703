import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import App from "App";
import Login from "containers/Login/Login";
import {
  performCredentialsTokenCheck,
  selectIsAuthenticating,
  selectIsBooting,
} from "./store/auth/authSlice";
import Loader from "components/Loader/Loader";

const Boot = (props: any) => {
  const isAuthenticating = useSelector(selectIsAuthenticating);
  const isBooting = useSelector(selectIsBooting);

  let dispatch = useDispatch();

  useEffect(() => {
    let initialToken = localStorage.getItem("user.jwt");
    let metaToken = localStorage.getItem("user.meta-jwt");

    dispatch(
      performCredentialsTokenCheck(initialToken, metaToken || undefined)
    );
  }, [dispatch]);

  return (
    <article className={"app-content"}>
      {isBooting ? (
        <div className={"app-loading"}>
          <Loader />
        </div>
      ) : (
        <>
          {isAuthenticating && <Login />}
          {!isAuthenticating && <App />}
        </>
      )}
    </article>
  );
};

export default Boot;
