import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, nl } from "./locales";

const defaultLng = "nl";
let lng = defaultLng;

i18n.use(initReactI18next).init({
  lng,
  debug: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
  resources: {
    en: {
      translation: en,
    },
    nl: {
      translation: nl,
    },
  },
});

export default i18n;
