import React from "react";
import AsyncSelect from "react-select/async";
import classes from "./EventSelect.module.scss";
import { SingleSelectOption } from "models/SelectOption";
import { useTranslation } from "react-i18next";
import { Lang } from "models/Lang";
import {
  externalToInternal,
  feedFactoryAxios,
} from "helpers";
import { InternalEventItemModel } from "models/Ndtrc";
import { fetchEventsByQuery } from "./fetchEventsByQuery";
import { StylesConfig } from "react-select";
import { HoverDesc } from "../HoverDesc";

class ColourOption {}
const colourStyles: StylesConfig<ColourOption, true> = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    let myBgColor;
    switch (data.wfstatus) {
      case "approved":
        myBgColor = "rgba(var(--FF-COLOR-SUCCESS-RGB), 0.3)";
        break;
      case "rejected":
      case "deleted":
        myBgColor = "rgba(var(--FF-COLOR-FAILURE-RGB), 0.3)";
        break;
      case "readyforvalidation":
      case "draft":
        myBgColor = "rgba(var(--FF-COLOR-WARNING-RGB), 0.3)";
        break;
      case "archived":
        myBgColor = "rgba(var(--FF-COLOR-BLACK-RGB), 0.3)";
        break;
      default:
        myBgColor = "white";
    }

    return {
      ...styles,
      backgroundColor: myBgColor,
      // color: data.type ? "white" : "inherit",
    };
  },
};

const EventSelect = (props: {
  lang: Lang;
  filterOnUserOrganisations?: string[];
  value: InternalEventItemModel | null;
  onChange: (event: InternalEventItemModel) => any;
  additionalParams?: { key: string; value: string }[];
  className?: string;
}) => {
  const { t } = useTranslation();

  const changeEvent = (option: SingleSelectOption) => {
    feedFactoryAxios.get(`/events/${option.value}`).then((res) => {
      const myEvent = externalToInternal(res.data) as InternalEventItemModel;
      props.onChange(myEvent);
    });
  };

  // TODO: Consider adding debounce
  const updateEvents = async (inputValue: string) => {
    return fetchEventsByQuery(
      inputValue,
      props.lang,
      props.filterOnUserOrganisations,
      props.additionalParams,
    );
  };

  return (
    <div className={`${classes.EventSelect} ${props.className || ""}`}>
      <div>
        <HoverDesc description={"root.eventGroupAddInfo"} />
      </div>
      <AsyncSelect
        value={props.value}
        cacheOptions
        defaultOptions={[]}
        styles={colourStyles}
        loadOptions={updateEvents}
        formatOptionLabel={(data: any) => {
          return data.richLabel;
        }}
        className={classes.asyncSelect}
        // @ts-ignore
        onChange={(option: SingleSelectOption) => {
          changeEvent(option);
        }}
        onInputChange={(inputValue) => {
          // Do nothing; but needed for async refresh
        }}
        placeholder={t("root.selectEventGroupItem")}
      />
    </div>
  );
};

export default EventSelect;
