import React from "react";
import AsyncSelect from "react-select/async";
import classes from "./ItemForm.module.scss";
import { SingleSelectOption } from "models/SelectOption";
import { useTranslation } from "react-i18next";
import { Lang } from "models/Lang";
import {
  externalToInternal,
  feedFactoryAxios,
} from "helpers";
import { InternalLocationItemModel } from "models/Ndtrc";
import { fetchLocationsByQuery } from "./fetchLocationsByQuery";
import { StylesConfig } from "react-select";
import { HoverDesc } from "components/HoverDesc";

class ColourOption {}
const colourStyles: StylesConfig<ColourOption, true> = {
  control: (styles: any) => ({ ...styles, backgroundColor: "white" }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: data.type ? "#f3e3e3" : undefined,
      // color: data.type ? "white" : "inherit",
    };
  },
};

const MIN_CHAR_COUNT = 3; // Minimum number of characters to start searching

const LocationSelect = (props: {
  lang: Lang;
  filterOnUserOrganisations?: string[];
  value: InternalLocationItemModel | null;
  onChange: (loc: InternalLocationItemModel) => any;
  additionalParams?: { key: string; value: string }[];
}) => {
  const { t } = useTranslation();

  const changeLocation = (option: SingleSelectOption) => {
    feedFactoryAxios.get(`/locations/${option.value}`).then((res) => {
      const myLocation = externalToInternal(
        res.data,
      ) as InternalLocationItemModel;
      props.onChange(myLocation);
    });
  };

  // TODO: Consider adding debounce
  const updateLocations = async (inputValue: string) => {
    if (inputValue.length < MIN_CHAR_COUNT) {
      return [];
    }

    return fetchLocationsByQuery(
      inputValue,
      props.lang,
      props.filterOnUserOrganisations,
      props.additionalParams,
    );
  };

  return (
    <div className={classes.LocationSelect}>
      <AsyncSelect
        cacheOptions
        defaultOptions
        styles={colourStyles}
        loadOptions={updateLocations}
        className={classes.asyncSelect}
        noOptionsMessage={(data) =>
          data.inputValue.length < MIN_CHAR_COUNT
            ? t("locationSelect.promptMinChar", { charCount: MIN_CHAR_COUNT })
            : t("locationSelect.noOptions", { inputString: data.inputValue })
        }
        loadingMessage={() => t("locationSelect.loading")}
        // @ts-ignore
        onChange={(option: SingleSelectOption) => {
          changeLocation(option);
        }}
        onInputChange={(inputValue) => {
          // Do nothing; but needed for async refresh
        }}
        placeholder={t("root.selectLocationItem")}
      />
      <HoverDesc description={`root.locationSelect.desc`} />
    </div>
  );
};

export default LocationSelect;
