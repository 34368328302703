import React from "react";
import classes from "./DuplicateModal.module.scss";
import { ButtonType, PopupButton } from "components/buttons";

interface DuplicateModalProps {
  handleClose: () => void;
  show: boolean;
  duplicates: any[];
  type: string;
}

const DuplicateModal = ({
  handleClose,
  show,
  duplicates,
  type,
}: DuplicateModalProps) => {
  const showHideClassName = show ? classes.displayBlock : classes.displayNone;

  return (
    <div className={`${classes.modal} ${showHideClassName} `}>
      <div className={`${classes.modalContainer}`}>
        <PopupButton
          action={handleClose}
          type={ButtonType.Failure}
          className={classes.closeButton}
        >
          Close
        </PopupButton>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Title</th>
              <th>Link to item</th>
              <th>Last updated by</th>
              <th>Created by</th>
            </tr>
          </thead>
          <tbody>
            {duplicates.map((duplicate: any, index: number) => (
              <DuplicateTrcItem key={index} data={duplicate} type={type} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const DuplicateTrcItem = ({ data, type }: any) => {
  return (
    <tr className={classes.duplicateTrcItem}>
      <td>
        {data.trcItemDetails.find((item: any) => item.lang === "nl").title}
      </td>
      {type === "EVENEMENT" ? (
        <td>
          <a href={`/evenementen/${data.id}`} target="__blank">
            Link
          </a>
        </td>
      ) : (
        <td>
          <a href={`/locaties/${data.id}`} target="__blank">
            Link
          </a>
        </td>
      )}
      <td>{data.lastupdatedby}</td>
      <td>{data.createdby}</td>
    </tr>
  );
};

export default DuplicateModal;
