import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DateWidget } from "./DateWidget";

export const DateWidgetWrapper = (props: {
  name: string;
  formMethods: any;
  defaultValue: string | null;
  hasError?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={props.name}
      control={props.formMethods.control}
      defaultValue={props.defaultValue}
      rules={{ required: true }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => {
        return (
          <DateWidget
            value={value}
            onChange={onChange}
            hasError={props.hasError}
          />
        );
      }}
    />
  );
};

export default DateWidgetWrapper;
