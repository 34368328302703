import React from "react";
import {
  EntityType,
  extPromotionsToIntPromotions,
  IntPromotion,
} from "models/Ndtrc";
import classes from "./PromotionsWidget.module.scss";
import { IoMdAddCircle } from "react-icons/io";
import PromotionWidget from "./PromotionWidget";
import { Lang } from "models/Lang";
import { Controller, useFieldArray } from "react-hook-form";
import { TiDelete } from "react-icons/ti";
import { v4 as uuidv4 } from "uuid";
import { PermissionObject } from "models/PermissionObject";
import PromotionPopover from "./PromotionPopover/PromotionPopover";
import { getPromotionState } from "helpers";

const generateEmptyPromotionItem = (): IntPromotion => ({
  id: uuidv4(),
  discount: undefined,
  product: "",
  promotionType: null,
  translations: [
    {
      lang: Lang.NL,
      description: "",
    },
    {
      lang: Lang.EN,
      description: "",
    },
  ],
  // detailsUrls: [],
  startDate: null,
  endDate: null,
  enabled: true,
  opens: [],
});

export type CreationTypeAllowed =
  | "templateOnly"
  | "newOnly"
  | "both"
  | "neither";

const PromotionsWidget = (props: {
  formMethods: any;
  namespace: string;
  lang: Lang;
  readonly myPermissions: PermissionObject;
  readonly entityType: EntityType;
  creationAllowed?: CreationTypeAllowed;
}) => {
  const control: any = props.formMethods.control; // required, as field array does not like input via props
  const { fields, append, prepend, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${props.namespace}`, // unique name for your Field Array
    keyName: "id", // default to "id", you can change the key name
  });

  return (
    <div className={`${classes.PromotionsWidget}`}>
      {
        // @ts-ignore // IntPromotion is right type
        fields.map((promoDetails: IntPromotion, index) => {
          if (promoDetails.id === undefined) {
            console.error("PromotionWidget: id is undefined", promoDetails);
          }
          return (
            <div
              className={`${classes.wrappedPromotionItem} ${
                getPromotionState(promoDetails) === "disabled"
                  ? classes.disabled
                  : ""
              } ${
                getPromotionState(promoDetails) === "expired"
                  ? classes.expired
                  : ""
              } ${
                getPromotionState(promoDetails) === "notYetStarted"
                  ? classes.notYetStarted
                  : ""
              }`}
              key={promoDetails.id}
            >
              {getPromotionState(promoDetails) === "expired" && (
                <div className={classes.expired} />
              )}
              {getPromotionState(promoDetails) === "notYetStarted" && (
                <div className={classes.notYetStarted} />
              )}
              <Controller
                control={props.formMethods.control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <PromotionWidget
                      index={index}
                      value={value}
                      onChange={onChange}
                      myPermissions={props.myPermissions}
                      entityType={props.entityType}
                      readOnlyProduct={
                        props.creationAllowed === "templateOnly" ||
                        props.creationAllowed === "neither"
                      }
                      errors={
                        props.formMethods.formState.errors.ndtrcEntry
                          ?.promotions?.[index]
                      }
                    />
                  );
                }}
                name={`${props.namespace}[${index}]`}
              />
              <div
                onClick={() => {
                  remove(index);
                }}
                className={classes.danger}
              >
                <TiDelete />
              </div>
            </div>
          );
        })
      }
      {props.creationAllowed === "newOnly" && (
        <div
          onClick={() => {
            append(generateEmptyPromotionItem());
          }}
          className={classes.add}
        >
          <IoMdAddCircle />
        </div>
      )}
      {(props.creationAllowed === "templateOnly" ||
        props.creationAllowed === "both") && (
        <PromotionPopover
          onSubmit={(promotion) => {
            promotion
              ? append(extPromotionsToIntPromotions([promotion]))
              : append(generateEmptyPromotionItem());
          }}
          entityType={props.entityType}
          creationAllowed={props.creationAllowed}
        >
          {(registerPopoverOrigin: any, onButtonClickHandler: any) => (
            <div
              onClick={onButtonClickHandler}
              className={classes.add}
              ref={registerPopoverOrigin}
            >
              <IoMdAddCircle />
            </div>
          )}
        </PromotionPopover>
      )}
    </div>
  );
};

export default PromotionsWidget;
