import {
  IntCalendar as Calendar,
  IntSingleDate,
  IntWhen as When,
} from "models/Ndtrc";

const generateSingleNdtrcDateTime = (singleDate: IntSingleDate) => {
  const day = generateNdtrcDate(singleDate.date);
  const time = generateNdtrcTime(singleDate.when);
  return `${day}, ${time}`;
};

interface DateRangeBase {
  startdate: string | null;
  enddate: string | null;
}

export type DateRange = DateRangeBase | null;

export const extractNdtrcStartEndDates = (
  calendar: Calendar | null,
): DateRange => {
  if (!calendar) {
    return null;
  }
  let startdate,
    enddate = null;

  if (
    (calendar.calendarType === "PATTERNDATES" ||
      calendar.calendarType === "OPENINGTIMES") &&
    calendar.patternDates
  ) {
    startdate = calendar.patternDates
      .map((patternDate) => {
        return patternDate.startdate;
      })
      .sort()[0];
    enddate = calendar.patternDates
      .map((patternDate) => {
        return patternDate.enddate;
      })
      .sort()[calendar.patternDates.length - 1];
  } else if (calendar.singleDates) {
    startdate = calendar.singleDates
      .map((singleDate) => {
        return singleDate.date;
      })
      .sort()[0];
    enddate = calendar.singleDates
      .map((singleDate) => {
        return singleDate.date;
      })
      .sort()[calendar.singleDates.length - 1];
  }

  if (!startdate && !enddate) {
    return null;
  } else {
    return { startdate: startdate || null, enddate: enddate || null };
  }
};

export const generateNdtrcDateTime = (
  calendar: Calendar | null | undefined,
): { allDates: string; dateSummary: string } => {
  if (!calendar || !calendar.singleDates) {
    return { allDates: "", dateSummary: "" };
  }

  const allDates = calendar.singleDates
    .map((singleDate) => generateSingleNdtrcDateTime(singleDate))
    .join("\n");

  const startEndDates = extractNdtrcStartEndDates(calendar);

  let dateSummary;
  if (!startEndDates) {
    dateSummary = "";
  } else if (calendar.singleDates?.length === 1) {
    dateSummary = allDates;
  } else {
    dateSummary = `${generateNdtrcDate(
      startEndDates.startdate,
    )} — ${generateNdtrcDate(startEndDates.enddate)}`;
  }
  return { allDates: allDates, dateSummary: dateSummary };
};

const generateNdtrcDate = (date: string | null): string => {
  if (!date) {
    return "???";
  }
  const dateTimeFormat = new Intl.DateTimeFormat("nl", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const [{ value: day }, , { value: month }, , { value: year }] =
    dateTimeFormat.formatToParts(new Date(date));
  return `${day}-${month}-${year}`;
};

const generateNdtrcTime = (when: When[]): string => {
  let time = "";
  if (!when || when.length < 1) {
    return time;
  }
  if (when.length > 1) {
    return "Multiple time preview not yet implemented!";
  }

  // Force format to be "13:00" rather than "13:00:00"
  let timeslot = when[0];
  if (timeslot) {
    if (!timeslot.timestart) {
      time = "Whole day";
    } else if (!timeslot.timeend) {
      time += timeslot.timestart.match(/[0-9]+:[0-9]+/)?.[0] + "-";
    } else {
      time += `${timeslot.timestart.match(/[0-9]+:[0-9]+/)?.[0]}–${timeslot.timeend.match(/[0-9]+:[0-9]+/)?.[0]
        }`;
    }
  }

  return time;
};