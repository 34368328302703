import React, { useEffect, useState } from "react";
import classes from "./UrlInputPopover.module.scss";
import { AiOutlineEnter } from "react-icons/ai";
import { usePopper } from "react-popper";
import popoverStyles from "./PopoverSharedStyle.module.scss";
import Select from "react-select";
import {
  UrlServiceType,
  UrlServiceTypes,
} from "models/Ndtrc";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Lang, langMetadata } from "models/Lang";
import { Twemoji } from "helpers";

const UrlInputPopover = (props: {
  children: any;
  onTextSubmit: (text: string, urlType: UrlServiceType, lang?: Lang) => void;
  placeholder?: string;
  prefillText?: string;
  defaultLang?: Lang;
}) => {
  const { t } = useTranslation();

  const [inputField, setInputField] = useState<HTMLInputElement | null>(null);
  const [inputFieldMissing, setInputFieldMissing] = useState(false);

  const [popover, setPopover] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes, update } = usePopper(popover, popperElement, {});
  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  const [urlType, setUrlType] = useState<UrlServiceType | null>("general");
  const [urlTypeMissing, setUrlTypeMissing] = useState(false);

  const [urlLang, setUrlLang] = useState<Lang | null>(
    props.defaultLang || null,
  );
  const [urlLangMissing, setUrlLangMissing] = useState(false);

  const urlServiceTypesTranslations = UrlServiceTypes.map((type) => {
    const newType = _.cloneDeep(type);
    newType.label = t(type.label);
    return newType;
  });

  const langOptions = Object.values(Lang).map((lang: Lang) => {
    return {
      value: lang,
      label: (
        <>
          <Twemoji>{langMetadata[lang]?.flag}</Twemoji> {lang}
        </>
      ),
    };
  });

  const onSubmit = () => {
    let hasMissingValue = false;
    if (!inputField || !inputField.value) {
      setInputFieldMissing(true);
      hasMissingValue = true;
    }
    if (!urlType) {
      setUrlTypeMissing(true);
      hasMissingValue = true;
    }
    if (!urlLang) {
      setUrlLangMissing(true);
      hasMissingValue = true;
    }
    if (hasMissingValue) return;
    if (!inputField || !urlType) return; // redundant; but type checker requires this

    props.onTextSubmit(inputField.value, urlType, urlLang || undefined);
    resetFields();
    setPopoverActive(false);
  };

  const onButtonClickHandler = () => {
    resetFields();
    if (update) {
      void update();
    }
    setPopoverActive(true);
    inputField?.focus();
  };

  const resetFields = () => {
    if (inputField) {
      inputField.value = "";
    }
    setInputFieldMissing(false);
    setUrlType(null);
    setUrlTypeMissing(false);
  };

  const handleUrlTypeChange = (
    newType: { value: UrlServiceType | null; label: string } | null,
  ) => {
    setUrlTypeMissing(!newType?.value);
    setUrlType(newType?.value || null);
  };

  const handleLangChange = (
    newLang: { value: Lang | null; label: string } | null,
  ) => {
    setUrlLangMissing(!newLang?.value);
    setUrlLang(newLang?.value || null);
  };

  useEffect(() => {
    if (inputField) {
      inputField.value = props.prefillText || "";
    }
  }, [inputField, props.prefillText]);

  return (
    <>
      {props.children(setPopover, onButtonClickHandler)}
      <div
        className={[
          popoverStyles.popover,
          classes.popover,
          popoverActive ? popoverStyles.active : "",
        ].join(" ")}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className={classes.langSelect}>
          <Select
            className={`${classes.urlServiceTypeSelect} ${
              urlLangMissing ? classes.infoMissing : ""
            }`}
            value={langOptions.find((type) => type.value === urlLang) || null}
            options={langOptions as any}
            menuPlacement={"auto"}
            placeholder={t("form.chooseLangPrompt")}
            styles={{
              menu: (provided: any, state) => {
                return { ...provided, zIndex: 2 };
              },
            }}
            onChange={handleLangChange as any}
          />
        </div>
        <Select
          className={`${classes.urlServiceTypeSelect} ${
            urlTypeMissing ? classes.infoMissing : ""
          }`}
          value={
            urlServiceTypesTranslations.find(
              (type) => type.value === urlType,
            ) || null
          }
          options={urlServiceTypesTranslations}
          menuPlacement={"auto"}
          placeholder={t("form.chooseUrlTypePrompt")}
          styles={{
            menu: (provided: any, state) => {
              return { ...provided, zIndex: 2 };
            },
          }}
          onChange={handleUrlTypeChange}
        />
        <input
          className={`${inputFieldMissing ? classes.inputFieldMissing : ""}`}
          placeholder={props.placeholder || ""}
          ref={setInputField}
          onKeyDown={(event) => {
            setInputFieldMissing(false);
            if (event.key === "Enter") {
              onSubmit();
            }
          }}
        />
        <button type="button" onClick={onSubmit}>
          <AiOutlineEnter />
        </button>
      </div>
      <div
        className={`${popoverStyles.backdrop} ${
          popoverActive ? popoverStyles.active : ""
        }`}
        onClick={() => {
          setPopover(null);
          setPopoverActive(false);
        }}
      />
    </>
  );
};

export default UrlInputPopover;
