import React, { useState } from "react";
import classes from "./TuneWidget.module.scss";
import { FaInbox } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Dropdown } from "./Dropdown";
import { RiPlayList2Fill, RiUserStarFill } from "react-icons/ri";
import _ from "lodash";
import {
  defaultTuningOptions,
  inboxTuningOptions,
  TuningProps,
} from "../../models/TuningProps/TuningProps";
import { BiReset } from "react-icons/bi";
import TextInputPopover from "../popovers/TextInputPopover/TextInputPopover";
import { MdAddCircle } from "react-icons/md";
import { useFilterPresets } from "helpers";
import { AiFillDelete } from "react-icons/ai";
import BooleanInputPopover from "../popovers/BooleanInputPopover/BooleanInputPopover";

const PresetsButton = (props: {
  onClick: (tuningProps: TuningProps) => any;
  tuningProps: TuningProps | null;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const { presets: myFilters, addPreset, removeFilter } = useFilterPresets();

  return (
    <div className={`${classes.PresetsButton} ${classes.Button}`}>
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <div
            className={`${classes.DropdownContent}`}
            onClick={toggleOpen}
            title={t("tune.presets")}
          >
            <div>
              <RiPlayList2Fill />
            </div>
          </div>
        }
      >
        <div className={classes.FilterInterface}>
          <div
            onClick={() => {
              props.onClick(defaultTuningOptions);
            }}
            className={`${classes.PresetButton} ${
              _.isEqual(props.tuningProps, defaultTuningOptions)
                ? classes.activated
                : ""
            }`}
          >
            <BiReset />
            <span>{t("tune.reset")}</span>
          </div>
          <div
            onClick={() => {
              props.onClick(inboxTuningOptions);
            }}
            className={`${classes.PresetButton} ${
              _.isEqual(props.tuningProps, inboxTuningOptions)
                ? classes.activated
                : ""
            }`}
          >
            <FaInbox />
            <span>{t("tune.inbox")}</span>{" "}
          </div>
          {myFilters.map((preset) => {
            return (
              <div
                onClick={() => {
                  props.onClick(preset.props);
                }}
                className={`${classes.PresetButton} ${
                  _.isEqual(props.tuningProps, preset.props)
                    ? classes.activated
                    : ""
                }`}
              >
                <RiUserStarFill />
                <span>{preset.name}</span>{" "}
                <BooleanInputPopover
                  onBooleanSubmit={(deletion) => {
                    if (deletion) removeFilter(preset.name);
                  }}
                  prompt={t("tune.removePresetFilterPrompt")}
                  type={"danger"}
                >
                  {(setPopover: any, onButtonClickHandler: any) => (
                    <div
                      ref={setPopover}
                      className={classes.filterPresetDeletion}
                    >
                      <AiFillDelete onClick={onButtonClickHandler} />
                    </div>
                  )}
                </BooleanInputPopover>
              </div>
            );
          })}
          <TextInputPopover
            onTextSubmit={(filterName) =>
              addPreset(filterName, props.tuningProps)
            }
            placeholder={t("tune.typeFilterName")}
          >
            {(setPopover: any, onButtonClickHandler: any) => (
              <div
                ref={setPopover}
                onClick={onButtonClickHandler}
                className={`${classes.PresetButton} ${classes.addCurrentButton}`}
              >
                <span>
                  <MdAddCircle />
                  <span>{t("tune.addCurrent")}</span>
                </span>{" "}
              </div>
            )}
          </TextInputPopover>
        </div>
      </Dropdown>
    </div>
  );
};

export default PresetsButton;
