import {
  EntityType,
  ExternalItemModel,
  ExtLabelLangString,
  ExtLangString,
  ExtPatternDate,
  extPromotionsToIntPromotions,
  ExtSingleDate,
  ExtSingleExceptionDate,
  ExtTrcItemDetail,
  extUrlsToIntUrls,
  FileItem,
  IntCalendar,
  InternalItemModel,
  IntPatternDate,
  IntPriceElement,
  intPromotionsToExtPromotions,
  IntSingleDate,
  IntSingleExceptionDate,
  IntTrcItemDetail,
  intUrlsToExtUrls,
  PriceElement,
  weekDaysDisplayOrder,
} from "models/Ndtrc";
import { Lang } from "models/Lang";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { format } from "date-fns";
import moment from "moment";
import { cleanCurrencyString } from "./cleanCurrencyString";

export interface IntPaginatedResponse {
  size: number;
  page: number;
  hits: number;
  results: InternalItemModel[];
}

export interface ExtPaginatedResponse {
  size: number;
  page: number;
  hits: number;
  results: ExternalItemModel[];
}

const sortSingleDateItems = (a: ExtSingleDate, b: ExtSingleDate) => {
  if (!a.date || !b.date) {
    return 0;
  }
  if (
    format(new Date(a.date), "yyyy-MM-dd") >
    format(new Date(b.date), "yyyy-MM-dd")
  ) {
    return 1;
  }
  if (
    format(new Date(a.date), "yyyy-MM-dd") <
    format(new Date(b.date), "yyyy-MM-dd")
  ) {
    return -1;
  }

  // If not sorted yet: same date, so requires sorting on time
  if (!a.when[0].timestart || !b.when[0].timestart) {
    return 0;
  }
  if (a.when[0].timestart > b.when[0].timestart) {
    return 1;
  }
  if (a.when[0].timestart < b.when[0].timestart) {
    return -1;
  }
  return 0;
};

// TODO: Finish transformer by changing all language strings as described by model
export const externalToInternal = (
  ext: any /* ExternalItemModel */,
): InternalItemModel => {
  let transformedModel: any = _.cloneDeep(ext); // this object is gradually transformed
  // Note: without a deep clone, there is too much risk of manual errors

  // Perform validity checks
  const modelIsValid =
    (!transformedModel.availablefrom ||
      moment(transformedModel.availablefrom).isValid()) &&
    (!transformedModel.availableto ||
      moment(transformedModel.availableto).isValid());
  if (!modelIsValid) {
    throw new Error("Incoming data is not valid");
  }
  if (!transformedModel.availablefrom) {
    transformedModel.availablefrom = null;
  }
  if (!transformedModel.availableto) {
    transformedModel.availableto = null;
  }

  if (ext.trcItemDetails) {
    let transformedTrcItemDetails: IntTrcItemDetail | any = {};
    ext.trcItemDetails.forEach((detailItem: ExtTrcItemDetail) => {
      const extDetailItemClone: any = { ...detailItem };
      // Transform data structure concerning language in trcItemDetails
      const lang = extDetailItemClone.lang;
      delete extDetailItemClone.lang;

      // Remove specific HTML characters from title and short description
      if (extDetailItemClone?.title) {
        extDetailItemClone.title = extDetailItemClone.title
          .replace(new RegExp("&amp;", "g"), "&")
          .replace(new RegExp("&nbsp;", "g"), " ");
      }
      if (extDetailItemClone?.shortdescription) {
        extDetailItemClone.shortdescription =
          extDetailItemClone.shortdescription
            .replace(new RegExp("&amp;", "g"), "&")
            .replace(new RegExp("&nbsp;", "g"), " ");
      }

      transformedTrcItemDetails[lang] = extDetailItemClone;
    });

    transformedModel.trcItemDetails = transformedTrcItemDetails;
  }

  if (!transformedModel.calendar) {
    // Set empty object, as otherwise useFieldArray will malfunction in CalendarWidget
    // Does not need to be removed in internalToExternal function
    if (!ext.calendar) {
      transformedModel.calendar = {};
    }
    if (!ext.calendar?.singleDates) {
      transformedModel.calendar.singleDates = [];
    }
  }

  // Turn nested single dates items into separate items (for correct UI display/logic)
  if (transformedModel.calendar.singleDates.length > 0) {
    let extractedSingleDates: IntSingleDate[] = [];
    transformedModel.calendar.singleDates.forEach((date: IntSingleDate) => {
      // Only transform if when-length is more than one
      if (date.when.length <= 1) {
        extractedSingleDates.push(date);
        return;
      }
      date.when.forEach((when) => {
        const splitSingleDate = { ...date };
        splitSingleDate.when = [when];
        extractedSingleDates.push(splitSingleDate);
      });
    });
    transformedModel.calendar.singleDates = extractedSingleDates;
  }

  // Add id field to single dates
  if (transformedModel.calendar.singleDates.length > 0) {
    transformedModel.calendar?.singleDates?.forEach(
      (singleDate: ExtSingleDate, index: number) => {
        const extSingleDateClone: any = { ...singleDate };
        extSingleDateClone.id = uuidv4();
        transformedModel.calendar.singleDates[index] = extSingleDateClone;
      },
    );
  }

  // Add id field to urls
  if (transformedModel.contactinfo?.urls) {
    transformedModel.contactinfo.urls = extUrlsToIntUrls(
      transformedModel.contactinfo.urls,
    );
  }

  // Add id field to promotions & set discountType
  if (transformedModel.promotions) {
    transformedModel.promotions = extPromotionsToIntPromotions(
      transformedModel.promotions,
    );
  }

  if (transformedModel.calendar.singleDates.when) {
    // Sort singleDates, and make sure dates are in string format
    transformedModel.calendar.singleDates =
      transformedModel.calendar.singleDates.sort(sortSingleDateItems);
  }
  // Add NONE calendar type if missing
  if (!transformedModel.calendar.calendarType) {
    transformedModel.calendar.calendarType = "NONE";
  }

  // Add id field to priceElements
  if (ext.priceElements) {
    transformedModel.priceElements = transformedModel.priceElements?.map(
      (priceEl: PriceElement<ExtLangString[]>, index: number) => {
        const extPriceElClone: any = { ...priceEl };
        extPriceElClone.id = uuidv4();
        return extPriceElClone;
      },
    );
  }

  // Add id field to pattern dates
  if (ext.calendar?.patternDates) {
    transformedModel.calendar.patternDates =
      transformedModel.calendar?.patternDates?.map(
        (patternDate: ExtPatternDate<ExtLangString[]>, index: number) => {
          const extClone: any = { ...patternDate };
          extClone.id = uuidv4();
          return extClone;
        },
      );
  }
  // Add id field to opens dates
  if (ext.calendar?.opens) {
    transformedModel.calendar.opens = transformedModel.calendar?.opens?.map(
      (date: ExtSingleExceptionDate, index: number) => {
        const extClone: any = { ...date };
        extClone.id = uuidv4();
        return extClone;
      },
    );
  }
  // Add id field to closeds dates
  if (ext.calendar?.closeds) {
    transformedModel.calendar.closeds = transformedModel.calendar?.closeds?.map(
      (date: ExtSingleExceptionDate, index: number) => {
        const extClone: any = { ...date };
        extClone.id = uuidv4();
        return extClone;
      },
    );
  }

  // Set activation state of pattern date items and make complete the expected object (with all days)
  if (ext.calendar?.patternDates) {
    transformedModel.calendar.patternDates =
      transformedModel.calendar?.patternDates?.map(
        (patternDate: IntPatternDate, index: number) => {
          const intClone: IntPatternDate = {
            ...patternDate,
            opens: [...patternDate.opens],
          };
          // If the item is listed at the back-end, it is (assumed) activated.
          intClone.opens = weekDaysDisplayOrder.map((weekday) => {
            const weekdayOpen = patternDate.opens.find(
              (open) => open.day === weekday,
            );
            if (weekdayOpen) {
              weekdayOpen.activated = true;
              return weekdayOpen;
            } else {
              return { day: weekday, activated: false, whens: [] };
            }
          });
          return intClone;
        },
      );
  }

  // Add empty trcitemRelation for location items if missing
  if (ext.entitytype === "LOCATIE" && !ext.trcitemRelation) {
    transformedModel.trcitemRelation = { subItemGroups: [] };
  }

  // NOTE: LEGACY DATA MODEL SUPPORT
  // Transfer files label to description array in primary language
  if (ext.files && ext.files?.length > 0) {
    transformedModel.files = ext.files?.map(
      (file: FileItem<ExtLabelLangString[]>) => {
        if (!file.title?.label) {
          // Only transfer needed if label present
          return file;
        }

        // Check if primary lang string already exists
        const primaryLangString = file.title?.titleTranslations?.find(
          (t) => t.lang === ext.translations?.primaryLanguage,
        );
        if (
          !primaryLangString &&
          file.title?.label &&
          file.title.titleTranslations &&
          ext.translations?.primaryLanguage
        ) {
          // Add primary lang string from label value
          file.title.titleTranslations.push({
            lang: ext.translations.primaryLanguage,
            label: file.title.label,
          });
        }
        return file;
      },
    );
  }

  return transformedModel;
};

// TODO: Finish transformer by changing all language strings as described by model
export const internalToExternal = (
  int: any /* InternalItemModel */,
): ExternalItemModel => {
  if (int === undefined) {
    throw new Error("No form data passed");
  }

  let transformedModel: any = _.cloneDeep(int); // this object is gradually transformed
  // Note: without a deep clone, there is too much risk of manual errors

  // Perform validity checks
  const modelIsValid =
    (!transformedModel.availablefrom ||
      moment(transformedModel.availablefrom).isValid()) &&
    (!transformedModel.availableto ||
      moment(transformedModel.availableto).isValid());
  if (!modelIsValid) {
    throw new Error("Form data is not valid");
  }

  // Transform data structure concerning language in trcItemDetails
  // Back-end takes order of trcItemDetails, ignoring the availableLanguages order - so, align here
  if (int.trcItemDetails) {
    let transformedTrcItemDetails: ExtTrcItemDetail[] = [];
    int.translations?.availableLanguages?.forEach((lang: Lang) => {
      const extDetailItem: any = _.cloneDeep(int.trcItemDetails[lang]);
      if (!extDetailItem) {
        throw new Error("Invalid language - trcItemDetails pairing");
      }
      extDetailItem.lang = lang as Lang;
      transformedTrcItemDetails.push(extDetailItem as ExtTrcItemDetail);
    });
    transformedModel.trcItemDetails = transformedTrcItemDetails;
  }

  if (int.calendar) {
    // Remove id field from single dates
    transformedModel.calendar?.singleDates?.forEach(
      (singleDate: IntSingleDate, index: number) => {
        const intSingleDateClone: any = { ...singleDate };
        delete intSingleDateClone.id;
        transformedModel.calendar.singleDates[index] = intSingleDateClone;
      },
    );
  }

  // Remove id field from urls
  if (transformedModel.contactinfo?.urls) {
    transformedModel.contactinfo.urls = intUrlsToExtUrls(
      transformedModel.contactinfo.urls,
    );
  }

  // Filter out empty items of contactinfo
  // First check if any contactinfo is present; some old items lack this field
  if (transformedModel.contactinfo) {
    if (
      transformedModel.contactinfo?.mail &&
      !transformedModel.contactinfo.mail.email
    ) {
      transformedModel.contactinfo.mail = null;
    }
    if (
      transformedModel.contactinfo?.phone &&
      !transformedModel.contactinfo.phone.number
    ) {
      transformedModel.contactinfo.phone = null;
    }
  }

  // Remove id field from promotions & enforce discountType
  if (transformedModel.promotions) {
    transformedModel.promotions = intPromotionsToExtPromotions(
      transformedModel.promotions,
    );
  }

  if (int.calendar) {
    // Nest single date items
    const nestedSingleDates: ExtSingleDate[] = [];
    // We need to make sure all date values are turned into strings
    const originalSingleDates = transformedModel.calendar?.singleDates
      ?.map((singleDate: ExtSingleDate) => {
        let date: any = singleDate.date;
        if ((singleDate.date as any) instanceof Date) {
          date = (singleDate.date as unknown as Date).toISOString();
        }

        // Force format to be "13:00" rather than "13:00:00"
        let when = singleDate.when[0];
        if (when) {
          when.timestart = when.timestart?.match(/[0-9]+:[0-9]+/)?.[0] || null;
          when.timeend = when.timeend?.match(/[0-9]+:[0-9]+/)?.[0] || null;
        }

        return {
          date: date,
          when: singleDate.when,
        };
      })
      .sort(sortSingleDateItems);
    if (originalSingleDates) {
      originalSingleDates.forEach(
        (singleDate: ExtSingleDate, index: number) => {
          const prevSingleDateIndex = nestedSingleDates.findIndex(
            (date) => date.date === singleDate.date,
          );
          if (prevSingleDateIndex === -1) {
            nestedSingleDates.push(singleDate);
          } else {
            const nestedSingleDate = _.cloneDeep(
              nestedSingleDates[prevSingleDateIndex],
            );
            if (!nestedSingleDate) {
              console.error("Error processing single dates for export");
              return;
            }
            nestedSingleDate.when.push(singleDate.when[0]);
            nestedSingleDates[prevSingleDateIndex] = nestedSingleDate;
          }
        },
      );
    }

    transformedModel.calendar.singleDates = nestedSingleDates;
  }

  if (
    transformedModel.calendar.calendarType === "PATTERNDATES" &&
    transformedModel.calendar.patternDates
  ) {
    const patternDates: IntPatternDate[] =
      transformedModel.calendar.patternDates;

    // Check if second `when` field is used. If not, remove. Otherwise, transform data.
    transformedModel.calendar.patternDates = patternDates.map((pd) => {
      const pdClone = _.cloneDeep(pd);
      if (pdClone.opens) {
        pdClone.opens = pd.opens.map((op) => {
          const opClone = _.cloneDeep(op);
          for (let i = opClone.whens.length - 1; i >= 0; i--) {
            if (!opClone.whens[i].timestart) {
              opClone.whens.splice(i, 1);
            } else {
              opClone.whens[i].timestart =
                opClone.whens[i].timestart?.match(/[0-9]+:[0-9]+/)?.[0] || null;
              opClone.whens[i].timeend =
                opClone.whens[i].timeend?.match(/[0-9]+:[0-9]+/)?.[0] || null;
            }
          }
          return opClone;
        });
      }
      return pdClone;
    });
  }

  if (int.priceElements) {
    transformedModel.priceElements = transformedModel.priceElements?.map(
      (priceEl: IntPriceElement, index: number) => {
        // Remove id field from price elements
        const intPriceElClone: any = { ...priceEl };
        delete intPriceElClone.id;

        // External model only allows dots rather than comma's for prices; forcefully convert
        if (intPriceElClone.priceValue?.from) {
          intPriceElClone.priceValue.from = cleanCurrencyString(
            intPriceElClone.priceValue.from.toString(),
          );
        }
        if (intPriceElClone.priceValue?.until) {
          intPriceElClone.priceValue.until = cleanCurrencyString(
            intPriceElClone.priceValue.until.toString(),
          );
        }

        if (intPriceElClone.comments) {
          // Contains array of comments; check if any of them are empty
          intPriceElClone.comments = intPriceElClone.comments.filter(
            (comment: ExtLangString) => comment.text !== "",
          );
          if (intPriceElClone.comments.length === 0) {
            delete intPriceElClone.comments;
          }
        }

        if (
          intPriceElClone.description &&
          intPriceElClone.description.value === null
        ) {
          intPriceElClone.description = null;
        }

        return intPriceElClone;
      },
    );
  }

  if (int.calendar?.patternDates) {
    // Remove id field from pattern dates
    transformedModel.calendar.patternDates =
      transformedModel.calendar?.patternDates?.map(
        (patternDate: IntPatternDate, index: number) => {
          const intClone: any = { ...patternDate };
          delete intClone.id;
          return intClone;
        },
      );
  }
  if (int.calendar?.opens) {
    // Remove id field from opens dates
    transformedModel.calendar.opens = transformedModel.calendar?.opens?.map(
      (date: IntSingleExceptionDate, index: number) => {
        const intClone: any = { ...date };
        delete intClone.id;
        return intClone;
      },
    );
  }
  if (int.calendar?.closeds) {
    // Remove id field from closeds dates
    transformedModel.calendar.closeds = transformedModel.calendar?.closeds?.map(
      (date: IntSingleExceptionDate, index: number) => {
        const intClone: any = { ...date };
        delete intClone.id;
        return intClone;
      },
    );
  }

  // Only add pattern dates that are active
  if (int.calendar?.patternDates) {
    transformedModel.calendar.patternDates =
      transformedModel.calendar?.patternDates?.map(
        (patternDate: IntPatternDate, index: number) => {
          const intClone: IntPatternDate = {
            ...patternDate,
            opens: [...patternDate.opens],
          };
          intClone.opens = intClone.opens.filter((open) => open.activated);
          return intClone;
        },
      );
  }

  // remove redundant elements of calendar model based on its type
  transformedModel.calendar = cleanupCalendarModel(transformedModel.calendar);

  // Force lowercase for legalowner and trim
  if (transformedModel.legalowner) {
    transformedModel.legalowner = transformedModel.legalowner
      .toLowerCase()
      .trim();
  }

  // NOTE: LEGACY DATA MODEL SUPPORT
  // Transfer files description in primary language to label field for backwards support
  if (transformedModel.files) {
    transformedModel.files = transformedModel.files.map(
      (file: FileItem<ExtLabelLangString[]>) => {
        if (!file.title) {
          return file;
        }

        const titleTranslation = file.title?.titleTranslations?.find(
          (title) =>
            title.lang === transformedModel.translations.primaryLanguage,
        );

        file.title.label = titleTranslation?.label || "";
        return file;
      },
    );
  }

  return transformedModel;
};

export const externalResultsToInternalResults = (
  data: ExtPaginatedResponse,
): IntPaginatedResponse => {
  const transformedResults = data.results.map((item) => {
    return externalToInternal(item);
  });

  return {
    ...data,
    results: transformedResults,
  };
};

export const cleanupCalendarModel = (calendar: IntCalendar): IntCalendar => {
  if (!calendar?.calendarType) {
    return calendar;
  }

  const calendarCopy = _.cloneDeep(calendar);
  switch (calendarCopy.calendarType) {
    case "SINGLEDATES":
      // Keep single dates in place

      calendarCopy.patternDates = [];
      calendarCopy.opens = [];
      calendarCopy.closeds = [];
      calendarCopy.cancelleds = [];
      calendarCopy.soldouts = [];
      calendarCopy.onrequest = false;
      calendarCopy.alwaysopen = false;
      break;
    case "PATTERNDATES":
    case "OPENINGTIMES":
      // Keep pattern dates / opening times in place

      calendarCopy.singleDates = [];
      calendarCopy.onrequest = false;
      calendarCopy.alwaysopen = false;
      break;
    case "ALWAYSOPEN":
      calendarCopy.alwaysopen = true;

      calendarCopy.patternDates = [];
      calendarCopy.opens = [];
      calendarCopy.onrequest = false;
      break;
    case "ONREQUEST":
      calendarCopy.onrequest = true;

      calendarCopy.patternDates = [];
      calendarCopy.opens = [];
      calendarCopy.closeds = [];
      calendarCopy.cancelleds = [];
      calendarCopy.soldouts = [];
      calendarCopy.alwaysopen = false;
      break;
    default:
      // includes "NONE" and null
      calendarCopy.singleDates = [];
      calendarCopy.patternDates = [];
      calendarCopy.opens = [];
      calendarCopy.closeds = [];
      calendarCopy.cancelleds = [];
      calendarCopy.soldouts = [];
      calendarCopy.onrequest = false;
      calendarCopy.alwaysopen = false;
  }
  return calendarCopy;
};

// Implements mission-critical defaults otherwise not described by form
export const getEmptyExternalItem = (type: EntityType): ExternalItemModel => {
  return {
    acl: {
      readers: [],
      editors: [],
      managers: [],
    },
    id: null,
    entitytype: type,
    trcItemDetails: [], // needs to be present for translations to work without setting in main language first
    priceElements: [],
    calendar:
      type === "ROUTE"
        ? undefined
        : {
          calendarType: "NONE",
          patternDates: [],
          singleDates: [],
          opens: [],
          closeds: [],
          soldouts: [],
          cancelleds: [],
        },
    files: [],
    availablefrom: null,
    availableto: null,
    markers: "",
    keywords: "",
    trcitemRelation: { subItemGroups: [] },
    wfstatus: "readyforvalidation",
  };
};
