import SettingsField from "./SettingsField";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../../components/buttons/NavigationButton/NavigationButton";
import { MdChevronLeft } from "react-icons/md";
import TextButton from "../../../components/buttons/TextButton/TextButton";
import { useTranslation } from "react-i18next";
import {
  feedFactoryAxios,
  useNavTranslation,
} from "helpers";

const SettingsHStack = (props: any) => {
  return (
    <div className={`settings-hstack ${props.className}`}>{props.children}</div>
  );
};

const NewAccount = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { accountsUrl } = useNavTranslation();

  const [didCreateAccount, setDidCreateAccount] = useState(false);
  const [didFailToCreateAccount, setDidFailToCreateAccount] = useState(false);

  const [name, setName] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [userOrganisation, setUserOrganisation] = useState("");

  const handleSubmit = () => {
    feedFactoryAxios
      .post(`/accounts`, {
        name: name,
        logoUrl: logoUrl,
        userOrganisation: userOrganisation,
      })
      .then((response) => {
        setDidCreateAccount(true);
        setDidFailToCreateAccount(false);
      })
      .catch((error) => {
        setDidFailToCreateAccount(true);
      });
  };

  const returnToAccounts = () => {
    history.push(accountsUrl);
  };

  const createdInterface = (
    <>
      <header className={"settings-header"}>
        <div className={"settings-eyebrow"}>
          <TextButton action={returnToAccounts}>
            <>
              <MdChevronLeft /> {t("root.backToAccounts")}
            </>
          </TextButton>
        </div>
      </header>
      <div className={"settings-message"}>
        <h1 className={"settings-headline"}>
          {t("root.accountSuccessfullyAdded")}
        </h1>
        <ul>
          <li>
            <span>
              {t("root.name")}: <em className={"selectable"}>{name}</em>
            </span>
          </li>
          <li>
            <span>
              {t("root.logoUrl")}:<em className={"selectable"}>{logoUrl}</em>
            </span>
          </li>
        </ul>
        <SettingsHStack className={"settings-success-options"}>
          <TextButton
            action={() => {
              setDidCreateAccount(false);
              setName("");
              setLogoUrl("");
            }}
          >
            {t("root.createAnotherAccount")}
          </TextButton>
          <em>of</em>
          <NavigationButton action={returnToAccounts}>
            {t("root.backToAccounts")}
          </NavigationButton>
        </SettingsHStack>
      </div>
    </>
  );

  const createInterface = (
    <>
      <header className={"settings-header"}>
        <div className={"settings-eyebrow"}>
          <TextButton action={returnToAccounts}>
            <>
              <MdChevronLeft /> {t("root.backToAccounts")}
            </>
          </TextButton>
        </div>
        <h2 className={"settings-title"}>{t("root.newAccount")}</h2>
      </header>
      <div className={"settings-group"}>
        <div className={"settings-row"}>
          <SettingsField
            label={t("root.name")}
            value={name}
            setValue={setName}
          />
        </div>
        <div className={"settings-row"}>
          <SettingsField
            label={t("root.logoUrl")}
            value={logoUrl}
            setValue={setLogoUrl}
          />
        </div>
        <div className={"settings-row"}>
          <SettingsField
            label={t("root.userOrganisation")}
            value={userOrganisation}
            setValue={setUserOrganisation}
          />
        </div>
        <NavigationButton action={handleSubmit}>
          {t("root.createAccount")}
        </NavigationButton>

        {didFailToCreateAccount && t("root.couldNotCreateAccount")}
      </div>
    </>
  );

  return (
    <>
      {!didCreateAccount && createInterface}
      {didCreateAccount && createdInterface}
    </>
  );
};

export default NewAccount;
