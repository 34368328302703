import React, { Suspense } from "react";
import "./App.scss";
import Nav from "components/Nav/Nav";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Settings from "./containers/Settings/Settings";
import User from "./containers/Settings/components/User";
import ItemForm from "./containers/ItemForm/ItemForm";
import { useSelector } from "react-redux";
import { selectIsAdmin, selectIsEditor } from "./store/auth/authSlice";
import EventsBrowser from "./containers/ItemsBrowser/EventsBrowser";
import LocationsBrowser from "./containers/ItemsBrowser/LocationsBrowser";
import { LoadScript } from "@react-google-maps/api";
import Loader from "./components/Loader/Loader";
import Dashboard from "./containers/Dashboard/Dashboard";
import EventGroupsBrowser from "./containers/ItemsBrowser/EventGroupsBrowser";
import RoutesBrowser from "./containers/ItemsBrowser/RoutesBrowser";

const SelfUserWrapper = (props: any) => (
  <div className={"ff-settings-component"}>
    <div className={"settings-main"}>
      <User isMe={true} />
    </div>
  </div>
);

const apiKey = "AIzaSyCZ_1AHRoo4tSiML5wU4g_gf25zhK6KmvM";
const libraries = ["places"];

const App = (props: any) => {
  const { t } = useTranslation();

  const isAdmin = useSelector(selectIsAdmin);
  const isEditor = useSelector(selectIsEditor);

  let routes = (
    <Switch>
      <Switch>
        {/*THIS REDIRECT IS REQUIRED FOR SETTINGS*/}
        {/* in settings, you can view the users for your account (and as feed factory admin for all accounts).
         * /instellingen/organisaties/4865746764756454/gebruikers
         * /instellingen/gebruikers
         * On these pages relative urls are used that break if any url ends on a slash ("/")
         */}
        <Redirect
          from="/:url*(/+)"
          to={window.location.pathname.slice(0, -1)}
        />

        <Route
          path={`/${t("root.locations.slug")}`}
          exact
          component={LocationsBrowser}
        />
        <Route path={`/${t("root.locations.slug")}/:id`}>
          <ItemForm entityType={"LOCATIE"} />
        </Route>
        <Route
          path={`/${t("root.events.slug")}`}
          exact
          component={EventsBrowser}
        />
        <Route path={`/${t("root.events.slug")}/:id`}>
          <ItemForm entityType={"EVENEMENT"} />
        </Route>
        <Route
          path={`/${t("root.eventGroups.slug")}`}
          exact
          component={EventGroupsBrowser}
        />
        <Route path={`/${t("root.eventGroups.slug")}/:id`}>
          <ItemForm entityType={"EVENEMENTGROEP"} />
        </Route>
        <Route
          path={`/${t("root.routes.slug")}`}
          exact
          component={RoutesBrowser}
        />
        <Route path={`/${t("root.routes.slug")}/:id`}>
          <ItemForm entityType={"ROUTE"} />
        </Route>

        {isAdmin && (
          <Route path={`/${t("root.settings.slug")}`} component={Settings} />
        )}
        <Route
          path={`/${t("root.myaccount.slug")}`}
          exact
          component={SelfUserWrapper}
        />
        {(isAdmin || isEditor) && (
          <Route
            path={`/${t("root.dashboard.slug")}`}
            exact
            component={Dashboard}
          />
        )}
        <Redirect to={`/${t("root.events.slug")}`} />
      </Switch>
    </Switch>
  );

  return (
    <Suspense
      fallback={
        <div>
          <Loader />
        </div>
      }
    >
      <LoadScript
        googleMapsApiKey={apiKey}
        // @ts-ignore
        libraries={libraries}
        loadingElement={<></>}
      >
        <Nav>{routes}</Nav>
      </LoadScript>
    </Suspense>
  );
};

export default withRouter(App);
