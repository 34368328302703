import React from "react";
import { DateWidgetWrapper } from "components/FormWidgets/DateWidget";
import { useTranslation } from "react-i18next";
import {
  CalendarType,
  IntPatternDate,
  Weekday,
  weekDaysDisplayOrder,
} from "models/Ndtrc";
import WhenWidget from "components/FormWidgets/CalendarWidget/WhenWidget";
import classes from "./PatternDateWidget.module.scss";
import { Controller } from "react-hook-form";
import { TiDelete } from "react-icons/ti";

const PatternDateWidget = (props: {
  formMethods: any;
  namespace: string;
  defaultValue: IntPatternDate;
  onRemove: () => any;
  calendarType: CalendarType;
  hideSecondWhen?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.PatternDateWidget}>
      {props.calendarType === "PATTERNDATES" && (
        <div className={classes.fromTo}>
          <div>
            <label>{t("event.calendar.patternDates.startdate")}</label>
            <DateWidgetWrapper
              name={`${props.namespace}.startdate`}
              formMethods={props.formMethods}
              defaultValue={props.defaultValue.startdate}
              // hasError={props.formMethods.formState.errors.ndtrcEntry?.availablefrom}
            />
          </div>
          <div>
            <label>{t("event.calendar.patternDates.enddate")}</label>
            <DateWidgetWrapper
              name={`${props.namespace}.enddate`}
              formMethods={props.formMethods}
              defaultValue={props.defaultValue.enddate}
              // hasError={props.formMethods.formState.errors.ndtrcEntry?.availableto}
            />
          </div>
        </div>
      )}
      <table>
        <thead>
          <tr>
            <th>{t("calendarWidget.weekDay")}</th>
            <th />
            <th>{`${t("calendarWidget.time")}${
              !props.hideSecondWhen ? " 1" : ""
            }`}</th>
            {!props.hideSecondWhen && (
              <th>{`${t("calendarWidget.time")} 2`}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {weekDaysDisplayOrder.map((weekDay: number, index: number) => {
            props.formMethods.register(
              `${props.namespace}.opens[${index}].day`,
            );
            props.formMethods.setValue(
              `${props.namespace}.opens[${index}].day`,
              weekDay,
            );
            const activated = props.formMethods.watch(
              `${props.namespace}.opens[${index}].activated`,
            );
            return (
              <tr key={weekDay}>
                <td>{Weekday[weekDay]}</td>
                <td>
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <input
                          type="checkbox"
                          checked={value}
                          onChange={() => {
                            onChange(!value);
                          }}
                        />
                      );
                    }}
                    name={`${props.namespace}.opens[${index}].activated`}
                    control={props.formMethods.control}
                    defaultValue={activated || false}
                  />
                </td>
                <td>
                  <WhenWidget
                    namespace={`${props.namespace}.opens[${index}].whens[${0}]`}
                    formMethods={props.formMethods}
                    defaultValue={
                      props.defaultValue?.opens?.[index]?.whens?.[0]
                    }
                    disabled={!activated}
                    noStatus
                    startTimeRequired={false}
                    endTimeRequired={false}
                  />
                </td>
                {!props.hideSecondWhen && (
                  <td>
                    <WhenWidget
                      namespace={`${
                        props.namespace
                      }.opens[${index}].whens[${1}]`}
                      formMethods={props.formMethods}
                      defaultValue={
                        props.defaultValue?.opens?.[index]?.whens?.[1]
                      }
                      disabled={!activated}
                      noStatus
                      startTimeRequired={false}
                      endTimeRequired={false}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {props.calendarType === "PATTERNDATES" && (
        <div onClick={props.onRemove} className={classes.danger}>
          <TiDelete />
        </div>
      )}
    </div>
  );
};

export default PatternDateWidget;
