import React, { useEffect, useState } from "react";
import classes from "./TextInputPopover.module.scss";
import { AiFillDelete, AiOutlineEnter } from "react-icons/ai";
import { usePopper } from "react-popper";
import popoverStyles from "./PopoverSharedStyle.module.scss";

const TextInputPopover = (props: {
  children: any;
  onTextSubmit: (text: string) => void;
  placeholder?: string;
  prefillText?: string;
  allowEmpty?: boolean;
}) => {
  const [inputField, setInputField] = useState<HTMLInputElement | null>(null);
  const [popover, setPopover] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(popover, popperElement, {});
  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  const onEmpty = () => {
    if (!inputField) return;
    inputField.value = "";
    onMediaHandler();
  };

  const onMediaHandler = () => {
    if (!inputField) return;
    if (!props.allowEmpty && !inputField.value) {
      return;
    }
    props.onTextSubmit(inputField?.value || "");
    inputField.value = "";
    setPopoverActive(false);
  };

  const onButtonClickHandler = () => {
    if (update) {
      void update();
    }
    setPopoverActive(true);
    inputField?.focus();
  };

  useEffect(() => {
    if (inputField) {
      inputField.value = props.prefillText || "";
    }
  }, [inputField, props.prefillText]);

  return (
    <>
      {props.children(setPopover, onButtonClickHandler)}
      <div
        className={[
          popoverStyles.popover,
          classes.popover,
          popoverActive ? popoverStyles.active : "",
        ].join(" ")}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <input
          placeholder={props.placeholder || ""}
          ref={setInputField}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              onMediaHandler();
            }
          }}
        />
        <button type="button" onClick={onMediaHandler}>
          <AiOutlineEnter />
        </button>
        {props.allowEmpty && (
          <button
            type="button"
            className={classes.extraButton}
            onClick={onEmpty}
          >
            <AiFillDelete />
          </button>
        )}
      </div>
      <div
        className={`${popoverStyles.backdrop} ${
          popoverActive ? popoverStyles.active : ""
        }`}
        onClick={() => {
          setPopover(null);
          setPopoverActive(false);
        }}
      />
    </>
  );
};

export default TextInputPopover;
