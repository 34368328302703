import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../store";
import axios, { AxiosError, CancelTokenSource } from "axios";
import {
  comparisonIgnoringUndefined,
  externalResultsToInternalResults,
  fetchApiItems,
  IntPaginatedResponse,
} from "helpers";
import {
  defaultTuningOptions,
  TuningProps,
} from "../../models/TuningProps/TuningProps";
import _ from "lodash";

// As a non-serializable object, needs to be stored outside of redux
let cancelTokenSource: CancelTokenSource | null;

interface EventsSlice {
  events: IntPaginatedResponse | null; // also contains poge and size
  loading: boolean;
  error: string | null;
  tuningProps: TuningProps;
  nextProps: null | {
    page: number;
    size: number;
    tuningProps: TuningProps;
  };
}

const initialState: EventsSlice = {
  events: null,
  loading: false,
  error: null,
  tuningProps: defaultTuningOptions,
  nextProps: null,
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    fetchEventsStart: (
      state,
      action: PayloadAction<{
        nextProps: {
          page: number;
          size: number;
          tuningProps: TuningProps;
        };
      }>
    ) => {
      if (state.loading) {
        fetchEventsInterrupt({
          interruptReason: "Request canceled: event data to be replaced.",
        });
      }
      state.loading = true;
      state.error = null;
      state.nextProps = action.payload.nextProps;
      return state;
    },
    fetchEventsInterrupt: (
      state,
      action: PayloadAction<{
        interruptReason: string;
      }>
    ) => {
      cancelTokenSource?.cancel(action.payload.interruptReason);

      state.nextProps = null;
      state.loading = false;
      state.error = null;
      return state;
    },
    fetchEventsSuccess: (
      state,
      action: PayloadAction<{
        data: IntPaginatedResponse;
        tuningProps: TuningProps;
      }>
    ) => {
      console.log("EVENTS LOADED", action.payload.data.results); // DEBUG
      state.loading = false;
      state.events = action.payload.data;
      state.tuningProps = action.payload.tuningProps;
      return state;
    },
    fetchEventsFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;

      return state;
    },
  },
});

export const {
  fetchEventsStart,
  fetchEventsSuccess,
  fetchEventsFailure,
  fetchEventsInterrupt,
} = eventsSlice.actions;

export const performFetchEvents =
  (page = 0, size = 24, tuningProps: TuningProps): AppThunk =>
  async (dispatch, getState) => {
    // Check if query is not equal to previously completed query. If so, don't perform
    // TODO: Fix this so that page changes while loading don't cancel queries
    const tuningPropsSame = _.isEqualWith(
      tuningProps,
      getState().events.tuningProps,
      comparisonIgnoringUndefined
    );

    // Check if query is not equal to currently running query. If so, don't perform
    if (
      page === getState().events.nextProps?.page &&
      size === getState().events.nextProps?.size &&
      _.isEqualWith(
        tuningProps,
        getState().events.nextProps,
        comparisonIgnoringUndefined
      )
    ) {
      // console.log("EVENT SLICE INPUT WAS EQUAL TO CURRENT QUERY"); // DEBUG
      return;
    }

    // console.log("NEW EVENT SLICE", tuningProps, getState().events.tuningProps); // DEBUG

    if (!tuningPropsSame) {
      page = 0;
    }

    cancelTokenSource?.cancel("Request canceled: event data already present.");
    cancelTokenSource = axios.CancelToken.source();
    dispatch(
      fetchEventsStart({
        nextProps: { page: page, size: size, tuningProps: tuningProps },
      })
    );

    fetchApiItems(
      "EVENEMENT",
      page,
      size,
      tuningProps,
      cancelTokenSource,
      "json",
      true
    )
      .then(async (response) => {
        dispatch(
          fetchEventsSuccess({
            data: externalResultsToInternalResults(response.data),
            tuningProps: tuningProps,
          })
        );
      })
      .catch((error: AxiosError) => {
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error.message); // DEBUG
        } else {
          return dispatch(fetchEventsFailure(error.message));
        }
      });
  };

export const selectLoading = (state: RootState) => state.events.loading;
export const selectError = (state: RootState) => state.events.error;
export const selectEvents = (state: RootState) => state.events.events;
export const selectTuningProps = (state: RootState) => state.events.tuningProps;

export default eventsSlice.reducer;
