import React, { useEffect, useState } from "react";
import { LocationMapModel } from "../../models/LocationMapModel/LocationMapModel";
import LocationSelector from "../../components/LocationSelector/LocationSelector";
import { Lang } from "../../models/Lang/Lang";
import { feedFactoryAxios } from "helpers";
import { AxiosResponse } from "axios";
import { AccountModel } from "../../models/AccountModel/AccountModel";
import _ from "lodash";
import classes from "./LocationMapper.module.scss";
import ReactJson from "react-json-view";
import { useTranslation } from "react-i18next";
import assertValidResponse from "../Settings/components/helpers/assertValidResponse";

const LocationMapEditor = (props: {
  locationMap: LocationMapModel;
  onChange?: (locationMap: LocationMapModel) => any;
}) => {
  const { t } = useTranslation();
  const [locationSelected, setLocationSelected] = useState<string | null>(null);
  const [userorganisations, setUserorganisations] = useState<string[]>([]);

  useEffect(() => {
    setLocationSelected(props.locationMap.trcId || null);
  }, [props.locationMap.trcId]);

  useEffect(() => {
    const accountUrl = "/accounts/me";

    feedFactoryAxios
      .get(accountUrl)
      .then((response) =>
        assertValidResponse(response, "No account found for this account id")
      )
      .then((response: AxiosResponse<AccountModel>) => {
        const account = response.data;
        setUserorganisations(account?.userOrganisation?.split(";") || []);
        //   }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, []);

  return (
    <div>
      <div className={classes.originalName}>
        {props.locationMap.locationName}
      </div>
      <LocationSelector
        lang={Lang.NL}
        value={locationSelected}
        myUserOrganisations={userorganisations}
        locationUrl={`/${t("root.locations.slug")}/${locationSelected}`}
        onSelect={(myLocation) => {
          if (!props.onChange) {
            return;
          }
          if (myLocation) {
            const newLocationMap = _.cloneDeep(props.locationMap);
            newLocationMap.ndtrcName = myLocation.trcItemDetails.nl.title; // TODO: make language agnostic
            newLocationMap.trcId = myLocation.id || myLocation.trcid;
            props.onChange(newLocationMap);
          } else {
            const newLocationMap = _.cloneDeep(props.locationMap);
            newLocationMap.ndtrcName = null;
            newLocationMap.trcId = null;
            props.onChange(newLocationMap);
          }
        }}
      />
      <ReactJson src={props.locationMap} theme={"monokai"} />
    </div>
  );
};

export default LocationMapEditor;
