import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  feedFactoryAxios,
  useNavTranslation,
} from "helpers";
import PopupButton from "../../../components/buttons/PopupButton/PopupButton";
import { MdAddCircle, MdChevronRight } from "react-icons/md";
import { ButtonSize } from "../../../components/buttons/ButtonProps";
import { AiFillEdit } from "react-icons/ai";
import moment from "moment";
import "moment/locale/nl";
import { UserResponse } from "../../../models/UserResponse/UserResponse";
import { AxiosResponse } from "axios";
import {
  performMasquerade,
  selectAccountId,
} from "../../../store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import TextButton from "../../../components/buttons/TextButton/TextButton";
import SearchWidget from "../../../components/TuneWidget/SearchWidget";
import { AccountModel } from "../../../models/AccountModel/AccountModel";
import assertValidResponse from "./helpers/assertValidResponse";
import ExportUsersButton from "../../../components/ExportUsersButton/ExportUsersButton";
import FileDownload from "js-file-download";
import { format } from "date-fns";
import { GiDominoMask } from "react-icons/gi";
import classes from "./../Settings.module.scss";

interface UserSlice {
  firstName?: string;
  lastName?: string;
  roles: Array<string>;
  email: string;
  lastSeen?: string;
}

interface UsersProps {
  isSelf?: boolean;
}

const Users = (props: UsersProps) => {
  const { t } = useTranslation();

  let dispatch = useDispatch();

  const [users, setUsers] = useState<Array<UserSlice> | null>(null);
  const [error, setError] = useState<String | null>(null);

  const [searchString, setSearchString] = useState<string>("");

  const history = useHistory();
  const {
    accountsUrl,
    getAccountUserUrl,
    getAccountNewUserUrl,
    getAccountUrl,
  } = useNavTranslation();

  const { accountId: accountIdParam } = useParams<{ accountId: string }>();
  const userAccountId = useSelector(selectAccountId);
  const accountId = props.isSelf ? userAccountId : accountIdParam;

  const [account, setAccount] = useState<AccountModel>({} as AccountModel);

  const downloadUsersExcelFile = async () => {
    const usersExcelUrl = `/accounts/${accountId}/users?format=excel`;
    feedFactoryAxios
      .get(usersExcelUrl, { responseType: "blob" })
      .then((response) =>
        assertValidResponse(response, "No account found for this account id")
      )
      .then((response) => {
        FileDownload(
          response.data,
          `thefeedfactory-users-export_${format(
            Date.now(),
            "yyyy-MM-dd-HHmm"
          )}.xlsx`
        );
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  useEffect(() => {
    const accountUrl = `/accounts/${accountId}`;
    feedFactoryAxios
      .get(accountUrl)
      .then((response) => assertValidResponse(response, `No account found`))
      .then((response) => {
        setAccount(response.data);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [accountId]);

  useEffect(() => {
    feedFactoryAxios
      .get(`/accounts/${accountId}/users`)
      .then((response) =>
        assertValidResponse(response, "No account found for this account id")
      )
      .then((response: AxiosResponse<Array<UserResponse>>) => {
        setError(null);

        if (response.data.length === 0) {
          return feedFactoryAxios
            .get(`/accounts/${accountId}`)
            .then((response) =>
              assertValidResponse(
                response,
                "No account found for this account id"
              )
            )
            .then(() => {
              setUsers(response.data);
            })
            .catch((error) => {
              setError(error.message);
            });
        } else {
          setUsers(response.data);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [accountId]);

  // const baseUrl = `/${t("root.settings.slug")}/${t("root.users.slug")}`;

  const navigateToUser = (userId: string) => {
    history.push(getAccountUserUrl(accountIdParam, userId));
  };

  const navigateToNew = () => {
    history.push(getAccountNewUserUrl(accountIdParam));
  };

  const returnToAccount = () => {
    history.push(getAccountUrl(accountIdParam));
  };
  const returnToAccounts = () => {
    history.push(accountsUrl);
  };

  const masqueradeAsUser = (userEmail: string) => {
    // Fetch a new masquerade token for the user
    feedFactoryAxios
      .post(`/auth/switch?usernameOrEmail=${userEmail}`)
      .then((response) => {
        assertValidResponse(response, "No account found for this account id");
        // Save admin user token
        console.log(response, response.data);
        dispatch(performMasquerade(response.data.token.accessToken));
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <>
      <header className={"settings-header"}>
        {!props.isSelf && (
          <>
            <div className={"settings-eyebrow"}>
              <span className={"settings-breadcrumb-label"}>
                {t("root.backTo")}:{" "}
              </span>
              <TextButton action={returnToAccounts}>
                {t("root.accounts")}
              </TextButton>
              <MdChevronRight style={{ verticalAlign: "middle" }} />
              <TextButton action={returnToAccount}>{account.name}</TextButton>
            </div>
          </>
        )}
        <h2 className={"settings-title"}>
          {t("root.users")} ({account.name})
        </h2>

        <div className={"settings-header-button"}>
          <ExportUsersButton onClick={downloadUsersExcelFile} />

          <PopupButton action={navigateToNew} size={ButtonSize.Small}>
            <>
              {t("root.createUser")} <MdAddCircle />
            </>
          </PopupButton>
        </div>
      </header>
      {error && error}
      {!error && users && (
        <>
          <SearchWidget value={searchString} setValue={setSearchString} />
          {users.length > 0 && (
            <table>
              <thead>
                <tr>
                  <td>{t("root.name")}</td>
                  <td>{t("root.role")}</td>
                  <td>{t("root.email")}</td>
                  <td>{t("root.lastActive")}</td>
                  <td />
                </tr>
              </thead>
              <tbody>
                {(users || [])
                  .filter((user) => {
                    if (!searchString) {
                      return true;
                    }
                    const lowercaseSearchString = searchString.toLowerCase();
                    return (
                      user.email
                        .toLowerCase()
                        .includes(lowercaseSearchString) ||
                      user.firstName
                        ?.toLowerCase()
                        .includes(lowercaseSearchString) ||
                      user.lastName
                        ?.toLowerCase()
                        .includes(lowercaseSearchString)
                    );
                  })
                  .map((user) => {
                    let firstName = user.firstName || "";

                    let lastName = user.lastName || "";

                    if (firstName === "_setup") {
                      firstName = "";
                    }
                    if (lastName.startsWith("pw:")) {
                      lastName = "";
                    }

                    const roles = user.roles || [];

                    const email = user.email;

                    const lastActivityRaw = user.lastSeen;

                    let lastActivity: string = t("root.noActivityKnown");

                    if (lastActivityRaw) {
                      moment.locale("nl"); // @todo update this by user language, on sign-in?
                      lastActivity = moment(lastActivityRaw).fromNow();
                    }

                    return (
                      <tr
                        onClick={() => navigateToUser(email)}
                        key={user.email}
                      >
                        <td>
                          <div className={classes.userNameTd}>
                            <span>
                              {firstName} {lastName}{" "}
                            </span>

                            <span className={classes.masquerade}>
                              <GiDominoMask
                                onClick={() => {
                                  masqueradeAsUser(user.email);
                                }}
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          {roles.map((role: string) => (
                            <span className={"settings-role"} key={role}>
                              {role}
                            </span>
                          ))}
                        </td>
                        <td>{email}</td>
                        <td>{lastActivity}</td>
                        <td className={"action"}>
                          <AiFillEdit />
                        </td>
                      </tr>
                    );
                  })}
                {/*{isLoading &&*/}
                {/*  [...Array(8)].map(() => (*/}
                {/*    <tr>*/}
                {/*      <td></td>*/}
                {/*      <td></td>*/}
                {/*      <td></td>*/}
                {/*      <td></td>*/}
                {/*      <td>*/}
                {/*        <span style={{ opacity: 0 }}>a</span>*/}
                {/*      </td>*/}
                {/*    </tr>*/}
                {/*  ))}*/}
              </tbody>
            </table>
          )}
          {users.length === 0 && t("root.thisAccountDoesNotContainAnyUsers")}
        </>
      )}
    </>
  );
};

export default Users;
