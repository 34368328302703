import { propertiesOntology } from "data/propertiesOntology";
import {
  GenericProperty,
  PropertyCategory,
} from "models/Ndtrc";
import { SingleSelectOption } from "models/SelectOption";

// Source: https://thewebdev.info/2021/06/19/how-to-find-an-object-property-by-key-deep-in-a-nested-javascript-array/
const findNestedObject = (object: any, key: string, value: any): any => {
  if (Array.isArray(object)) {
    for (const obj of object) {
      const result = findNestedObject(obj, key, value);
      if (result) {
        return result;
      }
    }
  } else {
    if (object.hasOwnProperty(key) && object[key] === value) {
      return object;
    }

    for (const k of Object.keys(object)) {
      if (typeof object[k] === "object") {
        const o = findNestedObject(object[k], key, value);
        if (o !== null && typeof o !== "undefined") return o;
      }
    }

    return null;
  }
};

export const getPropertyNameById = (id: string): string | null => {
  return getPropertyObjectById(id)?.name || "UNKNOWN";
};

export const getPropertyObjectById = (id: string): GenericProperty | null => {
  const ontology = propertiesOntology.propertyCategories as PropertyCategory[];

  const digits = getPropertyObjectDigits(id);
  if (!digits) {
    return null;
  }

  const propertyObject = findNestedObject(ontology, "id", id);
  return propertyObject || null;
};

// May return 1-3 digits in array, or null
export const getPropertyObjectDigits = (id: string): number[] | null => {
  // Determine if valid id
  if (id.match(/[0-9]+(.[0-9]+)?(.[0-9]+)?/)?.[0] !== id) {
    console.error(`Requested category with invalid ID: ${id}`);
    return null;
  }
  return id.match(/[0-9]+/g)?.map((v) => parseInt(v)) || null;
};

export const getObjectArrayByCategoryIds = (
  ids: number[]
): PropertyCategory[] | null => {
  return ids.map((id) => {
    return getPropertyObjectById(id.toString()) as PropertyCategory;
  });
};

export const getRemainingCategoryOptions = (
  catIds: number[]
): SingleSelectOption[] => {
  return propertiesOntology.propertyCategories
    .filter((cat) => {
      const firstDigit = getPropertyObjectDigits(cat.id)?.[0];
      if (!firstDigit) {
        return false;
      }
      return !catIds.find((mainCat) => mainCat === firstDigit);
    })
    .map((cat) => ({ value: cat.id, label: cat.name }))
    .sort((a, b) => {
      return a.label > b.label ? 1 : -1;
    });
};
