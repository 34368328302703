import React from "react";
import Plot from "react-plotly.js";
import Loader from "../Loader";
import {
  DateAggType,
  IngressMethodDelta,
} from "../../models/IngressMethodDelta/IngressMethodDelta";
import classes from "./LineChart.module.scss";
import { useTranslation } from "react-i18next";

const plotColours = {
  feed: "#D81B60",
  form: "#1E88E5",
  manual: "#FFC107",
  total: "#004D40",
  background: "transparent",
};

// create a component to display the data
const LineChart = (props: {
  data: IngressMethodDelta[];
  granularity: DateAggType;
}) => {
  const { t } = useTranslation();

  const data = props.data;
  const granularity = props.granularity;

  return (
    <div className={classes.LineChart}>
      {data && data.length > 0 ? (
        <Plot
          data={[
            {
              x: data && data.map((item) => item.date_agg),
              y: data && data.map((item) => item.total),
              type: "scatter",
              mode: "lines",
              marker: { color: plotColours.total },
              name: t(`dashboard.total`),
            },
            {
              x: data && data.map((item) => item.date_agg),
              y: data && data.map((item) => item.feed),
              type: "scatter",
              mode: "lines",
              marker: { color: plotColours.feed },
              name: t(`dashboard.feed`),
            },
            {
              x: data && data.map((item) => item.date_agg),
              y: data && data.map((item) => item.form),
              type: "scatter",
              mode: "lines",
              marker: { color: plotColours.form },
              name: t(`dashboard.form`),
            },
            {
              x: data && data.map((item) => item.date_agg),
              y: data && data.map((item) => item.manual),
              type: "scatter",
              mode: "lines",
              marker: { color: plotColours.manual },
              name: t(`dashboard.manual`),
            },
          ]}
          // TODO: need to sort out the border padding as the x-axis labels are being cut off
          style={{ width: "100%", height: "100%" }}
          layout={{
            title: {
              // text: "Upload Method",
              // text: data && data.map((item) => item.userorganisation),
              font: {
                // family: "Courier New, monospace",
                size: 20,
              },
              xref: "paper",
              x: 0.05,
            },
            xaxis: {
              // showgrid: false,
              // showline: false,
              // title: {
              //   text: "Date",
              //   font: {
              //     size: 18,
              //     color: "#7f7f7f",
              //   },
              // },
            },
            yaxis: {
              // showgrid: false,
              // showline: false,
              title: {
                text: t("dashboard.numberOfEvents"),
                font: {
                  // size: 18,
                },
              },
            },
            margin:
              granularity === "Week"
                ? {
                    l: 70,
                    r: 50,
                    b: 200,
                    t: 30,
                    pad: 4,
                  }
                : {
                    l: 70,
                    r: 50,
                    b: 50,
                    t: 30,
                    pad: 4,
                  },
            paper_bgcolor: plotColours.background,
            plot_bgcolor: plotColours.background,
            font: {
              family: "Inter var, sans-serif",
            },
          }}
          config={{ displaylogo: false, responsive: true }}
        />
      ) : (
        <div className={classes.loaderWrapper}>
          <Loader></Loader>
        </div>
      )}
    </div>
  );
};

export default LineChart;
