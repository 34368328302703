import React, { useState } from "react";
import classes from "./StandardImageLibrary.module.scss";
import { createPortal } from "react-dom";
import { MdClose, MdPhotoLibrary } from "react-icons/md";
import { useTranslation } from "react-i18next";
import {
  ExtLabelLangString,
  FileItem,
  IntFileItem,
} from "models/Ndtrc";

const StandardImageLibrary = (props: {
  children: any;
  onSubmit: (imageFile: IntFileItem) => void;
  standardImageLibrary: IntFileItem[];
}) => {
  const { t } = useTranslation();
  const parentContainer = document.querySelector("#root") as HTMLElement;

  const [modalActive, setModalActive] = useState<boolean>(false);

  const onMediaHandler = (imageUrl: FileItem<ExtLabelLangString[]>) => {
    props.onSubmit(imageUrl);
    setModalActive(false);
  };

  const onButtonClickHandler = () => {
    setModalActive(true);
  };

  return (
    <>
      {props.children(onButtonClickHandler)}
      {createPortal(
        <>
          <div
            className={[classes.modal, modalActive ? classes.active : ""].join(
              " ",
            )}
          >
            <div>
              <button
                className={classes.closeButton}
                onClick={() => {
                  setModalActive(false);
                }}
              >
                <MdClose />
              </button>
            </div>
            <div className={classes.elaboration}>
              <MdPhotoLibrary />
              <h1>{t("form.imageLibraryTitle")}</h1>
              <p>{t("form.imageLibraryDesc")}</p>
            </div>
            <div className={classes.imagesContainer}>
              {props.standardImageLibrary.map((imageFile, index) => (
                <img
                  key={index}
                  className={classes.image}
                  src={imageFile.hlink}
                  onClick={() => {
                    onMediaHandler(imageFile);
                  }}
                  alt={""}
                />
              ))}
            </div>
          </div>
          <div
            className={`${classes.backdrop} ${
              modalActive ? classes.active : ""
            }`}
            onClick={() => {
              setModalActive(false);
            }}
          />
        </>,
        parentContainer,
      )}
    </>
  );
};

export default StandardImageLibrary;
