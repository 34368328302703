import { Lang } from "models/Lang";
import { AxiosResponse } from "axios";
import {
  externalResultsToInternalResults,
  ExtPaginatedResponse,
  feedFactoryAxios,
  generateNdtrcDateTime,
} from "helpers";
import { getItemTitle } from "./getItemTitle";
import stringSimilarity from "string-similarity";
import { MdApproval, MdArchive, MdCheckBox, MdDelete } from "react-icons/md";
import React from "react";
import { SingleSelectOption } from "../../models/SelectOption";
import { InternalEventItemModel } from "../../models/Ndtrc";

export const fetchEventsByQuery = async (
  inputValue: string,
  primaryLang: Lang,
  userOrganisations?: string[] | null,
  additionalParams?: { key: string; value: string }[],
): Promise<SingleSelectOption[]> => {
  const resultsToShow = 100;
  const res: AxiosResponse<ExtPaginatedResponse> = await feedFactoryAxios.get(
    `/events?size=${resultsToShow}&page=0&search=${encodeURIComponent(
      inputValue.toLowerCase(),
    )}&sortField=eventStart` +
      additionalParams?.map((p) => `&${p.key}=${p.value}`).join("") || "",
  );

  const rawEvents = externalResultsToInternalResults(res.data);
  return (rawEvents.results as InternalEventItemModel[])
    .map((event) => {
      const eventDate = generateNdtrcDateTime(event.calendar).dateSummary;
      const eventLoc = event.location?.label || "";
      const eventStatusEmoji = (() => {
        switch (event.wfstatus) {
          case "approved":
            return <MdCheckBox />;
          case "rejected":
          case "deleted":
            return <MdDelete />;
          case "readyforvalidation":
            return <MdApproval />;
          case "draft":
            return "📝";
          case "archived":
            return <MdArchive />;
          default:
            return "❓";
        }
      })();

      const eventLabelText = `${getItemTitle(event, primaryLang)} (${eventLoc}${
        eventDate ? `, ${eventDate}` : ""
      })`;

      const eventLabel = (
        <span className={"eventOption"}>
          {eventStatusEmoji}
          <span>{eventLabelText}</span>
        </span>
      );

      return {
        label: eventLabelText,
        richLabel: eventLabel,
        wfstatus: event.wfstatus,
        value: event.id,
        distance: stringSimilarity.compareTwoStrings(
          inputValue,
          eventLabelText,
        ),
      };
    })
    .sort((a, b) => {
      if (a.distance > b.distance) return -1;
      if (a.distance < b.distance) return 1;
      return 0;
    })
    .map((option) => ({
      label: option.label,
      wfstatus: option.wfstatus,
      richLabel: option.richLabel,
      value: option.value || "NO_ID",
    }));
};
