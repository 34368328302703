export enum Weekday {
  zo = 1,
  ma = 2,
  di = 3,
  wo = 4,
  do = 5,
  vr = 6,
  za = 7,
}

export const weekDaysDisplayOrder = [
  Weekday.ma,
  Weekday.di,
  Weekday.wo,
  Weekday.do,
  Weekday.vr,
  Weekday.za,
  Weekday.zo,
];
