import React, { memo } from "react";
import twemoji from "twemoji";

export const Twemoji = (props: { children: any }) => {
  if (!props.children) {
    return null;
  }

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: twemoji.parse(props.children, {
          folder: "svg",
          ext: ".svg",
          base: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/",
        }) as unknown as string,
      }}
    />
  );
};

export default memo(Twemoji);
