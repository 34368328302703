import React from "react";
import classes from "../TuneWidget/TuneWidget.module.scss";
import { useTranslation } from "react-i18next";
import { HiViewGrid, HiViewList } from "react-icons/hi";
import { ViewType } from "../../models/ViewType/ViewType";

const ViewChangeButton = (props: {
  onClick: () => any;
  currentView: ViewType;
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${classes.Button} ${classes.ViewChangeButton}`}
      onClick={props.onClick}
    >
      <div title={t("tune.viewType")} className={classes.Dropdown}>
        <div className={classes.DropdownContent}>
          {props.currentView === "CardView" ? <HiViewList /> : <HiViewGrid />}
        </div>
      </div>
    </div>
  );
};

export default ViewChangeButton;
