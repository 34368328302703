import React, { useState } from "react";
import classes from "./BooleanInputPopover.module.scss";
import { usePopper } from "react-popper";
import popoverStyles from "./PopoverSharedStyle.module.scss";
import { useTranslation } from "react-i18next";

export type BooleanInputPopoverType = "normal" | "danger";

const BooleanInputPopover = (props: {
  children: any;
  onBooleanSubmit: (response: boolean) => any;
  prompt: string;
  type?: BooleanInputPopoverType;
}) => {
  const { t } = useTranslation();
  const [popover, setPopover] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(popover, popperElement, {});
  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  const onOpenPopover = () => {
    if (update) {
      void update();
    }
    setPopoverActive(true);
  };

  let styleClass = "";
  switch (props.type) {
    case "danger":
      styleClass = classes.danger;
  }

  const onBooleanButtonClick = (response: boolean) => {
    props.onBooleanSubmit(response);
    setPopoverActive(false);
  };

  return (
    <>
      {props.children(setPopover, onOpenPopover)}
      <div
        className={[
          popoverStyles.popover,
          classes.popover,
          popoverActive ? popoverStyles.active : "",
          styleClass,
        ].join(" ")}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <p>{props.prompt}</p>
        <div className={classes.buttons}>
          <button type="button" onClick={() => onBooleanButtonClick(false)}>
            {t("root.no")}
          </button>
          <button type="button" onClick={() => onBooleanButtonClick(true)}>
            {t("root.yes")}
          </button>
        </div>
      </div>
      <div
        className={`${popoverStyles.backdrop} ${
          popoverActive ? popoverStyles.active : ""
        }`}
        onClick={() => {
          setPopover(null);
          setPopoverActive(false);
        }}
      />
    </>
  );
};

export default BooleanInputPopover;
