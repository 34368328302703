import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { feedFactoryAxios } from "helpers";
import classes from "./ObjectSelectWidget.module.scss";
import Select, { ActionMeta } from "react-select";
import { ExtPromotion } from "models/Ndtrc";

export enum ObjectType {
  EventPromotionProduct,
  LocationPromotionProduct,
}

const getRightDictionaryUrl = (type: ObjectType): string | null => {
  switch (type) {
    case ObjectType.EventPromotionProduct:
      return "/dictionary/event_promotion_products";
    case ObjectType.LocationPromotionProduct:
      return "/dictionary/location_promotion_products";
    default:
      return null;
  }
};

interface ObjectSelectOption {
  label: string;
  value: string;
  type: ObjectType;
  data: ExtPromotion;
}

const ObjectSelectWidget = (props: {
  value: ObjectSelectOption | null;
  onChange: (value: ObjectSelectOption) => void;
  type: ObjectType;
  onKeyDown?: (event: any) => any;
  allowRemovingItems?: boolean;
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<ObjectSelectOption[]>([]);

  useEffect(() => {
    const url = getRightDictionaryUrl(props.type);
    if (!url) {
      return;
    }
    feedFactoryAxios.get(url).then((res) => {
      const options = res.data
        .filter((opt: ExtPromotion) => {
          // Check if promotion is enabled and not expired
          return (
            opt.enabled && (!opt.endDate || new Date(opt.endDate) >= new Date())
          );
        })
        .map((opt: ExtPromotion) => {
          return {
            value: opt.product,
            label: opt.product,
            type: props.type,
            data: opt,
          };
        });
      setOptions(options);
    });
  }, []);

  return (
    <div className={classes.DictionarySelectWidget}>
      <Select
        className={`multiSelect ${classes.select}`}
        value={props.value}
        options={options}
        menuPlacement={"auto"}
        placeholder={`${t("form.select")}...`}
        formatCreateLabel={(inputText: string) =>
          `${t("form.createPrefix")} "${inputText}" ${t("form.createSuffix")}`
        }
        styles={{
          menu: (provided: any, state) => {
            return { ...provided, zIndex: 1000000 };
          },
          multiValue: (base: any, state) => {
            return !props.allowRemovingItems
              ? { ...base, backgroundColor: "gray" }
              : base;
          },
          multiValueLabel: (base: any, state) => {
            return !props.allowRemovingItems
              ? {
                  ...base,
                  fontWeight: "bold",
                  color: "white",
                  paddingRight: 6,
                }
              : base;
          },
          multiValueRemove: (base: any, state) => {
            return !props.allowRemovingItems
              ? { ...base, display: "none" }
              : base;
          },
        }}
        isClearable={props.allowRemovingItems || false}
        onChange={(item: any, actionMeta: ActionMeta<any>) => {
          if (
            (actionMeta.action === "pop-value" ||
              actionMeta.action === "remove-value" ||
              actionMeta.action === "clear") &&
            !props.allowRemovingItems
          ) {
            return;
          }

          props.onChange(item || null);
        }}
        onKeyDown={(event) => {
          if (props.onKeyDown) {
            props.onKeyDown(event);
          }
        }}
      />
    </div>
  );
};

export default ObjectSelectWidget;
