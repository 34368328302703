import { Filters } from "models/Filters";

export const createTokenFilter = (newTokenFilter: Filters) => {
  const params = new URLSearchParams();

  if (newTokenFilter.markers) {
    params.append("markers", newTokenFilter.markers);
  }
  if (newTokenFilter.extendedMarkers) {
    params.append("extendedmarkers", newTokenFilter.extendedMarkers);
  }
  if (newTokenFilter.userOrganisation) {
    params.append("userorganisation", newTokenFilter.userOrganisation);
  }
  if (newTokenFilter.geoDistanceLatLon) {
    params.append("geo_distance_latlon", newTokenFilter.geoDistanceLatLon);
  }
  if (newTokenFilter.geoDistance) {
    params.append("geo_distance", newTokenFilter.geoDistance);
  }
  if (newTokenFilter.transformer) {
    params.append("transformer", newTokenFilter.transformer);
  }
  if (newTokenFilter.wfstatus) {
    params.append("wfstatus", newTokenFilter.wfstatus);
  }

  return params.toString();
};

export const parseTokenFilter = (filter: string) => {
  const params = new URLSearchParams(filter);
  const markers = params.get("markers") || "";
  const extendedMarkers = params.get("extendedmarkers") || "";
  const userOrganisation = params.get("userorganisation") || "";
  const geoDistanceLatLon = params.get("geo_distance_latlon") || "";
  const geoDistance = params.get("geo_distance") || "";
  const transformer = params.get("transformer") || "";
  const wfstatus = params.get("wfstatus") || "";

  return {
    markers,
    extendedMarkers,
    userOrganisation,
    geoDistanceLatLon,
    geoDistance,
    transformer,
    wfstatus
  };
};
