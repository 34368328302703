import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  feedFactoryAxios,
  useNavTranslation,
} from "helpers";
import PopupButton from "../../../components/buttons/PopupButton/PopupButton";
import { MdAddCircle } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { ButtonSize } from "../../../components/buttons/ButtonProps";
import { AiFillEdit } from "react-icons/ai";
import moment from "moment";
import "moment/locale/nl";
import { AxiosResponse } from "axios";
import {
  AccountModel,
  AccountsResponse,
} from "../../../models/AccountModel/AccountModel";

const Accounts = (props: {}) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState<Array<AccountModel>>([]);

  const [error, setError] = useState<String | null>(null);
  const history = useHistory();
  const { getAccountUrl, newAccountUrl, getAccountUsersUrl } =
    useNavTranslation();

  useEffect(() => {
    feedFactoryAxios
      .get(`/accounts?size=100`)
      .then((response: AxiosResponse<AccountsResponse>) => {
        setAccounts(response.data.results);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const navigateToAccount = (accountId: string) => {
    history.push(getAccountUrl(accountId));
  };

  const navigateToNew = () => {
    history.push(newAccountUrl);
  };

  const navigateToAccountUsers = (accountId: string) => {
    history.push(getAccountUsersUrl(accountId));
  };

  return (
    <>
      <header className={"settings-header"}>
        <h2 className={"settings-title"}>{t("root.accounts")}</h2>
        <PopupButton
          className={"settings-header-button"}
          action={navigateToNew}
          size={ButtonSize.Small}
        >
          <>
            {t("account.newAccount")} <MdAddCircle />
          </>
        </PopupButton>
      </header>
      {error && error}
      {!error && accounts.length === 0 && t("root.noAccountsAvailable")}
      {!error && accounts.length > 0 && (
        <table>
          <thead>
            <tr>
              <td>{t("root.account")}</td>
              <td>{t("root.id")}</td>
              <td>{t("root.lastChange")}</td>
              <td>{t("root.createdOn")}</td>
              <td />
              <td />
            </tr>
          </thead>
          <tbody>
            {(accounts || []).map((account) => {
              let name = account.name;
              let id = account.id;
              let modifiedRaw = account.modified;
              let createdRaw = account.created;

              // let lastActivity: string = "geen activiteit bekend";
              //
              // if (lastActivityRaw) {
              //   moment.locale("nl"); // @todo update this by user language, on sign-in?
              //   lastActivity = moment(lastActivityRaw).fromNow();
              // }
              let modified: string = t("root.noActivityKnown");
              if (modifiedRaw) {
                modified = moment(modifiedRaw).fromNow();
              }

              let created: string = t("root.unknown");
              if (createdRaw) {
                created = moment(createdRaw).format("dd-MM-yyyy");
              }

              return (
                <tr onClick={() => navigateToAccount(id)} key={id}>
                  <td>{name}</td>
                  <td>{id}</td>
                  <td>{modified}</td>
                  <td>{created}</td>
                  <td
                    className={"action"}
                    onClick={(event) => {
                      event.stopPropagation();
                      navigateToAccountUsers(id);
                    }}
                  >
                    <FaUsers />
                  </td>
                  <td className={"action"}>
                    <AiFillEdit />
                  </td>
                </tr>
              );
            })}
            {isLoading &&
              [...Array(8)].map((empty, index: number) => (
                <tr key={index}>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <span style={{ opacity: 0 }}>a</span>
                  </td>
                  <td>
                    <span style={{ opacity: 0 }}>a</span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default Accounts;
