import React, { useEffect, useState } from "react";
import "./Nav.scss";
import {
  performSignOut,
  performUnMasquerade,
  selectIsMasquerading,
  selectUserMail,
} from "../../store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  MdAccountCircle,
  MdAddCircle,
  MdExitToApp,
  MdNotificationsActive,
} from "react-icons/md";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectAccountName } from "../../store/theme/themeSlice";
import classes from "./Nav.module.scss";
import { feedFactoryAxios } from "helpers";
import { AxiosResponse } from "axios";
import { UserResponse } from "../../models/UserResponse";
import useGlobalPermissions from "../../statefulHelpers/useGlobalPermissions";
import { GiDominoMask } from "react-icons/gi";
import { VERSION } from "../../version";

const Nav = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const router = useHistory();
  const location = useLocation();

  const accountName = useSelector(selectAccountName);
  const userMail = useSelector(selectUserMail);
  const isMasquerading = useSelector(selectIsMasquerading);

  const globalPermissions = useGlobalPermissions();

  const [eventsAssignedToSelf, setEventsAssignedToSelf] = useState<
    number | null
  >(null);
  const [locationsAssignedToSelf, setLocationsAssignedToSelf] = useState<
    number | null
  >(null);
  const [eventGroupsAssignedToSelf, setEventGroupsAssignedToSelf] = useState<
    number | null
  >(null);
  const [routesAssignedToSelf, setRoutesAssignedToSelf] = useState<
    number | null
  >(null);

  const getAssignedItems = () => {
    // Get items assigned to self
    const userUrl = `/auth/me`;
    feedFactoryAxios
      .get(userUrl)
      .then((response: AxiosResponse<UserResponse>) => {
        const validator = response.data.email;
        feedFactoryAxios.get(`/events?validator=${validator}`).then((res) => {
          setEventsAssignedToSelf(res.data.hits);
        });
        feedFactoryAxios
          .get(`/locations?validator=${validator}`)
          .then((res) => {
            setLocationsAssignedToSelf(res.data.hits);
          });
        feedFactoryAxios
          .get(`/eventgroups?validator=${validator}`)
          .then((res) => {
            setEventGroupsAssignedToSelf(res.data.hits);
          });
        // TODO: turn on when routes are implemented
        // feedFactoryAxios.get(`/routes?validator=${validator}`).then((res) => {
        //   setRoutesAssignedToSelf(res.data.hits);
        // });
      });
  };

  // Listen to any URL change
  useEffect(() => {
    getAssignedItems();
  }, [location]);
  // And on first load
  useEffect(() => {
    getAssignedItems();
  }, []);

  const handleSignOut = () => {
    dispatch(performSignOut(router));
  };

  const unmasquarade = () => {
    dispatch(performUnMasquerade());
  };

  return (
    <>
      <div className={"root-app"}>
        <header
          className={
            "ff-nav-header" + (isMasquerading ? " " + classes.masquerading : "")
          }
        >
          <NavLink to="/">
            <img src="/img/ff_logo_white.png" alt="Logo" />
          </NavLink>
          <nav>
            <ul>
              <li className="addable">
                <NavLink to={`/${t("root.events.slug")}`}>
                  {t("root.events")}
                </NavLink>{" "}
                <NavLink
                  to={`/${t("root.events.slug")}/${t("root.new")}`}
                  title={t("root.makeNewEvent")}
                >
                  <MdAddCircle />
                </NavLink>
                {eventsAssignedToSelf !== null && eventsAssignedToSelf > 0 && (
                  <NavLink
                    to={`/${t("root.events.slug")}?${t("root.myItems")}=true`}
                    exact={true}
                  >
                    <div className={classes.notificationBannerBadge}>
                      <MdNotificationsActive />
                      <span>{eventsAssignedToSelf}</span>
                    </div>
                  </NavLink>
                )}
              </li>
              {globalPermissions?.mayAccessEventGroups && (
                <li className="addable">
                  <NavLink to={`/${t("root.eventGroups.slug")}`}>
                    {t("root.eventGroups")}
                  </NavLink>{" "}
                  <NavLink
                    to={`/${t("root.eventGroups.slug")}/${t("root.new")}`}
                    title={t("root.makeNewEventGroup")}
                  >
                    <MdAddCircle />
                  </NavLink>
                  {eventGroupsAssignedToSelf !== null &&
                    eventGroupsAssignedToSelf > 0 && (
                      <NavLink
                        to={`/${t("root.eventGroups.slug")}?${t(
                          "root.myItems",
                        )}=true`}
                        exact={true}
                      >
                        <div className={classes.notificationBannerBadge}>
                          <MdNotificationsActive />
                          <span>{eventGroupsAssignedToSelf}</span>
                        </div>
                      </NavLink>
                    )}
                </li>
              )}
              <li className="addable">
                <NavLink to={`/${t("root.locations.slug")}`} exact={true}>
                  {t("root.locations")}
                </NavLink>
                {globalPermissions?.mayCreateNewLocations && (
                  <NavLink
                    to={`/${t("root.locations.slug")}/${t("root.new")}`}
                    title={t("root.makeNewLocation")}
                  >
                    <MdAddCircle />
                  </NavLink>
                )}
                {locationsAssignedToSelf !== null &&
                  locationsAssignedToSelf > 0 && (
                    <NavLink
                      to={`/${t("root.locations.slug")}?${t(
                        "root.myItems",
                      )}=true`}
                    >
                      <div className={classes.notificationBannerBadge}>
                        <MdNotificationsActive />
                        <span>{locationsAssignedToSelf}</span>
                      </div>
                    </NavLink>
                  )}
              </li>
              {globalPermissions?.mayAccessRoutes && (
                <li className="addable">
                  <NavLink to={`/${t("root.routes.slug")}`}>
                    {t("root.routes")}
                  </NavLink>{" "}
                  <NavLink
                    to={`/${t("root.routes.slug")}/${t("root.new")}`}
                    title={t("root.makeNewRoute")}
                  >
                    <MdAddCircle />
                  </NavLink>
                  {routesAssignedToSelf !== null && routesAssignedToSelf > 0 && (
                    <NavLink
                      to={`/${t("root.routes.slug")}?${t("root.myItems")}=true`}
                      exact={true}
                    >
                      <div className={classes.notificationBannerBadge}>
                        <MdNotificationsActive />
                        <span>{routesAssignedToSelf}</span>
                      </div>
                    </NavLink>
                  )}
                </li>
              )}
              <li className={"nav-header-spacer"} />
              {/*Account*/}
              {isMasquerading && (
                <div
                  className={classes.returnToMainUser}
                  onClick={unmasquarade}
                >
                  <span>{t("root.backToMainUser")}</span>
                </div>
              )}
              <li style={{ marginTop: "5px" }}>
                <NavLink to={`/${t("root.myaccount.slug")}`}>
                  {isMasquerading ? (
                    <GiDominoMask style={{ fontSize: "22px" }} />
                  ) : (
                    <MdAccountCircle style={{ fontSize: "22px" }} />
                  )}
                </NavLink>
              </li>

              {/*Dashboard*/}
              {globalPermissions?.mayAccessDashboard && (
                <li>
                  <NavLink to={`/${t("root.dashboard.slug")}`}>
                    {/*<MdBarChart style={{ fontSize: "22px" }} />*/}
                    {t("root.dashboard")}
                  </NavLink>
                </li>
              )}

              {/*Settings*/}
              {globalPermissions?.mayAccessSettings && (
                <li>
                  <NavLink to={`/${t("root.settings.slug")}`}>
                    {t("root.settings")}
                    {/*<MdSettings style={{ fontSize: "22px" }} />*/}
                  </NavLink>
                </li>
              )}
              <li>
                <a
                  href={"https://support.thefeedfactory.nl/help/nl-nl"}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t("root.help")}
                </a>
              </li>
              <li onClick={handleSignOut} style={{ cursor: "pointer" }}>
                <a href="/">
                  {t("root.logOut")}
                  <MdExitToApp />
                </a>
              </li>
            </ul>
          </nav>
        </header>
        <main>{props.children}</main>
      </div>
      <footer
        className={
          "root-footer" + (isMasquerading ? " " + classes.masquerading : "")
        }
      >
        <span className={"company"}>The Feed Factory</span>{" "}
        <span className={classes.version} title={VERSION.raw}>
          v{VERSION.version}
        </span>{" "}
        <span className={"seperator"}>/</span>{" "}
        <span className={"account"}>{accountName}</span>
        <span className={"seperator"}>/</span>{" "}
        <span className={"user"}>{userMail}</span>
        {isMasquerading && (
          <>
            <span className={"seperator"}></span>{" "}
            <span>
              <GiDominoMask />
            </span>
          </>
        )}
      </footer>
    </>
  );
};

export default Nav;
