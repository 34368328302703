// Adapted from https://usehooks.com/useKeyPress/
import { useEffect, useState } from "react";

export function useSaveShortcut() {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler(e: KeyboardEvent) {
    if (
      (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
      e.key === "s"
    ) {
      e.preventDefault();
      setKeyPressed(true);
    }
  }

  const upHandler = (e: KeyboardEvent) => {
    if (
      (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) ||
      e.key === "s"
    ) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}
