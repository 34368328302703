import React, { useCallback, useEffect, useState } from "react";

import classes from "./TuneWidget.module.scss";
import { AiOutlineSearch } from "react-icons/ai";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const SearchWidget = (props: {
  value: string | null;
  setValue: (newValue: string) => void;
}) => {
  const [searchQuery, setSearchQuery] = useState(props.value || "");
  const [hasInitialised, setHasInitialised] = useState(false);

  const { t } = useTranslation();

  const fetchSearch = useCallback(
    _.debounce(
      (searchQuery) => {
        if (searchQuery.length > 2) {
          props.setValue(searchQuery);
        } else {
          props.setValue("");
        }
        // props.onQuery(searchQuery);
      },
      700,
      {
        leading: false,
        trailing: true,
      }
    ),
    []
  );

  useEffect(() => {
    if (props.value !== searchQuery) {
      fetchSearch(searchQuery); // Force latest user entry to load
    }
  }, [props.value]);

  useEffect(() => {
    // Avoid triggering empty dispatch on first render
    if (!hasInitialised) {
      setHasInitialised(true);
      return;
    }
    fetchSearch(searchQuery);
    // Should not update on hasInitialised change
    // eslint-disable-next-line
  }, [fetchSearch, searchQuery]);

  return (
    <>
      <div className={classes.SearchWidget}>
        <input
          type={"text"}
          placeholder={""}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <span
          className={`${classes.placeholder} ${
            searchQuery.length > 0 ? classes.hasInput : ""
          }`}
        >
          <AiOutlineSearch />
          {searchQuery.length === 0 && <i>{t("tuning.search")}...</i>}
        </span>
      </div>
    </>
  );
};

export default SearchWidget;
