import React, { useEffect, useState } from "react";
import LoginField from "../components/LoginField";
import classes from "../Login.module.scss";
import { useTranslation } from "react-i18next";
import NavigationButton from "components/buttons/NavigationButton/NavigationButton";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { feedFactoryAxios } from "helpers";
import SettingsField from "../../Settings/components/SettingsField";

const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const PasswordResetPrompt = (props: { origPath?: string }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [usernameOrEmailInvalid, setUsernameOrEmailInvalid] = useState(false);

  const [unknownMailAddress, setUnknownMailAddress] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [newPasswordMismatch, setNewPasswordMismatch] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);

  const [submittedInvalid, setSubmittedInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const location = useLocation();

  // TODO: use once received
  const token = new URLSearchParams(location.search).get("token");
  const accountid = new URLSearchParams(location.search).get("accountid");
  const username = new URLSearchParams(location.search).get("username");

  useEffect(() => {
    if (newPasswordConfirmation.length > 0) {
      if (newPassword !== newPasswordConfirmation) {
        return setNewPasswordMismatch(true);
      }
    }

    setNewPasswordMismatch(false);
  }, [newPassword, newPasswordConfirmation]);

  const performResetRequest = () => {
    setUnknownMailAddress(false);
    setIsLoading(true);
    if (usernameOrEmail.length < 1 || !validateEmail(usernameOrEmail)) {
      setUsernameOrEmailInvalid(true);
      setIsLoading(false);
      return;
    }

    feedFactoryAxios
      .post(`/users/${usernameOrEmail}/resetPassword`)
      .then((res) => {
        // console.log(res); // DEBUG
        setIsLoading(false);
        setSuccess(true);
      })
      .catch((res) => {
        console.error(res); // DEBUG
        setIsLoading(false);
        setSuccess(false);
        setUnknownMailAddress(true);
      });
  };

  const submitNewPassword = () => {
    // check if valid password
    if (newPassword.length < 4 || newPasswordMismatch) {
      return;
    }

    feedFactoryAxios
      .put(
        `/accounts/${accountid}/users/${username}`,
        {
          password: newPassword,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        // Forward to main page
        history.push("/?password-reset=true");
      })
      .catch((res) => {
        setNewPasswordError(true);
        console.error(res);
      });
  };

  const handleFieldChange = (updateValue: any, invalidateValue: any) => {
    return (newValue: string) => {
      invalidateValue(false);

      setSubmittedInvalid(false);
      setSuccess(false); // Reset field status

      updateValue(newValue);
    };
  };

  return (
    <section
      className={"ff-login-form"}
      onKeyPress={(e) => {
        e.key === "Enter" && performResetRequest();
      }}
    >
      <NavLink to={props.origPath || "/"}>
        {"<"} {t("root.back")}
      </NavLink>
      <header className={"form-header"}>
        <h2 className={"form-headline"}>{t("login.passwordReset")}</h2>
      </header>

      {!(token && accountid && username) ? (
        <>
          <LoginField
            autofocus={true}
            value={usernameOrEmail}
            setValue={handleFieldChange(
              setUsernameOrEmail,
              setUsernameOrEmailInvalid
            )}
            invalid={usernameOrEmailInvalid}
            label={t("login.usernameOrEmailLabel")}
            invalidLabel={t("login.usernameOrEmailRequired")}
          />
          {!success && (
            <NavigationButton action={performResetRequest} disabled={isLoading}>
              {t("login.passwordResetButton")}
            </NavigationButton>
          )}
        </>
      ) : (
        <div
          className={`settings-block ${classes.setNewPasswordContainer}`}
          onKeyPress={(e) => {
            e.key === "Enter" && submitNewPassword();
            e.stopPropagation();
          }}
        >
          <SettingsField
            label={t("root.newPassword")}
            value={newPassword}
            setValue={setNewPassword}
            type={"password"}
          />
          <SettingsField
            label={t("root.repeatNewPassword")}
            value={newPasswordConfirmation}
            setValue={setNewPasswordConfirmation}
            type={"password"}
          />
          <NavigationButton
            action={submitNewPassword}
            disabled={
              isLoading ||
              success ||
              newPasswordMismatch ||
              newPasswordConfirmation.length < 1
            }
          >
            {t("login.submitNewPassword")}
          </NavigationButton>
        </div>
      )}

      {unknownMailAddress && (
        <p className={`form-error-message ${classes.errorBox}`}>
          {t("login.emailUnknown")}
        </p>
      )}

      {submittedInvalid && (
        <p className={`form-error-message ${classes.errorBox}`}>
          {t("login.usernameOrEmailOrPasswordInvalid")}
        </p>
      )}

      {newPasswordMismatch && (
        <p className={`form-error-message ${classes.errorBox}`}>
          {t("root.passwordsDoNotMatch")}
        </p>
      )}

      {newPasswordError && (
        <p className={`form-error-message ${classes.errorBox}`}>
          {t("login.passwordSetFail")}
        </p>
      )}

      {success && (
        <div className={classes.resetSuccess}>
          {t("login.passwordResetSuccess")}
        </div>
      )}
    </section>
  );
};

export default PasswordResetPrompt;
