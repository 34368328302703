import React from "react";
import Loader from "../Loader";
import classes from "./TimeSaved.module.scss";
import { useTranslation } from "react-i18next";
import { ItemsLeftToValidateData } from "../../models/ItemsLeftToValidateData/ItemsLeftToValidateData";

const ItemsLeftToValidate = (props: {
  data: ItemsLeftToValidateData | null;
}) => {
  const { t } = useTranslation();
  return (
    <div className={classes.TimeSaved}>
      <h2>{t("dashboard.itemsLeftToValidate")}</h2>
      <p>
        {props.data ? props.data.items_left_to_validate || "???" : <Loader />}{" "}
        {t("dashboard.items")}
      </p>
    </div>
  );
};

export default ItemsLeftToValidate;
