import React from "react";
import { ExtProperty } from "../../../../models/Ndtrc/Ndtrc";
import classes from "./VenuePreview.module.scss";
import { getPropertyObjectById } from "helpers";
import { Property } from "../../../../models/Ndtrc/PropertiesSource";

const VenuePreview = (props: {
  properties: ExtProperty[] | null;
  numberOfRooms?: number | null;
}) => {
  if (!props.properties) {
    return null;
  }

  const internetConnectionId = props.properties.find(
    (prop) => prop.catid === "22.11"
  )?.valueid;
  const internetConnectionVal = internetConnectionId
    ? (getPropertyObjectById("22.11") as Property)?.options?.find(
        (option) => option.id === internetConnectionId
      )?.name
    : null;

  const preview = {
    // Property overwrites count of array of rooms
    numberEventRooms:
      props.properties.find((prop) => prop.catid === "5.4")?.value ||
      props.numberOfRooms ||
      "-",
    numberHotelRooms: props.properties.find((prop) => prop.catid === "5.6")
      ?.value,
    capLargestRoom: props.properties.find((prop) => prop.catid === "5.17")
      ?.value,
    numberInhouseRestaurants: props.properties.find(
      (prop) => prop.catid === "44.5"
    )?.value,
    internetConnections: internetConnectionVal,
    parkingPlaces: props.properties.find((prop) => prop.catid === "20.14")
      ?.value,
    distanceAirport: props.properties.find((prop) => prop.catid === "10.9")
      ?.value,
    distanceTrainStation: props.properties.find(
      (prop) => prop.catid === "10.39"
    )?.value,
  };

  return (
    <div className="table-responsive">
      <table cellPadding="0" cellSpacing="0" className={classes.VenueTable}>
        <thead>
          <tr>
            <th>
              <img
                src="/venue-finder-icons/icon-5.6.webp"
                alt="Number of event rooms"
                title="Number of event rooms"
              />
            </th>
            <th>
              <img
                src="/venue-finder-icons/icon-5.6.1.webp"
                alt="Number of hotel rooms"
                title="Number of hotel rooms"
              />
            </th>
            <th>
              <img
                src="/venue-finder-icons/icon-5.17.webp"
                alt="Capacity of largest room"
                title="Capacity of largest room"
              />
            </th>
            <th>
              <img
                src="/venue-finder-icons/icon-44.5.webp"
                alt="Number of inhouse restaurants"
                title="Number of inhouse restaurants"
              />
            </th>
            <th>
              <img
                src="/venue-finder-icons/icon-22.11.webp"
                alt="Internet connection"
                title="Internet connection"
              />
            </th>
            <th>
              <img
                src="/venue-finder-icons/icon-20.14.webp"
                alt="Number of parking places"
                title="Number of parking places"
              />
            </th>
            <th>
              <img
                src="/venue-finder-icons/icon-10.9.webp"
                alt="Distance from airport"
                title="Distance from airport"
              />
            </th>
            <th>
              <img
                src="/venue-finder-icons/icon-10.39.webp"
                alt="Distance to train station"
                title="Distance to train station"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{preview.numberEventRooms ? preview.numberEventRooms : "-"}</td>
            <td>{preview.numberHotelRooms ? preview.numberHotelRooms : "-"}</td>
            <td>{preview.capLargestRoom ? preview.capLargestRoom : "-"}</td>
            <td>
              {preview.numberInhouseRestaurants
                ? preview.numberInhouseRestaurants
                : "-"}
            </td>
            <td>
              {preview.internetConnections ? preview.internetConnections : "-"}
            </td>
            <td>{preview.parkingPlaces ? preview.parkingPlaces : "-"}</td>
            <td>
              {preview.distanceAirport ? preview.distanceAirport + " km" : "-"}
            </td>
            <td>
              {preview.distanceTrainStation
                ? preview.distanceTrainStation + " km"
                : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default VenuePreview;
