import { Lang } from "models/Lang";
import { AxiosResponse } from "axios";
import {
  externalResultsToInternalResults,
  ExtPaginatedResponse,
  feedFactoryAxios,
  toTitleCase,
} from "helpers";
import { getItemTitle } from "./getItemTitle";
import stringSimilarity from "string-similarity";
import { SingleSelectOption } from "models/SelectOption";

export const fetchLocationsByQuery = async (
  inputValue: string,
  primaryLang: Lang,
  userOrganisations?: string[] | null,
  additionalParams?: { key: string; value: string }[]
): Promise<SingleSelectOption[]> => {
  const resultsToShow = 100;
  const res: AxiosResponse<ExtPaginatedResponse> = await feedFactoryAxios.get(
    `/locations?size=${resultsToShow}&page=0&search=${encodeURIComponent(
      inputValue.toLowerCase()
    )}&userorganisation=${
      userOrganisations ? userOrganisations.join(";") : "*"
    }&wfstatus=approved${
      additionalParams?.map((p) => `&${p.key}=${p.value}`) || ""
    }`
  );
  const rawLocations = externalResultsToInternalResults(res.data);
  return rawLocations.results
    .map((location) => {
      const address = location.location?.address;

      const isCongressLocation = location.trcItemCategories?.types?.some(
        (val) => val.catid === "1.1.1"
      );
      const typeEmoji = isCongressLocation ? "💼" : "🎭";

      const locationLabel = `${typeEmoji} ${getItemTitle(
        location,
        primaryLang
      )} (${toTitleCase(address?.city)}, ${address?.street} ${
        address?.housenr
      })`;

      return {
        label: locationLabel,
        value: location.id,
        distance: stringSimilarity.compareTwoStrings(inputValue, locationLabel),
        type: isCongressLocation,
      };
    })
    .sort((a, b) => {
      if (a.distance > b.distance) return -1;
      if (a.distance < b.distance) return 1;
      return 0;
    })
    .map((option) => ({
      label: option.label,
      value: option.value || "NO_ID",
      type: option.type,
    }));
};
