import React, { MouseEvent } from "react";

import "../SharedButton.scss";
import "./NavigationButton.scss";
import ButtonProps, { ButtonType } from "../ButtonProps";

/**
 * Use a NavigationButton where the action will relate to a navigational action.
 * I.e. the opening of a form, the saving of a form
 */
const NavigationButton = (props: ButtonProps) => {
  const handleClick = (event: MouseEvent) => {
    props.disabled !== true && props.action();
  };

  return (
    <button
      className={`ff-component-navigation-button ${props.className}  ${
        props.disabled ? "ff-button-disabled" : "clickable"
      } ${props.type || ButtonType.Primary} ${props.size}`}
      onClick={handleClick}
      ref={props.myRef}
      type={"button"}
    >
      <span
        className={`displayFix ${
          props.expandTextOnHover ? "expand-text-on-hover" : ""
        }`}
      >
        {props.children}
      </span>
    </button>
  );
};

export default NavigationButton;
