import React from "react";
import { Controller, get } from "react-hook-form";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import {
  IntWhen,
  SingleDateStatus,
} from "models/Ndtrc";
import Select from "react-select";
import classes from "./WhenWidget.module.scss";
import { useTranslation } from "react-i18next";
import { SingleSelectOption } from "models/SelectOption";

const stringHHmmToDate = (value: string | null): Date | null => {
  if (!value) return null;
  const [h, m] = value.split(":");
  if (!h || !m) {
    // also if not date provided
    return null;
  }
  try {
    return new Date(1972, 1, 1, parseInt(h), parseInt(m));
  } catch (e) {
    return null; // If int cannot be parsed
  }
};

const WhenWidget = (props: {
  namespace: string;
  formMethods: any;
  defaultValue: IntWhen | null;
  disabled?: boolean;
  noStatus?: boolean;
  startTimeRequired?: boolean;
  endTimeRequired?: boolean;
  overrideSingleDateOptions?: SingleSelectOption[];
}) => {
  const { t } = useTranslation();
  let startTimeIsRequired = !props.disabled;
  if (props.startTimeRequired !== undefined) {
    // Default value is true
    startTimeIsRequired = props.startTimeRequired;
  }

  let endTimeIsRequired = false;
  if (props.endTimeRequired !== undefined) {
    // Default value is false
    endTimeIsRequired = props.endTimeRequired;
  }

  return (
    <div className={classes.WhenWidget}>
      <div>
        <Controller
          name={`${props.namespace}.timestart`}
          control={props.formMethods.control}
          defaultValue={props.defaultValue?.timestart}
          rules={{
            required: startTimeIsRequired,
          }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => {
            return (
              <DatePicker
                selected={stringHHmmToDate(value)}
                onChange={(date: Date) => {
                  onChange(date ? format(date, "HH:mm") : null);
                }}
                showTimeSelect
                showTimeSelectOnly
                locale="nl"
                timeIntervals={15}
                dateFormat="HH:mm"
                disabled={props.disabled || false}
                className={
                  "time " +
                  (get(
                    props.formMethods.formState.errors,
                    `${props.namespace}.timestart`,
                  ) && "fieldError")
                }
              />
            );
          }}
        />
      </div>
      <div>
        <Controller
          name={`${props.namespace}.timeend`}
          control={props.formMethods.control}
          defaultValue={props.defaultValue?.timeend}
          rules={{
            required: endTimeIsRequired,
          }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => {
            return (
              <DatePicker
                selected={stringHHmmToDate(value)}
                onChange={(date: Date) => {
                  onChange(date ? format(date, "HH:mm") : null);
                }}
                showTimeSelect
                showTimeSelectOnly
                locale="nl"
                timeIntervals={15}
                dateFormat="HH:mm"
                disabled={props.disabled || false}
                showDisabledMonthNavigation
                className={"time"}
              />
            );
          }}
        />
      </div>
      {!props.noStatus && (
        <div>
          <Controller
            name={`${props.namespace}.status`}
            control={props.formMethods.control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => {
              const currentSelection = SingleDateStatus.find(
                (item) => item.value === value,
              );
              return (
                <Select
                  className={`${classes.singleDateStatusSelect} ${
                    props.disabled ? classes.isDisabled : ""
                  }`}
                  value={currentSelection}
                  isDisabled={props.disabled || false}
                  isClearable
                  options={props?.overrideSingleDateOptions ?? SingleDateStatus}
                  menuPlacement={"auto"}
                  placeholder={`${t("form.select")}...`}
                  styles={{
                    menu: (provided: any, state) => {
                      return { ...provided, zIndex: 2 };
                    },
                  }}
                  onChange={(item: any) => {
                    onChange(item?.value || null);
                  }}
                />
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default WhenWidget;
